import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import * as service from '../../api/SmartLibraryService';
import {getClient,getErrorClient} from '../../utils/proxyFactory';
interface IEnsureValidPolicies { type: "ENSURE_VALID_POLICIES";  }
interface IEnsureValidPoliciesComplete { type: "ENSURE_VALID_POLICIES_COMPLETE"; payload:{ result:service.IValidationError } }
interface IEnsureValidPoliciesFailed { type: "ENSURE_VALID_POLICIES_FAILED"; payload:{ error:string } }
interface IEnsureValidTrialPolicies { type: "ENSURE_VALID_TRIAL_POLICIES";  }
interface IEnsureValidTrialPoliciesComplete { type: "ENSURE_VALID_TRIAL_POLICIES_COMPLETE"; payload:{ result:service.IValidationError } }
interface IEnsureValidTrialPoliciesFailed { type: "ENSURE_VALID_TRIAL_POLICIES_FAILED"; payload:{ error:string } }
interface IAddUserToTrial { type: "ADD_USER_TO_TRIAL";  }
interface IAddUserToTrialComplete { type: "ADD_USER_TO_TRIAL_COMPLETE"; payload:{ result:boolean } }
interface IAddUserToTrialFailed { type: "ADD_USER_TO_TRIAL_FAILED"; payload:{ error:string } }
export type VeracityActions = IEnsureValidPolicies | IEnsureValidPoliciesComplete | IEnsureValidPoliciesFailed | IEnsureValidTrialPolicies | IEnsureValidTrialPoliciesComplete | IEnsureValidTrialPoliciesFailed | IAddUserToTrial | IAddUserToTrialComplete | IAddUserToTrialFailed;
type TA = ThunkAction<void, IRootState, void, VeracityActions>;

export const veracityActions = {
    ensureValidPolicies: (): TA => async (dispatch,getState)  => {
        dispatch({ type: "ENSURE_VALID_POLICIES"});
        var client = await getClient(dispatch,getState());
        await client.validateVeracityProcess()
        .then(x => {       
            dispatch({ type: "ENSURE_VALID_POLICIES_COMPLETE", payload: {result :x}});               
        },
          async (reason) => {
            dispatch({ type: "ENSURE_VALID_POLICIES_FAILED", payload:{error:reason.message}});
            var client = await getErrorClient(dispatch,getState());     
            await client.logError( new service.ClientError({name:reason.message,message:JSON.stringify(reason), stack:reason.message,info:reason.message}));
          }
        );
    },
    ensureValidTrialPolicies: (): TA => async (dispatch,getState)  => {
      dispatch({ type: "ENSURE_VALID_TRIAL_POLICIES"});
      var client = await getClient(dispatch,getState());
      await client.validateTrialProcess()
      .then(x => {       
          dispatch({ type: "ENSURE_VALID_TRIAL_POLICIES_COMPLETE", payload: {result :x}});             
      },
        async (reason) => {
          dispatch({ type: "ENSURE_VALID_TRIAL_POLICIES_FAILED", payload:{error:reason.message}});
          var client = await getErrorClient(dispatch,getState());     
          await client.logError( new service.ClientError({name:reason.message,message:JSON.stringify(reason), stack:reason.message,info:reason.message}));
        }
      );
    },
    addUserToTrial: (): TA => async (dispatch,getState)  => {
      dispatch({ type: "ADD_USER_TO_TRIAL"});
      var client = await getClient(dispatch,getState());
      await client.addUserToTrialProcess()
      .then(x => {       
          dispatch({ type: "ADD_USER_TO_TRIAL_COMPLETE", payload: {result :x}});
          dispatch(veracityActions.ensureValidTrialPolicies());              
      },
        async (reason) => {
          dispatch({ type: "ADD_USER_TO_TRIAL_FAILED", payload:{error:reason.message}});
          var client = await getErrorClient(dispatch,getState());     
          await client.logError( new service.ClientError({name:reason.message,message:JSON.stringify(reason), stack:reason.message,info:reason.message}));
        }
      );
    },
}