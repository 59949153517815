import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";

interface ISetSearchDocumentBoxText { type: "SET_SEARCHDOCUMENTBOXTEXT"; payload: { searchDocumentBoxText: string; }; }

export type SearchDocumentBoxActions = ISetSearchDocumentBoxText;
type TA = ThunkAction<void, IRootState, void, SearchDocumentBoxActions>;

export const searchDocumentBoxActions = {
    onChangeSearchBox: (searchDocumentBoxText: string): TA => (dispatch) => {
        dispatch({
            type: "SET_SEARCHDOCUMENTBOXTEXT",
            payload: { searchDocumentBoxText }
        });
    }
}