import React from 'react';
// import styles from './Bookmarks.module.css';
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { provide } from "../../../store/provide";
import * as service from '../../../api/SmartLibraryService';
import { editionAndAmendedLong } from '../../../utils/documentHelpers';
import { DotIcon } from '../../Icons/DotIcon';
import { SingleBookmark } from './Bookmarks_SingleBookmark'
import { groupCommentsByFullReference } from '../../../utils/comments';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { DocumentCardElement } from '../../Documents/DocumentCard';
import { userNotesActions } from '../../../store/actions/userNotesActions';
import { ChevronIcon } from '../../Icons/Chevron';
import { sideBarActions } from '../../../store/actions/sideBarActions';
import styled from 'styled-components/macro';
const StyleDocumentBookmarkDiv = styled.div<{ isOpen: boolean }>`   
    background-color:  ${({ theme }) => theme.colors.background.white};
    margin-bottom:  ${({ theme }) => theme.margin.medium};
    margin-top:  ${({ theme }) => theme.margin.medium};
    margin-left: ${({ isOpen, theme }) => isOpen ? "0px" : theme.padding.small};
    border: ${({ theme }) => theme.borderWidth.thinnest + ` solid ` + theme.colors.border.neutral10};
    border-left: ${({ isOpen, theme }) => isOpen ? theme.borderWidth.thick + ` solid ` + theme.colors.border.cyanlight : theme.borderWidth.thinnest + ` solid ` + theme.colors.border.neutral10};  
   
`;
const StyleDocumentBookmarkHeaderDiv = styled.div`   
    display: flex;
    justify-content: space-between;  
    &:hover{
    background-color: ${({ theme }) =>  theme.colors.background.neutral05};
  

   }  
`;
const StyleDocumentLink = styled(Link)`   
       padding:  ${({ theme }) => theme.padding.medium + ' ' + theme.padding.large};
        width: 100%;
        &:hover{
        text-decoration:none;
    } 
`;
const StyleDocumentReferenceDiv = styled.div`   
    color:  ${({ theme }) => theme.colors.text.neutral60};
    font-size:  ${({ theme }) => theme.fonts.sizes.small};
`;

const StyleDocumentTitleDiv = styled.div`   
    font-family:${({ theme }) => theme.fonts.AvenirDemi}; 
    color:  ${({ theme }) => theme.colors.text.neutral80};
    font-size:  ${({ theme }) => theme.fonts.sizes.medium};
`;
const StyleDocumentEditionDiv = styled.div`   
    color:  ${({ theme }) => theme.colors.text.neutral80};
    font-size:  ${({ theme }) => theme.fonts.sizes.small};
`;


const StyleChevronContainerDiv = styled.div`   
    min-width: 32px;
    cursor: pointer;
    border-left: ${({ theme }) => theme.borderWidth.thinnest + ` solid ` + theme.colors.border.neutral10};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:${({ theme }) => theme.colors.background.neutral02};
    &:hover{
    background-color: ${({ theme }) =>  theme.colors.background.neutral05};
   }  
`;

const StyleBookmarkContainerDiv = styled.div`   
     border-top: ${({ theme }) => theme.borderWidth.thinnest + ` solid ` + theme.colors.border.neutral10};
    padding-left: ${({ theme }) =>  theme.padding.large};
    padding-right:${({ theme }) =>  theme.padding.large};
`;
class BookmarksForDocumentComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{


    toggleOpen = () => {

        if (this.props.openBookmarkDocument === this.props.document.document.configId + (this.props.document.document.revision + '')) {
            this.props.toggleOpenBookmarkDocument('');
        } else {
            this.props.toggleOpenBookmarkDocument(this.props.document.document.configId + (this.props.document.document.revision + ''));
        }

    }

    preferredRevisionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        var newPreferred = e.currentTarget.value;
        this.setState(s => ({ ...s, preferredRevision: +newPreferred }));
    }

    render = () => {
        const isOpen = this.props.document.document.configId + (this.props.document.document.revision + '') === this.props.openBookmarkDocument;
        const notesForRevision = this.props.sortedNotes ? this.props.sortedNotes.filter(x => x.configId === this.props.document.document.configId && x.revision === this.props.document.document.revision && x.bookmarkedId && x.htmlOrder > 0).sort((a, b) => a.globalOrder - b.globalOrder) : [];
        const bookmarksByFullReference = groupCommentsByFullReference(notesForRevision || []);
        return (
            <StyleDocumentBookmarkDiv data-cy="bookmark" data-configid={this.props.document.document.configId} key={this.props.document.document.configId} isOpen={notesForRevision.length > 0 && isOpen} >
                <StyleDocumentBookmarkHeaderDiv>

                    <StyleDocumentLink data-cy="bookmarkeddocument" onClick={this.toggleOpen} to={"/document/" + this.props.document.document.configId + "/" + this.props.document.document.revision}>
                        <StyleDocumentReferenceDiv>{this.props.outOfDateIndicatorsWithBookmarks && this.props.outOfDateIndicatorsWithBookmarks.find(indicator => indicator.configId === this.props.document.document.configId) && <DotIcon />} {this.props.document.document.documentReference}</StyleDocumentReferenceDiv>
                        <StyleDocumentTitleDiv>{this.props.document.document.title}</StyleDocumentTitleDiv>
                        <StyleDocumentEditionDiv>Edition: {editionAndAmendedLong(this.props.document)}</StyleDocumentEditionDiv>
                    </StyleDocumentLink>

                    {notesForRevision.length > 0 &&
                        <StyleChevronContainerDiv data-cy="bookmarkeddocumenttoggle" onClick={this.toggleOpen} >
                            <ChevronIcon direction={isOpen ? "Up" : "Down"} expanded={false} />
                        </StyleChevronContainerDiv>}
                </StyleDocumentBookmarkHeaderDiv>

                {notesForRevision && isOpen &&  notesForRevision.length > 0 &&
                    <StyleBookmarkContainerDiv>
                        {Array.from(bookmarksByFullReference.entries()).map(([key, arr]) => {
                            const sortedByHtmlOrder = arr.sort((a, b) => a.htmlOrder - b.htmlOrder)
                            if (arr.length === 1) {
                                return <SingleBookmark key={sortedByHtmlOrder[0].bookmarkId} bookmark={sortedByHtmlOrder[0]} document={this.props.document} forceShowNoteIcon={false} />
                            }
                            else {
                                return <SingleBookmark key={sortedByHtmlOrder[0].bookmarkId} bookmark={sortedByHtmlOrder[0]} document={this.props.document} forceShowNoteIcon={sortedByHtmlOrder.find(x => x.customText) !== undefined} />
                            }
                        })
                        }
                    </StyleBookmarkContainerDiv>}
            </StyleDocumentBookmarkDiv>)


    }

};

const provider = provide((state) => ({
    openBookmarkDocument: state.sideBar.openBookmarkDocument,
}), { ...userNotesActions, ...sideBarActions }).withExternalProps<{ document: DocumentCardElement, sortedNotes: service.IBookmarkDto[] | null, outOfDateIndicatorsWithBookmarks: service.LastRevisionForUserIndicatorDto[] | null }>();

type Props = typeof provider.allProps;
export const BookmarksForDocument = provider.connect(withRouter(BookmarksForDocumentComponent));

