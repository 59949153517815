import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
interface ISetScrollPosition { type: "SET_SCROLL_POSITION"; payload:{path:string,offset:number} }
export type ScrollMemoryActions = ISetScrollPosition;
type TA = ThunkAction<void, IRootState, void, ScrollMemoryActions>;

export const scrollMemoryActions = {
    setScrollPosition: (path:string,offset:number): TA => async (dispatch)  => {
        dispatch(({type: "SET_SCROLL_POSITION",payload:{path:path,offset:offset}}));        
    }
}