export const scrolltoId = (id: string):boolean => {
    // console.log("Scrolling to element " + id);
    var el = document.getElementById(id);
    return scrolltoElement(el);
}

export const scrollToCorrection = -170;
export const scrolltoElement = (el: Element | null):boolean => {
    if (el != null) {
        // console.log("Scrolling to element ");
        // console.log(el);
        // console.log("Scrolling to position " + (el.getBoundingClientRect().top-50));
        window.scrollTo(0, window.pageYOffset + el.getBoundingClientRect().top+scrollToCorrection);
        return true;
        //el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
        return false;
        
    }
}

export const scrollParentToChild = (parent: Element, child: Element) => {

    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);

    // if you can't see the child try to scroll parent
    if (!isViewable) {
        // scroll by offset relative to parent
        parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
    }
}