import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import { getClient } from '../../utils/proxyFactory';
import { ApiException, DocumentWithLocalToc} from '../../api/SmartLibraryService';

interface ILoadDocument { type: "LOAD_DOCUMENT"; }
interface ILoadDocumentMounted { type: "LOAD_DOCUMENT_MOUNTED"; }
interface ILoadDocumentPaymentRequired { type: "LOAD_DOCUMENT_PAYMENT_REQUIRED"; }
interface ILoadDocumentClearErrors { type: "LOAD_DOCUMENT_CLEAR_ERRORS"; }
interface ILoadDocumentComplete { type: "LOAD_DOCUMENT_COMPLETE"; payload: DocumentWithLocalToc }
interface ILoadDocumentFailed { type: "LOAD_DOCUMENT_FAILED"; payload: { error: string } }
interface ILoadDocumentNavigationMode { type: "SET_NAVIGATION_MODE"; payload: {mode:NavigationMode} }
interface ISetEloquaTracking { type: "SET_ELOQUA_TRACK"; payload:{page: string} }


export type NavigationMode ="TOC"|"BOOKMARKS"|"EDIT_BOOKMARKS";

export type DocumentActions = ILoadDocument | ILoadDocumentComplete | ILoadDocumentFailed | ILoadDocumentMounted | ILoadDocumentPaymentRequired |ILoadDocumentClearErrors | ILoadDocumentNavigationMode|ISetEloquaTracking;
type TA = ThunkAction<void, IRootState, void, DocumentActions>;

export const documentActions = {
  requestDocument: (configId: string, revision: number, highlightTerm: string, filtered: boolean, issued: string | null): TA => async (dispatch, getState) => {
    dispatch({
      type: "LOAD_DOCUMENT"
    });

    var client = await getClient(dispatch,getState());
    await client.getDocument(configId, revision, highlightTerm, filtered, issued)
      .then(x => {
        
        if (x != null) {
          dispatch({ type: "LOAD_DOCUMENT_COMPLETE", payload: x })
        } else {
          dispatch({ type: "LOAD_DOCUMENT_FAILED", payload: { error: "Toc load failed" } })
        }
      },
        (x: ApiException) => {
          if(x.status === 402)
          {
            dispatch({ type: "LOAD_DOCUMENT_PAYMENT_REQUIRED"})
          }else{
            dispatch({ type: "LOAD_DOCUMENT_FAILED", payload: { error: x.message } })
          }
          
        }
      );
    // }else{
    //   dispatch({ type: "LOAD_DOCUMENT_FAILED", payload: { error: "no permission" } })
    // }


  },
  documentMounted: (): TA => (dispatch) => {
    dispatch({ type: "LOAD_DOCUMENT_MOUNTED" })
  },
  documentClearErrors:():TA=>(dispatch)=>{
    dispatch({type:"LOAD_DOCUMENT_CLEAR_ERRORS"})
  },
  navigationModeToc:():TA=>(dispatch)=>{
    dispatch({type:"SET_NAVIGATION_MODE",payload:{mode:"TOC"}})
  }, 
  navigationModeBookmarks:():TA=>(dispatch)=>{
    dispatch({type:"SET_NAVIGATION_MODE",payload:{mode:"BOOKMARKS"}})
  }, 
  navigationModeEditBookmarks:():TA=>(dispatch)=>{
    dispatch({type:"SET_NAVIGATION_MODE",payload:{mode:"EDIT_BOOKMARKS"}})
  },
  eloquatracking:(pageID:string):TA=>(dispatch)=>{
    dispatch({type:"SET_ELOQUA_TRACK", payload:{page:pageID}})
  }

}