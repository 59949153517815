import { Reducer } from "redux";
import { FilterActions } from "../actions/filterActions";
import { SearchActions } from "../actions/searchActions";
import { Audience, WithdrawnType } from "../../api/SmartLibraryService";
import * as service from '../../api/SmartLibraryService';

import { flattenDocsInCategories } from "../../utils/documentHelpers";

export interface IDocumentFilters {
    category: string | null;
    subCategory: string | null;
    domainId: Audience | null;
    openChevronCategory: string | null;
    openChevronSubCategory: string | null;
    favouriteFilter:boolean;
    issuedDate: string |null;
    selectedYear: string | null;
    selectedMonth : string | null;
    retiredType: WithdrawnType | null;
    replacedType: WithdrawnType | null;
    isReplaced:boolean;
    isRetired:boolean;
    isFiltered:boolean;
    isFilterinScope:boolean;
}

export const filterReducer: Reducer<IDocumentFilters, FilterActions | SearchActions>
    = (state = { category: " ", subCategory: null, domainId: null, openChevronCategory: " ", openChevronSubCategory: null,favouriteFilter:false, issuedDate:null,selectedYear:null,selectedMonth:null, retiredType:null, replacedType: null, isReplaced:true, isRetired:true, isFiltered:false, isFilterinScope:false }, action) => {

        switch (action.type) {

           
            case 'SET_FILTER_CLEAR': {
                return { category: " ", subCategory: null, domainId: null, openChevronCategory: " ", openChevronSubCategory: null,favouriteFilter:false,issuedDate:null,selectedYear:null, selectedMonth:null, retiredType:null, replacedType:null, isReplaced:true , isRetired:true, isFiltered:false, isFilterinScope:false}
            }
            case 'SET_CATEGORY_CLEAR': {
                return {...state, category: " ", openChevronCategory: " "}
            }
            case 'SET_FILTER_FAVOURITE':{
                return   { ...state, favouriteFilter: !state.favouriteFilter };
            }
            case 'SET_FILTER_DOMAIN': {
                if (action.payload.item !== state.domainId) {

                    return { ...state, domainId: action.payload.item };
                } else {

                    return { ...state, domainId: null };
                }
            }

            case 'SET_FILTER_ISSUEDDATE':{
                return {...state, issuedDate:action.payload.issuedDate, isFiltered:true};
            }

            case 'SET_FILTER_SELECTEDYEAR':{
                return {...state, selectedYear:action.payload.selectedYear,isFiltered:true};
            }

            case 'SET_FILTER_SELECTEDMONTH':{
                return {...state, selectedMonth:action.payload.selectedMonth, isFiltered:true};
            }

            case 'SET_FILTER_RETIREDREPLACED': {
                    return { ...state, retiredType: action.payload.itemRetired, replacedType: action.payload.itemReplaced, isFiltered:true };
            }

            case'SET_FILTER_ISREPLACED':{
                return{...state, isReplaced: action.payload.isReplaced, isFiltered:true}
            }

            case'SET_FILTER_ISRETIRED':{
                return{...state, isRetired: action.payload.isRetired, isFiltered:true}
            }
            // case 'LOAD_FiLTER_LIST':{
            //     return {...state, list:listofDoc(action.payload)}

            // }
            case 'SET_FILTER_INSCOPE':{
                return{...state, isFilterinScope:action.payload.isFilterinScope, isFiltered:true}
            }
            case 'SET_FILTER_CATEGORY': {
                return {
                    ...state, category: action.payload.category, subCategory: action.payload.subCategory,
                    openChevronCategory: action.payload.category, openChevronSubCategory: action.payload.subCategory
                };
            }

            case 'SET_OPEN_CHEVRON': {
                if (state.openChevronCategory === action.payload.category && !action.payload.subCategory)
                return { ...state, openChevronCategory: null, openChevronSubCategory: null }
                if (state.openChevronCategory === action.payload.category && state.openChevronSubCategory === action.payload.subCategory)
                    return { ...state, openChevronCategory: action.payload.category, openChevronSubCategory: null }
               
                return {
                    ...state,
                    openChevronCategory: action.payload.category,
                    openChevronSubCategory: action.payload.subCategory
                };
            }
            default:
                break;
        }

        return state;
    };