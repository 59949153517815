import * as React from "react";
// export const topBell = "Subscribe to notifications";
// export const topFeedback = "Give us feedback";
// export const topUserIcon = "Log out";

// export const universalSearchExecute ="Search";
// export const universalSearchClear ="Clear search";
// export const universalSearchPlaceholder ="Search for word, document code...";

export const leftSidebarMinimize = "Collapse panel";
export const leftSidebarMaximize = "Expand panel";
// export const leftSidebarDocuments = "All documents";
// export const leftSidebarBookmarks = "My bookmarks";

export const filterMaritime ="Filter Maritime";
export const filterOG ="Filter Oil & Gas"; 
export const filterEnergy = "Filter Energy";
// export const filterCurrentSearch= "Clear search";

export const navigationTreeBookmark ="An edition of this document has been bookmarked";

// export const leftSideBarDocumentbookmarkExpand="Expand";
// export const leftSideBarDocumentbookmarkCollape="Collapse";
export const leftSideBarDocumentbookmarkNoteIndicator="You have a note on this item";

export const metadataNotBookmarked ="Click to bookmark";
export const metadataUnbookmarkValid ="Click to remove bookmark"
export const metadataUnbookmarkInValid ="You must remove all bookmarks within the document to remove this bookmark"
// export const metdataDropdown = "Navigate to another edition";
// export const metdataPdfDownload = "Download in PDF format";

 export const infoNotLatestVersion = "This is not the latest edition of this document. Links to other documents are inactive";

// export const documentInternalSearchExecute = "Search"
// export const documentInternalSearchClear = "Clear search"
export const documentInternalSearchNext = "Next result"
export const documentInternalSearchPrevious = "Previous result"

// export const rightNavigationToc="Table of contents"
export const rightNavigationBookmarks="My bookmarked content"
export const rightNavigationBookmarksEmpty="No bookmarks in this edition";
export const rightNavigationBookmarksNewEdition="To copy your bookmarks from the #PREV edition, press copy. Your bookmarks will still be available in the previous edition. Bookmarks that cannot be linked to this edition will be listed in the broken references section."
export const rightNavigationBookmarksNewEditionTitle="Copy bookmarks/notes to this edition"
export const rightNavigationBookmarksNewerEditionReplacedRetired="This document has been replaced or retired. Your bookmarks/notes in this edition will be kept"
export const rightNavigationBookmarksNotSeenNewEdition="This document has been released in a new edition. Navigate to the new edition to copy your bookmarks/notes"
export const rightNavigationBookmarksBroken="Bookmarks/notes copied from the previous edition that cannot be linked to this edition due to restructuring of the content."
export const rightNavigationBookmarksRetiredOrReplaced:JSX.Element=<>This document has been replaced or retired.<br/> Navigate to a previous edition to see your bookmarks/notes</>;
export const rightNavigationBookmarksNext="Next bookmark"
export const rightNavigationBookmarksPrev="Previous bookmark"
export const rightNavigationBookmarksNoteAdd="Add note"
export const rightNavigationBookmarksNote="You have a note on this item"