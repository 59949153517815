import * as React from 'react';
import { provide } from "../../../store/provide";
import { scrolltoId } from '../../../utils/scroll';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { userNotesActions } from '../../../store/actions/userNotesActions';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { TrashIcon } from '../../Icons/TrashIcon';
import styles from './DocumentMap_Bookmark.module.scss';
import { NoteIcon } from '../../Icons/NoteIcon';
import { PrimaryButton } from '../../Styled/Buttons/PrimaryButton';
import { SubtleButton } from '../../Styled/Buttons/SubtleButton'
import * as service from '../../../api/SmartLibraryService';
import { PenIcon } from '../../Icons/PenIcon';
import * as tooltips from '../../../utils/tooltips';
import { DocumentMapBookmarkEdit } from './DocumentMap_BookmarkEdit';
import styled from 'styled-components/macro';
type IBookmarkState = {
    css: string,
    isDeleting: boolean
}

const StyledDeleteBookmarkContainer = styled.div`

    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.border.neutral20};
    border-left: 6px solid  ${({ theme }) => theme.colors.border.cyanlight};   
   padding: 16px 0px;
`

const StyledDeleteBookmarkHeader = styled.div`
   font-size: ${({theme}) => theme.fonts.sizes.medium};
    font-weight: bold;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.neutral10};
    padding:0px 24px;
`
const StyledDetailText = styled.div`
   
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.neutral10};
    padding:16px 24px;
`
const StyledDeleteControlsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 2.5%;
    margin-left: 2.5%;
    padding:16px 24px 0px 24px;
`

const StyledBookmarkContainerDiv = styled.div<{ $isHighlighted: boolean }>`
    border: ${({ $isHighlighted,theme }) => $isHighlighted ? "1px solid " + theme.colors.border.cyanlight : "1px solid " + theme.colors.border.neutral20};
    background-color: ${({ $isHighlighted,theme }) => $isHighlighted ? theme.colors.background.skyebluelightest : "white"};
    border-left:  ${({ $isHighlighted,theme }) => $isHighlighted ? "5px solid " + theme.colors.border.cyanlight  : "1px solid " + + theme.colors.border.neutral20};
    margin-top: 16px;
    margin-bottom: 16px;
    padding:   ${({ theme }) => theme.padding.medium};
    &:hover{
            ${({ $isHighlighted, theme}) => ! $isHighlighted? "background-color:" + theme.colors.background.neutral05:""};
        }   
`



const StyledBookmarkRowDiv = styled.div`
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;   
   
`;

const StyledBookmarkContorlsContainer = styled.div<{ $isHighlighted: boolean }>`
    display: flex; 
    justify-content: space-between;
    visibility: ${({ $isHighlighted }) => $isHighlighted ? "visible" : "hidden"};
    ${/* sc-declaration */ StyledBookmarkRowDiv}:hover & {
        visibility: visible;
    }
`
const StyledCustomTextDisplayDiv = styled.div`
    border-radius: 10px;
    background-color: white;
    margin: 8px;
    padding: 0.5rem 1rem;
    border: 2px solid white;
`

const StyledLink = styled(Link)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 8px;
    width:100%;
    &:hover{
        text-decoration:none; 
    }   
`

const StyledTrashIcon = styled(TrashIcon)`
    fill: ${({theme}) => theme.colors.icon.digitalblue};
    cursor: pointer;
    margin-right: 1.5rem;
   
`

const StyledIndicatorNoteIcon = styled(NoteIcon)`
    fill: ${({theme}) => theme.colors.icon.digitalblue};
    height: 0.9375rem;
    width: 0.9375rem;
    visibility:visible !important;
    cursor:pointer;
   
`
const StyledAddNoteIcon = styled(NoteIcon)`
    height: 0.9375rem;
    width: 0.9375rem;
    fill: ${({theme}) => theme.colors.icon.digitalblue};
    cursor:pointer;
   
`

class DocumentMapBookmarkComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>, IBookmarkState> {
    state = { css: "", isDeleting: false };


    private refTextArea = React.createRef<HTMLTextAreaElement>();


    renderTopRightElement = (bookmark: service.IBookmarkDto) => {
        if (this.props.userNotes.highlightBookmark === bookmark.bookmarkId && this.props.userNotes.editNote === bookmark.bookmarkId) {
            return <PenIcon title="Edit note" style={{ cursor: "pointer", fill: "rgba(0, 103, 197, 0.5)", height: "15px" }} />
        } if (this.props.userNotes.highlightBookmark === bookmark.bookmarkId && bookmark.customText) {
            return <PenIcon data-cy="edit-existing-note" onClick={() => this.props.editBookmark(bookmark.bookmarkId)} style={{ cursor: "pointer", fill: "rgb(0, 103, 197)", height: "15px" }} />
        } else if (bookmark.customText) {
            return <StyledIndicatorNoteIcon data-cy="edit-note" title={tooltips.rightNavigationBookmarksNote} onClick={() => this.props.editBookmark(bookmark.bookmarkId)} />
        } else {
            return <StyledAddNoteIcon data-cy="add-note" title={tooltips.rightNavigationBookmarksNoteAdd} onClick={() => this.props.editBookmark(bookmark.bookmarkId)} />
        }
    }
    closeBookmarkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.props.editBookmark("");
        this.props.highlightBookmark(this.props.bookmark.bookmarkId);
    }

    bookmarkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.props.highlightBookmark(this.props.bookmark.bookmarkId);
        scrolltoId(this.props.bookmark.bookmarkedId || '');
    }

    deleteClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (this.props.bookmark.customText) {
            this.props.requestDeleteUserNote(this.props.bookmark.bookmarkId);
        } else {
            this.delete();
        }
    }


    delete = () => {
        this.setState(s => ({ ...s, css: styles.slideFade, isDeleting: true }));
        setTimeout(() => this.props.deleteUserNote(this.props.bookmark.bookmarkId), 400);
    }
    deleteClickNoConfirmation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.delete();

    render() {
        if (this.props.userNotes.requestDeleteBookmarkId === this.props.bookmark.bookmarkId) {
            return (
                <StyledDeleteBookmarkContainer>
                    <StyledDeleteBookmarkHeader>Delete bookmark with note</StyledDeleteBookmarkHeader>
                    <StyledDetailText>Are you sure you want to delete this bookmark along with its note?</StyledDetailText>
                    <StyledDeleteControlsContainer>
                        <SubtleButton data-cy="cancel-note-delete" onClick={() => { this.props.requestDeleteUserNote(''); }} >No</SubtleButton>
                        <PrimaryButton data-cy="confirm-note-delete" style={{ marginLeft: "8px" }} onClick={this.deleteClickNoConfirmation} >Yes</PrimaryButton>
                    </StyledDeleteControlsContainer>
                </StyledDeleteBookmarkContainer>)
        }
        const isHighlighted = this.props.userNotes.highlightBookmark === this.props.bookmark.bookmarkId;
        const isEditingBookmark =
            this.props.userNotes.requestDeleteBookmarkId !== this.props.bookmark.bookmarkId //Not deleting
            && isHighlighted && //Highligted
            this.props.userNotes.editNote === this.props.bookmark.bookmarkId; //And in editmode

        const theLink = "/document/" + this.props.match.params.configId + "/" + this.props.match.params.revision + "#" + this.props.bookmark.bookmarkedId || '';
        return (
            <StyledBookmarkContainerDiv
                $isHighlighted={isHighlighted}
                key={this.props.bookmark.bookmarkId}
                className={this.state.css}
                data-bookmark-id={this.props.bookmark.bookmarkId}>
                <StyledBookmarkRowDiv>
                    <StyledLink data-cy={"htmlOrder-" + this.props.bookmark.htmlOrder} title={this.props.bookmark.fullReferenceWithoutDocument + (this.props.bookmark.renderedTitleText ? " " + this.props.bookmark.renderedTitleText : "")} onClick={this.bookmarkClick} to={theLink}>{this.props.bookmark.fullReferenceWithoutDocument !== null ? (parseInt(this.props.bookmark.edition ? this.props.bookmark.edition : "") > 202104 ? this.props.bookmark.fullReferenceWithoutDocument?.substring(0) : this.props.bookmark.fullReferenceWithoutDocument) + " " + this.props.bookmark.renderedTitleText : "Broken"}</StyledLink>
                    <StyledBookmarkContorlsContainer $isHighlighted={isHighlighted}>
                        {<StyledTrashIcon title="Delete bookmark and note." data-cy="deletebookmark" onClick={this.deleteClick} />}{this.props.document && this.renderTopRightElement(this.props.bookmark)}
                    </StyledBookmarkContorlsContainer>
                </StyledBookmarkRowDiv>
                {isEditingBookmark ? <DocumentMapBookmarkEdit isDeleting={this.state.isDeleting} bookmark={this.props.bookmark} />
                    : isHighlighted && this.props.bookmark.customText &&
                    <StyledCustomTextDisplayDiv >{this.props.bookmark.customText}</StyledCustomTextDisplayDiv>
                }
            </StyledBookmarkContainerDiv>
        );
    }
}

const provider = provide((state) => ({
    userNotes: state.userNotes,
    document: state.document.document

}), { ...userNotesActions }).withExternalProps<{ bookmark: service.IBookmarkDto }>();

type Props = typeof provider.allProps;
export const DocumentMapBookmark = provider.connect(withRouter(DocumentMapBookmarkComponent));