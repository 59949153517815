
import { InfoCircleIcon } from '../../Icons/InfoCircleIcon';
 import styled from 'styled-components/macro';
const StyledInformationBoxContainer = styled.div`
    color: ${({theme}) => theme.colors.text.neutral80};
    background: ${({theme}) => theme.colors.background.skyebluelightest}; 
    border-color: ${({theme}) => theme.colors.border.cyanlight};
    border-width: ${({theme}) => theme.borderWidth.thinnest};
    border-style: solid;
    display: flex;
    font-size: 0.875rem;
    line-height:normal;
    padding:8px;
`;

const StyledContentContainer = styled.div`
    margin-left: ${({theme}) => theme.margin.medium};
    margin-right: ${({theme}) => theme.margin.medium};
    padding-top: ${({theme}) => theme.padding.medium};
    padding-bottom: ${({theme}) => theme.padding.medium};
    white-space: nowrap;
    display: flex;
`

const StyledIconContainer = styled.div`
    width: 1rem;
    height: 1rem; 
    
`

const StyledChildrenContainer = styled.div`
    padding-left: 0.75rem;
    display: flex;
 
    white-space: normal;
    padding-right: 0.75rem;
`
export const InformationBox: React.FunctionComponent<{}> = (props) => (

    <StyledInformationBoxContainer>
        <StyledContentContainer>
            <StyledIconContainer><InfoCircleIcon style={{width:"1rem", height:"1rem"}} /></StyledIconContainer>
            <StyledChildrenContainer>
                {props.children}
            </StyledChildrenContainer>
        </StyledContentContainer>
    </StyledInformationBoxContainer>)