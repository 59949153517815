import { Reducer } from "redux";
import { HighlightActions } from "../actions/highlightActions";

interface IHighlightState {
    enabled: boolean;
    highlightIndex: number; 
    count: number;   
    highlightText:string;
    autoScroll:boolean;
  }
export const highlightReducer: Reducer<IHighlightState, HighlightActions>
    = (state = { enabled:false,highlightIndex:-1,count:0,highlightText:"",autoScroll:false}, action) => {

        switch (action.type) {
            case "SET_HIGHTLIGHT_ENABLED": {                
                return { ...state,enabled:action.payload.enabled};
            }
            case "SET_HIGHTLIGHT_INDEX":{
                return { ...state,highlightIndex:action.payload.index}
            }  
            case "SET_HIGHTLIGHT_COUNT":{
                return { ...state,count:action.payload.count,highlightIndex:-1}
            }   
            case "SET_HIGHTLIGHT_TEXT":{
                return { ...state,highlightText:action.payload.highlightText}
            }
            case "SET_HIGHTLIGHT_AUTOSCROLL":{
                return { ...state,autoScroll:action.payload.enabled}
            }
            default:
                break;
        }

        return state;
    };