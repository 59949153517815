import React from 'react';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { provide } from "../../../store/provide";
import { DocumentCardElement } from '../../Documents/DocumentCard';
import * as service from '../../../api/SmartLibraryService';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import {BookmarksForDocument} from './Bookmarks_Document'
import { TutorialBookmarks} from '../../InfoBox/TutorialBookmarks'
import styled from 'styled-components/macro';
const StyledLeftBookmarksDiv = styled.div`   
    padding: ${({ theme }) =>  theme.padding.large};     
`;
const StyledLeftBookmarksHeadingDiv = styled.div`   
    padding-left: ${({ theme }) =>  theme.padding.large};     
    padding-bottom: ${({ theme }) =>  theme.padding.large};     
    font-size: ${({ theme }) =>  theme.fonts.sizes.large};     
    font-weight: ${({ theme }) =>  theme.fonts.weights.bold};     
`;

class BookmarksComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{
    render = () => {
        const sortedNotes = this.props.userNotes.notes ? this.props.userNotes.notes.sort((a, b) => (a.configId || '').localeCompare(b.configId || '')) : null;
        const configIdRevisionWithNotes = new Set(sortedNotes != null ? sortedNotes.map(x => (x.configId ||'') + (x.revision || '')) : ['']);
        const useCurrentCardForDetachedNotes = new Set(sortedNotes && sortedNotes.filter(x=>this.props.documentCards.find(y=>y.document.configId === x.configId && y.document.revision === x.revision) === undefined).map(x=>x.configId));

        // editionWithNotes -> bookmark only HTML and isCurrent documents
        //const editionWithNotes  = this.props.documentCards.filter(x => (configIdRevisionWithNotes.has((x.document.configId || '') +  (x.document.revision || '')) || (useCurrentCardForDetachedNotes.has(x.document.configId) && x.document.isCurrent)) && (x.document.revision > 0 || x.document.isCurrent));        

        // allbookmark -> irrespective of isCurrent can now bookmark any document since we have filter on edition date.
        const allbookmark  = this.props.documentCards.filter(x => (configIdRevisionWithNotes.has((x.document.configId || '') +  (x.document.revision || '')) || (useCurrentCardForDetachedNotes.has(x.document.configId) )));        
        return (
            <StyledLeftBookmarksDiv data-cy="leftbookmarks">
                    <StyledLeftBookmarksHeadingDiv>My bookmarks</StyledLeftBookmarksHeadingDiv>
                    {this.props.userNotes.isFetching ? <div>Loading</div>
                        : sortedNotes  &&
                        allbookmark.length > 0?
                        allbookmark
                            .map(doc => <BookmarksForDocument key={doc.document.configId + (doc.document.revision + '')} document={doc} sortedNotes={sortedNotes} outOfDateIndicatorsWithBookmarks={this.props.outOfDateIndicatorsWithBookmarks}></BookmarksForDocument>)
                                
                            :<TutorialBookmarks/>
                        }
            </StyledLeftBookmarksDiv>
        )
    }

};

const provider = provide((state) => ({
    userNotes: state.userNotes,
}), { }).withExternalProps<{ documentCards: DocumentCardElement[], outOfDateIndicatorsWithBookmarks: service.LastRevisionForUserIndicatorDto[] | null }>();
type Props = typeof provider.allProps;
export const Bookmarks = provider.connect(withRouter(BookmarksComponent));

