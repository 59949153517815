import { Reducer } from "redux";
import { SearchActions } from "../actions/searchActions";
import * as service from '../../api/SmartLibraryService';
import {FilterActions} from "../actions/filterActions";
export interface ISearchContext {
    searchResult: service.IResult | null;
    isFetching: boolean;    
    error:string | null;
    query:string | null;
  }
export const searchReducer: Reducer<ISearchContext, SearchActions>
    = (state = { searchResult:null,isFetching:false,error:null,query:null }, action) => {

        switch (action.type) {
            case "EXECUTE_SEARCH": {                
                return { ...state,isFetching:true,query:action.payload.query };
            }
            case "EXECUTE_SEARCH_COMPLETE":{
                return { ...state,isFetching:false,error:null,searchResult:action.payload}
            }   
            case "EXECUTE_SEARCH_FAILED":{
                return { ...state,isFetching:false,error:action.payload.error,searchResult:null}
            } 
            case "SET_SEARCH_CLEAR":{
                return { ...state, isFetching:false, error:null,searchResult:null, query:null };
            }
            default:
                break;
        }

        return state;
    };