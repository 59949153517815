import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './_fonts.scss';
import { BrowserRouter } from "react-router-dom";
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { getStore } from "./store/store";
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components/macro';
import GlobalStyles from './styles/global-styles';
import {Theme} from './styles/theme';
import { ErrorBoundary } from './Components/ErrorHandler/ErrorBoundary';
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication} from "@azure/msal-browser";
import 'antd/dist/antd.css';

var authConfig = (window as any).authConfig;
const tagManagerArgs = {
  gtmId: authConfig.gtm_id !== "#{GTMID}" ? authConfig.gtm_id : "GTM-WSFZP44",//This value (GTM-WSFZP44) is just an account to not get errors, no use whatsoever,
  // dataLayer : {internaluser : "unkown"} //Need to declare the internaluser as a variable, to be able to set it later
}

TagManager.initialize(tagManagerArgs);
for ( var len = window.localStorage.length; len >=0; len-- ) {
  const key = window.localStorage.key(len);
  if(key && key.startsWith("oidc")){
    window.localStorage.removeItem(key);    
  }
}
//Prevent double click selecting text
document.addEventListener('mousedown', function (event) {
  if (event.detail > 1) {
    event.preventDefault();
    // of course, you still do not know what you prevent here...
    // You could also check event.ctrlKey/event.shiftKey/event.altKey
    // to not prevent something useful.
  }
}, false);
const store = getStore();
 
// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: authConfig.client_id !== '#{Authorization:ClientId}'?authConfig.client_id :'51c4f1f1-30c5-416b-b235-1929fba1cc61', 
    authority:'https://login.veracity.com/tfp/a68572e3-63ce-4bc1-acdc-b64943502e9d/B2C_1A_SignInWithADFSIdp',  
    knownAuthorities:["login.veracity.com"],
    postLogoutRedirectUri:"https://www.veracity.com/auth/logout",    
    redirectUri:  `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${process.env.PUBLIC_URL}/`
  },
  //  cache: {
  //     cacheLocation: "session", // This configures where your cache will be stored    
  //     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge    

  // },
  // system:{
  //   loggerOptions:{
  //     piiLoggingEnabled:true,
  //     logLevel: LogLevel.Verbose,
  //     loggerCallback:(level,message,containsPii)=> console.log(message)
  //   }
  // }
};

const pca = new PublicClientApplication(configuration);
ReactDOM.render(<Provider store={store}>
  <ErrorBoundary>
    <MsalProvider instance={pca}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App/>          
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  </ErrorBoundary>

  {/* </MsalProvider> */}

</Provider>, document.getElementById('root'));
// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();