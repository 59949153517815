
import * as React from 'react';
import { ErrorMessage } from './ErrorMessage'
import { ExclamationCircleIcon } from '../Icons/ExclamationCircleIcon';
import { Link } from "react-router-dom";
export const ErrorMessageDocumentNotFound = () =>
    <ErrorMessage
        icon={<ExclamationCircleIcon style={{ width: "6.25rem", height: "6.25rem" }} />}
        message={<><div style={{fontSize:"1.25rem"}}>Sorry, we couldn't find that document</div>
            <div style={{ marginTop: "1.875rem" }}>The document you're searching for doesn't exist anymore, either it has been moved or the link is broken.</div>

            <div style={{ marginTop: "1.875rem" }}><Link to={"/"}>Go to homepage</Link></div>
        </>}
    />