// @flow
import React from 'react';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { provide } from "../../store/provide";
import { scrollMemoryActions } from '../../store/actions/scrollMemoryActions';
import {
    isBrowser,
    getScrollPage,
    scrollTo,
} from "./utils/utils";

type ScrollState = {

    location: any;
}

class ScrollMemoryComponent extends React.Component<Props & RouteComponentProps, ScrollState> {

    state = { location: null };
    componentDidMount(): void {
        window.addEventListener("popstate", this.detectPop);
    }


    componentWillUnmount(): void {
        window.removeEventListener("popstate", this.detectPop);
    }

    shouldComponentUpdate(nextProps: Props & RouteComponentProps): boolean {
        if (!isBrowser()) return false;
        const { location } = this.props;
        // location before change url
        const actual = location;
        // location after change url
        const next = nextProps.location;

        if (next.pathname !== actual.pathname) {
            // get scroll of the page
            //console.log("Setting scroll: " + next.pathname + " " + actual.pathname + " " + window.pageYOffset)
            const scroll = getScrollPage();
            this.props.setScrollPosition(location.key || "enter", scroll);
        }
        //console.log("shouldComponentUpdate " + this.state.location)
        if (!this.props.document.mounted && nextProps.document.mounted) {
            if (this.state.location) {
              
                //console.log("trying to find last scroll position " + this.state.location)
                const nextFind = this.props.scrollMemory.locationsWithScoll.filter(x => x.path === this.state.location);
                // console.log(nextFind);
                // if find in url map => scroll to position
                if (nextFind.length > 0) {                   
                    //console.log("scrolling to next find " + nextFind[0].offset);
                    scrollTo(nextFind[0].offset);
                }
                this.setState({ location: null });
            } else {
              //  window.scrollTo(0, 0);
            }
        }


        // never render
        return false;
    }

    /**
     * callback for event popstate
     *
     * @memberof ScrollMemory
     */
    detectPop = (location: any) => {

        if (!isBrowser()) return;

        const { state } = location;
        // key or enter page
        const key = state ? state.key : "enter";
        this.setState({ location: key });
        // get the next for scroll position

    }

    render() {
        return null;
    }
}
const provider = provide((state) => ({
    scrollMemory: state.scrollMemory,
    document: state.document
}), { ...scrollMemoryActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const ScrollMemory = provider.connect(withRouter(ScrollMemoryComponent));
