import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { StyledMenu, StyledMenuHeader, StyledMenuName, StyledMenuDivider, StyledMenuAction } from '../../Components/Styled/ContextMenu/ContextMenu'
import { useSelector, useDispatch } from "react-redux"
import { SilentRequest, AuthenticationResult } from '@azure/msal-browser';
import { toast } from 'react-toastify';
import { getFullName, getInitials } from '../../utils/user'; //, getExpireDateTime, getNotBeforeTime, getIssuedAt, getAuthTime



const StyledProfile = styled.div<{$active:boolean}>`
height: 1.875rem;
width: 1.875rem;
display: flex;
justify-content: space-around;
align-items: center;
background-color: ${({ theme }) => theme.colors.background.darkblue};
color: ${({ theme }) => theme.colors.text.white};
font-weight: 400;
border-radius: 1.1563rem;
font-size: 0.75rem;
letter-spacing: -0.025rem;
text-align: center;
text-transform: uppercase;
overflow: hidden;
${({$active,theme}) => $active?"border: 2px solid " +  theme.colors.border.cyanlight:""}; 
&:hover{
  border-color: ${({ theme,$active }) =>$active?  theme.colors.border.cyanlight: theme.colors.border.neutral05};
  border-width:2px;
  border-style:solid;
}
`;

enum InteractionStatus {
  Startup = "startup",
  Login = "login",
  Logout = "logout",
  AcquireToken = "acquireToken",
  SsoSilent = "ssoSilent",
  HandleRedirect = "handleRedirect",
  None = "none"
}
//The enum abouve could not be imported, cheating with "none" and toString()

const scope = "https://dnvglb2cprod.onmicrosoft.com/83054ebf-1d7b-43f5-82ad-b2bde84d7b75/user_impersonation";

export const Azure = () => {

  const [menuOpen, setmenuOpen] = useState(false);

  const dispatch = useDispatch();
  const userInfo = useSelector(x => (x as any).user.userInfo) as AuthenticationResult;
  const msal = useMsal();
  //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#i-dont-understand-the-redirect-flow-how-does-the-handleredirectpromise-function-work
  //Not sure if there is a setting to clear the state, but it's done by the handleRedirectPromise  
  useEffect(() => {    
    if (window.location.hash && msal.inProgress === InteractionStatus.HandleRedirect.toString()) {

      msal.instance.handleRedirectPromise().then(x => {
        dispatch({ type: "LOGIN_COMPLETE", payload: x });
      }).catch(x => {
        toast.error(x);
      });
    }
  }, [dispatch, msal.instance, msal.inProgress]);
  //Determine if we are authenticated or not (there are many ways to do that)
  const isAuthenticated = useIsAuthenticated();
  const onMenuClick = () => {
    setmenuOpen(!menuOpen);
  }
  //Not actually used as a button, but is triggered when no account is found
  const onLoginRedirectButtonClick = useCallback(() => {

    msal.instance.loginRedirect({ scopes: [scope] });

  }, [msal.instance]);

  //Not actually used but works, if we go for a user initiated login later?
  // const onLoginPopupButtonClick = (event: any) => {
  //   event.preventDefault();
  //   msal.instance.loginPopup({ scopes: [scope] }).then(x => {
  //     dispatch({ type: "LOGIN_COMPLETE", payload: x })
  //   })
  //     .catch(e => {
  //       console.error(e);
  //       toast.error(e.toString());
  //     });

  // }
  //Silent refresh only seems to get the token with new expiretime when force = true. Again, what if refresh token is out of date? Should the catch do a redirect?
  const onSilentRefresh = useCallback((force: boolean) => {

    const accounts = msal.instance.getAllAccounts();
    if (accounts.length > 0) {
      const silentRequest: SilentRequest = {
        scopes: [scope],
        account: accounts[0],
        forceRefresh: force
      }
      msal.instance.acquireTokenSilent(silentRequest).then(x => {

        dispatch({ type: "RENEW_TOKEN_COMPLETE", payload: x })
      })
        .catch(x => {
          onLoginRedirectButtonClick();
        });
    }
  }, [dispatch, msal.instance,onLoginRedirectButtonClick])

  //Periodically renew token so it's always valid for the user. What happens after 24 hours (refresh token is only valid 24h)?
  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo) {
        if (+(userInfo.idTokenClaims as any).exp) {
          let untilExpires = (userInfo.idTokenClaims as any).exp - Date.now() / 1000; //Seconds between now and expire tiem
          if (untilExpires < 60 * 5) {//If expires in less than 5 minutes
            onSilentRefresh(true);//Force refresh token to get a new expire time
          }          
        }
      }
    }, 1000 * 60);//Run every minute
    return () => clearInterval(interval);//Clear the interval, so no duplicate timer runes
  }, [userInfo, onSilentRefresh]); //On every change of usertoken


  //This is to: 
  //1 When the user refresh the page and there is an account, but no token. get the token
  //2 When there is no account, we automatically do a redirect
  useEffect(() => {
    if (window.Cypress){
      return;
    }    
    if (msal.inProgress.toString() === InteractionStatus.None) {
      if (msal.instance.getAllAccounts().length === 0) {
        onLoginRedirectButtonClick();
      }
      else
        if (msal.instance.getAllAccounts().length > 0 && !userInfo) {
          onSilentRefresh(true);
        }
    }
  }, [msal.instance, onSilentRefresh, onLoginRedirectButtonClick, userInfo, msal.inProgress]);

  //The logout
  const onLogoutButtonClick = (event: any) => {
    event.preventDefault();
    msal.instance.logout();

  }

  return (<>
    <StyledProfile $active={menuOpen} style={{ cursor: "pointer", marginRight: "24px" }} title="Profile" onClick={onMenuClick}>{isAuthenticated ? getInitials(userInfo) : "??"}</StyledProfile>
    { menuOpen && <StyledMenu onMouseLeave={()=>setmenuOpen(false)} style={{ top: "50px" }}>
      <StyledMenuHeader>Your profile</StyledMenuHeader>
      <StyledMenuName>{getFullName(userInfo)}</StyledMenuName>
      <StyledMenuDivider></StyledMenuDivider>
      <StyledMenuHeader>Manage account</StyledMenuHeader>
      {/* <StyledMenuAction onClick={onLoginRedirectButtonClick}>Log in redirect</StyledMenuAction>
      <StyledMenuAction onClick={onLoginPopupButtonClick}>Log in popup</StyledMenuAction>
      <StyledMenuAction onClick={e => onSilentRefresh(false)}>Refresh if needed</StyledMenuAction>
      <StyledMenuAction onClick={e => onSilentRefresh(true)}>Refresh force</StyledMenuAction> */}
      <StyledMenuAction onClick={onLogoutButtonClick}>Log out</StyledMenuAction>
      {/* <StyledMenuHeader>Token info</StyledMenuHeader>
      <StyledMenuAction >Expire: {getExpireDateTime(userInfo)}</StyledMenuAction>
      <StyledMenuAction >Not before: {getNotBeforeTime(userInfo)}</StyledMenuAction>
      <StyledMenuAction >Issued: {getIssuedAt(userInfo)}</StyledMenuAction>
      <StyledMenuAction >Auth time: {getAuthTime(userInfo)}</StyledMenuAction>
      <StyledMenuAction >Accounts: {msal.instance.getAllAccounts().length}</StyledMenuAction> */}

    </StyledMenu>}
  </>)

}
