import { Reducer } from "redux";

import * as service from '../../api/SmartLibraryService';
import {FilterActions} from "../actions/filterActions";
interface ISearchDocumentContext {
    searchResult: service.IResult | null;
    isFetching: boolean;    
    error:string | null;
    query:string | null;
  }
export const searchDocumentReducer: Reducer<ISearchDocumentContext,FilterActions>
    = (state = { searchResult:null,isFetching:false,error:null,query:null }, action) => {

        switch (action.type) {
        
            case "SET_FILTER_CLEAR":{
                state.isFetching = false;
                state.error = null;
                state.searchResult = null;
                state.query= null;
                return { ...state };
            }
            default:
                break;
        }

        return state;
    };