import { Reducer } from "redux";
import { SelectedTextActions } from "../actions/selectedTextActions";

export const selectedTextReducer: Reducer<{ configId:string,revision:number, bookmarkedId:string, bookmarkedIdFullPath:string, coordX:number, coordY: number}, SelectedTextActions>
    = (state = { configId:"",revision:0,bookmarkedId: "",bookmarkedIdFullPath:"", coordX:0, coordY:0}, action) => {

        switch (action.type) {
            case "SELECTED_TEXT": {
                return { ...state,selectedText:action.payload.selectedText, configId:action.payload.configId, revision:action.payload.revision, bookmarkedId:action.payload.bookmarkedId,bookmarkedIdFullPath:action.payload.bookmarkedIdFullPath, coordX: action.payload.coordX, coordY: action.payload.coordY };
            }           
            default:
                break;
        }

        return state;
    };