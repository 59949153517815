import { Reducer } from "redux";
import { UserActions } from "../actions/userActions";
import { AuthenticationResult } from "@azure/msal-browser";
export interface IUserInfo {      
    userInfo: AuthenticationResult | null 
  }
export const userReducer: Reducer<IUserInfo,UserActions>
    = (state = { userInfo:null}, action) => {

        switch (action.type) {
            case "RENEW_TOKEN_COMPLETE":
            case "LOGIN_COMPLETE": {     
                        
                return { ...state,userInfo:action.payload};
            }   
        
            default:
                break;
        }

        return state;
    };