import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store/store";
import styles from "../ContentManager/ContentManager.module.scss";
import { Menu } from "../Sidebar/Menu";
import documentIcon from './document-icon.png';
import "./OfflinePackages.css";
import { offlinePackagesActions } from "./OfflinePackagesActions";

export const OfflinePackages = () => {
  const dispatch = useDispatch();
  const offlinepackages = useSelector((state: IRootState) => { return state.offlinepackages})

  useEffect(() => {
    if (offlinepackages.categories == null) {     
        dispatch(offlinePackagesActions.requestPackages());      
    }
  }, []);
  
    return (
      <Menu layout="Dashboard">
        <div className={styles.documentList}>
          <div>
            <h1>
              Offline packages for rules and standards
            </h1>
            <div>
              <h2>
                Rules, class guidelines, class programmes, offshore standards
                and statutory interpretations
              </h2>
              <div className="rs-explorer-offlinepackages-messagebox">
                <div><img className="rs-explorer-offlinepackages-messagebox-image" src={documentIcon} /></div>
                <div>
                  <p>Interlinked package including the following documents:</p>
                  <ul>
                    <li>
                      Rules for classification (GEN, SHIP, HSLC, INV, YACHT,
                      UWT, NAV, NAVAL, FD, OU)
                    </li>
                    <li>Class guidelines (CG)</li>
                    <li>Class programmes (CP)</li>
                    <li>Offshore standards (OS)</li>
                    <li>Statutory interpretations (SI)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
                <ul className="rs-explorer-offlinepackages-download-list">
                  {
                    offlinepackages.isFetching ? 
                      <li>Fetching packages...</li> : 
                      offlinepackages.categories != null ? 
                      offlinepackages.categories.map(x => <li key={x.release}><p className="rs-explorer-offlinepackages-download-list-right">{x.release}:</p><p><a id={"offlinepackage" + x.release?.split(" ").join("")} href={x.zipUrl}>{x.zipTitle}</a></p></li>) : 
                      <li>No offline packages available at the moment.</li>
                  }                    
                </ul>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>Each Zip file contains:</p>
              <ul>
                <li>The Pdf file(s) with active links</li>
                <li>Contents.pdf - the list of contents</li>
                <li>A text search index</li>
                <li>Guidance on using the advanced text search function</li>
              </ul>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>To retrace your viewing path when using links or moving in documents, you can navigate between views in Acrobat Reader (Windows PC) by:</p>
              <ul>
                <li>Previous view (Alt + Left arrow)</li>
                <li>Next view (Alt + Right Arrow)</li>
              </ul>
            </div>
          </div>
        </div>
      </Menu>
    );
}