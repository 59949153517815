import { Reducer } from "redux";
import { DocumentActions,NavigationMode } from "../actions/documentActions";
import { SearchActions } from "../actions/searchActions";
import {UserNoteActions} from "../actions/userNotesActions"
import * as service from '../../api/SmartLibraryService';
interface IDocumentContext {
    document: service.IDocumentWithLocalToc | null;
    isFetching: boolean;

    error: string | null;
    mounted: boolean;
    paymentRequired: boolean;
    navigationMode:NavigationMode;
    eloquaPage:string;
}
export const documentReducer: Reducer<IDocumentContext, DocumentActions | SearchActions |UserNoteActions>
    = (state = { document: null, isFetching: false, error: null, mounted: false, paymentRequired: false,navigationMode:"TOC", eloquaPage:""}, action) => {

        switch (action.type) {
            case "EXECUTE_SEARCH":
                {
                    return { ...state, document: null, isFetching: false, error: null, mounted: false, paymentRequired: false,navigationMode:"TOC" }
                }
            case "LOAD_DOCUMENT": {
                return { ...state, isFetching: true, mounted: false, document: null, error: null, paymentRequired: false ,navigationMode:"TOC"};
            }
            case "LOAD_DOCUMENT_COMPLETE": {
                return { ...state, isFetching: false, mounted: false, error: null, paymentRequired: false, document: action.payload,navigationMode:"TOC" }
            }
            case "LOAD_DOCUMENT_FAILED": {
                return { ...state, isFetching: false, mounted: false, error: action.payload.error, document: null, paymentRequired: false,navigationMode:"TOC" }
            }
            case "LOAD_DOCUMENT_MOUNTED": {
                return { ...state, mounted: true, paymentRequired: false,navigationMode:"TOC" }
            }
            case "LOAD_DOCUMENT_PAYMENT_REQUIRED": {
                return { ...state, isFetching: false, mounted: false, error: null, paymentRequired: true, document: null,navigationMode:"TOC" }
            }
            case "LOAD_DOCUMENT_CLEAR_ERRORS": {
                return { ...state, error: null, paymentRequired: false,navigationMode:"TOC" }
            }
            case "SET_NAVIGATION_MODE":{
                return {...state, navigationMode:action.payload.mode}
            }
            case "SET_ELOQUA_TRACK":{
                return{...state,eloquaPage:action.payload.page}
            }
            case "HIGHLIGHT_BOOKMARK":
            case "EDIT_NOTE":{
                return {...state, navigationMode:"BOOKMARKS"}
            }
            case "SAVE_USER_NOTE_COMPLETE":
            case "UPDATE_USER_NOTE_COMPLETE":{
                if(action.payload.bookmark.bookmarkedId){
                    return {...state, navigationMode:"BOOKMARKS"}
                }
                break;
            }
            default:
                break;
        }

        return state;
    };