import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import { Audience, WithdrawnType } from "../../api/SmartLibraryService";

interface ISetFilterDomain { type: "SET_FILTER_DOMAIN"; payload: { item: Audience | null } }
interface ISetFilterCategory { type: "SET_FILTER_CATEGORY"; payload: { category: string | null, subCategory: string | null } }
interface ISetIssuedDate {type:"SET_FILTER_ISSUEDDATE";payload:{issuedDate:string|null}}
interface ISetSelectedYear {type:"SET_FILTER_SELECTEDYEAR";payload:{selectedYear:string|null}}
interface ISetSelectedMonth {type:"SET_FILTER_SELECTEDMONTH";payload:{selectedMonth:string|null}}
interface ISetFilterRetiredReplaced { type: "SET_FILTER_RETIREDREPLACED"; payload: { itemRetired: WithdrawnType | null, itemReplaced: WithdrawnType | null } }
interface ISetFilterIsReplaced {type: "SET_FILTER_ISREPLACED" ; payload:{isReplaced: boolean}}
interface ISetFilterIsRetired {type: "SET_FILTER_ISRETIRED" ; payload:{isRetired: boolean}}

interface ISetFilterIsinScope {type: "SET_FILTER_INSCOPE" ; payload:{isFilterinScope: boolean}}

interface ISetOpenChevron { type: "SET_OPEN_CHEVRON"; payload: { category: string | null, subCategory: string | null } }
interface IToggleFavouriteFilter { type: "SET_FILTER_FAVOURITE";  }
interface IClearAllFilters { type: "SET_FILTER_CLEAR"; }
interface IClearCategory { type: "SET_CATEGORY_CLEAR"; }


export type FilterActions = ISetFilterDomain | ISetFilterCategory | ISetOpenChevron | IClearAllFilters |IToggleFavouriteFilter|ISetIssuedDate|ISetSelectedYear|ISetSelectedMonth|ISetFilterRetiredReplaced|ISetFilterIsReplaced|ISetFilterIsRetired|IClearCategory|ISetFilterIsinScope;
type TA = ThunkAction<void, IRootState, void, FilterActions>;

export const filterActions = {

  setDomainFilter: (item: Audience | null): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_DOMAIN", payload: { item: item } });
  }, 
  setCategoryFilter: (category: string | null, subCategory: string | null): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_CATEGORY", payload: { category: category, subCategory: subCategory } });
  },
  setIssuedDtFilter: (issuedDate: string | null): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_ISSUEDDATE", payload: { issuedDate: issuedDate } });
  },
  setSelectedYearFilter: (selectedYear: string|null): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_SELECTEDYEAR", payload: { selectedYear: selectedYear} });
  },
  setSelectedMonthFilter: (selectedMonth: string|null): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_SELECTEDMONTH", payload: { selectedMonth: selectedMonth} });
  },
  setRetiredReplacedFilter: (itemRetired: WithdrawnType | null, itemReplaced: WithdrawnType | null): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_RETIREDREPLACED", payload: { itemRetired: itemRetired, itemReplaced: itemReplaced } });
  },
  setIsReplaced:(isReplaced:boolean):TA=>(dispatch)=>{
    dispatch({type:"SET_FILTER_ISREPLACED", payload:{isReplaced:isReplaced}})
  },
  setIsRetired:(isRetired:boolean):TA=>(dispatch)=>{
    dispatch({type:"SET_FILTER_ISRETIRED", payload:{isRetired:isRetired}})
  },
  toggleFavouriteFilter: (): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_FAVOURITE"});
  }, 
  setOpenChevron: (category: string | null, subCategory: string | null): TA => (dispatch) => {
    dispatch({ type: "SET_OPEN_CHEVRON", payload: { category: category, subCategory: subCategory } });
  }, 
  clearFilters: (): TA => (dispatch) => {
    dispatch({ type: "SET_FILTER_CLEAR" });
  },
  clearCategory:():TA=>(dispatch)=>{
    dispatch({type:"SET_CATEGORY_CLEAR"});
  },
  setIsFilterinScope:(isFilterinScope:boolean):TA=>(dispatch)=>{
    dispatch({type:"SET_FILTER_INSCOPE", payload:{isFilterinScope:isFilterinScope}})
  }
  
}

