// ErrorBoundary component
import * as React from 'react';
import { provide } from "../../store/provide";
import { errorActions } from '../../store/actions/clientErrorActions';

type ErrorBoundaryState = {
    error: Error | null;
    hasError:boolean;
    info:object |null;
}

class ErrorBoundaryComponent extends React.Component<Props, ErrorBoundaryState> {
    state = {error:null, hasError: false,info:null };

    componentDidMount(){
        window.addEventListener('error', (event) => {
            this.props.logError('error','error','error', JSON.stringify(event, ["message", "arguments", "type", "name"]));      
        });
    }

    componentDidCatch = (error :Error | null, info: object) =>{
        // Display fallback UI
        if(error != null){
            this.props.logError(error.name,error.message,error.stack || '', JSON.stringify(info, ["message", "arguments", "type", "name"]));
        }else{
            this.props.logError('Unkown','Unkown','Unkown', JSON.stringify(info, ["message", "arguments", "type", "name"]));
        }
        
        this.setState(()=>({ hasError: true, error:error, info: info }));
        
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                 We are sorry, an unexpected error has occured. If the issue persists, please contact Rules and Standard support at rules@dnv.com.
                </div>
            );
        }

        return this.props.children;
    }
}

const provider = provide((state) => ({  
}), { ...errorActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const ErrorBoundary = provider.connect(ErrorBoundaryComponent);

