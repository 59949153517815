import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import { success } from '../../utils/customToast';
import { getBookmarkClient } from '../../utils/proxyFactory';
import * as service from '../../api/SmartLibraryService';
interface ILoadAllUserNotes { type: "LOAD_ALL_USER_NOTES"; }
interface ILoadAllUserNotesComplete { type: "LOAD_ALL_USER_NOTES_COMPLETE"; payload: service.IBookmarkContainerDto }
interface ILoadAllUserNotesFailed { type: "LOAD_ALL_USER_NOTES_FAILED"; payload: { error: string } }
interface ISaveUserNote { type: "SAVE_USER_NOTE"; }
interface ISaveUserNoteComplete { type: "SAVE_USER_NOTE_COMPLETE"; payload: { bookmark: service.IBookmarkDto } }
interface ISaveUserNoteFailed { type: "SAVE_USER_NOTE_FAILED"; payload: { error: string } }
interface IRequestDeleteUserNote { type: "REQUEST_DELETE_USER_NOTE"; payload: { bookmarkId: string } }
interface IDeleteUserNote { type: "DELETE_USER_NOTE"; }
interface IDeleteUserNoteComplete { type: "DELETE_USER_NOTE_COMPLETE"; }
interface IDeleteUserNoteFailed { type: "DELETE_USER_NOTE_FAILED"; payload: { error: string } }
interface IEditBookmark { type: "EDIT_NOTE"; payload: { bookmarkId: string } }
interface IHighlightBookmark { type: "HIGHLIGHT_BOOKMARK"; payload: { bookmarkId: string } }
interface IUpdateUserNote { type: "UPDATE_USER_NOTE"; }
interface IUpdateUserNoteComplete { type: "UPDATE_USER_NOTE_COMPLETE"; payload: { bookmark: service.IBookmarkDto } }
interface IUpdateUserNoteFailed { type: "UPDATE_USER_NOTE_FAILED"; payload: { error: string } }
interface IAutoUpdateUserNote { type: "AUTO_UPDATE_USER_NOTE"; }
interface IAutoUpdateUserNoteComplete { type: "AUTO_UPDATE_USER_NOTE_COMPLETE"; payload: { bookmark: service.IBookmarkDto, when: number } }
interface IAutoUpdateUserNoteFailed { type: "AUTO_UPDATE_USER_NOTE_FAILED"; payload: { error: string } }

interface IUserNoteCopyFromRevisionToRevision { type: "COPY_USER_NOTE_FROM_REVISION_TO_REVISION"; }
interface IUserNoteCopyFromRevisionToRevisionComplete { type: "COPY_USER_NOTE_FROM_REVISION_TO_REVISION_COMPLETE"; }
interface IUserNoteCopyFromRevisionToRevisionFailed { type: "COPY_USER_NOTE_FROM_REVISION_TO_REVISION_FAILED"; payload: { error: string } }

export type UserNoteActions =
    ILoadAllUserNotes | ILoadAllUserNotesComplete | ILoadAllUserNotesFailed
    | ISaveUserNote | ISaveUserNoteComplete | ISaveUserNoteFailed
    | IRequestDeleteUserNote | IDeleteUserNote | IDeleteUserNoteComplete | IDeleteUserNoteFailed
    | IEditBookmark
    | IUpdateUserNote | IUpdateUserNoteComplete | IUpdateUserNoteFailed
    | IAutoUpdateUserNote | IAutoUpdateUserNoteComplete | IAutoUpdateUserNoteFailed
    | IUserNoteCopyFromRevisionToRevision | IUserNoteCopyFromRevisionToRevisionComplete | IUserNoteCopyFromRevisionToRevisionFailed
    | IHighlightBookmark
    ;
type TA = ThunkAction<void, IRootState, void, UserNoteActions>;

export const userNotesActions = {
    loadUserNotes: (): TA => async (dispatch, getState) => {
        dispatch({
            type: "LOAD_ALL_USER_NOTES"
        });

        var client = await getBookmarkClient(dispatch, getState());
        await client.getBookmarksForUser()
            .then(x => {
                if (x != null) {
                    dispatch({ type: "LOAD_ALL_USER_NOTES_COMPLETE", payload: x })
                } else {
                    dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: "LOAD_ALL_USER_NOTES_FAILED" } })
                }
            },
                (x: string) => {
                    dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: x } })
                }
            );

    },

    updateLastSeen: (configId: string, revision: number): TA => async (dispatch, getState) => {
        var client = await getBookmarkClient(dispatch, getState());

        await client.setLastSeenRevisionByUser(configId, revision)
            .then(async () => {
                await client.getBookmarksForUser()
                    .then(x => {
                        if (x != null) {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_COMPLETE", payload: x })
                        } else {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: "LOAD_ALL_USER_NOTES_FAILED" } })
                        }
                    },
                        (x: string) => {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: x } })
                        }
                    );
            });
    },
    editBookmark: (bookmarkId: string): TA => async (dispatch, getState) => {
        dispatch({
            type: "EDIT_NOTE",
            payload: { bookmarkId: bookmarkId }
        })
    },
    highlightBookmark: (bookmarkId: string): TA => async (dispatch, getState) => {
        dispatch({
            type: "HIGHLIGHT_BOOKMARK",
            payload: { bookmarkId: bookmarkId }
        })
    },
    updateBookmark: (bookmarkId: string, comment: string): TA => async (dispatch, getState) => {
        dispatch({
            type: "UPDATE_USER_NOTE"
        });

        var client = await getBookmarkClient(dispatch, getState());

        await client.updateBookmark(service.BookmarkCommentUpdateDto.fromJS({ bookmarkId: bookmarkId, customText: comment }))
            .then(x => {
                dispatch({ type: "UPDATE_USER_NOTE_COMPLETE", payload: { bookmark: x } });
                success("Note updated");
            },
                (x: string) => {
                    dispatch({ type: "UPDATE_USER_NOTE_FAILED", payload: { error: x } })
                }
            ).then(async () => {
                await client.getBookmarksForUser()
                    .then(x => {
                        if (x != null) {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_COMPLETE", payload: x })
                        } else {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: "LOAD_ALL_USER_NOTES_FAILED" } })
                        }
                    },
                        (x: string) => {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: x } })
                        }
                    );
            });

    },

    autosaveBookmark: (bookmark:service.IBookmarkDto): TA => async (dispatch, getState) => {
        dispatch({
            type: "AUTO_UPDATE_USER_NOTE"
        });
        const now = new Date().getTime();

        var client = await getBookmarkClient(dispatch, getState());

        await client.updateBookmark(service.BookmarkCommentUpdateDto.fromJS({ bookmarkId: bookmark.bookmarkId, customText: bookmark.customText }))
            .then(x => {
                dispatch({ type: "AUTO_UPDATE_USER_NOTE_COMPLETE", payload: { bookmark: bookmark,when:now } });
                //success("Note updated");
            },
                (x: string) => {
                    dispatch({ type: "AUTO_UPDATE_USER_NOTE_FAILED", payload: { error: x } })
                }
            );
            // .then(async () => {
            //     await client.getBookmarksForUser()
            //         .then(x => {
            //             if (x != null) {
            //                 dispatch({ type: "LOAD_ALL_USER_NOTES_COMPLETE", payload: x })
            //             } else {
            //                 dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: "LOAD_ALL_USER_NOTES_FAILED" } })
            //             }
            //         },
            //             (x: string) => {
            //                 dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: x } })
            //             }
            //         );
            // });

    },
    requestDeleteUserNote: (bookmarkId: string): TA => async (dispatch, getState) => {
        dispatch({
            type: "REQUEST_DELETE_USER_NOTE",
            payload: { bookmarkId: bookmarkId }
        });
    },
    deleteUserNote: (bookmarkId: string): TA => async (dispatch, getState) => {
        dispatch({
            type: "DELETE_USER_NOTE"
        });

        var client = await getBookmarkClient(dispatch, getState());

        await client.deleteBookmark(bookmarkId)
            .then(x => {
                dispatch({ type: "DELETE_USER_NOTE_COMPLETE" });
                success("Your bookmark was removed.");
            },
                (x: string) => {
                    dispatch({ type: "DELETE_USER_NOTE_FAILED", payload: { error: x } })
                }
            ).then(async () => {
                await client.getBookmarksForUser()
                    .then(x => {
                        if (x != null) {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_COMPLETE", payload: x })
                        } else {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: "LOAD_ALL_USER_NOTES_FAILED" } })
                        }
                    },
                        (x: string) => {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: x } })
                        }
                    );
            });


    },

    saveUserNote: (bookmark: service.BookmarkDto, asNote: boolean): TA => async (dispatch, getState) => {
        const rootState = getState();
        if (rootState != null && rootState.userNotes != null && rootState.userNotes.notes) {
            const prevSavedBookmark = rootState.userNotes.notes.filter(x => x.bookmarkedId && x.bookmarkedId === bookmark.bookmarkedId && bookmark.revision === x.revision);
            if (prevSavedBookmark.length > 0) {
                dispatch({
                    type: "HIGHLIGHT_BOOKMARK",
                    payload: { bookmarkId: prevSavedBookmark[0].bookmarkId }
                });
                return;
            }
        }

        dispatch({
            type: "SAVE_USER_NOTE"
        });

        var client = await getBookmarkClient(dispatch, getState());

        await client.addBookmark(bookmark)
            .then(x => {

                dispatch({ type: "SAVE_USER_NOTE_COMPLETE", payload: { bookmark: x } });
                if (asNote) {
                    success("You added a note.");
                } else {
                    success("You added a bookmark.");
                }

            },
                (x: string) => {
                    dispatch({ type: "SAVE_USER_NOTE_FAILED", payload: { error: x } })
                }
            ).then(async () => {
                await client.getBookmarksForUser()
                    .then(x => {
                        if (x != null) {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_COMPLETE", payload: x })
                            // if(x.filter(x=>x.configId === bookmark.configId).length === 1){
                            //     dispatch({ type: "FIRST_BOOKMARK_ADDED" })
                            // }
                        } else {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: "LOAD_ALL_USER_NOTES_FAILED" } })
                        }
                    },
                        (x: string) => {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: x } })
                        }
                    );
            });


    },
    copyFromRevisionToRevision: (configId: string, fromRevision: number, toRevision: number): TA => async (dispatch, getState) => {
        dispatch({
            type: "COPY_USER_NOTE_FROM_REVISION_TO_REVISION"
        });

        var client = await getBookmarkClient(dispatch, getState());

        await client.copyBookmarksFromRevisionToRevision(configId, fromRevision, toRevision)
            .then(x => {
                dispatch({ type: "COPY_USER_NOTE_FROM_REVISION_TO_REVISION_COMPLETE" });
            },
                (x: string) => {
                    dispatch({ type: "COPY_USER_NOTE_FROM_REVISION_TO_REVISION_FAILED", payload: { error: x } })
                }
            ).then(async () => {
                await client.getBookmarksForUser()
                    .then(x => {
                        if (x != null) {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_COMPLETE", payload: x })
                        } else {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: "LOAD_ALL_USER_NOTES_FAILED" } })
                        }
                    },
                        (x: string) => {
                            dispatch({ type: "LOAD_ALL_USER_NOTES_FAILED", payload: { error: x } })
                        }
                    );
            });


    },
}