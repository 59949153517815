import { IRootState } from "./store";
import { applyMiddleware, combineReducers, compose, createStore, Store, AnyAction, Reducer } from "redux";
import thunk from "redux-thunk";
import { ResetActions } from "./actions/resetActions";

export function buildRootReducer(allReducers: any) {
    return combineReducers<IRootState>({ ...allReducers });
}

const resetStateReducer =(rootReducer: Reducer<IRootState, AnyAction>)=> (state: any, action: ResetActions) => {

    if (action.type === "RESET_STATE") {
        const { toc, documentList, authConfig, appUpdate, veracitySettings, user, news, userNotes, mathJax } = state;
      //  console.log("update")
        state = { toc, documentList, authConfig, appUpdate, veracitySettings, user, news,userNotes, mathJax }
    }
    return rootReducer(state, action);

}
const actionSanitizer = (action: any) => (
    action.type === 'FILE_DOWNLOAD_SUCCESS' && action.data ?
        { ...action, data: '<<LONG_BLOB>>' } : action
);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    sendTo: "http://localhost:3000",
    actionsDenylist: ["ENTITY_LOAD_STATUS_CHANGE", "ENTITY_RECIEVED", "SCOPE_MODIFICATION_RECIEVED", "DNVX_REMOVE_NOTIFICATION_ACTION", "DNVX_ADD_NOTIFICATION_ACTION"],
    actionSanitizer,
    stateSanitizer: (state: any) => state.document && state.document.document && state.document.document.html ? { ...state, document: { ...state.document, document: { ...state.document.document, html: 'sanitized' } } } : state
}) : compose;

export function configureStore(reducers: any, middlewares: any[], initialState?: IRootState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    // If devTools is installed, connect to it
    const createStoreWithMiddleware = composeEnhancers(
        applyMiddleware(thunk, ...middlewares)
    )(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const rootReducer = buildRootReducer(reducers);
    const rootReducerWithResetter = resetStateReducer(rootReducer)
    const store = createStoreWithMiddleware(rootReducerWithResetter, initialState) as Store<IRootState>;

    // Enable Webpack hot module replacement for reducers
    if ((module as any).hot) {
        (module as any).hot.accept("./store", () => {
            const nextRootReducer = require("./store");
            store.replaceReducer(buildRootReducer(nextRootReducer.reducers));
        });
    }

    return store;
}
