import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
interface ISetHighlight { type: "SET_HIGHTLIGHT_ENABLED"; payload: { enabled: boolean } }
interface ISetHighlightIndex { type: "SET_HIGHTLIGHT_INDEX"; payload: { index: number } }
interface ISetHighlightCount { type: "SET_HIGHTLIGHT_COUNT"; payload: { count: number } }
interface ISetHighlightAutoScroll { type: "SET_HIGHTLIGHT_AUTOSCROLL"; payload: { enabled: boolean } }
interface ISetHighlightText { type: "SET_HIGHTLIGHT_TEXT"; payload: { highlightText: string } }
export type HighlightActions = ISetHighlight | ISetHighlightIndex | ISetHighlightCount | ISetHighlightAutoScroll |  ISetHighlightText;
type TA = ThunkAction<void, IRootState, void, HighlightActions>;

export const highlightActions = {
    toggleHighlight: (enabled: boolean): TA => async (dispatch) => {
        dispatch(({ type: "SET_HIGHTLIGHT_ENABLED", payload: { enabled: enabled } }));
    },
    setHighlightIndex: (index: number): TA => async (dispatch) => {
        dispatch(({ type: "SET_HIGHTLIGHT_INDEX", payload: { index: index } }));
    }, setHighlightCount: (count: number): TA => async (dispatch) => {
        dispatch(({ type: "SET_HIGHTLIGHT_COUNT", payload: { count: count } }));
    }, setHighlightText: (highlightText: string): TA => async (dispatch) => {
        dispatch(({ type: "SET_HIGHTLIGHT_TEXT", payload: { highlightText: highlightText } }));
    }
    , setAutoScroll: (enabled: boolean): TA => async (dispatch) => {
        dispatch(({ type: "SET_HIGHTLIGHT_AUTOSCROLL", payload: { enabled: enabled } }));
    }
}