import React from 'react';
import { Spinner } from '../Icons/Spinner';
import { DocumentCard, DocumentCardElement, RowCounter } from './DocumentCard';
import { provide } from "../../store/provide";
import {ridgeborderbottom} from '../StyledComponents/CustomStyles'
import { isIndustryFiltered, isCategoryFiltered, isIssuedFiltered } from '../../utils/documentHelpers';
import { SortIndicator } from '../../Components/Icons/SortIndicator';
import { documentListActions } from '../../store/actions/documentListActions';
import { ErrorMessageNoResults } from '../ErrorMessage/ErrorMessageNoResults';
import { SubscriptionInfobox } from '../Document/DocumentViewer/Subscription/SubscriptionInfobox';
import styled from 'styled-components/macro';

const StyledDocumentGridDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 90px auto;
  justify-items: stretch;
  justify-content: stretch;	
  grid-template-rows: auto; 
  font-size:${({ theme }) => theme.fonts.sizes.medium}; 
  height: "100%"; 
`
const StyledDocumentGridHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.AvenirDemi};
  font-size:${({ theme }) => theme.fonts.sizes.large};
  font-weight: ${({ theme }) => theme.fonts.weights.regular};
  text-decoration: none solid ${({ theme }) => theme.colors.text.darkblue};
  line-height: 2.3rem;
  white-space:nowrap;
  display:flex;
  align-items:center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.darkblue};
`
const StyledDocumentTitleDiv = styled.div`
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    ${({ theme }) => ridgeborderbottom(theme)};
`

const StyledDocumentTitleSubscriptionDiv = styled.div`
    padding-bottom: 1rem;
    padding-top: 1rem;
    ${({ theme }) => ridgeborderbottom(theme)};
    font-size: x-large;
`

const StyledFakeBorderDiv = styled.div`
    grid-column-start:1;
    grid-column-end:5;
    height:0.0625rem;
    background-color: ${({ theme }) => theme.colors.border.neutral10};
`

const StyledSearchText = styled.div`
width: 110pt;
height: 30pt;
font-family: Avenir Next;
font-size: 14pt;
margin-left:38%;
color: #003591;
text-decoration: none solid rgb(0, 53, 145);
line-height: 20pt;
white-space:nowrap;
`

class DocumentListComponent extends React.Component<Props> {
    sortCode = () => {
        if (this.props.documentListState.column === "code") {
            if (this.props.documentListState.order === "Ascending") {
                this.props.setSorting(this.props.documentListState.column, "Descending");
            }
            else {
                this.props.setSorting(this.props.documentListState.column, "Ascending");
            }
        } else {
            this.props.setSorting("code", "Ascending");
        }
    }
    sortTitle = () => {
        if (this.props.documentListState.column === "title") {
            if (this.props.documentListState.order === "Ascending") {
                this.props.setSorting(this.props.documentListState.column, "Descending");
            }
            else {
                this.props.setSorting(this.props.documentListState.column, "Ascending");
            }
        } else {
            this.props.setSorting("title", "Ascending");
        }
    }


    render() {
        window.document.title = "Rules and Standards Explorer";
        // if (this.props.searchResult.isFetching) {
        //     return <div><Spinner /> <StyledSearchText>Searching for: {this.props.searchResult.query}</StyledSearchText></div>
        // }

        let filteredDocumentCards = this.props.documentCards.filter(documentInfo =>
            isCategoryFiltered(documentInfo, this.props.filterState.category, this.props.filterState.subCategory)
           
            && isIssuedFiltered(documentInfo, this.props.filterState.issuedDate, this.props.filterState.retiredType, this.props.filterState.replacedType)
           
        );
        
        // const isSearch = this.props.searchResult.query;
        //  if (!isSearch && this.props.filterState.category === null && this.props.filterState.subCategory === null && this.props.filterState.domainId === null && !this.props.filterState.favouriteFilter && !this.props.subscription) {
        //      //Landingpage
        //      return <div dangerouslySetInnerHTML={{ __html: this.props.news.news ? this.props.news.news.lastestNews || '' : 'Loading news' }} />

        //  }
        // if (!isSearch) {
        //     if (this.props.documentListState.column !== "none") {
        //         if (this.props.documentListState.column === "code") {
        //             filteredDocumentCards = filteredDocumentCards.sort((x, y) => (this.props.documentListState.order === "Ascending" ? 1 : -1) * (x.document.chapter && y.document.chapter && x.document.part && y.document.part ? x.document.part > y.document.part && x.document.chapter > y.document.chapter ? -1 : 1 : (x.document.documentCode || '').localeCompare(y.document.documentCode || '')))
        //         } else {
        //             filteredDocumentCards = filteredDocumentCards.sort((x, y) => (this.props.documentListState.order === "Ascending" ? 1 : -1) * (x.document.title || '').localeCompare(y.document.title || ''))
        //         }
        //     }
        // }
        // if (isSearch && filteredDocumentCards.length === 0 && this.props.searchResult.query) {
        //     return <ErrorMessageNoResults searchterm={this.props.searchResult.query} />
        // }
        const rowCounter: RowCounter = { rowcount: 2 };
        return <>
            {this.props.subscription ? <SubscriptionInfobox type="List" title=' '/> : <></> }
            {
                this.props.subscription ?
                <></>
                :
                <StyledDocumentTitleDiv>Showing <span data-cy="documentListCount">{filteredDocumentCards.length}</span>  rules and standards </StyledDocumentTitleDiv>
            }
            <StyledDocumentGridDiv>
                <StyledDocumentGridHeader style={{ paddingLeft: "1rem", cursor: "pointer", }} onClick={this.sortCode}>Document code {true && <span style={{ marginLeft: "1rem" }}> <SortIndicator enabled={this.props.documentListState.column === "code"} direction={this.props.documentListState.order} /></span>} </StyledDocumentGridHeader>
                <StyledDocumentGridHeader style={{ paddingLeft: "1rem", cursor: "pointer" }} onClick={this.sortTitle}>Title {true && <span style={{ marginLeft: "1rem" }}> <SortIndicator enabled={this.props.documentListState.column === "title"} direction={this.props.documentListState.order} /></span>}</StyledDocumentGridHeader>
                <StyledDocumentGridHeader>Edition</StyledDocumentGridHeader>
                <StyledDocumentGridHeader></StyledDocumentGridHeader>
                <StyledFakeBorderDiv />
                {this.props.subscription ? this.props.documentCards.map((documentCard, index) => <DocumentCard key={documentCard.document.configId} documentCard={documentCard} index={index} isSearch={false} rowCounter={rowCounter} />) : filteredDocumentCards.map((documentCard, index) => <DocumentCard key={documentCard.document.configId} documentCard={documentCard} index={index} isSearch={false} rowCounter={rowCounter} />)}
            </StyledDocumentGridDiv>
        </>
    }
};

const provider = provide((state) => ({
    searchResult: state.searchResult,
    filterState: state.filterState,
    documentListState: state.documentList,
    news: state.news,
    notes: state.userNotes.notes,
    
}), { ...documentListActions }).withExternalProps<{ documentCards: DocumentCardElement[], subscription: boolean }>();

type Props = typeof provider.allProps;
export const DocumentList = provider.connect(DocumentListComponent);

