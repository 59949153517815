import styled from 'styled-components/macro';
export const  SubtleButton = styled.span`
 
 padding:  ${({ theme }) => theme.padding.small + ' ' + theme.padding.medium};
  border: ${({ theme }) => theme.borderWidth.thinnest} solid ${({ theme }) => theme.colors.border.skyeblue};
  background-color: ${({ theme }) => theme.colors.background.white};
  font-family:${({ theme }) => theme.fonts.AvenirNextMedium}; 
  font-size:  ${({ theme }) => theme.fonts.sizes.small};
  
  color: ${({ theme }) => theme.colors.link.digitalblue};
  text-decoration: none solid ${({ theme }) => theme.colors.link.digitalblue};
  text-align: center;
  cursor:pointer;
  &:hover{
    background-color: #e5f5fb;
  }
`;