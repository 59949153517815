import React, { useReducer, useState } from 'react';
import { provide } from '../../store/provide';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ChevronIcon } from '../Icons/Chevron';
import { DocumentCardElement } from '../Documents/DocumentCard';
import { editionMMtoStringShort, editionyyyytoStringShort, filterByIssuedDate } from '../../utils/documentHelpers';
import { ToggleOffIcon } from '../Icons/ToggleOff';
import { ToggleOnIcon } from '../Icons/ToggleOn';
import { tocReducer } from '../../store/reducers/tocReducer';
import { Zoom } from 'react-toastify';
import { filterActions, FilterActions } from "../../store/actions/filterActions";
import { useSelector } from 'react-redux';
import { IRootState } from '../../store/store';
import { filterReducer } from '../../store/reducers/filterReducer';
import { IDocumentRouteProps } from '../../utils/routeProps/DocumentRouteProps';
import { searchActions } from '../../store/actions/searchActions';
import { documentListActions } from '../../store/actions/documentListActions';
import { DocumentList } from '../Documents/DocumentList';
import * as service from '../../api/SmartLibraryService';
import { tocActions } from '../../store/actions/tocActions';


const StyledLeftAdvFilterHeadingDiv = styled.div`   
    padding-left: ${({ theme }) => theme.padding.large};     
    padding-bottom: ${({ theme }) => theme.padding.large};     
    font-size: ${({ theme }) => theme.fonts.sizes.large};     
    font-weight: ${({ theme }) => theme.fonts.weights.bold}; 
    padding-top:16px;    
`;

const StyledSpan = styled.span`
    float: left;
    height: 100%;
    padding: 10px;
    &:hover {
        background-color: #f8f9fa;
    }
`;

const DropDownContainer = styled(StyledSpan)`
     display: block;
`;

const DropDownItem = styled.select`
width: 107px;
height: 37px;
padding: 6px 10px;
border: 1px solid #99d9f0;
background-color: #ffffff;
font-family: Avenir Next;
font-size: 16px;
color: #0f204b;
text-decoration: none solid rgb(15, 32, 75);
text-align: center;
cursor:pointer;
`;
const DropDownContent = styled.div`
   
    position: absolute;
    min-width: 160px;
    z-index: 1;
    border:1px solid ${({ theme }) => theme.colors.border.neutral20};
`;

const DropDownListItem = styled(DropDownItem)`
    text-decoration: none;
    display: block;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.background.white};
    padding: 10px 5px;
    &:hover {
        background-color: ${({ theme }) => theme.colors.background.neutral05};
    }
`;

const StyledLeftHeadingDiv = styled.div`   
    padding-left: ${({ theme }) => theme.padding.large};     
    padding-bottom: ${({ theme }) => theme.padding.large};     
    font-size: ${({ theme }) => theme.fonts.sizes.medium};     
    font-weight: ${({ theme }) => theme.fonts.weights.bold}; 
    padding-top:16px;    
`;
type ToggleRetiredReplaced = {
    isReplaced: boolean;
    isRetired: boolean;
    isMonthSelected: number;
}

class AdvfilterFeatureComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>, ToggleRetiredReplaced> {

    state = { isReplaced: true, isRetired: true, isMonthSelected: 0 }

    render() {

        const toggleRetired = () => {
            //this.setState(x => ({ ...this.state, isRetired: !x.isRetired }))
            this.props.setIsRetired(!this.props.filterState.isRetired);
        }
        const toggleReplaced = () => {
            //this.setState(x =>({...this.state, isReplaced:!x.isReplaced}))
            this.props.setIsReplaced(!this.props.filterState.isReplaced);

        }

        const onYearChange = (event: any) => {
            const year = event.target.value;
            this.props.setSelectedYearFilter(year);
            this.setState({ ...this.state, isMonthSelected: 1 })
        }

        const onMonthChange = (event: any) => {
            const month = event.target.value;
            this.props.setSelectedMonthFilter(month);
        }

        const resetClick = () => {
            this.setState({ ...this.state, isMonthSelected: 0 })
            this.props.clearFilters();


        }
        const onLoadDataClick = () => {
            if (this.props.filterState.selectedYear != "Year" && this.props.filterState.selectedMonth != "Month" && this.props.filterState.selectedMonth != null) {
                const year = this.props.filterState.selectedYear;
                const month = this.props.filterState.selectedMonth;
                const dt = year + month;

                this.props.setIssuedDtFilter(dt);
              
                if (this.props.filterState.isReplaced == true && this.props.filterState.isRetired == true) {
                    this.props.setRetiredReplacedFilter(null, null);
                }
                else if(this.props.filterState.isReplaced == false && this.props.filterState.isRetired == false){
                    this.props.setRetiredReplacedFilter(service.WithdrawnType.Retired, service.WithdrawnType.Replaced);
               
                }
                else if(this.props.filterState.isRetired == false){
                    this.props.setRetiredReplacedFilter(service.WithdrawnType.Retired, null);
                }
                else if(this.props.filterState.isReplaced == false){
                    this.props.setRetiredReplacedFilter(null, service.WithdrawnType.Replaced);
                }

                // if(this.state.isReplaced == true){
                //     this.props.setRetiredReplacedFilter(service.WithdrawnType.Replaced);
                // }

                const aa = this.props.globalTocAllRevisions.filter(x => x.document.withdrawn && x.document.withdrawn == 1).length
                console.log("aa" + aa);
            }
            else {
                alert("Please select Year / Month");
            }


        }

        const loadYear = this.props.globalTocAllRevisions.map(toc => editionyyyytoStringShort(toc.document.edition || ''))
        let tempArr = loadYear.filter((q, indx) => loadYear.indexOf(q) === indx).sort();

        const loadMonth = this.props.globalTocAllRevisions.map(toc => editionMMtoStringShort(toc.document.edition || ''))
        let tempArrMonth = loadMonth.filter((q, selected) => loadMonth.indexOf(q) === selected).sort();

        return (
            <div>
                <StyledLeftAdvFilterHeadingDiv>Advance filter</StyledLeftAdvFilterHeadingDiv>
                <span>Selected Year: {this.props.filterState.selectedYear} and selected month : {this.props.filterState.selectedMonth}</span><br />
                <DropDownContainer>
                    <DropDownListItem onChange={onYearChange}>
                        <option value={"Year"}>Year</option>
                        {
                            tempArr.map(tocEntry => {
                                return (
                                    <option key={tocEntry} >{tocEntry}</option>
                                )
                            })
                        };

                    </DropDownListItem>
                    {/* <p>you seleted {selected}</p> */}
                </DropDownContainer>

                <DropDownContainer>
                    <DropDownListItem disabled={this.state.isMonthSelected == 0} onChange={onMonthChange}>
                        <option value={"Month"}>Month</option>
                        {tempArrMonth.map(tocEntry => {

                            return (
                                <option>{tocEntry}</option>

                            )
                        })}
                    </DropDownListItem>
                </DropDownContainer>
                <button onClick={onLoadDataClick}>Load Data</button>
                <button onClick={resetClick}>Clear filter</button><br />

                <StyledLeftHeadingDiv>
                    <p style={{ display: "block", marginBottom: "0px" }}>Show Retired</p>
                    <span>Off {this.props.filterState.isRetired ?<ToggleOnIcon onClick={toggleRetired}></ToggleOnIcon> :
                         <ToggleOffIcon onClick={toggleRetired}></ToggleOffIcon>}
                        ON</span>

                    <p style={{ display: "block", marginBottom: "0px" }}>Show Replaced</p>
                    <span>Off {this.props.filterState.isReplaced ? <ToggleOnIcon onClick={toggleReplaced}></ToggleOnIcon> :
                        <ToggleOffIcon onClick={toggleReplaced}></ToggleOffIcon>}
                        ON</span>
                </StyledLeftHeadingDiv>
            </div>
        )
    }


}


const provider = provide((state) => ({
    filterState: state.filterState,
    query: state.searchResult.query,
    toc: state.toc

}), { ...filterActions, ...documentListActions, ...tocActions }).withExternalProps<{ globalTocAllRevisions: DocumentCardElement[] }>();

type Props = typeof provider.allProps;
export const Advfilter = provider.connect(withRouter(AdvfilterFeatureComponent));
