
import * as React from 'react';
import { ErrorMessage } from './ErrorMessage'
import { DocumentCardElement } from '../Documents/DocumentCard';
import { editionyyyyMMtoStringShort } from '../../utils/documentHelpers';
export const ErrorMessageRetired = (props: { documentCardElement: DocumentCardElement }) => {
    let withdrawnDate: string | null = props.documentCardElement.document && props.documentCardElement.document.withdrawnDate ? editionyyyyMMtoStringShort(props.documentCardElement.document.withdrawnDate) : null;
    return <>
       
        <ErrorMessage
            icon={<></>}
            message={<>

                <div style={{ fontSize: "1.5rem" }}>Retired document</div>
                <div style={{ marginTop: "1.875rem" }}>This document was retired in {withdrawnDate}<br/><br/>
                Retired documents will no longer be maintained and shall not be used as reference in future projects.<br/> <br/>
                Previous editions are still available as PDF.
</div>
            </>}
        />
    </>
}
