import React from 'react';
import { createGlobalStyle } from 'styled-components/macro';
//import { normalize } from 'styled-normalize';
  //${normalize}

const GlobalStyle = createGlobalStyle`

  html {
    
    text-size-adjust: 100%;
    line-height: 1.5;
  }

  body {
    font-family: ${({ theme }) =>
      `${theme.fonts.family}, ${theme.fonts.fallbacks.join(', ')}`};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};    
    padding: 0;
    margin: 0;
 
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-rendering: optimizeLegibility;
  }

  *,::after,::before {
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
  }

  a {
    color:  ${({ theme }) => theme.colors.link.digitalblue};
    text-decoration: none;
    background-color: transparent;
  }

  a:hover {  
    text-decoration: underline;
  }

  button, input {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    appearance: none;
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.toast.landgreenevenlighter} !important;
    border-top: 2px solid ${({ theme }) => theme.colors.toast.landgreenlight} !important;
    color: black !important;
  }
  .Toastify__toast--warning {
    background: ${({ theme }) => theme.colors.toast.yellowlightest} !important;
    border-top: 2px solid ${({ theme }) => theme.colors.toast.yellowdark} !important;
    color: black !important;
  }
  
  /* .Toastify__toast--error {
    background: #555;
  }
   */
  .Toastify__close-button > svg {
    color:${({ theme }) => theme.colors.toast.neutral80} ;
  }

`;

const GlobalStyles = () => <GlobalStyle />;

export default GlobalStyles;