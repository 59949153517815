// ErrorBoundary component
import * as React from 'react';
import { provide } from "../../store/provide";
import { userNotesActions } from '../../store/actions/userNotesActions';
import { BookmarkIcon } from '../Icons/Bookmark';
import { BookmarkFilledIcon } from '../Icons/BookmarkFilled';
import * as service from '../../api/SmartLibraryService';
import { uuidv4 } from '../../utils/comments'
import * as tooltips  from '../../utils/tooltips';
import {warn} from '../../utils/customToast'
import styled from 'styled-components/macro';
const StyledBookmarkFilledIcon = styled(BookmarkFilledIcon)<{ $allowtoggle: boolean }>`   
    cursor:pointer;
    height: ${({ $allowtoggle }) => $allowtoggle ? "20px" :"14px"};
    z-index:2;    
`;
const StyledBookmarkIcon = styled(BookmarkIcon)<{ $allowtoggle: boolean }>`   
    cursor:pointer;
    height: ${({ $allowtoggle }) => $allowtoggle ? "20px" :"14px"};
    z-index:2;    
    fill:${({ theme }) => theme.colors.icon.neutral40};
`;
class FavouriteTogglerComponent extends React.Component<Props> {

    hasLowerLevelBoomarks = ()=>{
        return this.props.notes && this.props.notes.find(x => x.configId === this.props.document.configId && x.revision === this.props.document.revision && x.bookmarkId != null && x.bookmarkedId);        
    }
    toggleFavourite = (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
        
        if (this.props.allowToogle) {            
            var prevNote = this.props.notes && this.props.notes.find(x => x.configId === this.props.document.configId && x.revision === this.props.document.revision && x.bookmarkId != null && !x.bookmarkedId);
            if(this.hasLowerLevelBoomarks()){
                warn(tooltips.metadataUnbookmarkInValid);
            }else if (prevNote) {
               // console.log("deleting")
                this.props.deleteUserNote(prevNote.bookmarkId);

            } else {
               // console.log("adding")
                const bookMark = new service.BookmarkDto({
                    bookmarkId: uuidv4(),
                    upn: "",
                    configId: this.props.document.configId,
                    revision: this.props.document.revision,
                    bookmarkedId: "",
                    bookmarkedIdFullPath: "",
                    customText: "",
                    globalOrder: 0,
                    fullReference: "",
                    renderedTitleText: "",
                    fullReferenceWithoutDocument: "",
                    htmlOrder:0,
                    lastValidInRevision:  this.props.document.revision
                })
                this.props.saveUserNote(bookMark,false);
            }
        }

        e.stopPropagation();
        e.preventDefault();
    };

    render() {
        const isBookmarked = this.props.notes && this.props.notes.find(x => x.configId === this.props.document.configId  && x.revision === this.props.document.revision  && !x.bookmarkedId);
        let toolTip : string | undefined = tooltips.metadataNotBookmarked;
        if(isBookmarked){
            toolTip =tooltips.metadataUnbookmarkValid;
            if(this.hasLowerLevelBoomarks()){
                toolTip = tooltips.metadataUnbookmarkInValid;
            }
        }
        if(!this.props.allowToogle){
            toolTip = tooltips.navigationTreeBookmark
        }
        if(isBookmarked){
            return <StyledBookmarkFilledIcon  title={toolTip} data-cy="favourite-toggle" onClick={this.toggleFavourite}   $allowtoggle={this.props.allowToogle} />;
        }
        return <StyledBookmarkIcon title={toolTip}  data-cy="favourite-toggle" onClick={this.toggleFavourite}   $allowtoggle={this.props.allowToogle}  />;
    }
}

const provider = provide((state) => ({
    notes: state.userNotes.notes,
}), { ...userNotesActions }).withExternalProps<{ document: service.IDocument, allowToogle: boolean }>();

type Props = typeof provider.allProps;
export const FavouriteToggler = provider.connect(FavouriteTogglerComponent);

