import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import {DocumentsActions} from "../../Components/Documents/DocumentsActions";

interface IDocumentListSort { type: "DOCUMENT_LIST_SORT"; column: "none" | "code" | "title", order:"Ascending"|"Descending" }
interface IDocumentsListSort { type: "DOCUMENTS_LIST_SORT"; column: "none" | "code" | "title", order:"Ascending"|"Descending" }



export type DocumentListActions = IDocumentListSort|IDocumentsListSort;
type TA = ThunkAction<void, IRootState, void, DocumentListActions>;

export const documentListActions = {
    setSorting: (column: "none" | "code" | "title", order: "Ascending"|"Descending"): TA => async (dispatch) => {
     dispatch(DocumentsActions.setSorting(column, order));
    dispatch({
      type: "DOCUMENT_LIST_SORT", column:column,order:order
    })
  }
}