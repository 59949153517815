import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./App.css";
import { veracityActions } from "./store/actions/veracityActions";
import { Header } from "./Components/Header/Header";
import { MathJaxLoader } from "./Components/MathJax/MathJaxLoader";
import { tocActions } from "./store/actions/tocActions";
import { provide } from "./store/provide";
import { ScrollMemory } from "./Components/Scroll/ScrollMemory";
import { ContentRouter } from "./Components/ContentManager/ContentRouter";
import { newsActions } from "./store/actions/newsActions";
import { GlobalSearch } from "./Components//GlobalSearch/GlobalSearch";
import "react-toastify/dist/ReactToastify.css";
import { userSettingsActions } from "./store/actions/userSettingsActions";

type IAppComponentState = {
  isMenuOpen: boolean;
};

class AppComponent extends React.Component<
  Props & RouteComponentProps,
  IAppComponentState
> {
  state = { isMenuOpen: false };

  componentDidMount() {
    this.props.requestNews();
    this.checkUser();
  }
  checkUser() {
    if (this.props.user.userInfo) {
      if (
        !this.props.veracitySettings.isFetching &&
        !this.props.veracitySettings.complete
      ) {
        this.props.ensureValidPolicies();
      }
      if (this.props.userSettings.complete) {
        if (
          !this.props.userVeracityTrial.isFetching &&
          !this.props.userVeracityTrial.complete &&
          !this.props.userSettings.settings?.internal &&
          !this.props.userSettings.settings?.subscription
        ) {
          this.props.ensureValidTrialPolicies();
        }

        if (
          this.props.userVeracityTrial.complete &&
          this.props.userVeracityTrial.validationError?.message === null &&
          !this.props.userSettings.settings?.trial &&
          !this.props.userSettings.isFetching
        ) {          
          this.props.requestTrialUserSettings();
        }
      }
    }
  }

  componentDidUpdate() {
    this.checkUser();
  }

  render() {
    const noSubscription = "No subscription found";
    const termsNeedsAcceptance = "Terms need acceptance";

    if (this.props.user.userInfo) {
      if (
        this.props.veracitySettings.complete &&
        this.props.veracitySettings.error
      ) {
        return "Could not validate policy " + this.props.veracitySettings.error;
      }
      if (
        this.props.veracitySettings.complete &&
        this.props.veracitySettings.validationError &&
        this.props.veracitySettings.validationError.url
      ) {
        window.location.href = this.props.veracitySettings.validationError.url;
      }
      if (
        this.props.userVeracityTrial.complete &&
        this.props.userVeracityTrial.validationError &&
        this.props.userVeracityTrial.validationError.url
      ) {
        switch (this.props.userVeracityTrial.validationError.message) {
          case noSubscription:
            break;
          case termsNeedsAcceptance:
            window.location.href =
              this.props.userVeracityTrial.validationError.url;
            break;
          default:
            break;
        }
      }
    }

    return (
      <>
        <Header />
        {this.props.user.userInfo && (
          <div>
            <GlobalSearch />
            <MathJaxLoader />
            <ScrollMemory />
            <ContentRouter />           
          </div>
        )}
      </>
    );
  }
}

const provider = provide(
  (state) => ({
    user: state.user,
    veracitySettings: state.veracitySettings,
    userVeracityTrial: state.userVeracityTrial,
    userSettings: state.userSettings,
  }),
  { ...tocActions, ...veracityActions, ...newsActions, ...userSettingsActions }
).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const App = provider.connect(withRouter(AppComponent));

export default App;
