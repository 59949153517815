import styled from 'styled-components/macro';
export const StyledMenuItemButton =  styled.div`
    display: flex;
    width: 40px;
    height: 40px;  
  
    
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover,&:focus {
        background-color: ${({ theme }) => theme.colors.hover.skyebluelightest};     
    }
`