
import { AuthenticationResult } from "@azure/msal-browser";
export const getUpn = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        return (userInfo.idTokenClaims as any).upn
    }
    return '';
}
const getAuthenticatedBy = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        return (userInfo.idTokenClaims as any).authenticatedBy
    }
    return '';
}

export const isInternal = (userInfo: AuthenticationResult | null):string =>
{
    return getAuthenticatedBy(userInfo) ==='https://dnv.com' ? "internal":"external"
}
export const getFullName = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        return (userInfo.idTokenClaims as any).given_name + ' ' + (userInfo.idTokenClaims as any).family_name;
    }
    return '';
}
export const getInitials = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        return (userInfo.idTokenClaims as any).given_name.slice(0, 1) + ' ' + (userInfo.idTokenClaims as any).family_name.slice(0, 1);
    }
    return '';
}

export const getExpireDateTime = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        let date = new Date(0);
        date.setUTCSeconds((userInfo.idTokenClaims as any).exp);
        return date.toLocaleString();
    }
    return 'No token';
}
export const getNotBeforeTime = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        let date = new Date(0);
        date.setUTCSeconds((userInfo.idTokenClaims as any).nbf);
        return date.toLocaleString();
    }
    return 'No token';
}
export const getIssuedAt = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        let date = new Date(0);
        date.setUTCSeconds((userInfo.idTokenClaims as any).iat);
        return date.toLocaleString();
    }
    return 'No token';
}
export const getAuthTime = (userInfo: AuthenticationResult | null): string => {
    if (userInfo) {
        let date = new Date(0);
        date.setUTCSeconds((userInfo.idTokenClaims as any).auth_time);
        return date.toLocaleString();
    }
    return 'No token';
}