import * as React from 'react';
import { provide } from "../../../store/provide";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { IBookmarkDto } from '../../../api/SmartLibraryService';



type SectionLocation = {
    top: number;
    height: number;
    left:number;
    element: Element;
    bookmarkId: string;
    customText:string
}

type DocumentMapNoteIndicatorState = {
    sectionLocations: SectionLocation[] | null;
    entireHeight: number,
    configId: string,
    revision: string,
    allbookmarks:string
}

class DocumentMapNoteIndicatorComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>, DocumentMapNoteIndicatorState> {

    state: DocumentMapNoteIndicatorState = { sectionLocations: null, entireHeight: 0, configId: "", revision: "",allbookmarks:"" };
    comments = new Map<string, IBookmarkDto>();
    componentDidMount() {
     //   this.getTargetPositions();
       this.clearNoteIndicatorStyle();
       this.setNoteIndicatorStyle();

    }
    componentDidUpdate() {
        this.clearNoteIndicatorStyle();
        this.setNoteIndicatorStyle();
      // this.getTargetPositions();
    }

    clearNoteIndicatorStyle = ()=>{
        document.querySelectorAll(".bookmark").forEach(el => {
            el.classList.remove("bookmark");
            el.classList.remove("bookmarkeditnowExplorer");
            el.classList.remove("bookmarkwithnote");
        });
    }
    setNoteIndicatorStyle = () =>{
        this.props.userNotes.notes && this.props.userNotes.notes.filter(x=>x.configId === this.props.match.params.configId && x.revision === +this.props.match.params.revision).filter(x=>x.bookmarkedId).forEach(bookmark => {
                        //console.log(".documentViewer #" + key)
                        const target = document.querySelector(".documentViewer #" + bookmark.bookmarkedId);
                        if(target != null){
                            target.classList.add("bookmark");
                            if(this.props.userNotes.highlightBookmark === bookmark.bookmarkId){
                                target.classList.add("bookmarkeditnowExplorer");
                            }
                            if(bookmark.customText){
                                target.classList.add("bookmarkwithnote");
                            }
                        }
                        

                    })
    }

    // getTargetPositions = () => {
    //     this.comments = groupCommentsById(this.props.userNotes || []);
    //     const allKeys = Array.from(this.comments.keys());
    //     const allbookmarks = allKeys.join('|');
    //     if (this.props.userNotes && this.props.documentMounted && (this.state.sectionLocations === null || this.state.entireHeight !== document.documentElement.scrollHeight || this.props.match.params.configId !== this.state.configId || this.props.match.params.revision !== this.state.revision || this.state.allbookmarks !== allbookmarks)) {
          
    //         const sectionMap: SectionLocation[] = [];
    //         // console.log(this.comments);
    //         // console.log("test2");
    //         // console.log(allKeys);
    //        this.props.userNotes.filter(x=>x.configId === this.props.match.params.configId && x.revision === +this.props.match.params.revision).filter(x=>x.bookmarkedId && x.customText).forEach(bookmark => {
    //             //console.log(".documentViewer #" + key)
    //             const target = document.querySelector(".documentViewer #" + bookmark.bookmarkedId);
    //             if (target != null) {
    //                 const boundingRect = target.getBoundingClientRect();
    //                 sectionMap.push({ top: Math.floor(boundingRect.top + window.scrollY), height: boundingRect.height,left:boundingRect.left, element: target,bookmarkId:bookmark.bookmarkId,customText:bookmark.customText || ''});
    //             }
    //         })


    //         this.setState(s => ({ sectionLocations: sectionMap, entireHeight: document.documentElement.scrollHeight, configId: this.props.match.params.configId, revision: this.props.match.params.revision,allbookmarks:allbookmarks}));
    //     }
    // }

    render() {
    return <></>
        // return (
        //     <div>
        //         {this.state.sectionLocations && this.state.sectionLocations.map(x => this.comments.has(x.element.id) && <div key={x.bookmarkId} style={{ position: "absolute", top: x.top,left:x.left, width: "8px", height:"12px", }} title={x.customText}><NoteIcon key={x.element.id}  /></div>)}
        //     </div>)
    }

}
const provider = provide((state) => ({
    documentMounted: state.document.mounted,
    userNotes: state.userNotes,
   
}), {}).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const DocumentMapNoteIndicator = provider.connect(withRouter(DocumentMapNoteIndicatorComponent));