import * as React from 'react';
import { ChevronIcon } from '../Icons/Chevron';
import { ChevronDoubleIcon } from '../Icons/ChevronDouble';
import { StyledLinkButton } from '../Styled/Buttons/LinkButton'
import styled from 'styled-components/macro';
import { ToggleOffIcon } from '../Icons/ToggleOff';
import { ToggleOnIcon } from '../Icons/ToggleOn';
import { useSelector } from 'react-redux';
import { IRootState } from '../../store/store';
import { userSettingsActions } from '../../store/actions/userSettingsActions';
import { UserSettingFeaturesDto } from '../../api/SmartLibraryService';
import { provide } from '../../store/provide';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { IDocumentRouteProps } from '../../utils/routeProps/DocumentRouteProps';


const StyledChevronIcon = styled(ChevronIcon)`
    margin: 0rem 0.3125rem 0rem 0.3125rem;
`
const StyledChevronDoubleIcon = styled(ChevronDoubleIcon)`
    margin: 0rem 0.3125rem 0rem 0.3125rem;
    width: 0.4375rem;
`

const StyledInternalInfobox = styled.div`  
  
`

const StyledTitle = styled.h2`
    margin: 0;
    padding: 0;
`
const StyledBody = styled.body`
width: 550px;
height: 96px;
border: 1px solid #cccbc9;
background-color: #ffffff;
background-size: cover;
margin-left:7px;
display: grid;
grid-template-columns: 1fr auto 2.5rem;
`
const StyledBodyTitle = styled.h4`
width: 344px;
height: 24px;
font-family: Avenir Next;
font-size: 18px;
color: #0f204b;
text-decoration: none solid rgb(15, 32, 75);
line-height: 24px;
margin-bottom: 10px;
margin-left: 10px
`

const StyledSubtitle = styled.h6`
width: 450px;
height: 20px;
font-family: Avenir Next;
font-size: 14px;
color: #0f204b;
text-decoration: none solid rgb(15, 32, 75);
line-height: 20px;
margin-top:2px;
margin-left: 10px
`

const StyledStatusBar = styled.div`
width: 7px;
height: 96px;
background-color: #3f9c35;
background-size: cover;
`

class FeatureComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>> {
  
    render() {
        
        const ToggleOn =()=>{
            var advFilterSetting = new UserSettingFeaturesDto;
            advFilterSetting.advancedFilterFeature = false;
            this.props.saveUserSettings(advFilterSetting);
        }
        const ToggleOff =()=>{
            var advFilterSetting = new UserSettingFeaturesDto;
            advFilterSetting.advancedFilterFeature = true;
            this.props.saveUserSettings(advFilterSetting);
        }
       

        return (
            <StyledInternalInfobox>
                <div style={{display:"grid", gridTemplateColumns:"max-content"}}>
                <Link style={{ color: "#0067C5", gridArea:"1", width:"fit-content" }} to={"/"}>RS Explorer <StyledChevronIcon direction="Right" expanded={false} /></Link>
                <p style={{gridArea:"1 / 2 / 1/ 2", margin:"0px"}}>Settings <StyledChevronIcon direction="Right" expanded={false} /> Features</p>
                </div>
                <hr />
                <StyledTitle>Features</StyledTitle>

                <StyledStatusBar>
                    <StyledBody>

                        <StyledBodyTitle>Advanced filtering feature</StyledBodyTitle>
                        <StyledSubtitle style={{gridArea:"2"}}>Allow for showing and using advanced filtering in R&S Explorer</StyledSubtitle>
                        
                        <span style={{display:"flex", marginTop:"25px"}}>Off {this.props.userSetting.settings?.advancedFilterFeature ?<ToggleOnIcon  style={{margin:"3px"}} onClick={ToggleOn}></ToggleOnIcon> :
                            <ToggleOffIcon style={{margin:"3px"}} onClick={ToggleOff}></ToggleOffIcon> }
                            ON</span>
                       
                        

                    </StyledBody>
                    
                </StyledStatusBar>
                
            </StyledInternalInfobox>
        )
    }
}

const provider = provide((state) => ({
    userSetting: state.userSettings
}), { ...userSettingsActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const Feature = provider.connect(withRouter(FeatureComponent));
