import { ThunkAction } from "redux-thunk";
import { IRootState } from "../../store/store";
import { getOfflinePackagesClient } from '../../utils/proxyFactory';
import * as service from '../../api/SmartLibraryService';

interface ILoadOfflinePackages { type: "LOAD_OFFLINEPACKAGES"; }
interface ILoadOfflinePackagesComplete { type: "LOAD_OFFLINEPACKAGES_COMPLETE"; payload:service.IOfflinePackage[] }
interface ILoadOfflinePackagesFailed { type: "LOAD_OFFLINEPACKAGES_FAILED"; payload:{error:string} }

export type OfflinePackagesActions = ILoadOfflinePackages | ILoadOfflinePackagesComplete | ILoadOfflinePackagesFailed;

type TA = ThunkAction<void, IRootState, void, OfflinePackagesActions>;

export const offlinePackagesActions = {
    requestPackages: (): TA => async (dispatch,getState)  => {
        dispatch({
            type: "LOAD_OFFLINEPACKAGES"
        });
        var client = await getOfflinePackagesClient(dispatch,getState());
        await client.getOfflinePackages()
        .then(x => {         
          if (x != null) {
            dispatch({ type: "LOAD_OFFLINEPACKAGES_COMPLETE", payload: x })
          }else{
            dispatch({ type: "LOAD_OFFLINEPACKAGES_FAILED", payload:{error:"No offline packages returned"}})
          }          
        },
          (x:string) => {
            dispatch({ type: "LOAD_OFFLINEPACKAGES_FAILED", payload:{error:x}})           
          }
        );
    },
}