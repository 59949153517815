import * as service from '../api/SmartLibraryService';


export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    
    // eslint-disable-next-line no-mixed-operators, eqeqeq    
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const getParents = (node: Node | null) => {
  var nodes = [node]
  for (; node; node = node.parentElement) {
    if (nodes.indexOf(node) === -1)
      nodes.unshift(node)
  }
  return nodes
}
export const getPathToContainer = (el: Element): string => {
  if (el != null) {
    const parents = getParents(el).reverse();
    const stopAtParent = parents.find(x => x != null && x.nodeType === 1 && (x as HTMLElement).id.indexOf("start") >= 0);
    if (stopAtParent != null) {
      const parentsWithinDocument = parents.slice(0, parents.indexOf(stopAtParent) + 1);
      var topdown = parentsWithinDocument.reverse();
      // eslint-disable-next-line array-callback-return
      const pathToRoot = topdown.flatMap((x, index, all) => {
        if (x != null) {
          if (x.nodeType === 1 && (x as HTMLElement).id != null) {
            return (x as HTMLElement).id;
          } else {
            if (all != null && index > 0 && all[index - 1] != null) {
              const prevNode = all[index - 1];
              if (prevNode != null && prevNode.childNodes != null) {
                var array = Array.from(prevNode.childNodes);
                for (let i = 0; i < array.length; i++) {
                  if (array[i].isSameNode(x)) {
                    return i;
                  }
                }

              }
            }
          }
        }

      });
      return pathToRoot.filter(x=>x !== undefined && x.toString().length > 0).join("|");
    }
  }
  return "";
}


export const groupCommentsBySubsec = (notes: service.IBookmarkDto[]): Map<string, service.IBookmarkDto[]> => {

  return notes.reduce<Map<string, service.IBookmarkDto[]>>((map, current) => {
    if (current.bookmarkedIdFullPath) {
      const subsec1 = current.bookmarkedIdFullPath.split("|").find(x => x.startsWith("subsec1_"));
      if (subsec1) {
        var currentMap = map.get(subsec1);
        if (currentMap) {
          currentMap.concat(current);
        }
        map.set(subsec1, [current])
      }
    }
    return map;
  }, new Map<string, service.IBookmarkDto[]>());
}
export const groupCommentsByFullReference = (notes: service.IBookmarkDto[]): Map<string, service.IBookmarkDto[]> => {

  return notes.reduce<Map<string, service.IBookmarkDto[]>>((map, current) => {
    if (current.fullReferenceWithoutDocument) {
      var currentMap = map.get(current.fullReferenceWithoutDocument);
      if (currentMap) {
        map.set(current.fullReferenceWithoutDocument, [...currentMap, current])
      } else {
        map.set(current.fullReferenceWithoutDocument, [current])
      }
    }
    return map;
  }, new Map<string, service.IBookmarkDto[]>());
}
export const groupCommentsByLastValidInRevision = (notes: service.IBookmarkDto[]): Map<number, service.IBookmarkDto[]> => {

  return notes.reduce<Map<number, service.IBookmarkDto[]>>((map, current) => {
    if (current.lastValidInRevision) {
      var currentMap = map.get(current.lastValidInRevision);
      if (currentMap) {
        map.set(current.lastValidInRevision, [...currentMap, current])
      } else {
        map.set(current.lastValidInRevision, [current])
      }
    }
    return map;
  }, new Map<number, service.IBookmarkDto[]>());
}
