import * as React from 'react';
import { provide } from "../../../store/provide";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { flattenDocsInCategories } from '../../../utils/documentHelpers'
import { DocumentInfobox } from './DocumentMap_InfoBox';
import { DocumentMapTableOfContents } from './DocumentMap_TableOfContents'
import { DocumentMapBookmarks } from './DocumentMap_Bookmarks'
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { BookmarkIcon } from '../../Icons/Bookmark';
import { NavigationIcon } from '../../Icons/Navigation';
import { documentActions } from '../../../store/actions/documentActions';
import * as service from '../../../api/SmartLibraryService';
import { DotIcon } from '../../Icons/DotIcon';
import { SearchWithinDocument } from './DocumentMap_SearchWithinDocument'
import { userNotesActions } from '../../../store/actions/userNotesActions';
import { success } from '../../../utils/customToast';
import styled from 'styled-components/macro';
import { ridgeborderleft } from '../../StyledComponents/CustomStyles';
import {StyledMenuItemDiv} from '../../Styled/Menu/Buttons';
const StyledDocumentMapContainer = styled.div`    
    padding: ${({ theme}) =>  theme.padding.medium }  ${({ theme}) =>  theme.padding.large };
`

const StyledMapMenuContainer = styled.div`
    padding-top: 1rem;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
class DocumentMapComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>> {

    componentDidMount() {
        this.detectFromLeftBookmark();
        this.autoCopyBookmarksIfItgOrWpi();
        
    }
    componentDidUpdate() {
        this.detectFromLeftBookmark();
        this.autoCopyBookmarksIfItgOrWpi();
    }

    autoCopyBookmarksIfItgOrWpi = ()=>{
        const outofdateforthisdocument = this.props.outOfDateIndicatorsWithBookmarks !== null ? this.props.outOfDateIndicatorsWithBookmarks.find(x=>x.configId === this.props.match.params.configId) : undefined;
        if(outofdateforthisdocument){
            const allDocuments = flattenDocsInCategories(this.props.toc);            
            const currentCard = allDocuments.find(x => x.document.configId === this.props.match.params.configId && x.document.revision === +this.props.match.params.revision && x.document.isCurrent);        
            if(currentCard){
                    if(currentCard.document.documentCode){
                        if(currentCard.document.documentCode.startsWith("DNVGL-ITG") || currentCard.document.documentCode.startsWith("DNV-ITG") || currentCard.document.documentCode.startsWith("DNVGL-WPI") || currentCard.document.documentCode.startsWith("DNV-WPI")){
                            this.props.updateLastSeen(currentCard.document.configId || '',currentCard.document.revision);
                            this.props.copyFromRevisionToRevision(currentCard.document.configId || '',outofdateforthisdocument.revision,currentCard.document.revision);
                            success("This document has been released in a new edition. Your bookmarks/notes have been transferred.", {
                                toastId: "copy"
                            });
                            // There is a bug where this happens twice. Dirty workaround alert!
                        }
                    }
                    
            }  
        }

              
    }

    detectFromLeftBookmark = () => {
        if (this.props.document.document && this.props.document.mounted && this.props.location.state && (this.props.location.state as any).fromBookmarkLeft && this.props.match.params.configId === this.props.document.document.configId) {
            this.props.navigationModeBookmarks();
            this.props.history.replace({ pathname: this.props.location.pathname, hash: this.props.location.hash, state: null })
        }
    }
    render() {
         const outofdateforthisdocument = this.props.outOfDateIndicatorsWithBookmarks !== null ? this.props.outOfDateIndicatorsWithBookmarks.find(x=>x.configId === this.props.match.params.configId) : undefined;
     
        const allDocuments = flattenDocsInCategories(this.props.toc);
        const currentdocuments = allDocuments.filter(x => x.document.configId === this.props.match.params.configId && x.document.revision === +this.props.match.params.revision);
        if (currentdocuments.length === 0) {
            return <></>;
        }
        const currentdocument = currentdocuments[0];
        const currentdocumentAllRevisions = allDocuments.filter(x => x.document.documentCode === currentdocument.document.documentCode);
        const tocVisible = this.props.navigationMode === "TOC";
        const bookmarksVisible =this.props.navigationMode !== "TOC";
        return (
            <StyledDocumentMapContainer>
                <div>
                    <DocumentInfobox globalTocAllRevisions={currentdocumentAllRevisions} globalTocCurrentRevision={currentdocument} />
                </div>
                {this.props.document.document && this.props.document.document.revision > 0 && <div><SearchWithinDocument /></div>}
                <div>
                    <StyledMapMenuContainer>
                        <StyledMenuItemDiv style={{width:"40px", paddingTop:"8px", paddingBottom:"8px"}} title="Table of contents" data-cy="document-toc" onClick={this.props.navigationModeToc} isActive={tocVisible}  activeBorderOrientation="bottom"><NavigationIcon style={{ width: "1.5rem" }} /></StyledMenuItemDiv>
                        <StyledMenuItemDiv style={{width:"40px", paddingTop:"8px", paddingBottom:"8px"}}  title="My bookmarked content" data-cy="document-bookmarks" onClick={this.props.navigationModeBookmarks} isActive={bookmarksVisible} activeBorderOrientation="bottom"><BookmarkIcon style={{ fill:"#0F204B", width: "1.5rem" }} />
                         {outofdateforthisdocument && <DotIcon style={{position:"relative",top:"-20px",left:"13px"}} />}</StyledMenuItemDiv>
                    </StyledMapMenuContainer>
                    {this.props.navigationMode === "TOC" && <DocumentMapTableOfContents documentce={currentdocument} />}
                    {this.props.navigationMode !== "TOC" && <DocumentMapBookmarks outOfDateIndicatorsWithBookmarks={outofdateforthisdocument} allRevisions={currentdocumentAllRevisions} currentlyViewing={currentdocument} />}
                </div>

            </StyledDocumentMapContainer>)
    }

}
const provider = provide((state) => ({
    document: state.document,
    toc: state.toc.categories,
    navigationMode: state.document.navigationMode
}), { ...documentActions,...userNotesActions }).withExternalProps<{ outOfDateIndicatorsWithBookmarks: service.LastRevisionForUserIndicatorDto[] | null }>();

type Props = typeof provider.allProps;
export const DocumentMap = provider.connect(withRouter(DocumentMapComponent));