import React from 'react';
import { provide } from "../../../store/provide";
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PdfIcon } from '../../Icons/PdfIcon';
import { DocumentCardElement } from '../../Documents/DocumentCard';
import { getUpn } from '../../../utils/user';
import ReactTooltip from 'react-tooltip';
import { userSettingsActions } from '../../../store/actions/userSettingsActions';

import { Spinner } from '../../Icons/Spinner';
import { success, warn } from '../../../utils/customToast';

const StyledPDFIcon = styled(PdfIcon)`
    cursor:pointer;   
    color: red;
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  
  width: 20px;
  height: 50px;
  
  & .path {
    stroke: #5652BF;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;



class DocumentMapPdfComponent extends React.Component<Props & RouteComponentProps> {
    state ={
      loading :false
    }
    
    componentDidUpdate(){
      if(this.state.loading == false){
        ReactTooltip.rebuild();
      }
    }

    trackepdf =()=>{
      let eloquatrackpdfText;
      if(this.props.document.document.requiredVeracitySubscriptions){
        if(this.props.userSetting.settings?.internal){
          eloquatrackpdfText = "Download PDF"
        }
        else if(this.props.userSetting.settings?.subscription){
          eloquatrackpdfText = "Subscriber download PDF"
        }
      }
      else{
        eloquatrackpdfText = "Download PDF"
      }
      const thecurrentPdfLinkToUse = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + getUpn(this.props.user) + "&docTitle=" + this.props.document.document.documentCode + " " + this.props.document.document.title + "&docUrl=" + this.props.document.document.documentReference + "&type=" + eloquatrackpdfText;
      fetch(thecurrentPdfLinkToUse);
    }

    render(){

      const data = {configId: this.props.document.document.configId, revision: this.props.document.document.revision}
      const fetchpdf =(name:any)=>{
        this.setState({loading:true});
        // setTimeout(() => {
        //   this.setState({loading:false})
        // }, 1000);
        fetch(`../../api/Getpdf?configId=${data.configId}&revision=${encodeURIComponent(data.revision)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/pdf',
            },
           
          })
          .then((response) => response.blob())
          .then((blob) => {
            this.setState({loading:false});
            const file = new Blob([blob], { type: "application/pdf" });
            const url = window.URL.createObjectURL(file);
            var a = document.createElement('a');
            a.href = url;
            a.download = name + ".pdf";
            a.click();
            this.trackepdf();
            success("PDF downloaded successfully");
          }).catch(error => {
            console.log(error.response);
            warn("PDF download failed");
          })
      }


      if(this.props.document.document.requiredVeracitySubscriptions){
        if(this.props.userSetting.settings?.subscription || this.props.userSetting.settings?.internal){
          return(
            <div>
               {this.state.loading ? <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <p style={{opacity:"50%", display:"flex",alignItems:"center",justifyContent:"center",marginLeft: "0.625rem"}} data-tip="Download in PDF format">
                 <PdfIcon style={{ pointerEvents: "none", width: "1rem" }} /></p><StyledSpinner viewBox="0 0 50 50"><circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" /></StyledSpinner>
                </div> : 
                <p id="pdfdownload" style={{display:"flex",alignItems:"center",justifyContent:"center", marginLeft: "0.625rem", cursor:"pointer" }} onClick={()=>{fetchpdf(this.props.document.document.documentReference)}} data-tip="Download in PDF format"><PdfIcon style={{ pointerEvents: "none", width: "1rem" }} /></p>}
            </div>
          )
        }
        else{
          return(<div>
              <p style={{display:"flex",alignItems:"center",justifyContent:"center", marginLeft: "0.625rem" }} data-tip="Rules and Standards Explorer+ subscription required"><PdfIcon style={{ pointerEvents: "none", width: "1rem", opacity:"50%" }} /></p>
          </div>)
        }
      }
      else{
        return(
          <div>
            {this.state.loading ? <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <p style={{opacity:"50%", display:"flex",alignItems:"center",justifyContent:"center",marginLeft: "0.625rem"}} data-tip="Download in PDF format">
                 <PdfIcon style={{ pointerEvents: "none", width: "1rem" }} /></p><StyledSpinner viewBox="0 0 50 50"><circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" /></StyledSpinner>
                </div> : 
             <p id="pdfdownload" style={{display:"flex",alignItems:"center",justifyContent:"center", marginLeft: "0.625rem", cursor:"pointer" }} onClick={()=>fetchpdf(this.props.document.document.documentReference)} data-tip="Download in PDF format"><PdfIcon style={{ pointerEvents: "none", width: "1rem" }} /><ReactTooltip type="light" border place="bottom"/></p>
            }
             {/* <span title="Requires Subscription to Download pdf" style={{display:"flex",alignItems:"center",justifyContent:"center", marginLeft: "0.625rem"}}><PdfIcon style={{ pointerEvents: "none", width: "1rem", opacity:"50%" }} /></span> */}
            </div>
        )
      }
    }
}

const provider = provide((state) => ({ 
  user: state.user.userInfo,
  userSetting: state.userSettings
 
}), {...userSettingsActions }).withExternalProps<{document:DocumentCardElement}>();
type Props = typeof provider.allProps;

export const DocumentMap_Pdf = provider.connect(withRouter(DocumentMapPdfComponent));

