import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BookmarkIcon } from '../Icons/Bookmark';
import { DotIcon } from '../Icons/DotIcon';
import { NavigationIcon } from '../Icons/Navigation';
// import { FilterIcon } from '../Icons/Filter';
import { SimpleChevronDoubleIcon } from '../Icons/ChevronDouble';
import { provide } from "../../store/provide";
import { sideBarActions } from "../../store/actions/sideBarActions";
import * as service from '../../api/SmartLibraryService';
import * as tooltips  from '../../utils/tooltips';
import styled from 'styled-components/macro';
import {StyledMenuItemDiv} from '../Styled/Menu/Buttons'
import { userSettingsActions } from '../../store/actions/userSettingsActions';
import {FilterIcon} from '../../Components/Icons/Filter';
import { filterActions } from '../../store/actions/filterActions';
import { DocumentsActions } from '../Documents/DocumentsActions';

import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import { faHome, faListUl, faBookmark } from '@fortawesome/free-solid-svg-icons';

const StyledBookmarkIcon = styled(BookmarkIcon)`
    fill:  ${({ theme }) =>  theme.colors.icon.darkblue};
`;

const StyledFilterIcon = styled(FilterIcon)`
    width: 32px;
    height: 32px;
`;

class SidebarComponent extends React.Component<Props & RouteComponentProps>{
    
    toggleSideBar = () => {
        this.props.toggleSideBar(!this.props.sideBar.visible)
    }

    toggleSideBarNavigationNav = () => {
        this.props.toggleSideBarNavigation(true, "NAV");
        // this.props.clearValues();
        // this.props.clearCategory();
        window.scrollTo(0,0);
        this.props.history.push("/documents");

    }

    toggleSideBarNavigationHome = () => {
        this.props.toggleSideBarNavigation(true, "HOME")
        // this.props.clearCategory();
        this.props.history.push("/");
    }

    toggleSideBarNavigationMyBooks = () => {
        this.props.toggleSideBarNavigation(true, "MYBOOKS")
        // this.props.clearFilters();
        // this.props.clearValues();
        // this.props.clearCategory();
        // this.props.history.push("/bookmarks");
    }

    toggleSideBarNavigationFilter =()=>{
        this.props.toggleSideBarNavigation(true, "FILTER")
        this.props.history.push("/settings/features");

    }
    
    
    render() {
        const dashboardBarSelected = this.props.sideBar.visible && this.props.sideBar.tab === "HOME";
        const navBarSelected = this.props.sideBar.visible && this.props.sideBar.tab === "NAV";
        const favouritesSelected = this.props.sideBar.visible && this.props.sideBar.tab === "MYBOOKS";
        const filterSelected = this.props.sideBar.visible && this.props.sideBar.tab === "FILTER";
        return <div  style={{ display: "flex",  flexDirection: "column" }}>

            <StyledMenuItemDiv isActive={false}  activeBorderOrientation={"left"} onClick={this.toggleSideBar} title={this.props.sideBar.visible?tooltips.leftSidebarMinimize:tooltips.leftSidebarMaximize}>
                <SimpleChevronDoubleIcon className={this.props.sideBar.visible ? "rs-chevron rs-chevron-left" : " rs-chevron rs-chevron-right"} style={{ height: "1.25rem" }} />
            </StyledMenuItemDiv>

            <StyledMenuItemDiv title="All documents"  data-cy="leftnavigationmodebutton"  onClick={this.toggleSideBarNavigationHome} isActive={dashboardBarSelected} activeBorderOrientation={"left"}>
                <FontAwesomeIcon icon={faHome} size="lg" color='#0F204B' />                
            </StyledMenuItemDiv>

            <StyledMenuItemDiv title="All documents"  data-cy="leftnavigationmodebutton"  onClick={this.toggleSideBarNavigationNav} isActive={navBarSelected} activeBorderOrientation={"left"}>            
                <FontAwesomeIcon icon={faListUl} size="lg" color='#0F204B' />                
            </StyledMenuItemDiv>

             <StyledMenuItemDiv title="My bookmarks" data-cy="leftbookmarkmodebutton" onClick={this.toggleSideBarNavigationMyBooks} isActive={favouritesSelected} activeBorderOrientation={"left"}>

             <StyledBookmarkIcon style={{ width: "1.5rem"}} />

                {this.props.outOfDateIndicatorsWithBookmarks && this.props.outOfDateIndicatorsWithBookmarks.length > 0 && <DotIcon style={{ position: "absolute", left: "2.4rem" }} />}
            </StyledMenuItemDiv>

            {/* <StyledMenuItemDiv isActive={filterSelected}  activeBorderOrientation={"left"} onClick={this.toggleSideBarNavigationFilter}>
                 {this.props.userSetting.settings?.advancedFilterFeature ? <StyledFilterIcon style={{ width: "1.5rem"}}/> : ('')}
           </StyledMenuItemDiv> */}
            
        </div>
    }
}

const provider = provide((state) => ({
    sideBar: state.sideBar,
    notes: state.userNotes.notes,
    toc: state.toc,
    userSetting: state.userSettings,
    filterState: state.filterState

}), { ...sideBarActions, ...userSettingsActions, ...filterActions,...DocumentsActions }).withExternalProps<{ outOfDateIndicatorsWithBookmarks: service.LastRevisionForUserIndicatorDto[] | null }>();

type Props = typeof provider.allProps;
export const Sidebar = provider.connect(withRouter(SidebarComponent));

