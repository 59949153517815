import { Reducer } from "react";
import { start } from "repl";
import { ICategory } from "../../api/SmartLibraryService";
import { DocumentCardElement } from "./DocumentCard";
import { DocumentsActions } from "./DocumentsActions";
import { DocumentListActions, documentListActions } from "../../store/actions/documentListActions";
export interface IListContext {
    list: DocumentCardElement[];
    isFetching: boolean;
    error: string | null;
    hasMore: boolean;
    start:number;
    stop:number
}
export const DocumentsReducer: Reducer<IListContext, DocumentsActions>
    = (state = { list: [], isFetching: false, error: null, hasMore: true, start:0, stop:150 }, action) => {

        switch (action.type) {
            case "LOAD_LIST": {
                return { ...state, isFetching: true };
            }
            case "CLEAR_VALUES":{
                return{hasMore:true, start: 0, stop:150, isFetching:false, error:null, list:[]}
            }
            case "LOAD_LIST_COMPLETE": {
                return { ...state, isFetching: false, error: null, list: action.payload }
            }
            case "LOAD_LIST_FAILED": {
                return { ...state, isFetching: false, error: action.payload.error, list: [] }
            }
            case "SET_HAS_MORE": {
                return { ...state, hasMore: action.payload.renderarrayHasmore }
            }
            case "UPDATE_VALUES":{
                return{...state, start:state.start+150, stop:state.stop +150 }
            }
            case "DOCUMENTS_LIST_SORT":{
                return{...state, list:sort(action.column, action.order, state.list)}
            }
            default:
                break;
        }

        return state;
    };

     function sort(column:String, order: String, list:DocumentCardElement[]){
        return list.sort((a, b)=> (order === "Ascending" ? 1 : -1) * ((column=== "code" ? a.document.documentCode : a.document.title )|| '').localeCompare((column=== "code" ? b.document.documentCode : b.document.title )|| '') )
     }