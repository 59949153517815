import { useSelector } from "react-redux";
import { IRootState } from "../../store/store";
import { flattenDocsInCategories } from "../../utils/documentHelpers";
import { DetectLastSeenDifferentThanCurrent } from "../Document/DocumentRender";
import { DocumentFilters } from "../Documents/Filters/DocumentFilters";
import { Advfilter } from "../FeaturesSettings/AdvFilter_Feature";
import { Bookmarks } from "./Bookmarks/Bookmarks";
import { Sidebar } from "./Sidebar";
import styles from "../ContentManager/ContentManager.module.scss";
import { DocumentCardElement } from "../Documents/DocumentCard";
import * as service from '../../api/SmartLibraryService';
import { IDocumentFilters } from "../../store/reducers/filterReducer";

interface Menuprops {
    children: React.ReactNode;
    layout: string;
}
export function Menu(props: Menuprops) {
    const allDocs = useSelector((state: IRootState) => flattenDocsInCategories(state.toc.categories));
    const currentDocs = allDocs && allDocs.filter(doc => doc.document.isCurrent);

    //const currentDocs = useSelector((state: IRootState) => state.toc.list);
    const filterState =  useSelector((state: IRootState) => state.filterState);
    const searchResult =  useSelector((state: IRootState) => state.searchResult);
    const searchBox =  useSelector((state: IRootState) => state.searchBox);

    let filteredDocs: DocumentCardElement[] =[];
    filteredDocs = currentDocs;
    let filterRangeDoc = Filter(allDocs, filterState); 

    //only filter
    if(filterState.isFiltered && searchResult.query === null){
      filteredDocs = filterRangeDoc; 
    }

    //only search
    if (searchResult.query && searchResult.searchResult && !filterState.isFiltered) {
        let filterRangeSearchDoc = Filter(currentDocs, filterState); 
        filteredDocs = filterBasedOnSearch(filterRangeSearchDoc, searchResult.searchResult);
    }

    //only current docs
    if(!filterState.isFiltered && searchResult.query ===null){
        filteredDocs = Filter(currentDocs, filterState);  
    }

    //both filter and search
    if(filterState.isFiltered && searchResult.query && searchResult.searchResult){
        filteredDocs = filterBasedOnSearch(filterRangeDoc, searchResult.searchResult);
    }
   

    const outOfDateIndicatorsWithBookmarks = DetectLastSeenDifferentThanCurrent(currentDocs);

    const isGlobSer = useSelector((state: IRootState) => state.globalsearch.compVisible);
    const sideBar = useSelector((state: IRootState) => state.sideBar);
    if (props.layout === "DocumentRender") {
        return (
            <div style={isGlobSer ? { position: "relative", top: "250px" } : {}}>
                <div className={sideBar.visible ? styles.leftSideBarExpanded : styles.leftSideBarCollapsed} >
                    <div>
                        <div className={styles.documentLayout}>
                        <div data-cy="sidebarnavigation" className={styles.sideBar} style={{ gridColumn: 1, gridRowStart: 1, gridRowEnd: 3, borderLeft: "1px solid #f2f2f2", borderTop: "1px solid #f2f2f2" }}>
                                        <div className="rs-sticky" style={isGlobSer ? { top: "210px", overflowY: "auto", height: "calc(100vh - 210px)" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Sidebar outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                        </div>
                                    </div>
                                    <div data-cy="sidebarcontent" className={sideBar.visible ? "rs-sticky" : styles.documentFilters} style={isGlobSer ? { top: "208px", gridRowStart: 1, gridRowEnd: 3, backgroundColor: "rgb(249, 249, 249)", height: "calc(100vh - 208px)" } : { gridRowStart: 1, gridRowEnd: 3, backgroundColor: "rgb(249, 249, 249)", height: "100%" }}>
                                        <div className="rs-sticky" style={isGlobSer ? { height: "calc(100vh - 208px)", top: "208px", overflowY: "auto" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto" }}>
                                        {
                                            sideBar.tab === "MYBOOKS" ? <Bookmarks documentCards={allDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} /> : <DocumentFilters documentCards={filteredDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                        }

                                        </div>
                                    </div>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    if (props.layout === "ListLayout") {
        return (
            <div style={isGlobSer ? { position: "relative", top: "250px" } : {}}>
                <div className={sideBar.visible ? styles.leftSideBarExpanded : styles.leftSideBarCollapsed} >
                    <div className={styles.documentListLayout}>
                        <div data-cy="sidebarnavigation" className={styles.sideBar} style={{ gridColumn: 1, borderLeft: "1px solid #f2f2f2", borderTop: "1px solid #f2f2f2" }}>
                            <div className="rs-sticky" style={isGlobSer ? { top: "215px" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto", overflowX: "hidden" }}>
                                <Sidebar outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                            </div>
                        </div>
                        <div data-cy="sidebarcontent" className={sideBar.visible ? "rs-sticky" : styles.documentFilters} style={isGlobSer ? { top: "232px", backgroundColor: "rgb(249, 249, 249)", height: "calc(96vh - 95px)" } : { backgroundColor: "rgb(249, 249, 249)", height: "100%" }}>
                            <div className="rs-sticky" style={isGlobSer ? { top: "210px", height: "calc(100vh - 210px)", overflowY: "auto" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto" }}>
                                {
                                     sideBar.tab === "MYBOOKS" ? <Bookmarks documentCards={allDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} /> : <DocumentFilters documentCards={filteredDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                }
                            </div>
                        </div>

                        {props.children}

                    </div>
                </div>
            </div>
        );
    }

    if (props.layout === "SubscriptionList") {
        return(<div style={isGlobSer ? { position: "relative", top: "250px" } : {}}>
            <div className={sideBar.visible ? styles.leftSideBarExpanded : styles.leftSideBarCollapsed} >
                <div className={styles.documentListLayout}>
                    <div data-cy="sidebarnavigation" className={styles.sideBar} style={{ gridColumn: 1, borderLeft: "1px solid #f2f2f2", borderTop: "1px solid #f2f2f2" }}>
                        <div className="rs-sticky" style={isGlobSer ? { top: "215px" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto", overflowX: "hidden" }}>
                            <Sidebar outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                        </div>
                    </div>
                    <div data-cy="sidebarcontent" className={sideBar.visible ? "rs-sticky" : styles.documentFilters} style={isGlobSer ? { top: "232px", backgroundColor: "rgb(249, 249, 249)", height: "calc(96vh - 95px)" } : { backgroundColor: "rgb(249, 249, 249)", height: "100%" }}>
                        <div className="rs-sticky" style={isGlobSer ? { top: "210px", height: "calc(100vh - 210px)", overflowY: "auto" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto" }}>
                           {
                                    sideBar.tab === "MYBOOKS" ? <Bookmarks documentCards={allDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} /> : <DocumentFilters documentCards={currentDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                }
                        </div>
                    </div>
                    {props.children}

                </div>
            </div>
        </div>
        )}

        if(props.layout ==="Settings"){
            return(
                <div style={isGlobSer ? { position: "relative", top: "250px" } : {}}>
                <div className={sideBar.visible ? styles.leftSideBarExpanded : styles.leftSideBarCollapsed} >
                    <div>
                        <div className={styles.documentListLayout}>
                            <div data-cy="sidebarnavigation" className={styles.sideBar} style={{ gridColumn: 1, borderLeft: "1px solid #f2f2f2", borderTop: "1px solid #f2f2f2" }}>
                                <div className="rs-sticky" style={isGlobSer ? { top: "215px" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto", overflowX: "hidden" }}>
                                    <Sidebar outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                </div>
                            </div>
                            <div data-cy="sidebarcontent" className={sideBar.visible ? "rs-sticky" : styles.documentFilters} style={isGlobSer ? { top: "232px", backgroundColor: "rgb(249, 249, 249)", height: "calc(96vh - 95px)" } : { backgroundColor: "rgb(249, 249, 249)", height: "100%" }}>
                                <div className="rs-sticky" style={isGlobSer ? { top: "210px", height: "calc(100vh - 210px)", overflowY: "auto" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto" }}>
                                    {sideBar.tab === "NAV"
                                        ? <DocumentFilters documentCards={currentDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                        : sideBar.tab === "MYBOOKS" ? <Bookmarks documentCards={allDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} /> : (<Advfilter globalTocAllRevisions={allDocs}></Advfilter>)
                                    }
                                </div>
                            </div>
    
                            {props.children}
                           
                           
                        </div>
                    </div>
                </div>
            </div>
            )
        }

        if(props.layout ==="Dashboard"){
            return(
                <div style={isGlobSer ? { position: "relative", top: "250px" } : {}}>
                <div className={sideBar.visible ? styles.leftSideBarExpanded : styles.leftSideBarCollapsed} >
                    <div>
                        <div className={styles.documentListLayout}>
                            <div data-cy="sidebarnavigation" className={styles.sideBar} style={{ gridColumn: 1, borderLeft: "1px solid #f2f2f2", borderTop: "1px solid #f2f2f2" }}>
                                <div className="rs-sticky" style={isGlobSer ? { top: "215px" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto", overflowX: "hidden" }}>
                                    <Sidebar outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                </div>
                            </div>
                            <div data-cy="sidebarcontent" className={sideBar.visible ? "rs-sticky" : styles.documentFilters} style={isGlobSer ? { top: "232px", backgroundColor: "rgb(249, 249, 249)", height: "calc(96vh - 95px)" } : { backgroundColor: "rgb(249, 249, 249)", height: "100%" }}>
                                <div className="rs-sticky" style={isGlobSer ? { top: "210px", height: "calc(100vh - 210px)", overflowY: "auto" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto" }}>
                                    {
                                        sideBar.tab === "MYBOOKS" ? <Bookmarks documentCards={allDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} /> : <DocumentFilters documentCards={currentDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                    }
                                </div>
                            </div>
    
                            {props.children}
                           
                           
                        </div>
                    </div>
                </div>
            </div>
            )
        }

        if (props.layout === "Search") {
            return (
                <div style={isGlobSer ? { position: "relative", top: "250px" } : {}}>
                    <div className={sideBar.visible ? styles.leftSideBarExpanded : styles.leftSideBarCollapsed} >
                        <div className={styles.documentListLayout}>
                            <div data-cy="sidebarnavigation" className={styles.sideBar} style={{ gridColumn: 1, borderLeft: "1px solid #f2f2f2", borderTop: "1px solid #f2f2f2" }}>
                                <div className="rs-sticky" style={isGlobSer ? { top: "215px" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto", overflowX: "hidden" }}>
                                    <Sidebar outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                </div>
                            </div>
                            <div data-cy="sidebarcontent" className={sideBar.visible ? "rs-sticky" : styles.documentFilters} style={isGlobSer ? { top: "232px", backgroundColor: "rgb(249, 249, 249)", height: "calc(96vh - 95px)" } : { backgroundColor: "rgb(249, 249, 249)", height: "100%" }}>
                                <div className="rs-sticky" style={isGlobSer ? { top: "210px", height: "calc(100vh - 210px)", overflowY: "auto" } : { height: "calc(100vh - 73px)", top: "73px", overflowY: "auto" }}>
                                {
                                     sideBar.tab === "MYBOOKS" ? <Bookmarks documentCards={allDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} /> : <DocumentFilters documentCards={filteredDocs} outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                                }
                                </div>
                            </div>
    
                            {props.children}
    
                        </div>
                    </div>
                </div>
            );
        }
    return <></>

}


function Filter(currentDocs: DocumentCardElement[], filterstate:IDocumentFilters){
  
    if (filterstate.isFiltered) {

        let newArr: DocumentCardElement[] = [];
        //   let internalDoc = currentDocs.filter(doc=>doc.category.code ==="WPI");
        let newArray = currentDocs.filter(doc => doc.document.issued != undefined && filterstate.issuedDate != null ? parseInt(doc.document.issued) <= parseInt(filterstate.issuedDate) || doc.category.code?.includes("WPI") || doc.category.code?.includes("ITG") : '');
        newArray.forEach((doc, index) => {
            let result = newArr.filter(x => x.document.documentCode === doc.document.documentCode)
            if (result.length == 0) {
                newArr.push(doc);
            }
        });
        //var combineDoc = [ ...newArr,...internalDoc]
        //console.log("combined", combineDoc);
        return newArr;
    }
    return currentDocs;
  }
  
  function filterBasedOnSearch (documents: DocumentCardElement[], searchResult: service.IResult) {
    const searchHits: { [id: string]: service.IHit; } = {};
    const documentCodeHits: { [id: string]: service.IHit; } = {};
    const titleHits: { [id: string]: service.IHit; } = {};
    const htmlHits: { [id: string]: service.IHit; } = {};
    //Find allowed documents
  
  
    if (searchResult.documentCode) {
        searchResult.documentCode.forEach(hit => {
            if (!searchHits[hit.configId || '']) searchHits[hit.configId || ''] = hit;
            if (!documentCodeHits[hit.configId || '']) documentCodeHits[hit.configId || ''] = hit;
        });
    }
    if (searchResult.title) {
        searchResult.title.forEach(hit => {
            if (!searchHits[hit.configId || '']) searchHits[hit.configId || ''] = hit;
            if (!titleHits[hit.configId || '']) titleHits[hit.configId || ''] = hit;
        });
    }
    if (searchResult.html) {
        searchResult.html.forEach(hit => {
            if (!searchHits[hit.configId || '']) searchHits[hit.configId || ''] = hit;
            if (!htmlHits[hit.configId || '']) htmlHits[hit.configId || ''] = hit;
        });
    }
  
    //Filter documents to only allowed documents
    documents = documents.filter(x => searchHits.hasOwnProperty(x.document.configId || 'ERROR'));
  
  
    documents.forEach(x => {
        var hitKey = x.document.configId || 'ERROR';
        x.searchScore = searchHits[hitKey] && searchHits[hitKey].score ? searchHits[hitKey].score || 0 : 0;
        x.htmlHit = htmlHits[hitKey] && htmlHits[hitKey];
        x.titleHit = titleHits[hitKey] && titleHits[hitKey];
        x.documentCodeHit = documentCodeHits[hitKey] && documentCodeHits[hitKey];
        if (x.documentCodeHit) {
            x.searchScore = (x.documentCodeHit.score || 0) * 10000;
        }
        if (x.titleHit) {
            x.searchScore = (x.titleHit.score || 0) * 100;
        }
    });
  
    documents = documents.sort((a, b) => b.searchScore - a.searchScore);
  
    return documents;
  }