import * as service from '../api/SmartLibraryService';
import { DocumentCardElement } from '../Components/Documents/DocumentCard';

export const editionyyyyMMtoStringShort = (edition:string)=>
{
  let editionDate:Date  = new Date(+edition.substr(0,4),+edition.substr(4,2)-1,1);
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[editionDate.getMonth()] + ", "+ editionDate.getFullYear()
}
export const editionyyyyMMtoStringLong = (edition:string)=>
{
  let editionDate:Date  = new Date(+edition.substr(0,4),+edition.substr(4,2)-1,1);
  var monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
  return monthNames[editionDate.getMonth()] + " "+ editionDate.getFullYear()
}
export const editionyyyytoStringShort = (edition:string)=>
{
  let editionDate:Date  = new Date(+edition.substr(0,4),+edition.substr(4,2)-1,1);
  return editionDate.getFullYear()
}
export const editionMMtoStringShort = (edition:string)=>
{
  let editionDate:Date  = new Date(+edition.substr(0,4),+edition.substr(4,2)-1,1);
  var monthNames = ["01", "02", "03", "04", "05", "06",
  "07", "08", "09", "10", "11", "12"];
  return monthNames[editionDate.getMonth()]
}
export const editionAndAmendedLongStrings = (edition:string |undefined,amended:string |undefined)=>{  
  const editionString = editionyyyyMMtoStringShort(edition || '');
  const amendedString =  amended ?" (amended " + editionyyyyMMtoStringShort(amended || '')+ ")":'';
   return editionString + amendedString;
 }

export const editionAndAmendedLong = (documentCard:DocumentCardElement)=>{  
 const editionString = editionyyyyMMtoStringShort(documentCard.document.edition || '');
 const amendedString =  documentCard.document.amended ?" (amended " + editionyyyyMMtoStringShort(documentCard.document.amended || '')+ ")":'';
  return editionString + amendedString;
}
export const isCategoryFiltered = (documentCard: DocumentCardElement, categoryFilter: string | null, subCategoryFilter: string | null) =>
  (categoryFilter === null || (documentCard.category && documentCard.category.code === categoryFilter))
  &&
  (subCategoryFilter === null || (documentCard.subCategory && documentCard.subCategory.code === subCategoryFilter))


// export const isFavouriteFiltered = (documentCard: DocumentCardElement, favouriteFilterEnabled:boolean, notes:service.IBookmark[]| null)=>{
//   return !favouriteFilterEnabled || (notes && notes.find(x=>x.configId === documentCard.document.configId) != null);
// }
export const isIndustryFiltered = (documentCard: DocumentCardElement, domainId: service.Audience | null) =>
  domainId === null || (documentCard.document.audience && documentCard.document.audience.filter(a => a === domainId).length > 0)
export const filterByIndustry = (documentCards: DocumentCardElement[], domainId: service.Audience | null) => {
  return documentCards.filter(documentInfo => isIndustryFiltered(documentInfo, domainId));
}

export const isIssuedFiltered = (documentCard: DocumentCardElement, issedDate: string | null, retired: service.WithdrawnType | null , replaced: service.WithdrawnType | null) =>
(issedDate === null || (documentCard.document.issued && documentCard.document.issued <= issedDate)) && (retired === null || documentCard.document.withdrawn != retired) && (replaced === null || documentCard.document.withdrawn!=replaced)
export const filterByIssuedDate = (documentCards: DocumentCardElement[], issedDate: string | null, retired: service.WithdrawnType | null, replace: service.WithdrawnType | null) => {
  return documentCards.filter(documentInfo => isIssuedFiltered(documentInfo, issedDate, retired, replace));
}


// export const filterByFavourites = (documentCards: DocumentCardElement[], favouriteFilterEnabled:boolean, notes:service.IBookmark[]| null) => {
//   return documentCards.filter(documentInfo => isFavouriteFiltered(documentInfo, favouriteFilterEnabled,notes));
// }
export  const flattenDocsInCategories=(categories: service.ICategory[] | null): DocumentCardElement[]=>
{
  let documents:  DocumentCardElement[] = [];

  categories && categories.forEach(x=>documents.push(...flattenDocs(x.subCategories)));
  return documents;
}
const flattenDocs = (categories: service.ICategory[] | undefined): DocumentCardElement[] =>
  categories ? 
  categories.flatMap(category => category.documents && category.documents.length > 0 ?
    category.documents.map<DocumentCardElement>(document => {
      return {
        document: document,
        category: category,
        subCategory: null,
        searchScore: 0,
        htmlHit: null,
        titleHit: null,
        documentCodeHit: null
      };
    })
    :
    category.subCategories ? category.subCategories.flatMap(subCategory => subCategory.documents ?
      subCategory.documents.map<DocumentCardElement>(document => {
        return {
          document: document,
          category: category,
          subCategory: subCategory,
          searchScore: 0,
          htmlHit: null,
          titleHit: null,
          documentCodeHit: null
        };
      })
      :
      [])
      : []) : [];