import { Reducer } from "redux";
import { SearchBoxActions } from "../actions/searchBoxActions";
import {FilterActions} from "../actions/filterActions";
import { SearchActions } from "../actions/searchActions";
export const searchBoxReducer: Reducer<{ searchBoxText: string; }, SearchBoxActions |SearchActions>
    = (state = { searchBoxText: "" }, action) => {

        switch (action.type) {
            case "SET_SEARCHBOXTEXT": {
                state.searchBoxText = action.payload.searchBoxText;
                return { ...state };
            }
            case "SET_SEARCH_CLEAR":{
                return { ...state, searchBoxText:"" };
            }
            default:
                break;
        }

        return state;
    };