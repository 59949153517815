import { Reducer } from "redux";
import { SearchDocumentBoxActions } from "../actions/searchDocumentBoxActions";
import { SearchBoxActions } from "../actions/searchBoxActions";
import {FilterActions} from "../actions/filterActions";
export const searchDocumentBoxReducer: Reducer<{ searchDocumentBoxText: string; }, SearchDocumentBoxActions |FilterActions|SearchBoxActions>
    = (state = { searchDocumentBoxText: "" }, action) => {

        switch (action.type) {
            // case "SET_SEARCHBOXTEXT": {
            //     state.searchDocumentBoxText = action.payload.searchBoxText;
            //     return { ...state };
            // }
            case "SET_SEARCHDOCUMENTBOXTEXT": {
                state.searchDocumentBoxText = action.payload.searchDocumentBoxText;
                return { ...state };
            }
            case "SET_FILTER_CLEAR":{
                state.searchDocumentBoxText = "";
                return { ...state };
            }
            default:
                break;
        }

        return state;
    };