import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";


interface IResetState { type: "RESET_STATE"; }


export type ResetActions = IResetState;
type TA = ThunkAction<void, IRootState, void, ResetActions>;

export const resetActions = {

  resetState: (): TA => (dispatch) => {
    dispatch({ type: "RESET_STATE" });
  }
}