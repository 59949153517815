import { ThemeType } from "../../styles/theme";

export const ellipsis = () => `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const iconcontainer = (theme:ThemeType) => `
display:flex;    
align-items:center;
width: 32px;
height: 32px;
justify-content:center;
&:hover{
  background:${theme.colors.background.neutral05};
  border-radius: 1.1563rem;
}
`

export const ridgeborderbottom = (theme:ThemeType) => `
border-bottom-color: ${theme.colors.border.neutral10};
border-bottom-width: 0.0625rem;
border-bottom-style: ridge;
`

export const ridgeborderleft = (theme:ThemeType) => `
border-left-color: ${theme.colors.border.neutral10};
border-left-width: 0.0625rem;
border-left-style: ridge;
`;


export const ridgeborderright = (theme:ThemeType) => `
border-right-color: ${theme.colors.border.neutral10};
border-right-width: 0.0625rem;
border-right-style: ridge;
`;