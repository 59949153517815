import { Reducer } from "redux";
import { AuthConfigActions } from "../actions/authConfigActions";
import * as service from '../../api/SmartLibraryService';
// import { MsalAuthProvider, LoginType } from 'react-aad-msal';
interface IAuthConfigContext {
    authConfig: service.IAuthorization | null;
    isFetching: boolean;    
    error:string | null;
    authProvider:null;
  }
export const authConfigReducer: Reducer<IAuthConfigContext, AuthConfigActions>
    = (state = { authConfig:null,isFetching:false,error:null,authProvider:null }, action) => {

        switch (action.type) {
            case "LOAD_AUTH_CONFIG": {                
                return { ...state,isFetching:true ,authConfig:null, authProvider:null};
            }
            case "LOAD_AUTH_CONFIG_COMPLETE":{
                return { ...state,isFetching:false,error:null,authConfig:action.payload}
            }   
            case "LOAD_AUTH_CONFIG_FAILED":{
                return { ...state,isFetching:false,error:action.payload.error,authConfig:null,authProvider:null}
            }        
            default:
                break;
        }

        return state;
    };
//     //https://github.com/syncweek-react-aad/react-aad/blob/master/sample/src/authProviderFactory.js
// const getAuthprovider = (config:service.IAuthorization):MsalAuthProvider=>{
// return  new MsalAuthProvider(
//     {
//       auth: {
//         authority: config.authority,
//         clientId: config.clientId || '',
//         postLogoutRedirectUri: "https://www.veracity.com/auth/logout",
//         redirectUri: "https://"+window.location.hostname+(window.location.port ? ':'+window.location.port: '') + process.env.PUBLIC_URL   + "/",
//         validateAuthority: true,
        
//         // After being redirected to the "redirectUri" page, should user
//         // be redirected back to the Url where their login originated from?
//         navigateToLoginRequestUrl: true,
//       },
//       cache: {
//         cacheLocation: 'localStorage',//Instead of session, which might lead to excessive SAML token size https://wiki.dnvgl.com/display/RCMATD/SAMLSession+and+Header+request+too+long
//         storeAuthStateInCookie: false, //false for now,perhaps Needed so edge won't loop https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/705
//       },
//     },
//     {
//       scopes: [config.scope ||''],
//     },
//     LoginType.Redirect,
//   );
// }
