import { MouseEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'reactjs-popup';
import styled from 'styled-components/macro';
import { veracityActions } from '../../../store/actions/veracityActions';
import { IRootState } from '../../../store/store';
import { Spinner } from '../../Icons/ActionSpinner';

import 'reactjs-popup/dist/index.css';

import { getUpn,isInternal } from '../../../utils/user';


interface FreeTrail {
  type: string;
  title:string|undefined;
}

export function FreeTrailButton(props: FreeTrail) {
  const dispatch = useDispatch();
  const userInfo = useSelector((x: IRootState) => x.user.userInfo);
  const startTrial = () => {
    if(props.type=="Document"){
      const DocEloqua = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + getUpn(userInfo) + "&docTitle=" + props.title + " " + "&docUrl=" + window.location + "&type=" + "Non Subscriber clicks 14 day trial";
      fetch(DocEloqua);
    }
    dispatch(veracityActions.addUserToTrial());
  };

  const addUserToTrial = useSelector((state: IRootState) => {
    return state.addUserToTrial;
  });

  return (
    <>
      <Popup
        trigger={
          <StyledStartTrialButton>Start 14 day trial</StyledStartTrialButton>
        }
        modal
      >
        {(close: MouseEventHandler) => (
          <div style={{ textAlign: "center" }}>
            <h2>Start your 14 day trial</h2>
            <p>
              You are about to start a free trial of Rules and Standards
              Explorer+, and will be asked to accept the terms of use.
            </p>
            {addUserToTrial.isAdding || addUserToTrial.complete ? (
              <>
                <Spinner />
                <p>...adding trial subscription to your user, please wait.</p>
              </>
            ) : (
              <>
                <StyledStartTrialButton
                  style={{ gridArea: "trialB" }}
                  onClick={(event: React.MouseEvent) => {
                    startTrial();
                    // close(event);
                  }}
                >
                  Start 14 day trial
                </StyledStartTrialButton>
                <StyledCancelTrialButton
                  onClick={(event: React.MouseEvent) => close(event)}
                >
                  Cancel
                </StyledCancelTrialButton>
              </>
            )}
          </div>
        )}
      </Popup>
    </>
  );
}

// Styled component:
const StyledStartTrialButton = styled.button`
  background-color: #36842D;
  border-color: #36842D;
  border: 1px solid transparent;
  text-decoration: none;
  color: #ffffff;
  font-size: medium;
  font-weight: 400;
  width: 160px;
  height: 30px;
  margin-top: 1rem;

  :hover {
    background-color: #276121;
  }
`

const StyledCancelTrialButton = styled.button`
  background-color: #999999;
  border-color: #888888;
  border: 1px solid transparent;
  text-decoration: none;
  color: #ffffff;
  font-size: medium;
  font-weight: 400;
  width: 160px;
  height: 30px;
  margin-top: 1rem;
  margin-left: 2rem;

  :hover {
    background-color: #666666;
  }
`