import * as React from 'react';
import { MathJax3 } from '../../MathJax/MathJax';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { provide } from "../../../store/provide";
import { scrolltoId, scrolltoElement } from '../../../utils/scroll';
import { selectedTextActions } from '../../../store/actions/selectedTextActions'
import { userNotesActions } from '../../../store/actions/userNotesActions'
import * as service from '../../../api/SmartLibraryService';
import { getPathToContainer, uuidv4 } from '../../../utils/comments'
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { ContextMenu } from './DocumentViewer_ContextMenu'
import { debounce } from '../../../utils/debounce'
import { DocumentCardElement } from '../../Documents/DocumentCard';
import { warn } from '../../../utils/customToast';

import styled from 'styled-components/macro';
import { ExternalLinkIcon } from '../../Icons/ExternalLinkIcon';
import { LongArrowLeftIcon } from '../../Icons/LongArrowLeftIcon';
import { LongArrowRightIcon } from '../../Icons/LongArrowRightIcon';
import { currentYear } from '../../../utils/variables';

interface IDocumentViewerState {
    currentHighlightedElement: HTMLElement | undefined,
    tableOverlayComponent: HTMLElement | undefined,
    currentTableHighlighted: { element: HTMLElement | undefined, overflowed: boolean },
}
// If an element is attempted to be bookmarked, and is within one of these elements, we should choose the container
const containerElementsToPreferForBookmarks = [
    //'entry',
    'description',
    'dterm',
    'guidancenote',
    'interpretation',
    'listitem',
    'note',
    'quote'
];
//if an element is valid for bookmark, it's id starts with one of these
const validDirectElements = [...containerElementsToPreferForBookmarks,
    'title',
    'p',
    'equation',
    'figure'
]

const StyledIconContainer = styled.div`
   
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: ${({theme}) =>theme.colors.background.neutral80+ '80'} ;
    box-shadow: 2px 4px 8px #0000004d;
    margin: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,&:focus {
        background-color: ${({theme}) =>theme.colors.background.neutral80};
    }
`;
class DocumentViewerComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>, IDocumentViewerState> {

    state: IDocumentViewerState = { currentHighlightedElement: undefined, tableOverlayComponent: undefined, currentTableHighlighted: { element: undefined, overflowed: false } };
    getTableElement = (fullPath: string): HTMLElement | null => {
        const pathParts = fullPath.split('|');
        var table = pathParts.find(x => x.startsWith("table"));

        if (table !== null && table !== undefined) {
            return document.querySelector("#" + table);
        }
        return null;
    }
    clickHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (e != null && e.target != null && e.target != null) {
            const targetClass = (e.target as any).getAttribute("class") || '';
            const actualTarget = targetClass.indexOf("rs-highlight-search-term") > -1 ? (e.target as HTMLElement).parentElement : e.target as HTMLElement;
            if (actualTarget != null) {
                if (actualTarget.getAttribute('class') !== "url") {
                    var href: string = actualTarget.getAttribute('href') || '';
                    if (href.startsWith('#')) {
                        var scrollToId = href.slice(1);
                        scrolltoId(scrollToId);

                    } else if (href.length > 0) {
                        if (this.props.documentCard.document.isCurrent) {
                            this.props.history.push(href);
                        } else {
                            warn("Links to other documents active only on the latest edition")
                        }

                    } else if (actualTarget.getAttribute('class') === "footnoteindicator") {
                        const rootElementStart = getPathToContainer(actualTarget).split('|')[0];
                        const footnotenumber = actualTarget.getAttribute("data-footnote-number");
                        const targetFootnote = document.getElementById(rootElementStart)?.querySelectorAll("[data-footnote-number='" + footnotenumber + "']")[1];
                        if (targetFootnote) {
                            scrolltoElement(targetFootnote);
                        }
                    }
                    else {
                        //Only save bookmark if the selection is empty
                        const selection = (window.getSelection && window.getSelection());
                        if (selection == null || selection.isCollapsed) {
                            var target = e.target as HTMLElement;

                            if (target !== null) {
                                const fullPath = getPathToContainer(target);
                                this.openBookmarkOrNoteOnTarget(e, fullPath);
                            }

                        }
                    }
                    e.stopPropagation();
                    e.preventDefault();
                } else {
                    var hrefExternal: string = actualTarget.getAttribute('href') || '';
                    window.open(hrefExternal, "_blank");
                    e.stopPropagation();
                    e.preventDefault();
                }
            }

        }
    }
    copyStyles(sourceDoc: Document, targetDoc: Document) {
        Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
            let hasRules = false;
            try {
                hasRules = Boolean(styleSheet.cssRules);
            } catch (e) {
                console.log(e);
            }

            if (hasRules) {
                // for <style> elements
                const newStyleEl = sourceDoc.createElement('style');

                Array.from(styleSheet.cssRules).forEach(cssRule => {
                    // write the text of each rule into the body of the style element
                    newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
                });

                targetDoc.head.appendChild(newStyleEl);
            } else if (styleSheet.href) {
                // for <link> elements loading CSS from a URL
                const newLinkEl = sourceDoc.createElement('link');

                newLinkEl.rel = 'stylesheet';
                newLinkEl.href = styleSheet.href;
                targetDoc.head.appendChild(newLinkEl);
            }
        });
    }

    openBookmarkOrNoteOnTarget = (e: React.MouseEvent<HTMLElement, MouseEvent>, fullPath: string) => {
        var bookmarkableElement = this.getBookmarkableElement(fullPath);
        
        if (bookmarkableElement !== null && bookmarkableElement !== undefined) {

            var pathToBoomarkableEleent = getPathToContainer(bookmarkableElement);

            const bookMark = new service.BookmarkDto({
                bookmarkId: uuidv4(),
                upn: "",
                configId: this.props.match.params.configId,
                revision: +this.props.match.params.revision,
                bookmarkedIdFullPath: pathToBoomarkableEleent,
                bookmarkedId: pathToBoomarkableEleent.split('|').pop(),
                customText: "",
                globalOrder: 0,
                fullReference: "",
                renderedTitleText: "",
                fullReferenceWithoutDocument: "",
                htmlOrder: 0,
                lastValidInRevision: +this.props.match.params.revision
            })
            if(this.props.selectedText.bookmarkedId && this.props.selectedText.bookmarkedId ===  bookMark.bookmarkedId)
            {
                this.props.textSelected("", "", 0, "", "", 0, 0);
            }
            else{
                this.props.textSelected("", bookMark.configId || '', bookMark.revision, bookMark.bookmarkedId || '', bookMark.bookmarkedIdFullPath || '', e.pageX, e.pageY);
            }
            

        }
    }



    getBookmarkableElement = (fullPath: string) => {
        if (fullPath.startsWith("sectionstart") || fullPath.startsWith("appendixstart")) {
            const pathParts = fullPath.split('|');
            if (pathParts.find(x => x.startsWith("tbody"))) {
                return undefined;
            }
            if (pathParts.find(x => x.startsWith("thead"))) {
                return undefined;
            }
            const foundPrefferedContainer = pathParts.find(x => containerElementsToPreferForBookmarks.indexOf(x.split('_')[0]) >= 0);
            if (foundPrefferedContainer) {
                return document.querySelector("#" + foundPrefferedContainer) as HTMLElement
            }
            const validDirectElement = pathParts.find(x => validDirectElements.indexOf(x.split('_')[0]) >= 0);
            if (validDirectElement) {
                return document.querySelector("#" + validDirectElement) as HTMLElement
            }
        }
    }

    getMouseCoordinates = (e:React.MouseEvent)=> {     
        return {
            x: e.clientX,
            y: e.clientY
        };
    };
    onMouseMoveHandler = (currentElement: HTMLElement, coords:{x:number,y:number}) => {
        if (this.props.enableHover) {
            const currentHighlightedElement = this.state.currentHighlightedElement;
            const currentTableHighlightedElement = this.state.currentTableHighlighted.element;
            const currentTableOverlay = this.state.tableOverlayComponent;
           
            if (currentElement === currentHighlightedElement) {
                return;
            }
            if (currentElement === currentTableHighlightedElement) {
                return;
            }
            if(currentTableOverlay){
                var currentTableOverlayCoords = currentTableOverlay.getBoundingClientRect();

                if (coords.x >= currentTableOverlayCoords.left && coords.x <= currentTableOverlayCoords.right && coords.y >= currentTableOverlayCoords.top && coords.y <= currentTableOverlayCoords.bottom) {
                    return;
                } else {
                    //alert('Mouse not in box');
                }
            }
           

            const fullPath = getPathToContainer(currentElement);

            const bookmarkableElement = this.getBookmarkableElement(fullPath);
            if (currentHighlightedElement) {
                if (bookmarkableElement === currentHighlightedElement) {
                    return;
                }
            }
            const tableElement = this.getTableElement(fullPath);
            if (currentTableHighlightedElement) {
                if (tableElement === currentTableHighlightedElement) {
                    return;
                }
            }
            
            this.setState(s => {
                if (s.currentHighlightedElement) {
                    s.currentHighlightedElement.removeAttribute("noteable");
                }
           
                if (bookmarkableElement != null) {
                    bookmarkableElement.setAttribute("noteable", "true");
                }
                let isOverflowing = false;
                if (tableElement != null) {
                    //tableElement.setAttribute("tablehover", "true");
                    if (currentTableOverlay) {
                        currentTableOverlay.style.display = "flex";
                        currentTableOverlay.style.position = "absolute";
                        currentTableOverlay.style.top = Math.floor(tableElement.getBoundingClientRect().top + window.scrollY+20) + "px";
                        isOverflowing = tableElement.parentElement != null && tableElement.parentElement.clientWidth <tableElement.clientWidth;
                    }
                }else{
                    if (currentTableOverlay) {
                        currentTableOverlay.style.display = "none";                        
                    }
                }
                return ({ currentHighlightedElement: bookmarkableElement, currentTableHighlighted: { element: tableElement || undefined, overflowed: isOverflowing } })
            });


        }

    }
    onMouseMoveHandlerWithDebounceTarget = debounce(this.onMouseMoveHandler, 150);

    onMouseMoveHandlerWithDebounce = (e: React.MouseEvent<HTMLElement>) => {
        var htmlTarget = e.target as HTMLElement;
        var coords = this.getMouseCoordinates(e);
        if (htmlTarget) {
            this.onMouseMoveHandlerWithDebounceTarget(htmlTarget,coords)
        }

    }

    readjustHrefToFullUrl = (el:HTMLElement)=>{
        //window.location.href 
        Array.from(el.getElementsByTagName("img")).forEach(el=>{
            const oldHref = el.getAttribute("src");
            el.setAttribute("src",window.location.href + "/../" + oldHref);
        })
    }

    tablePopout = () => {
        if (this.state.currentTableHighlighted.element) {
            const portalWindow = window.open('', '_blank');
            if (portalWindow != null) {
                //portalWindow.document.open();
                this.copyStyles(window.document, portalWindow.document);
                const documentViewer = portalWindow.document.createElement('div');
                documentViewer.className = "documentViewer";
                documentViewer.id = 'root';
                const clonedElement = this.state.currentTableHighlighted.element.cloneNode(true);
                this.readjustHrefToFullUrl(clonedElement as HTMLElement);
                documentViewer.appendChild(clonedElement);
                const infoElement = portalWindow.document.createElement('div');
                infoElement.style.margin="0.5rem";
                const documentHeading =  portalWindow.document.createElement('div');
                documentHeading.style.color="#0f204b";                 
                documentHeading.style.fontWeight =  "500";   
                documentHeading.style.fontSize ="1.17rem";               
                documentHeading.innerText = window.document.title;
                infoElement.appendChild(documentHeading);
                const infoText =  portalWindow.document.createElement('div');               
                infoText.innerText ="When table is viewed in this tab, links will not work.";                
                infoText.style.fontWeight="normal";
                infoElement.appendChild(infoText);
                const copyrightText = portalWindow.document.createElement('div');
                copyrightText.innerText="Source: Rules and Standards Explorer   – © DNV AS " + currentYear;
                copyrightText.style.fontStyle="italic";
                copyrightText.style.marginBottom="1rem";
                copyrightText.style.fontSize="0.875rem";

                infoElement.appendChild(copyrightText);
                infoElement.appendChild(documentViewer);
                portalWindow.document.body.appendChild(infoElement);                
                portalWindow.document.title = window.document.title;
                portalWindow.document.close();
            }
        }
    }
    tableScrollLeft =()=>{
        const table = this.state.currentTableHighlighted.element;
        if (table) {
            const tableParent = table.parentElement;
            if(tableParent){
                tableParent.scrollBy(-100, 0);
            }
        }
    }
    tableScrollRight =()=>{
        const table = this.state.currentTableHighlighted.element;
        if (table) {
            const tableParent = table.parentElement;
            if(tableParent){
                tableParent.scrollBy(100, 0);
            }
        }
    }

    componentDidMount() {
        this.setState(s => ({ ...s, tableOverlayComponent: document.querySelector("#tableoverlay") as HTMLElement }));
    }

    render() {
        const classes = "documentViewer" + (this.props.highlightEnabled ? " highlightsEnabled" : "") + (this.props.documentCard.document.isCurrent ? "" : " notLatestRevision");
        return <><div onClick={this.clickHandler} onMouseMove={this.onMouseMoveHandlerWithDebounce} className={classes} >
            <MathJax3 html={this.props.html} numberOfImages={this.props.documentCard.document.numberOfImages} />
            <div id="tableoverlay" style={{display:"none"}} ><StyledIconContainer title="Open table in separate tab" onClick={this.tablePopout}><ExternalLinkIcon style={{fill:"white",height:"1.5rem"}} /></StyledIconContainer><StyledIconContainer title="Scroll table to the left" onClick={this.tableScrollLeft}  style={{display:this.state.currentTableHighlighted.overflowed?"flex":"none"}}><LongArrowLeftIcon  style={{fill:"white",height:"2rem"}} /></StyledIconContainer><StyledIconContainer  title="Scroll table to the right"  onClick={this.tableScrollRight} style={{display:this.state.currentTableHighlighted.overflowed?"flex":"none"}}><LongArrowRightIcon  style={{fill:"white",height:"2rem"}} /></StyledIconContainer></div>
        </div>
            
            {this.props.selectedText.configId !== "" && <ContextMenu documentCard={this.props.documentCard} />}
        </>
    }
}
const provider = provide((state) => ({
    highlightEnabled: state.highlight.enabled,
    selectedText: state.selectedText,
    enableHover: true
}), { ...selectedTextActions, ...userNotesActions }).withExternalProps<{ documentCard: DocumentCardElement, html: string }>();

type Props = typeof provider.allProps;
export const DocumentViewer = provider.connect(withRouter(DocumentViewerComponent));

