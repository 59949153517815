
import { Menu } from "../Sidebar/Menu";
import styles from "../ContentManager/ContentManager.module.scss";
import { DocumentsList } from "./DocumentsList";

export function DocumentListRender() {

    return (
        <Menu layout="ListLayout">
            <div className={styles.documentList}>
                <DocumentsList />
            </div>
        </Menu>
    )
}