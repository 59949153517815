import { Reducer } from "redux";
import { DocumentListActions } from "../actions/documentListActions";

interface IDocumentListState {
    column: "code" | "title" | "none",
    order: "Ascending" | "Descending"
}
export const documentListReducer: Reducer<IDocumentListState, DocumentListActions>
    = (state = { column: "none", order: "Ascending" }, action) => {

        switch (action.type) {
            case "DOCUMENT_LIST_SORT":
                {
                    return { ...state, column: action.column, order: action.order };
                }
            default:
                break;
        }

        return state;
    };