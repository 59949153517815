import { Reducer } from "redux";
import { NewsActions } from "../actions/newsActions";
import * as service from '../../api/SmartLibraryService';
// import { MsalAuthProvider, LoginType } from 'react-aad-msal';
interface INewsContext {
    news: service.INews | null;
    isFetching: boolean;
    error: string | null;
}
export const newsReducer: Reducer<INewsContext, NewsActions>
    = (state = { news: null, isFetching: false, error: null }, action) => {

        switch (action.type) {
            case "LOAD_NEWS": {
                return { ...state, isFetching: true, authConfig: null };
            }
            case "LOAD_NEWS_COMPLETE": {
                return { ...state, isFetching: false, error: null, news: action.payload }
            }
            case "LOAD_NEWS_FAILED": {
                return { ...state, isFetching: false, error: action.payload.error, news: null }
            }
            default:
                break;
        }

        return state;
    };
