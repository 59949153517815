import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import { getErrorClient } from '../../utils/proxyFactory';
import { ClientError } from "../../api/SmartLibraryService";

interface ILogError { type: "LOG_ERROR"; }
export type NewsActions = ILogError;
type TA = ThunkAction<void, IRootState, void, NewsActions>;

export const errorActions = {
    logError: (name:string, message:string,stack:string,info:string): TA => async (dispatch, getState) => {      
        var client = await getErrorClient(dispatch,getState());     
        await client.logError( new ClientError({name:name,message:message, stack:stack,info:info}));

    }
}