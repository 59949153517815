import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Spinner } from '../Icons/Spinner';
import { provide } from "../../store/provide";
import { flattenDocsInCategories } from '../../utils/documentHelpers'
import { DocumentViewer } from './DocumentViewer/DocumentViewer'
import { documentActions } from '../../store/actions/documentActions';
import { getUpn, isInternal } from '../../utils/user';
import { filterActions } from '../../store/actions/filterActions';
import { searchDocumentBoxActions } from '../../store/actions/searchDocumentBoxActions';
import { DocumentCardElement } from '../Documents/DocumentCard';
import { editionyyyyMMtoStringShort } from '../../utils/documentHelpers';
import TagManager from 'react-gtm-module'
import { highlightActions } from '../../store/actions/highlightActions';
import { IDocumentRouteProps } from '../../utils/routeProps/DocumentRouteProps';
import { Audience, WithdrawnType } from '../../api/SmartLibraryService';
import { ErrorMessageDocumentNotFound } from '../ErrorMessage/ErrorMessageDocumentNotFound'
import { ErrorMessagePaymentRequired } from '../ErrorMessage/ErrorMessagePaymentRequired'
import { ErrorMessagePdfOnly } from '../ErrorMessage/ErrorMessagePdfOnly';
import { ErrorMessageRetired } from '../ErrorMessage/ErrorMessageRetired';
import { ErrorMessageReplaced } from '../ErrorMessage/ErrorMessageReplaced';
import { SubscriptionHeader } from './DocumentViewer/Subscription/SubscriptionHeader';
import { SubscriptionInfobox } from './DocumentViewer/Subscription/SubscriptionInfobox';
import { userSettingsActions } from '../../store/actions/userSettingsActions';
import { DocumentsActions } from '../Documents/DocumentsActions';
import { info, dismissAll } from '../../utils/customToast';
import { IDocumentFilters } from '../../store/reducers/filterReducer';

class DocumentComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{

    currentdocument: DocumentCardElement | null = null;
    countCharacters = (str: string, chr: string): number => {
        return str.split('').reduce((prev, current) => prev + (current === chr ? 1 : 0), 0);
    }
    singleWordOrPhrase = (query: string | null): string | null => {
        //Null is not valid
        if (query === null)
            return null;
        //One word is one word and OK
        if (query.split(' ').length === 1) {
            return query;
        }
        //If it starts and ends with ", it is most likely a phrase
        if (query.startsWith('"') && query.endsWith('"')) {
            //But only if there is no other " in the string (just start and end)
            if (this.countCharacters(query, '"') === 2) {
                return query.slice(1, query.length - 1);
            }
        }
        return null;
    }

    getDocumentFromToc = () => {
        const allDocuments = flattenDocsInCategories(this.props.toc);
        const currentdocuments = allDocuments.filter(x => x.document.configId === this.props.match.params.configId && x.document.revision === +this.props.match.params.revision);
        if (currentdocuments.length > 0) {
            return currentdocuments[0];
        }
        return null;
    }
    getDocumentFromTocByCode = (documentCode: string) => {
        const allDocuments = flattenDocsInCategories(this.props.toc);
        const currentdocuments = allDocuments.filter(x => x.document.documentReference === documentCode);
        if (currentdocuments.length > 0) {
            return currentdocuments[0];
        }
        return null;
    }
    filterByEdition(currentDocs: DocumentCardElement[], filterstate:IDocumentFilters){
  
        if(filterstate.isFiltered ){
          let newArr :DocumentCardElement[] =[] ;
          let newArray = currentDocs.filter(doc=>doc.document.issued!==undefined && filterstate.issuedDate!== null ? parseInt(doc.document.issued) <= parseInt(filterstate.issuedDate) || doc.category.code?.includes("WPI") || doc.category.code?.includes("ITG"): '' );
          newArray.forEach((doc, index)=>{
                      //console.log(doc);
                      let result=  newArr.filter(x=> x.document.documentCode === doc.document.documentCode )
                      if(result.length ===0){
                          newArr.push(doc);
      
                      }
                  });
                  return newArr;
        }
        return currentDocs.filter(cat=> filterstate.category === " " ? cat.category.code != null :  (filterstate.subCategory != null ?cat.category.code === filterstate.category && cat.subCategory?.code === filterstate.subCategory: cat.category.code === filterstate.category));
      }

    detectChanges = () => {
        this.currentdocument = this.getDocumentFromToc();

        if (this.currentdocument && !this.props.document.paymentRequired && !this.props.document.error && (this.props.document.document === null || this.props.match.params.configId !== this.props.document.document.configId || +this.props.match.params.revision !== this.props.document.document.revision) && !this.props.document.isFetching) {
            const singleOrPhrase = this.singleWordOrPhrase(this.props.searchResult.query);
            window.document.title = "[" + this.currentdocument.document.documentReference + "] "
                + this.currentdocument.document.title
                + " - edition " + editionyyyyMMtoStringShort(this.currentdocument.document.edition || '')
                + (this.currentdocument.document.amended ? " (amended " + editionyyyyMMtoStringShort(this.currentdocument.document.amended) + ")" : "");
            TagManager.dataLayer({
                dataLayer: {
                    'event': 'VirtualPageView',
                    internaluser: isInternal(this.props.user)
                }
            });

            if (singleOrPhrase !== null) {
                this.props.setHighlightText(singleOrPhrase);
                this.props.toggleHighlight(true);
                this.props.onChangeSearchBox(singleOrPhrase);
                this.props.setAutoScroll(false);
                this.props.requestDocument(this.props.match.params.configId, +this.props.match.params.revision, singleOrPhrase, this.props.filterState.isFiltered, this.props.filterState.issuedDate);
            } else {
                this.props.setHighlightText('');
                this.props.toggleHighlight(false);
                this.props.onChangeSearchBox('');
                this.props.setAutoScroll(false);
                this.props.requestDocument(this.props.match.params.configId, +this.props.match.params.revision, "", this.props.filterState.isFiltered, this.props.filterState.issuedDate);
            }
            // var cnt = 0;
            if(this.props.filterState.isFiltered){
                let filteredDoc : DocumentCardElement[] = [];
                filteredDoc = this.filterByEdition(flattenDocsInCategories(this.props.toc), this.props.filterState);
                let filterinScope = filteredDoc.filter(doc=>doc.document.configId === this.currentdocument?.document.configId && doc.document.revision === this.currentdocument?.document.revision);
                if(filterinScope.length !== 0){
                     this.props.setIsFilterinScope(true);
                     dismissAll();                        
                 }
                 if(filterinScope.length === 0){
                    this.props.setIsFilterinScope(false);
                    dismissAll();                        
                    info("You have moved out of the filtered scope. Go back to continue within the filtered scope.", {autoClose: false} );
                }
            }
            
        }
    }

    componentDidMount() {
        this.detectChanges();
    }
    componentDidUpdate() {
        if (this.props.document.document?.configId !== undefined) {
            if (this.props.document.eloquaPage !== this.props.document.document?.configId) {
                this.trackDisplayOfDocument();
            }
        }
        this.detectChanges();
    }
    trackPaymentRequired() {
        if (this.currentdocument && this.props.document.paymentRequired && this.currentdocument.document.audience && this.currentdocument.document.audience.indexOf(Audience.OilAndGas) > -1 && this.props.user) {
            const trackingUrl = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + getUpn(this.props.user) + "&docTitle=" + this.currentdocument.document.documentCode + " " + this.currentdocument.document.title + "&docUrl=" + window.location + "&type=Non Subscriber view HTML";
            fetch(trackingUrl);
        }
    }
    trackDisplayOfDocument() {
        if (this.props.document.document?.configId !== undefined) {
            this.props.eloquatracking(this.props.document.document?.configId);
            let eloquatrackhtmlText;
            if (this.currentdocument?.document.requiredVeracitySubscriptions) {
                if (this.props.userSetting.settings?.internal) {
                    eloquatrackhtmlText = "View HTML"
                }
                else if (this.props.userSetting.settings?.trial) {
                    eloquatrackhtmlText = "Trial view HTML"
                }
                else if (this.props.userSetting.settings?.subscription) {
                    eloquatrackhtmlText = "Subscriber view HTML"
                }
                else {
                    eloquatrackhtmlText = "Non Subscriber view HTML"
                }
            }
            else {
                eloquatrackhtmlText = "View HTML"
            }
            if (this.currentdocument && this.props.user) {
                const trackingUrl = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + getUpn(this.props.user) + "&docTitle=" + this.currentdocument.document.documentCode + " " + this.currentdocument.document.title + "&docUrl=" + window.location + "&type=" + eloquatrackhtmlText;
                fetch(trackingUrl);
            }
        }
    }

    
    

    render() {

        this.currentdocument = this.getDocumentFromToc();
        // if(this.currentdocument){ //When testing all the error messages, uncomment this and comment out below
        //     return <>
        //     <ErrorMessageRetired documentCardElement={this.currentdocument} />
        //     <ErrorMessageReplaced documentCardElement={this.currentdocument} replacedByDocument={this.getDocumentFromTocByCode(this.currentdocument.document.replacedBy || '')} />
        //     <ErrorMessagePaymentRequired subscriptionPage={"https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" +getUpn(this.props.user)+ "&docTitle=" + this.currentdocument.document.documentCode + " " + this.currentdocument.document.title + "&docUrl=https://store.veracity.com/dnv-gl-oil-and-gas-standards-and-recommended-practices&type=RS intent to pay"} />
        //     <ErrorMessagePdfOnly documentCardElement={this.currentdocument} />
        //     </>;
        // }

        //Order is from SQLREQ-082
        if (this.currentdocument && this.currentdocument.document.withdrawn === WithdrawnType.Retired) {
            return <ErrorMessageRetired documentCardElement={this.currentdocument} />
        }
        if (this.currentdocument && this.currentdocument.document.withdrawn === WithdrawnType.Replaced) {
            return <ErrorMessageReplaced documentCardElement={this.currentdocument} replacedByDocument={this.getDocumentFromTocByCode(this.currentdocument.document.replacedBy || '')} />
        }
        if (this.currentdocument && this.props.document.paymentRequired && this.props.user) {
            this.trackPaymentRequired();
            return <ErrorMessagePaymentRequired subscriptionPage={"https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + getUpn(this.props.user) + "&docTitle=" + this.currentdocument.document.documentCode + " " + this.currentdocument.document.title + "&docUrl=https://store.veracity.com/rules-and-standards-explorer-plus&type=RS intent to pay"} />
        }
        if (this.currentdocument && this.currentdocument.document.configId?.includes("-")) {
            return <ErrorMessagePdfOnly documentCardElement={this.currentdocument} />
        }

        if (this.currentdocument && this.currentdocument.document.revision < 0) {
            return <ErrorMessagePdfOnly documentCardElement={this.currentdocument} />
        }

        if (this.props.document.isFetching || this.props.document === null)
            return <Spinner />;
        if (!this.props.document.isFetching && this.props.document === null) {

            return <ErrorMessageDocumentNotFound />
        }

        let editionDate: string | null = this.currentdocument && this.currentdocument.document.edition ? editionyyyyMMtoStringShort(this.currentdocument.document.edition) : null;
        let amendedDate: string | null = this.currentdocument && this.currentdocument.document.amended ? editionyyyyMMtoStringShort(this.currentdocument.document.amended) : null;

        if (this.props.document.error)
            return <p>{this.props.document.error.toString()}</p>;

        if (this.props.document.document && this.currentdocument != null) {
            
            return <>
                <div style={{ fontWeight: "bolder", fontSize: "1.5625rem", marginTop: "0.9375rem" }}>{this.currentdocument.document.title}</div><br />
                <div><b>Edition:</b>&nbsp;{editionDate}</div>
                <div><b>Amended:</b>&nbsp;{amendedDate}</div>
                <div><b>Document code:</b>&nbsp;{this.currentdocument.document.documentReference}</div>
                {this.currentdocument.document.requiredVeracitySubscriptions ? <SubscriptionHeader /> : null}
                <DocumentViewer documentCard={this.currentdocument} html={this.props.document.document.html || '<span>ERROR LOADING DOCUMENT</span>'} />
                {this.currentdocument.document.requiredVeracitySubscriptions ? <SubscriptionInfobox type="Document" title={this.currentdocument.document.title} /> : null}
            </>
        }
        return <p>No document data</p>
    }
}

const provider = provide((state) => ({
    searchResult: state.searchResult,
    document: state.document,
    toc: state.toc.categories,
    user: state.user.userInfo,
    userSetting: state.userSettings,
    filterState: state.filterState,
    documentlist: state.documents
}), { ...documentActions, ...filterActions, ...searchDocumentBoxActions, ...highlightActions, ...userSettingsActions, ...DocumentsActions }).withExternalProps<{ documentCards: DocumentCardElement[] }>();


type Props = typeof provider.allProps;
export const Document = provider.connect(withRouter(DocumentComponent));

