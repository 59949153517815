import * as React from 'react';
import { ChevronIcon } from '../Icons/Chevron';
import { DocumentCardElement } from '../Documents/DocumentCard';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { provide } from "../../store/provide";
import { filterActions } from '../../store/actions/filterActions';
import { IDocumentRouteProps } from '../../utils/routeProps/DocumentRouteProps';
import { StyledLinkButton } from '../Styled/Buttons/LinkButton'
import styled from 'styled-components/macro';
import { searchActions } from '../../store/actions/searchActions';
import { searchBoxActions } from "../../store/actions/searchBoxActions";
import { DocumentsActions } from '../Documents/DocumentsActions';

const StyledBreadCrumbLink = styled(StyledLinkButton)`
    font-size: ${({ theme }) => theme.fonts.sizes.medium};
`
const StyledChevronIcon = styled(ChevronIcon)`
    margin: 0rem 0.3125rem 0rem 0.3125rem;
`
const StyledLabel = styled.label`
box-sizing: border-box;
       margin: 0 8px 0 0;
       color: #fff;
       font-variant: tabular-nums;
       list-style: none;
       font-feature-settings: "tnum";
       display: inline-block;
       padding: 5px 9px;
       height: auto;
line-height: 20px;
white-space: nowrap;
border-radius: 2px;
opacity: 1;
background-color: rgb(0, 103, 197);
border-radius: 1.5rem;
`

class DocumentComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{
    setCategoryFilter = (document: DocumentCardElement | null) => {
        if (document != null) {
            this.props.setCategoryFilter(document.category.code || null, null)
            this.props.clearValues();

            this.props.history.push("/documents");
        }
    }
    setSubCategoryFilter = (document: DocumentCardElement | null) => {
        if (document != null) {
            this.props.clearValues();
            this.props.history.push("/documents");
            this.props.setCategoryFilter(document.category.code || null, document.subCategory && document.subCategory.code ? document.subCategory.code : null)
        }
    }
    backClick = () => {
        this.props.clearCategory();
        this.props.clearValues();

        this.props.history.push("/documents"); 
        if(this.props.searchResult !=null && this.props.searchResult.query!=null){
            this.props.requestSearch(this.props.searchBoxText);
            this.props.history.push("/search"); 

        }
        window.scrollTo(0, 0); 

    }
    categoryClick = (document:DocumentCardElement) => {
        window.scrollTo(0, 0); 
        this.setCategoryFilter(document);
    }
    subCategoryClick = (document:DocumentCardElement) => {
        this.props.history.push("/"); 
        window.scrollTo(0, 0); 
        this.setSubCategoryFilter(document)
    }
    documentClick = () => {
        window.scrollTo(0, 0)
    }
    getMonthName =(monthNumber:any)=>{
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', {
          month: 'long',
        });
      }
    render() {
        const currentdocuments = this.props.documentCards.filter(x => x.document.configId === this.props.match.params.configId && x.document.revision === +this.props.match.params.revision);
        if (currentdocuments.length === 0) {
            return <></>;
        }
        const currentdocument = currentdocuments[0];
        return <>
        <div style={this.props.filterState.isFiltered || this.props.searchResult.query ? { display: "grid", marginBottom: "0.5rem", alignItems: "center" , marginTop:"1.5rem"} : {display: "grid", marginBottom: "0.5rem", alignItems: "center"}}>
            <div style={{gridArea:"1/1", marginBottom:"2px"}}>
            {this.props.searchResult.query && <StyledLabel style={{marginLeft:"8px"}}>{this.props.searchResult.query}</StyledLabel>}                            
        
            {this.props.filterState.selectedMonth !=null ? this.props.filterState.isFilterinScope ? <StyledLabel style={{marginLeft:"8px"}}>{this.getMonthName(this.props.filterState.selectedMonth)} {this.props.filterState.selectedYear}</StyledLabel>: <StyledLabel style={{backgroundColor:"#f0f0f0", color:"#00000094"}}>{this.getMonthName(this.props.filterState.selectedMonth)} {this.props.filterState.selectedYear}</StyledLabel>:''}                            

            </div>

            <div style={{gridArea:"2/1", marginLeft:"7px"}}>

            <StyledBreadCrumbLink onClick={this.backClick}>Back to list</StyledBreadCrumbLink>
            {/* <StyledChevronDoubleIcon direction="Right" expanded={false} /> */} {">"}
            <StyledBreadCrumbLink onClick={() => this.categoryClick(currentdocument)}>{currentdocument && currentdocument.category.description} ({currentdocument && currentdocument.category.code})</StyledBreadCrumbLink>
            {currentdocument && currentdocument.subCategory ? <>
                <StyledChevronIcon direction="Right" expanded={false} />
                <StyledBreadCrumbLink onClick={() =>this.subCategoryClick(currentdocument)}>{currentdocument.subCategory.code}: {currentdocument.subCategory.description}</StyledBreadCrumbLink>
                <StyledChevronIcon direction="Right" expanded={false} />
                <StyledBreadCrumbLink data-cy="breadcrumbdoctitle" onClick={this.documentClick}>{currentdocument.document.chapter ? "Ch " + currentdocument.document.chapter + ": " + currentdocument.document.title : currentdocument.document.title}</StyledBreadCrumbLink>
            </> :
                <>    <StyledChevronIcon direction="Right" expanded={false}  /> <StyledBreadCrumbLink data-cy="breadcrumbdoctitle" onClick={this.documentClick}>{currentdocument && currentdocument.document.title}</StyledBreadCrumbLink>
                </>
            }
            </div>

            </div>
        </>

    }
}

const provider = provide((state) => ({
    // documents: state.documents.documents,
    searchResult: state.searchResult,
    searchBoxText: state.searchBox.searchBoxText,
    filterState: state.filterState,
    globalsearch: state.globalsearch,
    documentlist: state.documents

}), { ...filterActions,...searchActions,...searchBoxActions,...DocumentsActions }).withExternalProps<{ documentCards: DocumentCardElement[] }>();

type Props = typeof provider.allProps;
export const BreadCrumb = provider.connect(withRouter(DocumentComponent));

