import { Store } from "redux";//, combineReducers
import { configureStore } from "./storeHelpers";
import { searchBoxReducer } from "./reducers/searchBoxReducer";
import { searchDocumentBoxReducer } from "./reducers/searchDocumentBoxReducer";
import { tocReducer } from "./reducers/tocReducer";
import { documentReducer } from "./reducers/documentReducer";
import { documentListReducer } from "./reducers/documentListReducer";
import { searchReducer}from "./reducers/searchReducer";
import { searchDocumentReducer}from "./reducers/searchDocumentReducer";
import {filterReducer } from "./reducers/filterReducer"
import {highlightReducer } from "./reducers/highlightReducer"
import {authConfigReducer} from "./reducers/authConfigReducer"
import {scrollMemoryReducer} from "./reducers/scrollMemoryReducer"
import {appUpdateReducer} from "./reducers/appUpdateReducer"
import {mathJaxReducer} from "./reducers/mathJaxReducer"
import {veracityReducer, veracityTrialReducer, addUserToTrialReducer} from "./reducers/veracityReducer"
import {sidebarReducer} from "./reducers/sideBarReducer"
import {selectedTextReducer} from "./reducers/selectedTextReducer"
import {userNotesReducer} from "./reducers/userNotesReducer"
import {newsReducer} from "./reducers/newsReducer"
import {userReducer} from "./reducers/userReducer"
import {userSettingsReducer} from "./reducers/userSettingsReducer"
import {globalsearchReducer} from "./reducers/globalsearchReducer"
import { DocumentsReducer } from "../Components/Documents/DocumentsReducers";
import { offlinePackagesReducer } from "../Components/OfflinePackages/OfflinePackagesReducer";
import { attachmentReducer } from "../Components/Attachments/AttachmentReducer";

const rootReducers = {
    searchBox: searchBoxReducer,
    searchDocumentBox: searchDocumentBoxReducer,
    toc: tocReducer,
    sideBar:sidebarReducer,
    document: documentReducer,
    documentList: documentListReducer,
    searchResult: searchReducer,
    searchDocumentResult: searchDocumentReducer,
    filterState: filterReducer,
    highlight: highlightReducer,
    authConfig: authConfigReducer,
    scrollMemory: scrollMemoryReducer,
    appUpdate: appUpdateReducer,
    veracitySettings: veracityReducer,
    user: userReducer,
    selectedText:selectedTextReducer,
    userNotes:userNotesReducer,
    news:newsReducer,
    userSettings: userSettingsReducer,
    addUserToTrial: addUserToTrialReducer,
    userVeracityTrial: veracityTrialReducer,
    mathJax:mathJaxReducer,
    globalsearch:globalsearchReducer,
    documents:DocumentsReducer,
    offlinepackages: offlinePackagesReducer,
    attachment: attachmentReducer
};

export type IRootState = { [k in keyof (typeof rootReducers)]: ReturnType<(typeof rootReducers)[k]> };

/**
 * getStore
 * Wraps store in a 'singleton' function,
 * This allows for a deffered loading of initial data (like user id) on initial load.
 */
type SuperPartial<T> = {
    [P in keyof T]?: SuperPartial<T[P]>;
};
let store: Store<IRootState>;
export function getStore() {
    if (!!store) {
        return store;
    }

    store = configureStore(rootReducers, [], undefined);
    //store.subscribe();
  //  loadUser(store, userManager)
    return store;
}
