import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
interface IMathJaxAction { type: "MATHJAX_READY"; }
export type MathJaxActions = IMathJaxAction;
type TA = ThunkAction<void, IRootState, void, MathJaxActions>;

export const mathJaxActions = {
    onMathJaxReady: (): TA => async (dispatch)  => {
        dispatch({
            type: "MATHJAX_READY"
        });
    }
}