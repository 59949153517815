import React from 'react';
import {ReactComponent as Chevron} from '../../images/chevron-left.svg';
export type ChevronProps = {
    direction:"Up"|"Down"|"Left"|"Right",
    expanded:boolean
  
}
export const ChevronIcon:React.FC<ChevronProps& React.HTMLAttributes<HTMLOrSVGElement>>= (props)=>{
    const {direction,expanded,onClick,...restProps} = props;
    const classes = ["rs-chevron",
    direction ==="Up" && "rs-chevron-up",
    direction ==="Down" && "rs-chevron-down",
    direction ==="Left" && "rs-chevron-left",
    direction ==="Right" && "rs-chevron-right",
    expanded && "rs-chevron-selected"
].filter(e=>!!e).join(' ');

return <span onClick={onClick}  {...restProps}><Chevron className={classes}/></span>
}