
import * as React from 'react';
import { ErrorMessage } from './ErrorMessage'
import { KeyIcon } from '../Icons/KeyIcon';
export const ErrorMessagePaymentRequired = (props:{subscriptionPage:string | undefined}) =>
    <ErrorMessage
        icon={<KeyIcon style={{ width: "4.25rem", height: "4.25rem" }} />}
        message={<><div style={{ fontSize: "1.5rem" }}>Would you like to subscribe?</div>
            <div style={{ marginTop: "1.875rem", width:"70%" }}>The document you are requesting is part of a subscription-based portfolio of nearly 80 different DNV standards and recommended practices for the oil and gas industry.</div>

       
            <div style={{ marginTop: "1.875rem" }}><a href={props.subscriptionPage} target="_blank" rel="noreferrer">Read more</a></div>
        </>}
    />