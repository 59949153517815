import { Reducer } from "redux";
import { AttachmentActions } from "./AttachmentActions";
import * as service from '../../api/SmartLibraryService';

interface IAttachmentContext {
    data: service.FileResponse | null;
    isFetching: boolean;    
    error:string | null;
    
  }

export const attachmentReducer: Reducer<IAttachmentContext, AttachmentActions>
    = (state = { data:null, isFetching:false, error:null}, action) => {

        switch (action.type) {
            case "LOAD_ATTACHMENT": {                
                return { ...state, isFetching:true };
            }
            case "LOAD_ATTACHMENT_COMPLETE":{                
                return { ...state, isFetching:false,error:null,data: action.payload}
            }
            
            case "LOAD_ATTACHMENT_FAILED":{
                return { ...state, isFetching:false, error:action.payload.error, data: null}
            }
            
            default:
                break;
        }

        return state;
    };