import { Reducer } from "redux";
import { GlobalSearchActions } from "../actions/globalsearchActions";

interface IGlobalSearchState{
    compVisible:boolean
}


export const globalsearchReducer: Reducer<IGlobalSearchState, GlobalSearchActions>
=(state={compVisible:false},action)=>{
    switch(action.type){
        case 'SHOW':
            return {...state, compVisible:action.payload.compVisible};
        default:
            break;
    }
    return state;

}