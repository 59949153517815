import React from 'react';
// import styles from './Bookmarks.module.css';
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import { provide } from "../../../store/provide";

import { DocumentCardElement } from '../../Documents/DocumentCard';
import { filterActions } from '../../../store/actions/filterActions';
import * as service from '../../../api/SmartLibraryService';
import { NoteIcon } from '../../Icons/NoteIcon';
import { documentListActions } from '../../../store/actions/documentListActions';
import * as tooltips  from '../../../utils/tooltips';

import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { documentActions } from '../../../store/actions/documentActions';
import { userNotesActions } from '../../../store/actions/userNotesActions';
import { sideBarActions } from '../../../store/actions/sideBarActions';
import { scrolltoId } from '../../../utils/scroll';
import { ellipsis } from '../../StyledComponents/CustomStyles';
import styled from 'styled-components/macro';
const StyledLink = styled(Link)`      
    width:100%;
    &:hover{
        text-decoration:none;
    } 
    ${ellipsis()}  
  
`;

const StyledLinkContainer = styled.div`      
    display:flex;
    justify-content: space-between;
    font-size: ${({ theme }) =>  theme.fonts.sizes.medium};
    margin: 0px -16px;
    padding:0px 16px;
    &:hover{
    background-color: ${({ theme }) =>  theme.colors.background.neutral05};
   }  
`;

class SingleBookmarkComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{

    scrollToIfInDocumentAlready = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, document: DocumentCardElement, hash: string) => {
        if (this.props.match.params.configId === document.document.configId && +this.props.match.params.revision === document.document.revision) {
            scrolltoId(this.props.bookmark.bookmarkedId ||'');
        }
    }

    bookmarkClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.scrollToIfInDocumentAlready(e, this.props.document, this.props.bookmark.bookmarkedId|| '');
        this.props.highlightBookmark(this.props.bookmark.bookmarkId);       
    }
    render = () => {
        
        return this.props.bookmark &&
                <StyledLinkContainer  key={this.props.bookmark.bookmarkId}>
            <StyledLink title={this.props.bookmark.fullReferenceWithoutDocument + (this.props.bookmark.renderedTitleText ? " " + this.props.bookmark.renderedTitleText : "")} onClick={this.bookmarkClicked} to={{ pathname: "/document/" + this.props.bookmark.configId + "/" + this.props.bookmark.revision, hash: this.props.bookmark.bookmarkedId || '', state: { fromBookmarkLeft: true } }} > {this.props.bookmark.fullReferenceWithoutDocument !== null ? (parseInt(this.props.bookmark.edition ? this.props.bookmark.edition : "") > 202104 ? this.props.bookmark.fullReferenceWithoutDocument?.substring(0) : this.props.bookmark.fullReferenceWithoutDocument) + " " + this.props.bookmark.renderedTitleText : "Broken"}</StyledLink>
                    <div> {(this.props.bookmark.customText||this.props.forceShowNoteIcon) && <NoteIcon title={tooltips.leftSideBarDocumentbookmarkNoteIndicator} style={{ marginLeft: "1.5625rem", width:"14px", height:"15px" }} />}</div>
                </StyledLinkContainer>
    }

};

const provider = provide((state) => ({
    searchResult: state.searchResult,
    filterState: state.filterState,
    categories: state.toc.categories,
    openBookmarkDocument: state.sideBar.openBookmarkDocument,
    userNotes: state.userNotes,
}), { ...filterActions, ...documentListActions, ...documentActions, ...userNotesActions, ...sideBarActions }).withExternalProps<{ document: DocumentCardElement, bookmark: service.IBookmarkDto, forceShowNoteIcon:boolean }>();

type Props = typeof provider.allProps;
export const SingleBookmark = provider.connect(withRouter(SingleBookmarkComponent));

