import React,{useState} from 'react';

import { PdfIcon } from '../../Icons/PdfIcon';
import {infoNotLatestVersion} from '../../../utils/tooltips'
import * as service from '../../../api/SmartLibraryService';
import { CheckIcon } from '../../Icons/CheckIcon';
import { DocumentCardElement } from '../../Documents/DocumentCard';
import { editionyyyyMMtoStringShort } from '../../../utils/documentHelpers';
import { ChevronIcon } from '../../Icons/Chevron';
import { Provider, useSelector } from 'react-redux'
import { IRootState } from '../../../store/store';
import { FavouriteToggler } from '../../FavouriteToggler/FavouriteToggler';
import { InformationBox } from '../../Styled/Boxes/InformationBox'
import styled from 'styled-components/macro';
import { getUpn } from '../../../utils/user';
import { StyledLinkButton } from '../../Styled/Buttons/LinkButton'

import { provide } from '../../../store/provide';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {DocumentMap_Pdf} from '../../Document/DocumentMap/DocumentMap_Pdf';

import { PdfIconGray } from '../../Icons/PdfIconGray';


import { ESubIcon } from '../../Icons/ESubIcon';
import ReactTooltip from 'react-tooltip';
import { success, warn } from '../../../utils/customToast';
import { ShareAltOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Radio, Space } from 'antd';
import './ModalPopup.metadatabox.css';

const StyledSpan = styled.span`
    float: left;
    height: 100%;
    padding: 10px;
    &:hover {
        background-color: #f8f9fa;
    }
`;

const DropDownContent = styled.div`
   
    position: absolute;
    min-width: 160px;
    z-index: 1;
    border:1px solid ${({ theme}) =>  theme.colors.border.neutral20};
`;

const DropDownContainer = styled(StyledSpan)`
     display: block;
    /* &:hover ${DropDownContent} {
        display: block;
    } */
`;

const DropDownItem = styled.span`
    display: inline-block;
    text-align: center;
    text-decoration: none;
    cursor:pointer;
`;

const DropDownListItem = styled(DropDownItem)`
    text-decoration: none;
    display: block;
    text-align: left;
    background-color: ${({ theme}) =>  theme.colors.background.white};
    padding: 10px 5px;
    &:hover {
        background-color: ${({ theme}) =>  theme.colors.background.neutral05};
    }
`;

const StyledDocumentTitleSpan = styled.span`
    font-family: ${({ theme}) =>  theme.fonts.AvenirDemi};
    font-size: 1rem;
    color: ${({ theme}) =>  theme.colors.text.darkblue};
    font-weight: 400;
    line-height: 1.875rem;    
   
`

const StyledEditionContainerDiv = styled.div`
    display: flex;
    padding-left: 1.125rem;
    align-items: center;
    padding-bottom: 0.3125rem;
    padding-top: 0.625rem;
`

const StyledRoundedMetadataDiv = styled.div`
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #ced5e6;
    background-color: white;
`

const StyledDocumentCodeDiv = styled.div`
    padding-left: 0.325rem;
    padding-top: 0.3125rem;
    height: 1.875rem;  
    font-size: 0.875rem;
    color:${({ theme}) =>  theme.colors.text.neutral80};  
    line-height: 1.875rem;
    letter-spacing: .125rem;
`

const StyledTitleContainerDiv = styled.div`
   font-family: ${({ theme}) =>  theme.fonts.AvenirNextMedium};
  font-size: 1rem;
  color: ${({ theme}) =>  theme.colors.text.digitalblue};   
  padding-left: 0.325rem;
  letter-spacing: .0625rem;
`

const StyledMetadataContainerDiv = styled.div`
    display:grid;
`

const StyledFavouriteTogglerContainerDiv = styled.div`
padding-left: 1.125rem;
padding-top: 0.8rem;
`

const WithDrawnInfoBox = (props: { document: service.IDocument }) =>
    <div style={{ padding: "10px 0px", fontSize: "14px" }}>
        <InformationBox >
            {!props.document.isCurrent && infoNotLatestVersion}
            {props.document.withdrawn === service.WithdrawnType.Withdrawn && <>This document is withdrawn as of {editionyyyyMMtoStringShort(props.document.withdrawnDate || '')}.</>}
            {props.document.withdrawn === service.WithdrawnType.Retired && <>This document was retired in {editionyyyyMMtoStringShort(props.document.withdrawnDate || '')}.</>}
            {props.document.withdrawn === service.WithdrawnType.Replaced && <>This document was replaced in {editionyyyyMMtoStringShort(props.document.withdrawnDate || '')}</>}
            {props.document.documentCode && (props.document.documentCode.includes("WPI") === true|| props.document.documentCode.includes("ITG") === true) &&  <>When new editions of internal instructions are published, previous editions will be removed.</>}
        </InformationBox>
    </div>




const DownloadOptions: React.FC<{ globalTocAllRevisions: DocumentCardElement[], globalTocCurrentRevision: DocumentCardElement }> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);
    const userInfo = useSelector((x: IRootState) => x.user.userInfo);
    let editionDate: string | null = props.globalTocCurrentRevision && props.globalTocCurrentRevision.document.edition ? editionyyyyMMtoStringShort(props.globalTocCurrentRevision.document.edition) : null;
    let amendDate: string | null = props.globalTocCurrentRevision && props.globalTocCurrentRevision.document.amended ? editionyyyyMMtoStringShort(props.globalTocCurrentRevision.document.amended) : null;
    // const thecurrentPdfLinkToUse = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + getUpn(userInfo) + "&docTitle=" + props.globalTocCurrentRevision.document.documentCode + " " + props.globalTocCurrentRevision.document.title + "&docUrl=" + props.globalTocCurrentRevision.document.pdfRef + "&type=RS intent to download PDF";
    const userSettings = useSelector((state: IRootState) => { return state.userSettings.settings});
    const trackepdf =()=>{
        let eloquatrackpdfText;
        if(props.globalTocCurrentRevision.document.requiredVeracitySubscriptions){
            if(userSettings?.internal){
                eloquatrackpdfText = "Download PDF"
             }   
          else if(userSettings?.subscription){
            eloquatrackpdfText = "Subscriber download PDF"
          }
        }
        else{
          eloquatrackpdfText = "Download PDF"
        }
        const thecurrentPdfLinkToUse = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + getUpn(userInfo) + "&docTitle=" + props.globalTocCurrentRevision.document.documentCode + " " + props.globalTocCurrentRevision.document.title + "&docUrl=" + props.globalTocCurrentRevision.document.documentReference + "&type=" + eloquatrackpdfText;
        fetch(thecurrentPdfLinkToUse);
      }
    const fetchpdf =(configId:any, revision:any, name:any)=>{
        fetch(`../../api/Getpdf?configId=${configId}&revision=${encodeURIComponent(revision)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/pdf',
            },
           
          })
          .then((response) => response.blob())
          .then((blob) => {
            const file = new Blob([blob], { type: "application/pdf" });
            const url = window.URL.createObjectURL(file);
            var a = document.createElement('a');
            a.href = url;
            a.download = name + ".pdf";
            a.click();
            trackepdf();
            success("PDF downloaded successfully");
          }).catch(error => {
              console.log(error.response);
              warn("PDF download failed");
            })
      }

    return <StyledEditionContainerDiv >
        <StyledDocumentTitleSpan>Edition: </StyledDocumentTitleSpan>
        {props.globalTocAllRevisions.length > 0 &&
            <DropDownContainer >
                <DropDownItem onClick={toggling} id="dropdown-basic">
                    {editionDate}{amendDate && " (" + amendDate + ")"}  <ChevronIcon expanded={false} style={{ marginLeft: "1.25rem" }} direction="Down" />
                </DropDownItem>
                {isOpen && <DropDownContent onMouseLeave={()=>setIsOpen(false)}>
                    {" "}
                    {props.globalTocAllRevisions.map(tocEntry => {

                        // const theTocPdfLinkToUse = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress="+ getUpn(userInfo) +  "&docTitle=" + tocEntry.document.documentCode + " " + tocEntry.document.title + "&docUrl=" + tocEntry.document.pdfRef + "&type=RS intent to download PDF";                       
                        return (<DropDownListItem key={(props.globalTocCurrentRevision.document.configId || '') + tocEntry.document.edition + tocEntry.document.amended}>{tocEntry.document.configId &&

                            tocEntry.document.revision === props.globalTocCurrentRevision.document.revision && tocEntry.document.revision > 0 ?
                            <span style={{ display: "flex", alignItems: "center" }}><CheckIcon style={{ width: "0.875rem", height: "0.75rem" }} /><span style={{ marginLeft: "0.3125rem" }}>{editionyyyyMMtoStringShort(tocEntry.document.edition || '')}{tocEntry.document.amended && " (amended " + editionyyyyMMtoStringShort(tocEntry.document.amended || '') + ")"}</span></span>
                            : (tocEntry.document.configId && tocEntry.document.revision > 0) || tocEntry.document.isCurrent ?
                                <Link style={{ marginLeft: "1.1875rem" }} onClick={()=>setIsOpen(false)} to={"/document/" + tocEntry.document.configId + "/" + tocEntry.document.revision}>{editionyyyyMMtoStringShort(tocEntry.document.edition || '')}{tocEntry.document.amended && " (amended " + editionyyyyMMtoStringShort(tocEntry.document.amended || '') + ")"} </Link>
                                :
                                (!props.globalTocCurrentRevision.document.requiredVeracitySubscriptions || userSettings?.subscription || userSettings?.internal) ? 
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <PdfIcon style={{width: "1rem"  }} /> 
                                    <span id={tocEntry.document.amended != "" ? "pdfdownload" + tocEntry.document.amended?.split(" ").join("") : "pdfdownload" + tocEntry.document.edition?.split(" ").join("")} style={{ marginLeft: "0.4375rem", textDecoration:"none", color:"#0067c5" }} onClick={()=>fetchpdf(tocEntry.document.configId, tocEntry.document.revision, props.globalTocCurrentRevision.document.documentReference)}> {editionyyyyMMtoStringShort(tocEntry.document.edition || '')}
                                        {tocEntry.document.amended && " (amended " + editionyyyyMMtoStringShort(tocEntry.document.amended || '') + ")"} </span>
                                   </span> : <span style={{display: "flex", alignItems: "center", cursor:"default"}} data-tip="Rules and Standards Explorer+ subscription required">
                                        <PdfIcon style={{width: "1rem", opacity:"50%"  }}> </PdfIcon><ReactTooltip type="light" border place="bottom" />
                                    <a style={{ marginLeft: "0.4375rem", textDecoration:"none", color:"grey" }}>
                                        {editionyyyyMMtoStringShort(tocEntry.document.edition || '')}
                                        {tocEntry.document.amended && " (amended " + editionyyyyMMtoStringShort(tocEntry.document.amended || '') + ")"}
                                    </a></span>
                        }
                        </DropDownListItem>)
                    }
                    )}

                </DropDownContent>
                }
            </DropDownContainer>

        }
        {/* <span style={{display:"flex",alignItems:"center",justifyContent:"center", marginLeft: "0.625rem" }}><a href={thecurrentPdfLinkToUse} style={{display:"flex",alignItems:"center",justifyContent:"center"}} title="Download in PDF format" target="_blank" rel="noopener noreferrer"><PdfIcon style={{ pointerEvents: "none", width: "1rem" }} /></a></span> */}
        <DocumentMap_Pdf document={props.globalTocCurrentRevision}/>

    </StyledEditionContainerDiv>
}

export const DocumentInfobox: React.FC<{ globalTocAllRevisions: DocumentCardElement[], globalTocCurrentRevision: DocumentCardElement}> = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [value, setValue] = useState(1);
    const [text, setText] = useState(window.location.href);
    const [textInternal, setTextinternal] = useState(window.location.href.slice(0,window.location.href.lastIndexOf('/')));

    const [copySuccess, setCopySuccess] = useState("");

    const onShareBtnClick =()=>{
        setText(window.location.href);
        setIsModalVisible(true);
    }
    const onChange = (e:any) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        setCopySuccess(" ");
       
        if(e.target.value === 2){
            let urlElements = window.location.href;
            urlElements = urlElements.slice(0,urlElements.lastIndexOf('/'));
            setText(urlElements);
        }
        else if(e.target.value===1){
            setText(window.location.href);
        }
      };
    const handleCancel = () => {
        setIsModalVisible(false);
        setValue(1);
        setText(window.location.href);
        setCopySuccess(" ");

      };

      function copy(text:any){
        navigator.clipboard.writeText(text);
        setCopySuccess("Copied");
      }
      let internalDocs = false;
      var str = props.globalTocCurrentRevision.document.documentReference?.split('-');
      if(str != undefined){
        if(str[1] === "WPI" || str[1] === "ITG"){
            internalDocs = true;
        }
      }

    return props.globalTocCurrentRevision ?
        <div data-cy="documentinfobox">
            {
                (!props.globalTocCurrentRevision.document.isCurrent || props.globalTocCurrentRevision.document.withdrawn !== service.WithdrawnType.None || (props.globalTocCurrentRevision.document.documentCode && (props.globalTocCurrentRevision.document.documentCode.includes("WPI") === true || props.globalTocCurrentRevision.document.documentCode.includes("ITG") === true)))
                && <WithDrawnInfoBox document={props.globalTocCurrentRevision.document} />
            }
            
            <Modal closable={false} className="newStyle" title={props.globalTocCurrentRevision.document.documentReference +' - edition ' +editionyyyyMMtoStringShort(props.globalTocCurrentRevision.document.edition || '') + (props.globalTocCurrentRevision.document.amended && " (" + editionyyyyMMtoStringShort(props.globalTocCurrentRevision.document.amended || '')+ ")" )}  visible={isModalVisible} onCancel={handleCancel} okText="Copy link" cancelText="Close" onOk={internalDocs? ()=>copy(textInternal): ()=>copy(text)}>
                <Radio.Group onChange={onChange} value={value}>
                    <Space direction='vertical'>
                       {internalDocs ? <Radio value={1}>Link to always latest edition  of the document</Radio> : <><Radio value={1}>Link to this specific edition of the document</Radio>
                        <Radio value={2}>Link to always latest edition  of the document</Radio></>}
                        
                    </Space>
                </Radio.Group>
                {internalDocs ? <Input value={textInternal} style={{marginTop:"10px"}}></Input> : <Input value={text} style={{marginTop:"10px"}}></Input>}
                {/* <Button onClick={()=>copy(text)} style={{marginTop:"10px", marginRight:"5px", backgroundColor:"#1890ff", color:"#ffffff"}}>Copy link</Button> */}
                <span style={{marginLeft:"26rem"}}>{copySuccess}</span>
               
            </Modal>
            <StyledRoundedMetadataDiv >
                <StyledMetadataContainerDiv style={{gridTemplateColumns:"0fr auto 6.5rem"}}>
                    <div style={{ gridArea: "1" }}>
                        <StyledFavouriteTogglerContainerDiv>
                            <FavouriteToggler allowToogle={true} document={props.globalTocCurrentRevision.document} />
                        </StyledFavouriteTogglerContainerDiv>
                    </div>
                    <div style={{ gridArea: "1/2" }}>
                        <StyledDocumentCodeDiv>
                            {props.globalTocCurrentRevision.document.documentReference} {props.globalTocCurrentRevision.document.requiredVeracitySubscriptions ? <span style={{ letterSpacing: "0" }}><ESubIcon style={{ position: "relative", top: "4px" }} data-tip="Rules and Standards Explorer+ document" title="" /><ReactTooltip type="light" border place="bottom" /></span> : ""}
                        </StyledDocumentCodeDiv>
                    </div>
                    <div style={{ gridArea: "2/1" }}>
                        <ShareAltOutlined title='Copy link' style={{paddingLeft:"1.125rem", paddingTop:"0.3rem", fontSize:"large"}} onClick={onShareBtnClick} />
                    </div>
                    <div style={{gridArea:"2/2"}}>
                        <StyledTitleContainerDiv>

                            <StyledLinkButton title="Scroll to top" data-cy="infobox_title" onClick={() => window.scrollTo(0, 0)}>
                                {props.globalTocCurrentRevision.document.chapter && "Chapter " + props.globalTocCurrentRevision.document.chapter + ": "}{props.globalTocCurrentRevision.document.title}
                            </StyledLinkButton>
                        </StyledTitleContainerDiv>
                    </div>
                </StyledMetadataContainerDiv>

                <DownloadOptions {...props} />

            </StyledRoundedMetadataDiv>
        </div>
        : <>Loading</>
}
