import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import TagManager from 'react-gtm-module'
import {getClient} from '../../utils/proxyFactory';
import * as service from '../../api/SmartLibraryService';
interface IExecuteSearch { type: "EXECUTE_SEARCH";payload:{query:string} }
interface IExecuteSearchComplete { type: "EXECUTE_SEARCH_COMPLETE"; payload:service.IResult }
interface IExecuteSearchFailed { type: "EXECUTE_SEARCH_FAILED"; payload:{error:string} }
interface IClearAllSearch { type: "SET_SEARCH_CLEAR"; }

export type SearchActions = IExecuteSearch | IExecuteSearchComplete | IExecuteSearchFailed | IClearAllSearch;
type TA = ThunkAction<void, IRootState, void, SearchActions>;
const track =(query:string, x: service.IResult)=>{
  TagManager.dataLayer( {
    dataLayer:{
      'event' : 'GAEvent',
      'eventCategory' : 'Search',        
      'eventAction' : query,
      'eventLabel' : 'DocumentCode',
      'eventValue' : x.documentCode ? x.documentCode.length : 0
    }      
  });

  TagManager.dataLayer({
    dataLayer:{
      'event' : 'GAEvent',
      'eventCategory' : 'Search',        
      'eventAction' : query,
      'eventLabel' : 'Title',
      'eventValue' : x.title ? x.title.length : 0
    }      
  });        
  TagManager.dataLayer({
    dataLayer:{
      'event' : 'GAEvent',
      'eventCategory' : 'Search',        
      'eventAction' : query,
      'eventLabel' : 'HTML',
      'eventValue' : x.html ? x.html.length : 0
    }      
  });
}
//https://www.simoahava.com/analytics/create-a-generic-event-tag/
export const searchActions = {
    requestSearch: (query:string): TA => async (dispatch,getState)  => {
        dispatch({
            type: "EXECUTE_SEARCH",payload:{query}
        });
        
       
        var client = await getClient(dispatch,getState());
        await client.search(query)
        .then(x => {         
          if (x != null) {
            track(query,x);
            dispatch({ type: "EXECUTE_SEARCH_COMPLETE", payload: x })
          }else{
            dispatch({ type: "EXECUTE_SEARCH_FAILED", payload:{error:"No document types returned"}})
          }          
        },
          (x:string) => {
            dispatch({ type: "EXECUTE_SEARCH_FAILED", payload:{error:x}})           
          }
        );

    },
    clearSearch: (): TA => (dispatch) => {
      dispatch({ type: "SET_SEARCH_CLEAR" });
    },
}