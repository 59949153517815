import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import { success } from '../../utils/customToast';
import { getUserSettingClient } from '../../utils/proxyFactory';
import * as service from '../../api/SmartLibraryService';

interface ILoadUserSettings { type: "LOAD_USER_SETTINGS"; }
interface ILoadUserSettingsComplete { type: "LOAD_USER_SETTINGS_COMPLETE"; payload: { userSetting: service.IUserSettingDto } }
interface ILoadUserSettingsFailed { type: "LOAD_USER_SETTINGS_FAILED"; payload: { error: string } }

interface ISaveUserSettings { type: "SAVE_USER_SETTINGS"; }
interface ISaveUserSettingsComplete { type: "SAVE_USER_SETTINGS_COMPLETE"; payload: { userSetting: service.IUserSettingDto } }
interface ISaveUserSettingsFailed { type: "SAVE_USER_SETTINGS_FAILED"; payload: { error: string } }

interface IRequestTrialUserSettings { type: "REQUEST_TRIAL_USER_SETTINGS"; }
interface IRequestTrialUserSettingsComplete { type: "REQUEST_TRIAL_USER_SETTINGS_COMPLETE"; payload: { userSetting: service.IUserSettingDto } }
interface IRequestTrialUserSettingsFailed { type: "REQUEST_TRIAL_USER_SETTINGS_FAILED"; payload: { error: string } }

export type UserSettingActions = ILoadUserSettings | ILoadUserSettingsComplete | ILoadUserSettingsFailed |  ISaveUserSettings | ISaveUserSettingsComplete | ISaveUserSettingsFailed | IRequestTrialUserSettings | IRequestTrialUserSettingsComplete | IRequestTrialUserSettingsFailed;

type TA = ThunkAction<void, IRootState, void, UserSettingActions>;

export const userSettingsActions = {
    loadUserSettings: (): TA => async (dispatch, getState) => {
        dispatch({
            type: "LOAD_USER_SETTINGS"
        });

        var client = await getUserSettingClient(dispatch, getState());

        await client.getUserSettingForUser()
            .then(x => {
                if (x != null) {
                    dispatch({ type: "LOAD_USER_SETTINGS_COMPLETE", payload: { userSetting: x} })
                } else {
                    dispatch({ type: "LOAD_USER_SETTINGS_FAILED", payload: { error: x } })
                }
            },
                (x: string) => {
                    dispatch({ type: "LOAD_USER_SETTINGS_FAILED", payload: { error: x } })
                }            
            );
    },

    saveUserSettings: (userSettingAdd: service.UserSettingFeaturesDto): TA => async (dispatch, getState) => {
        dispatch({
            type: "SAVE_USER_SETTINGS"
        });

        var client = await getUserSettingClient(dispatch, getState());

        await client.updateFeatureSettingsForUser(userSettingAdd)
            .then(x => {
                dispatch({ type: "SAVE_USER_SETTINGS_COMPLETE", payload: { userSetting: x} });
                success("You have updated your user settings");
            },
                (x: string) => {
                    dispatch({ type: "SAVE_USER_SETTINGS_FAILED", payload: { error: x } });
                }
        )
    },

    requestTrialUserSettings: (): TA => async (dispatch, getState) => {
        dispatch({
            type: "REQUEST_TRIAL_USER_SETTINGS"
        });

        var client = await getUserSettingClient(dispatch, getState());

        await client.requestTrialForUser()
            .then(x => {
                dispatch({ type: "REQUEST_TRIAL_USER_SETTINGS_COMPLETE", payload: { userSetting: x} });
                success("You now have a free 14 day trial activated");
            },
            (x: string) => {
                dispatch({ type: "SAVE_USER_SETTINGS_FAILED", payload: { error: x } });
            })

    }
}