import { useSelector } from "react-redux";
import { IRootState } from "../../store/store";
import { flattenDocsInCategories } from "../../utils/documentHelpers";
import styles from "../ContentManager/ContentManager.module.scss";
// import './DocumentViewer/css/DocumentViewer.scss';
import { DocumentCardElement } from "../Documents/DocumentCard";
import { DocumentFilters } from "../Documents/Filters/DocumentFilters";
import { Bookmarks } from "../Sidebar/Bookmarks/Bookmarks";
import { Sidebar } from "../Sidebar/Sidebar";
import { BreadCrumb } from "./BreadCrumb";
import { Document } from "./Document";
import { DocumentMap } from "./DocumentMap/DocumentMap";
import { DocumentMapNoteIndicator } from "./DocumentMap/DocumentMap_NoteIndicator";
import { Advfilter } from "../FeaturesSettings/AdvFilter_Feature";
import { Menu } from "../Sidebar/Menu";

export function DocumentRender() {
  const allDocs = useSelector((state: IRootState) => flattenDocsInCategories(state.toc.categories));
  const currentDocs = allDocs && allDocs.filter((doc) => doc.document.isCurrent);

  const outOfDateIndicatorsWithBookmarks = DetectLastSeenDifferentThanCurrent(currentDocs);
  const filteredDocuments = currentDocs;

  const filterState =  useSelector((state: IRootState) => state.filterState);

  const isGlobSer = useSelector((state: IRootState) => state.globalsearch.compVisible);
  const searchResult =  useSelector((state: IRootState) => state.searchResult);

  return (
    <Menu layout="DocumentRender">
      
        <div className={styles.breadCrumb + " rs-sticky rs-divider-border-bottom"} style={(filterState.isFiltered || searchResult.query )&& isGlobSer?{gridRow: 1, display: "grid", alignItems: "flex-end", top: "183px", background: "white", marginTop: "-1.2rem", width: "100%",zIndex:1, height:"80px"}: filterState.isFiltered || searchResult.query ? { gridRow: 1, display: "grid", alignItems: "flex-end", top: "3.875rem", background: "white", marginTop: "-1.2rem", width: "100%",zIndex:1, height:"80px"} :isGlobSer ?{top:"183px",gridRow: 1, display: "flex", alignItems: "flex-end", background: "white", marginTop: "-1.1rem", width: "100%", zIndex:1} : {gridRow: 1, display: "grid", alignItems: "flex-end", top: "3.875rem", background: "white", marginTop: "-1.2rem", width: "100%",zIndex:1}}>
        
                        <BreadCrumb documentCards={allDocs} />
                    
                </div>
                <div className={styles.documentCell} style={filterState.isFiltered || searchResult.query ? { gridRow: 2,  paddingTop:"20px" } : {gridRow: 2}}>
                    <Document documentCards={filteredDocuments} />
                </div>
                <div className={styles.noteIndicator} >
                    <DocumentMapNoteIndicator />
                </div>
                <div data-cy="documentmap" className={styles.documentMap + " rs-sticky"} style={isGlobSer ?{backgroundColor: "rgba(229, 245, 251, 0.2)", gridRow: 2, height: "calc(100vh - 233px)",top:"233px"} :filterState.isFiltered || searchResult.query ? { backgroundColor: "rgba(229, 245, 251, 0.2)", gridRow: 2, maxHeight: "calc(100vh - 110px)", top: "110px", paddingTop:"32px"}:{backgroundColor: "rgba(229, 245, 251, 0.2)", gridRow: 2, maxHeight: "calc(100vh - 110px)", top: "110px"}}>
                    <DocumentMap outOfDateIndicatorsWithBookmarks={outOfDateIndicatorsWithBookmarks} />
                </div>
        
    </Menu>
    
  );
}

// If the user has bookmarked the document
// And lastSeen has different revision than current revision
export function DetectLastSeenDifferentThanCurrent(currentDocs: DocumentCardElement[]) {
  const lastSeenIndicators = useSelector(
    (state: IRootState) => state.userNotes.lastSeenIndicators
  );

  // The indicator revision does not exists among current docs
  const outOfDateIndicators =
    lastSeenIndicators &&
    lastSeenIndicators.filter(
      (x) =>
        currentDocs.find(
          (y) =>
            x.configId === y.document.configId &&
            x.revision === y.document.revision
        ) === undefined
    );

  // Get top level bookmarks (mandatory for lower level bookmarks to exist)
  const notes = useSelector((state: IRootState) => state.userNotes.notes);
  const topLevelBookmarks = notes && notes.filter((note) => !note.bookmarkedId);

  // User must still have a bookmark at any revision
  const outOfDateIndicatorsWithBookmarks =
    outOfDateIndicators &&
    outOfDateIndicators.filter(
      (x) =>
        topLevelBookmarks &&
        topLevelBookmarks.find((y) => x.configId === y.configId)
    );

  return outOfDateIndicatorsWithBookmarks;
};
