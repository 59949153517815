import React from 'react'
import { provide } from "../../store/provide";
import { highlightActions } from "../../store/actions/highlightActions"
import { scrolltoId } from '../../utils/scroll';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { documentActions } from '../../store/actions/documentActions';
import styled from 'styled-components/macro';
interface IMathJaxComponentState {
  typeSettingCalled: boolean
}

const StyledDocumentHtmlContainer = styled.div`
  animation: ${({theme}) => theme.animations.duration } ${({theme}) => theme.animations.fadeIn } ease-out;
`
class MathJax3Component extends React.Component<Props & RouteComponentProps, IMathJaxComponentState> {

  state: IMathJaxComponentState = { typeSettingCalled: false }
  onMathJaxDone = () => {
    var numberOfHighlights = document.querySelectorAll(".rs-highlight-search-term").length;
    this.props.setHighlightCount(numberOfHighlights);
    this.props.documentMounted();
    if (this.props.location.hash) {
      if (!scrolltoId(this.props.location.hash.slice(1))) {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    if (this.props.mathJax.isReady) {
      this.setState(x => ({ ...x, typeSettingCalled: true }));
      (window as any).MathJax.typesetPromise().then(() => this.onMathJaxDone())
    }
  }
  componentDidUpdate() {
    if (this.props.mathJax.isReady && !this.state.typeSettingCalled) {
      this.setState(x => ({ ...x, typeSettingCalled: true }));
      (window as any).MathJax.typesetPromise().then(() => this.onMathJaxDone())
    }
  }

  render = () => {

    return <StyledDocumentHtmlContainer id="docContainer" dangerouslySetInnerHTML={{ __html: this.props.html }}/>

  }
}
const provider = provide((state) => ({
  mathJax: state.mathJax
}), { ...highlightActions, ...documentActions }).withExternalProps<{ html: string, numberOfImages: number }>();

type Props = typeof provider.allProps;
export const MathJax3 = provider.connect(withRouter(MathJax3Component));