import { useParams, useHistory } from 'react-router-dom';
import { IDocumentRouteProps } from '../../utils/routeProps/DocumentRouteProps';
import { useSelector } from 'react-redux';
import { IRootState } from '../../store/store';
import { flattenDocsInCategories } from '../../utils/documentHelpers';

export function RedirectContent() {    
    const { configId, revision } = useParams<IDocumentRouteProps>();    
    const toc = useSelector((state: IRootState) => { return state.toc.categories})
    let history = useHistory();

    // PDF only document redirect, revision will always be 0
    if (configId.includes("DNV"))
    {
        const pdfOnlyRedirect = "/document/" + configId + "/0";
        history.push(pdfOnlyRedirect);        
    }   

    const getDocumentFromToc = () => {
        const allDocuments = flattenDocsInCategories(toc); 
        
        // This should always be the case when redirect is called
        if (revision === undefined)
        {
            const currentDocument = allDocuments.filter(x => x.document.configId === configId && x.document.revision !== 0);
            
            if (currentDocument.length > 0)
            {                
                return currentDocument[0];
            }
            return null;
        }
        
        // If somehow redirect happens when revision is known - wrong use of redirect content funtion
        const currentDocumentsWithRevision = allDocuments.filter(x => x.document.configId === configId && x.document.revision === +revision);
        if (currentDocumentsWithRevision.length > 0) {           
            return currentDocumentsWithRevision[0];
        }
        return null;
    }
    
    const document = getDocumentFromToc();
    const configIdToUse = document?.document.configId;
    const revisonToUse = document?.document.revision;
    const redirectURL = "/document/" + configIdToUse + "/" + revisonToUse;

    // Render when document is found
    if (document)
    {
        history.push(redirectURL);        
    };

    // Render when function tries to find the document
    return <div>Finding correct page...</div>
}