import * as React from 'react';
import { provide } from '../../store/provide';
import {  RouteComponentProps, withRouter } from 'react-router-dom';
import loadScript from 'load-script'
import { mathJaxActions } from '../../store/actions/mathJaxActions';
class MathJaxLoaderComponent extends React.Component<Props & RouteComponentProps> {

  
    componentDidMount(){
        (window as any).MathJax = {
            tex: {
              inlineMath: [['$', '$'], ['\\(', '\\)']]
            },
            svg: {
              fontCache: 'global'
            },
            typeset: false, 
            startup: {             
              ready: () => {
                (window as any).MathJax.startup.defaultReady();
                this.props.onMathJaxReady();              
              }
            }           
          };
          loadScript("https://cdn.jsdelivr.net/npm/mathjax@3.2.0/es5/tex-mml-chtml.js")
    }
    render() {
       return (<></>);
    }
}
const provider = provide((state) => ({   

}), { ...mathJaxActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const MathJaxLoader = provider.connect(withRouter(MathJaxLoaderComponent));