import { ThunkAction } from "redux-thunk";
import { IRootState } from "../../store/store";
import { getAttachmentsClient } from '../../utils/proxyFactory';
import * as service from '../../api/SmartLibraryService';

interface ILoadUserSettings { type: "LOAD_ATTACHMENT"; }
interface ILoadUserSettingsComplete { type: "LOAD_ATTACHMENT_COMPLETE"; payload: service.FileResponse }
interface ILoadUserSettingsFailed { type: "LOAD_ATTACHMENT_FAILED"; payload: { error: string } }

export type AttachmentActions = ILoadUserSettings | ILoadUserSettingsComplete | ILoadUserSettingsFailed;

type TA = ThunkAction<void, IRootState, void, AttachmentActions>;

export const attachmentActions = {
    loadAttachments: (documentx: string, version: string, attachment: string): TA => async (dispatch, getState) => {
        dispatch({
            type: "LOAD_ATTACHMENT"
        });

        var client = await getAttachmentsClient(dispatch, getState());

        await client.getAttachmentFile(documentx, version, attachment)
            .then(x => {
                if( x != null){                    
                    dispatch({ type: "LOAD_ATTACHMENT_COMPLETE", payload: x })
                }              
            },
                (x: string) => {
                    dispatch({ type: "LOAD_ATTACHMENT_FAILED", payload: { error: x } })
                }            
            );
    },
}