import React from 'react';
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ChevronIcon } from '../../Icons/Chevron';
import { provide } from "../../../store/provide";
import { DocumentCardElement } from '../DocumentCard';
import { filterActions } from '../../../store/actions/filterActions';
import { ICategory, IDocument, LastRevisionForUserIndicatorDto, Audience } from '../../../api/SmartLibraryService';
import { filterByIndustry, filterByIssuedDate } from '../../../utils/documentHelpers';
import { documentListActions } from '../../../store/actions/documentListActions';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { DocumentRow } from './DocumentFilters_DocumentRow';
import { SearchFilter } from './DocumentFilters_SearchFilter';
import { currentYear } from '../../../utils/variables';
import styled from 'styled-components/macro';
import { ESubIcon } from '../../Icons/ESubIcon';
import { tocActions } from '../../../store/actions/tocActions';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/store';
import {FilterCategory, FilteredCategory} from './FilterCategory';
import { DocumentsActions } from '../DocumentsActions';
import { sideBarActions } from '../../../store/actions/sideBarActions';
import { searchActions } from '../../../store/actions/searchActions';

const StyledFilterGridDiv = styled.div`
    width:100%;   
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr auto 2.5rem;
    justify-items: stretch;
    justify-content: stretch;	
    grid-template-rows: auto;
    font-size: ${({ theme }) => theme.fonts.sizes.medium};
`;


type GridRowCounter = {
    rowCount: number;
}
const increaseGridRowCounter = (rowCounter: GridRowCounter) => {
    rowCounter.rowCount = rowCounter.rowCount + 1;
}

const DocumentGroup = (props: { category: ICategory, rowNumber: number, }) => {
    return <div style={{ gridRow: props.rowNumber, gridColumnStart: 1, gridColumnEnd: 3,fontWeight:700, textTransform: "uppercase", textIndent: "1.8rem" }}>{props.category.description}</div>
}

const DocumentCategory = (props: {
    category: ICategory,
    rowNumber: number,
    categoryClick: React.MouseEventHandler<unknown>,
    chevronClick: React.MouseEventHandler<unknown>,
    isSelected: boolean,
    isOpen: boolean,
    displayedCount: string
}) => <>
        <div style={{ gridRow: props.rowNumber, gridColumn: 1, textIndent: "1rem" }}>
            <ChevronIcon style={{ cursor: "pointer" }} onClick={props.chevronClick} direction={props.isOpen ? "Down" : "Right"} expanded={props.isOpen} />
            <span data-cy={"category-" + props.category.description} style={{ marginLeft: "0.5rem", cursor: "pointer", fontWeight: props.isSelected ? "bold" : "unset" }} onClick={props.categoryClick}  >{props.category.description}</span>
        </div>
        <div style={{ gridRow: props.rowNumber, gridColumn: 2, whiteSpace: "nowrap", fontWeight: props.isSelected ? "bold" : "unset" }}>{props.category.code}</div>
        <div style={{ gridRow: props.rowNumber, gridColumn: 3, textAlignLast: "right",paddingRight:"8px", fontWeight: props.isSelected ? "bold" : "unset" }} data-cy="subcategory-count">{props.displayedCount}</div>
    </>

const DocumentSubCategory = (props: {
    category: ICategory,
    rowNumber: number,
    categoryClick: React.MouseEventHandler<unknown>,
    chevronClick: React.MouseEventHandler<unknown>,
    isSelected: boolean,
    isOpen: boolean,
    displayedCount: string
}) => <>
        <div style={{ gridRow: props.rowNumber, gridColumnStart: 1, gridColumnEnd: 3, textIndent: "2rem" }}>
            <ChevronIcon style={{ cursor: "pointer" }} onClick={props.chevronClick} direction={props.isOpen ? "Down" : "Right"} expanded={props.isOpen} />
            <span data-cy={"category-" + props.category.description} style={{ marginLeft: "0.5rem", cursor: "pointer", fontWeight: props.isSelected ? "bold" : "unset" }} onClick={props.categoryClick} >{props.category.code}: {props.category.description}</span>
        </div>
        <div style={{ gridRow: props.rowNumber, gridColumn: 3, textAlignLast: "right",paddingRight:"8px", fontWeight: props.isSelected ? "bold" : "unset" }} data-cy="subcategory-count">{props.displayedCount}</div>
    </>


class DocumentFiltersComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{
    gotoStartIfNotAlreadyThere = () => {
        window.scrollTo(0, 0);
        this.props.setSorting("none", "Ascending");
        if(this.props.searchResult.query !=null){
            this.props.history.push("/search");
            this.props.requestSearch(this.props.searchBoxText);

        }
        if (this.props.searchResult.query === null) {
            if (this.props.location.pathname !== "/documents") {
                this.props.history.push("/documents");
            }
        }
        // if (this.props.location.pathname !== "/documents") {
        //     this.props.history.push("/documents");
        // }
    }

    toggleSideBarNavigationNav = () => {
        this.props.toggleSideBarNavigation(true, "NAV")
    }

    renderSubCategory = (filteredCategory: FilteredCategory, gridRowCounter: GridRowCounter, filteredParentCategory: FilteredCategory): JSX.Element | null => {
        const category = filteredCategory.category;
        const parentCategory = filteredParentCategory.category;
        increaseGridRowCounter(gridRowCounter);
        const isOpen = this.props.filterState.openChevronCategory === (parentCategory.code || "") && (this.props.filterState.openChevronSubCategory === category.code);
        const currentRow = gridRowCounter.rowCount;
        let renderedDocuments: JSX.Element[] = [];
        if (isOpen) {
            renderedDocuments = filteredCategory.visibleDocuments.map((document, index) =>
                <DocumentRow  key={document.configId} category={category} d={document} rowCounter={gridRowCounter.rowCount + index + 1} paddingLeft={"1.5rem"} outOfDateIndicatorsWithBookmarks={this.props.outOfDateIndicatorsWithBookmarks} />
            );
        }
        gridRowCounter.rowCount += renderedDocuments.length;
        return <React.Fragment key={category.code + '' + parentCategory.code} >
            <DocumentSubCategory category={category}
                rowNumber={currentRow}
                chevronClick={(e) => { this.props.setOpenChevron(parentCategory.code || null, category.code || null); e.stopPropagation(); }}
                categoryClick={() => {this.props.clearValues(); this.gotoStartIfNotAlreadyThere(); this.props.setCategoryFilter(parentCategory.code || null, category.code || null); this.toggleSideBarNavigationNav(); }}
                isOpen={isOpen}
                isSelected={this.props.filterState.category === (parentCategory.code || "") && this.props.filterState.subCategory === category.code && !!!this.props.match.params.configId}
                displayedCount={this.props.filterState.isFiltered && this.props.searchResult.query? filteredCategory.subCategoryDocuments.length + '': this.props.searchResult.query ? (filteredCategory.subCategoryDocuments.length) + '' : ''}
            />
            {renderedDocuments}
        </React.Fragment>;


    }
    renderCategoryWithChildren = (filteredCategory: FilteredCategory, gridRowCounter: GridRowCounter) => {
        increaseGridRowCounter(gridRowCounter);
        const isOpen = this.props.filterState.openChevronCategory === (filteredCategory.category.code || "");
        let renderedCategories: (JSX.Element | null)[] = [];
        let renderedDocuments: JSX.Element[] = [];
        const currentRow = gridRowCounter.rowCount;
        if (isOpen) {
            renderedCategories = filteredCategory.filteredCategories.filter(x => x.subCategoryDocuments.length > 0).flatMap(subCategory => {
                return this.renderSubCategory(subCategory, gridRowCounter, filteredCategory)
            });
            renderedDocuments = filteredCategory.visibleDocuments.map((document, index) =>
                <DocumentRow key={document.configId} category={filteredCategory.category} d={document} rowCounter={gridRowCounter.rowCount + index + 1} paddingLeft={"0.75rem"} outOfDateIndicatorsWithBookmarks={this.props.outOfDateIndicatorsWithBookmarks} />
            );
            gridRowCounter.rowCount += renderedDocuments.length;
        }
        return (
            <React.Fragment key={filteredCategory.category.code} >
                <DocumentCategory category={filteredCategory.category}
                    rowNumber={currentRow}
                    isOpen={isOpen}
                    isSelected={this.props.filterState.category === (filteredCategory.category.code || "") && !this.props.filterState.subCategory && !this.props.match.params.configId}
                    chevronClick={(e: React.MouseEvent<any>) => { this.props.setOpenChevron(filteredCategory.category.code || null, null); e.stopPropagation(); }}
                    categoryClick={() => {this.props.clearValues(); this.gotoStartIfNotAlreadyThere(); this.props.setCategoryFilter(filteredCategory.category.code || null, null); this.toggleSideBarNavigationNav() }}
                    displayedCount={this.props.filterState.isFiltered && this.props.searchResult.query ? filteredCategory.subCategoryDocuments.length + '': this.props.searchResult.query ? filteredCategory.subCategoryDocuments.length + '' : ''}
                />
                {renderedCategories}
                {renderedDocuments}
            </React.Fragment>
        )
    }

    // Top level grouping, with spacing between each group
    renderGroupWithChildren = (filteredCategory: FilteredCategory, gridRowCounter: GridRowCounter) => {
        increaseGridRowCounter(gridRowCounter);
        return (
            <React.Fragment key={filteredCategory.category.code} >
                <DocumentGroup category={filteredCategory.category} rowNumber={gridRowCounter.rowCount} />
                {filteredCategory.filteredCategories.filter(x => x.subCategoryDocuments.length > 0).flatMap(subCategory => {
                    return this.renderCategoryWithChildren(subCategory, gridRowCounter);
                })}
                {increaseGridRowCounter(gridRowCounter)}
                <div style={{ gridRow: gridRowCounter.rowCount, gridColumnStart: 1, gridColumnEnd: 4, height: "1rem" }}></div>
            </React.Fragment>
        )
    }

    scrolltoTop = ()=>{
        this.props.clearFilters();
        this.props.clearSearch();
        window.scroll(0,0);
    }
    
    render ()
    {      
        //const filteredCardsByIssuedDate = filterByIssuedDate(this.props.documentCards, this.props.filterState.issuedDate, this.props.filterState.retiredType, this.props.filterState.replacedType);
        const filteredCardsByIndustry = filterByIndustry(this.props.documentCards, this.props.filterState.domainId);
        const configIdSet = new Set(filteredCardsByIndustry.map(x => x.document.documentCode || ''));

        if (this.props.categories) {
        const filteredCategories = this.props.categories.map(x => FilterCategory(x, configIdSet, this.props.filterState, this.props.searchResult));
        
        const rowCounter = { rowCount: 3 };

            return (
                <StyledFilterGridDiv>  
                    <div style={{ gridRow: 1, gridColumnStart: 1, gridColumnEnd: 4, justifyContent: "center", paddingTop: "1rem", display: "flex" }}>
                        {/* <IndustryFilters 
                        maritimeCount={this.props.documentCards.filter(x => x.document.audience && x.document.audience.filter(a => a === Audience.Maritime).length > 0).length}
                        ogCount={this.props.documentCards.filter(x => x.document.audience && x.document.audience.filter(a => a === Audience.OilAndGas).length > 0).length}
                        energyCount={this.props.documentCards.filter(x => x.document.audience && x.document.audience.filter(a => a === Audience.Energy).length > 0).length}
                        /> */}
                    </div>                 
                    {/* <div style={{ gridRow: 2, gridColumnStart: 1, gridColumnEnd: 4 }}>
                        {this.props.searchResult.query && <SearchFilter queryString={this.props.searchResult.query} onDismiss={() => { this.gotoStartIfNotAlreadyThere(); this.props.clearFilters() }} />}                            
                    </div> */}
                    <div style={{ gridRow: 3, gridColumnStart: 1, gridColumnEnd: 4, height: "1rem" }}></div>
                    {filteredCategories.filter(x => x.subCategoryDocuments.length > 0).flatMap(categoryWithRenderHelper => {
                        return this.renderGroupWithChildren(categoryWithRenderHelper, rowCounter);
                    })}
                    <div style={{ gridRow: rowCounter.rowCount++, gridColumnStart: 1, gridColumnEnd: 4, justifyContent: "center", marginTop: "1rem" }} >
                    <div style={{ borderTop: "1px solid #cccccc", fontSize: "smaller", paddingTop: "10px", paddingLeft: "5px", margin: "1rem" }}><span style={{ position: "relative", top: "5px" }}><ESubIcon title="E+" /></span> <Link to="/subscription" onClick={this.scrolltoTop} style={{ textDecoration: "underline" }}>Rules and Standards Explorer+ subscription info</Link></div>
                        <div style={{ fontSize: "smaller", paddingLeft: "5px", margin: "1rem" }}>The PDF is the official version.</div>
                        <div style={{ display: "flex", justifyContent: "space-around" , padding: "1rem 6rem" }}>
                            <a href="https://store.veracity.com/rules-and-standards-explorer" target="_blank" rel="noopener noreferrer" >About</a>
                            <div style={{ textAlign: "center" }}>&copy; DNV AS {currentYear}</div>
                        </div>
                    </div>
                </StyledFilterGridDiv>
            );
        }
        return <>No document types loaded </>
    }
};

const provider = provide((state) => ({
    searchResult: state.searchResult,
    searchBoxText: state.searchBox.searchBoxText,
    filterState: state.filterState,
    categories: state.toc.categories,
    toc: state.toc
}), { ...filterActions, ...documentListActions, ...tocActions, ...sideBarActions, ...DocumentsActions,...searchActions}).withExternalProps<{ documentCards: DocumentCardElement[], outOfDateIndicatorsWithBookmarks: LastRevisionForUserIndicatorDto[] | null }>();

type Props = typeof provider.allProps;
export const DocumentFilters = provider.connect(withRouter(DocumentFiltersComponent));

