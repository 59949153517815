import { Reducer } from "redux";
import { TocActions } from "../actions/tocActions";
import * as service from '../../api/SmartLibraryService';
import { DocumentCardElement } from "../../Components/Documents/DocumentCard";

interface ITocContext {
    categories: service.ICategory[] | null;
    isFetching: boolean;    
    error:string | null;
    
  }

export const tocReducer: Reducer<ITocContext, TocActions>
    = (state = { categories:null,isFetching:false,error:null}, action) => {

        switch (action.type) {
            case "LOAD_TOC": {                
                return { ...state,isFetching:true };
            }
            case "LOAD_TOC_COMPLETE":{                
                return { ...state,isFetching:false,error:null,categories:action.payload}
            }
            
            case "LOAD_TOC_FAILED":{
                return { ...state,isFetching:false,error:action.payload.error,categories:null}
            }
            
            default:
                break;
        }

        return state;
    };