import styles from "../ContentManager/ContentManager.module.scss";
import { Menu } from "../Sidebar/Menu";
import { DashboardNews } from "./DashboardNews";

export function Dashboard() {
   
    return (
        <Menu layout="Dashboard">
             <div className={styles.documentList}>
                 <DashboardNews/>
            </div>
        </Menu>
      
    );
}