import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";

interface IToggleGlobalSearch { type: "SHOW"; payload:{compVisible:boolean} }

export type GlobalSearchActions = IToggleGlobalSearch;
type TA = ThunkAction<void, IRootState, void, GlobalSearchActions>;


export const globalsearchActions = {
  showGlobalSearch: (compVisible:boolean): TA => async (dispatch)  => {
      dispatch(({type: "SHOW",payload:{compVisible:compVisible}}));        
  }
}