import React from 'react';
import {ReactComponent as Logo} from '../../images/DNV_logo_RGB.svg';
import { resetActions } from '../../store/actions/resetActions';
import { provide } from "../../store/provide";

class LogoIconComponent extends React.Component<Props>{
    
    render(){
        return <Logo onClick={()=>{this.props.resetState();window.scrollTo(0,0);}}/>
    }

    }
const provider = provide((state) => ({ }), { ...resetActions }).withExternalProps<{}>();
  
  type Props = typeof provider.allProps;
  export const LogoIcon = provider.connect(LogoIconComponent);
  
