import { Reducer } from 'redux';
import * as service from '../../api/SmartLibraryService';
import { UserSettingActions } from '../actions/userSettingsActions';

interface IUserSettingsContext {
    settings: service.IUserSettingDto | null;
    isFetching: boolean;
    complete: boolean;
    activateTrial: boolean;
    error: string | null;
}

export const userSettingsReducer: Reducer<IUserSettingsContext, UserSettingActions> = (state = { settings: null, isFetching: false, complete: false, activateTrial: false, error: null }, action) => {
    switch (action.type) {
        case "LOAD_USER_SETTINGS": {
            return { ...state, isFetching: true, complete: false }
        }
        case "LOAD_USER_SETTINGS_COMPLETE": {
            return { ...state, isFetching: false, complete: true, error: null, settings: action.payload.userSetting ? action.payload.userSetting : null }
        }
        case "LOAD_USER_SETTINGS_FAILED": {
            return { ...state, isFetching: false, complete: true, error: action.payload.error, settings: null }
        }
        case "SAVE_USER_SETTINGS": {
            return { ...state, isFetching: true, complete: false }
        }
        case "SAVE_USER_SETTINGS_COMPLETE": {
            return {...state, isFetching: true, complete: true, settings: action.payload.userSetting ? action.payload.userSetting : null }
        }
        case "SAVE_USER_SETTINGS_FAILED": {
            return {...state, isFetching: false, complete: true, error: action.payload.error }
        }
        case "REQUEST_TRIAL_USER_SETTINGS": {
            return { ...state, isFetching: true, complete: false, activateTrial: true }
        }
        case "REQUEST_TRIAL_USER_SETTINGS_COMPLETE": {
            return {...state, isFetching: true, complete: true, activateTrial: false, settings: action.payload.userSetting ? action.payload.userSetting : null }
        }
        case "REQUEST_TRIAL_USER_SETTINGS_FAILED": {
            return {...state, isFetching: false, complete: true, activateTrial: false, error: action.payload.error }
        }        
        default:
            break;
    }

    return state;
}