import styles from '../ContentManager/ContentManager.module.scss';
import { Feature } from './Features';
import { Menu } from '../Sidebar/Menu';


export function RenderFeature() {
    return (
        <Menu layout='Settings'>
            <div className={styles.documentList}>
                <Feature />
            </div>
        </Menu>

    )
}

