import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";

//import {getClient} from '../../utils/proxyFactory';
//import * as service from '../../api/SmartLibraryService';
interface ISelectedText { type: "SELECTED_TEXT";payload:{selectedText:string, configId:string, revision:number, bookmarkedId:string, bookmarkedIdFullPath:string,coordX:number, coordY:number} }
// interface IExecuteSearchComplete { type: "EXECUTE_SEARCH_COMPLETE"; payload:service.IResult }
// interface IExecuteSearchFailed { type: "EXECUTE_SEARCH_FAILED"; payload:{error:string} }
export type SelectedTextActions = ISelectedText ;//| IExecuteSearchComplete | IExecuteSearchFailed;
type TA = ThunkAction<void, IRootState, void, SelectedTextActions>;

export const selectedTextActions = {
    textSelected: (theText:string,configId:string,revision:number, bookmarkedId:string,bookmarkedIdFullPath:string, coordX: number, coordY: number): TA => async (dispatch,getState)  => {
        dispatch({
            type: "SELECTED_TEXT",payload:{selectedText:theText,configId:configId,revision:revision,bookmarkedId:bookmarkedId,bookmarkedIdFullPath:bookmarkedIdFullPath, coordX: coordX, coordY:coordY}
        });
    }
}