import styled from 'styled-components/macro';
export const StyledLinkButton = styled.span`
background-color: transparent;
border: none;
cursor: pointer;
text-decoration: none;
display: inline;
color: ${({ theme }) => theme.colors.link.digitalblue};
 &:hover,&:focus {
   text-decoration: underline;
 }
`;