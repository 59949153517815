import React from 'react';

import tutorial2 from '../../images/tutorial2.png';
import rebrandedbookmark from '../../images/tutorialBookmarknew.png';

export const TutorialBookmarks: React.FunctionComponent<{}> = (props) => <div style={{ paddingTop: "0.1875rem", paddingBottom: "0.1875rem" }}>

   <div style={{ background: "white", padding: "2rem" }}>
      <div style={{ fontWeight: "bold", fontSize: "1rem" }}>Add your personal bookmarks and notes</div>
      <div style={{ fontSize: "0.8rem", paddingBottom: "1rem" }}>Click on the bookmark icon to bookmark a document</div>
      <div><img src={rebrandedbookmark} alt="" style={{width:"132px"}} /></div>
      <div style={{ fontSize: "0.8rem", paddingBottom: "1rem", paddingTop: "1rem" }}>Click on text and choose either Add bookmark or Add note</div>
      <div><img src={tutorial2} alt="" /></div>
   </div>
</div>

