
import * as React from 'react';
import { ErrorMessage } from './ErrorMessage'
import { Link } from "react-router-dom";
import { DocumentCardElement } from '../Documents/DocumentCard';
import { editionyyyyMMtoStringShort } from '../../utils/documentHelpers';
export const ErrorMessageReplaced = (props: { documentCardElement: DocumentCardElement, replacedByDocument: DocumentCardElement| null }) => {
    let withdrawnDate: string | null = props.documentCardElement.document && props.documentCardElement.document.withdrawnDate ? editionyyyyMMtoStringShort(props.documentCardElement.document.withdrawnDate) : null;
    return <>
       
        <ErrorMessage
            icon={<></>}
            message={<>

                <div style={{ fontSize: "1.5rem"}}>Replaced document</div>
                <div style={{ marginTop: "1.875rem" }}>This document was replaced by&nbsp;
                {props.replacedByDocument ?<Link
       
        to={"/document/" + props.replacedByDocument.document.configId + "/" + props.replacedByDocument.document.revision} title={props.replacedByDocument.document.title}>           
           {props.documentCardElement.document.replacedBy} 
                </Link> :props.documentCardElement.document.replacedBy } in {withdrawnDate}.<br/><br/>



                Previous editions are still available as PDF.</div>
            </>}
        />
    </>
}
