import styles from "../ContentManager/ContentManager.module.scss";
import { Menu } from "../Sidebar/Menu";

export function MyDashboard() {
    return (
        <Menu layout="Dashboard">
             <div className={styles.documentList}>
                
            </div>
        </Menu>
      
    );
}