import { Reducer } from "redux";
import { UserNoteActions } from "../actions/userNotesActions";
import * as service from '../../api/SmartLibraryService';
interface IUserNotesContext {
    notes: service.IBookmarkDto[] | null;
    lastSeenIndicators: service.LastRevisionForUserIndicatorDto[] | null;
    isFetching: boolean;
    error: string | null;
    editNote: string | null;
    highlightBookmark: string | null;
    requestDeleteBookmarkId: string | null;
    lastAutosave: {bookmarkId:string, when:number}|null
}
function updateObjectInArray(array:service.IBookmarkDto[], action:{type:"AUTO_UPDATE_USER_NOTE_COMPLETE", payload:{bookmark:service.IBookmarkDto}}) {
    return array.map((item, index) => {
      if (item.bookmarkId !== action.payload.bookmark.bookmarkId) {
        // This isn't the item we care about - keep it as-is
        return item
      }
  
      // Otherwise, this is the one we want - return an updated value
      return {
        ...item,
        ...action.payload.bookmark
      }
    })
  }
export const userNotesReducer: Reducer<IUserNotesContext, UserNoteActions>
    = (state = { notes: null, isFetching: false, error: null, editNote: null, highlightBookmark: null, requestDeleteBookmarkId: null, lastSeenIndicators: null, lastAutosave: null }, action) => {

        switch (action.type) {
            case "LOAD_ALL_USER_NOTES": {
                return { ...state, isFetching: true };
            }
            case "LOAD_ALL_USER_NOTES_COMPLETE": {
                return {
                    ...state, isFetching: false, error: null,
                    notes: action.payload.bookmarks ? action.payload.bookmarks : null,
                    lastSeenIndicators: action.payload.lastRevisionForUserIndicators ? action.payload.lastRevisionForUserIndicators : null
                }
            }
            case "LOAD_ALL_USER_NOTES_FAILED": {
                return { ...state, isFetching: false, error: action.payload.error, notes: null }
            }
            case "UPDATE_USER_NOTE_COMPLETE": {
                return { ...state, editNote: "", highlightBookmark: action.payload.bookmark.bookmarkId }
            }
            case "SAVE_USER_NOTE_COMPLETE": {
                return { ...state, highlightBookmark: action.payload.bookmark.bookmarkId }
            }
            case "EDIT_NOTE": {
                return { ...state, editNote: action.payload.bookmarkId, highlightBookmark: action.payload.bookmarkId }
            }
            case "REQUEST_DELETE_USER_NOTE": {
                return { ...state, requestDeleteBookmarkId: action.payload.bookmarkId }
            }
            case "HIGHLIGHT_BOOKMARK": {
                return { ...state, highlightBookmark: action.payload.bookmarkId }
            }
            case "AUTO_UPDATE_USER_NOTE_COMPLETE": {
                if (state.notes) {                   
                    return { ...state, notes: updateObjectInArray(state.notes,action), lastAutosave:{bookmarkId:action.payload.bookmark.bookmarkId,when:action.payload.when}}
                }
                return state;
            }
            default:
                break;
        }

        return state;
    };