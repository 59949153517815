import * as React from 'react';
import { provide } from '../../store/provide';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { IDocumentRouteProps } from '../../utils/routeProps/DocumentRouteProps';
import { LogoIcon } from '../../Components/Icons/LogoIcon';
import { NewVersion } from './UpdateButton'
import { Azure } from '../../oidc/utils/AzureAD';
import { ToastContainer, Slide } from 'react-toastify';
import { resetActions } from '../../store/actions/resetActions';
import { StyledMenuShadowed, StyledMenuAction } from '../Styled/ContextMenu/ContextMenu'
import { EnvelopeIcon } from '../../Components/Icons/EnvelopeIcon';
import { PdfIcon } from '../../Components/Icons/PdfIcon';
import styled from 'styled-components/macro';
import { globalsearchActions } from "../../store/actions/globalsearchActions";
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import { faBell, faGear, faQuestionCircle, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';

import './Header.css';
import { MenuHeader } from './MenuHeader';

const StyledStickyHeaderDiv = styled.div`   
    position:sticky;
    top: 0px;    
    z-index: 10;
    border-bottom:${({ theme }) => theme.borderWidth.thinnest} solid ${({ theme }) => theme.colors.border.neutral10};
    background: ${({ theme }) => theme.colors.background.white};
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding-top:${({ theme }) => theme.padding.large};
    // padding-bottom:${({ theme }) => theme.padding.large};
`;

const StyledTitleDiv = styled.div`
    font-size:${({ theme }) => theme.fonts.sizes.applicationtitle};
    font-family: ${({ theme }) => theme.fonts.title};
    color:${({ theme }) => theme.colors.text.darkblue};
    font-weight: ${({ theme }) => theme.fonts.weights.slightlybold};
    text-decoration: none solid ${({ theme }) => theme.colors.text.darkblue};
    letter-spacing: 0.0625rem;  
    display: flex;
    cursor: pointer;
    margin-right:15px;
    white-space: nowrap;
    padding-top: ${({ theme }) => theme.padding.small};
`;

const StyledDividerDiv = styled(StyledTitleDiv)`
    color:${({ theme }) => theme.colors.text.neutral20};
    cursor: default;
`;

const StyledA = styled.a`
   display:flex;
   align-items:center;
   justify-content:space-around;
   color: ${({ theme }) => theme.colors.text.neutral80};
    &:hover{
      text-decoration:none;
    }
`;

const StyledPdfIcon = styled(PdfIcon)`
    height: 16px;
    fill:  ${({ theme }) => theme.colors.icon.neutral80};
    margin-right: ${({ theme }) => theme.margin.medium};
    cursor:pointer;
`;
const SyledEnvelopeIcon = styled(EnvelopeIcon)`
   margin-right: ${({ theme }) => theme.margin.medium};
   
`;

type HeaderComponentState = {
  isMenuOpen: boolean;
  isScrolling:boolean;
 
}

class HeaderComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>, HeaderComponentState> {
  timeout=0;
  state = { isMenuOpen: false, isScrolling:false};
  closeHelpMenu = () => {
    this.setState(({ ...this.state, isMenuOpen: false }));
  }
  openHelpMenu = () => {
    this.setState(x => ({ ...this.state, isMenuOpen: !x.isMenuOpen }));
  }


  toggleSearchButton = () => {
    if(this.props.globalSearch.compVisible === false){
      this.props.showGlobalSearch(!this.props.globalSearch.compVisible)
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }
  
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }
  
  onScroll =()=>{
    if(window.pageYOffset>50){
      this.setState({isScrolling:true});
    }
    else if(window.pageYOffset === 0){
      this.setState({isScrolling:false});
      if(this.props.globalSearch.compVisible === true){
        this.props.showGlobalSearch(!this.props.globalSearch.compVisible)
      }
    }
    
  };

    
  render() {
    return (
     
      <div className='dnv-rs-header'>
        <Link data-cy="topleftlogo" to="/" style={{ padding: "16px", height: "40px" }}><LogoIcon /></Link>
        {/* <NewVersion /> */}
        <div className='dnv-rs-header-topmenu'>
          <MenuHeader/>
        </div>
        <div className='headerApplicationName'>Rules and Standards Explorer</div>
        <div className='dnv-rs-header-usermenu'>
          <div className='dnv-rs-usermenu'>

            {this.state.isScrolling ? (<div style={{ cursor: "pointer" }} className="iconHover" title="Search"><FontAwesomeIcon icon={faSearch} size="lg" onClick={this.toggleSearchButton} /></div>) : ('')}

            <div className="iconHover" title="Subscribe to notifications">
              <a href="https://services.veracity.com/Notifications" title="Subscribe to notifications" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faBell} size="lg" />
              </a>
            </div>

            <div style={{ cursor: "pointer" }} className="iconHover" title="Help">
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" onClick={this.openHelpMenu} />
            </div>

            <div style={{ cursor: "pointer" }} className="iconHover" title="Offline packages">
              <Link to="/offline-packages" title="Offline packages">
                <FontAwesomeIcon icon={faDownload} size="lg" />
              </Link>
            </div>

            {this.state.isMenuOpen && <StyledMenuShadowed onMouseLeave={this.closeHelpMenu} style={{ top: "50px", right: "45px" }}>
              <StyledMenuAction style={{paddingLeft:"14px"}}><StyledA href={process.env.PUBLIC_URL + "/helpFile"} target="_blank"><StyledPdfIcon />User guide - publications</StyledA></StyledMenuAction>
              <StyledMenuAction style={{paddingLeft:"14px"}}><StyledA style={{paddingRight:"2rem"}} href={process.env.PUBLIC_URL + "/helpFileHearing"} target="_blank"><StyledPdfIcon />User guide - hearing</StyledA></StyledMenuAction>

              <StyledMenuAction style={{paddingLeft:"14px"}}><StyledA style={{paddingRight:"6.5rem"}} href="https://store.veracity.com/contact-provider/rules-and-standards-explorer" target="_blank"><SyledEnvelopeIcon />Contact us</StyledA></StyledMenuAction>
            </StyledMenuShadowed>}
            <Azure />
            <ToastContainer hideProgressBar={true} transition={Slide} />
          </div>
        </div>

      </div>
  
      // <StyledStickyHeaderDiv>
       
      //   <Link data-cy="topleftlogo" to="/" style={{ paddingLeft: "16px", height: "40px" }}><LogoIcon /></Link>
      //   <NewVersion />
      //   <MenuHeader />
      //   <StyledTitleDiv onClick={this.props.resetState} style={{ paddingRight:"8px" }}>Rules and Standards Explorer</StyledTitleDiv>
      //   <StyledDividerDiv>|</StyledDividerDiv>
        
      //   { this.state.isScrolling ? (<div style={{ cursor: "pointer" }} className="iconHover" title="Search"><FontAwesomeIcon icon={faSearch} size="lg" onClick={this.toggleSearchButton} /></div>) : ('') }       

      //   <div className="iconHover" title="Subscribe to notifications">
      //   <a href="https://services.veracity.com/Notifications" title="Subscribe to notifications" target="_blank" rel="noopener noreferrer">
      //     <FontAwesomeIcon icon={faBell} size="lg" />
      //   </a>
      //   </div>

      //   <div style={{ cursor: "pointer" }} className="iconHover" title="Help">
      //     <FontAwesomeIcon icon={faQuestionCircle} size="lg" onClick={this.openHelpMenu} />          
      //   </div>

      //   <div style={{ cursor: "pointer" }} className="iconHover" title="Offline packages">
      //     <Link to="/offline-packages" title="Offline packages">
      //       <FontAwesomeIcon icon={faDownload} size="lg"  />
      //     </Link>
      //   </div>

      //   {/* <div style={{ marginRight: "10px" }} className="iconHover" title="Settings">
      //     <Link to="/settings/features">
      //       <FontAwesomeIcon icon={faGear} size="lg" />
      //     </Link>
      //   </div> */}
      //   <div  style={{ marginRight: "10px" }} ></div>

      //   {this.state.isMenuOpen && <StyledMenuShadowed onMouseLeave={this.closeHelpMenu} style={{ top: "50px", right: "45px" }}>
      //     <StyledMenuAction><StyledA href={process.env.PUBLIC_URL + "/helpFile"} target="_blank"><StyledPdfIcon />User guide</StyledA></StyledMenuAction>
      //           <StyledMenuAction><StyledA href="https://store.veracity.com/contact-provider/rules-and-standards-explorer" target="_blank"><SyledEnvelopeIcon />Contact us</StyledA></StyledMenuAction>
      //   </StyledMenuShadowed>}

      //   <Azure />
      //   <ToastContainer hideProgressBar={true} transition={Slide} />
      // </StyledStickyHeaderDiv>);
  )}
}
const provider = provide((state) => ({
  globalSearch: state.globalsearch
}), { ...resetActions,...globalsearchActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const Header = provider.connect(withRouter(HeaderComponent));


