import { useSelector } from 'react-redux';
import { IRootState } from '../../../../store/store';
import styled from 'styled-components/macro';
import eplus from '../../../../images/e-pluss.svg';

interface SubscriptionHeaderProps {
    days: number;
}

const StyledSubscriptionHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  justify-items: stretch;
  justify-content: stretch;
  font-size:${({ theme }) => theme.fonts.sizes.medium}; 
  height: "100%";
  background-color: #E5F5FB;
  margin-top: 1rem;
  border-radius: 10px;
`

const StyledSubscriptionHeaderGridLeftDiv = styled.div`
  padding-top: 1.4rem;
  text-align: center;
`

const StyledSubscriptionHeaderGridRightDiv = styled.div`
  margin: 0.5rem 0;
`

export function SubscriptionHeader() {
    const userSettings = useSelector((state: IRootState) => { return state.userSettings.settings});
   
    if (userSettings?.internal || userSettings?.subscription)
    {
        return null;
    }

    if (userSettings?.trial)
    {
        const endDate = new Date(userSettings?.trialEndDateTime);                    
        const todaysDate = new Date();
        var date = endDate.getDate();
        var month = endDate.getMonth() + 1;
        var year = endDate.getFullYear();

        var Tdate = todaysDate.getDate();
        var Tmonth = todaysDate.getMonth() + 1;
        var Tyear = todaysDate.getFullYear();

        const formatedEndDate = new Date(year + '/' + month + '/' + date);
        const formatedTodayDate = new Date(Tyear + '/' + Tmonth + '/' + Tdate);
        const oneDay = 1000 * 60 * 60 * 24;                
        // const daysLeft = Math.round((endDate.getTime() - todaysDate.getTime()) / oneDay); 
        const daysLeft = Math.round(Number(formatedEndDate) - Number(formatedTodayDate)) / oneDay;

        if (formatedEndDate >= formatedTodayDate) {
            return <SubscriptionHeaderTrial days={daysLeft} />
        }

        if (daysLeft >= -5) {
            return <SubscriptionHeaderTrialEnd />
        }

        return <SubscriptionHeaderTrial days={-1} />
    }
    
    return <SubscriptionHeaderExternal />;
}

function SubscriptionHeaderExternal() {
    return (
        <StyledSubscriptionHeaderDiv>
            <StyledSubscriptionHeaderGridLeftDiv>
                <img src={eplus} width="44px" alt="eplus" />
            </StyledSubscriptionHeaderGridLeftDiv>
            <StyledSubscriptionHeaderGridRightDiv>
            <div style={{ fontWeight: "bold" }}>Rules and Standards Explorer+ subscription</div>
                <div>To read more, you need a free trial or an active Rules and Standards Explorer+ subscription.</div>
                <div><a href="#SubscriptionInfo" style={{ textDecoration: "underline" }}>14 day free trial and subscription information</a></div>
            </StyledSubscriptionHeaderGridRightDiv>
        </StyledSubscriptionHeaderDiv>
    );
}

// Need to read how many day left
function SubscriptionHeaderTrial(props: SubscriptionHeaderProps) {
    if (props.days > 0) {
        return (
            <StyledSubscriptionHeaderDiv>
                <StyledSubscriptionHeaderGridLeftDiv>
                    <img src={eplus} width="44px" alt="eplus" />
                </StyledSubscriptionHeaderGridLeftDiv>
                <StyledSubscriptionHeaderGridRightDiv>
                <div style={{ fontWeight: "bold" }}>Your Rules and Standards Explorer+ trial expires in {props.days > 1 ? props.days + " days " : props.days + " day "}</div>
                    <div>To download PDFs and to avoid losing access after your 14 day free trial, your organization needs a subscription.</div>
                    <div><a href="#SubscriptionInfo" style={{ textDecoration: "underline" }}>Subscription information</a></div>
                </StyledSubscriptionHeaderGridRightDiv>
            </StyledSubscriptionHeaderDiv>
        );
    }

    else if(props.days == 0){
        return (
            <StyledSubscriptionHeaderDiv>
                <StyledSubscriptionHeaderGridLeftDiv>
                    <img src={eplus} width="44px" alt="eplus" />
                </StyledSubscriptionHeaderGridLeftDiv>
                <StyledSubscriptionHeaderGridRightDiv>
                <div style={{ fontWeight: "bold" }}>Your Rules and Standards Explorer+ trial expires today</div>
                    <div>To download PDFs and to avoid losing access after your 14 day free trial, your organization needs a subscription.</div>
                    <div><a href="#SubscriptionInfo" style={{ textDecoration: "underline" }}>Subscription information</a></div>
                </StyledSubscriptionHeaderGridRightDiv>
            </StyledSubscriptionHeaderDiv>
        );
    }

    return (
               <StyledSubscriptionHeaderDiv>
            <StyledSubscriptionHeaderGridLeftDiv>
                <img src={eplus} width="44px" alt="eplus" />
            </StyledSubscriptionHeaderGridLeftDiv>
            <StyledSubscriptionHeaderGridRightDiv>
            <div style={{ fontWeight: "bold" }}>Rules and Standards Explorer+ subscription</div>
                <div>You have previously had a free trial for Rules and Standards Explorer+. To read more, you need an active Rules and Standards Explorer+ subscription.</div>
                <div><a href="#SubscriptionInfo" style={{ textDecoration: "underline" }}>Subscription information</a></div>
            </StyledSubscriptionHeaderGridRightDiv>
        </StyledSubscriptionHeaderDiv>
    );
}

function SubscriptionHeaderTrialEnd() {
    return (
        <StyledSubscriptionHeaderDiv style={{ backgroundColor: "#FFF5CB" }}>
            <StyledSubscriptionHeaderGridLeftDiv>
                <img src={eplus} width="44px" alt="eplus" />
            </StyledSubscriptionHeaderGridLeftDiv>
            <StyledSubscriptionHeaderGridRightDiv>
            <div style={{ fontWeight: "bold" }}>Your Rules and Standards Explorer+ trial has ended</div>
                <div>Your 14 day free trial of Rules and Standards Explorer+ has ended. You will need a subscription to maintain your access to the complete document.</div>
                <div><a href="#SubscriptionInfo" style={{ textDecoration: "underline" }}>Subscription information</a></div>
            </StyledSubscriptionHeaderGridRightDiv>
        </StyledSubscriptionHeaderDiv>
    );
}