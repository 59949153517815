import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DialogBoxIcon } from '../../Icons/DialogBoxIcon';
import { provide } from "../../../store/provide";
import { selectedTextActions } from '../../../store/actions/selectedTextActions'
import { userNotesActions } from '../../../store/actions/userNotesActions'
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { BookmarkIcon } from '../../Icons/Bookmark';
import { NoteIcon } from '../../Icons/NoteIcon';
import * as service from '../../../api/SmartLibraryService';
import { uuidv4 } from '../../../utils/comments'
import { ShareAltOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import { DocumentCardElement } from '../../Documents/DocumentCard';
import { editionyyyyMMtoStringShort } from '../../../utils/documentHelpers';
import ReactTooltip from 'react-tooltip';

type ContextMenuState = {
  
    currentNote: service.IBookmarkDto| null ,
    isModalVisible : boolean;
    copySuccess: string;
}

class ContextMenuComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>,ContextMenuState>{
    state: ContextMenuState = { currentNote:null, isModalVisible:false, copySuccess:""}
    
   
    componentDidMount = () => {
        this.highlightIfCurrentNoteExists();
    }
    componentDidUpdate = ()=>{
        this.highlightIfCurrentNoteExists();
    }

    highlightIfCurrentNoteExists = ()=>{
        let currentNotes = this.props.userNotes.notes && this.props.userNotes.notes.filter(x => x.configId === this.props.match.params.configId && x.revision === +this.props.match.params.revision
            && x.bookmarkedId  && x.bookmarkedId === this.props.selectedText.bookmarkedId);
        const note : service.IBookmarkDto| null =   currentNotes && currentNotes.length > 0 ? currentNotes[0] : null;
        if (note) {
            if(!this.state.currentNote || (this.state.currentNote && note.bookmarkId !== this.state.currentNote.bookmarkId)){
                this.setState(x=>({...x,currentNote:note}));
            }
            if(this.props.userNotes.highlightBookmark !== note.bookmarkId){
                this.props.highlightBookmark(note.bookmarkId);
                // if (note.customText) {
                //     this.props.editBookmark(note.bookmarkId)
                // }
            }
            this.props.textSelected("", "", 0, "", "", 0, 0);
        }else{
            if(this.state.currentNote){
                this.setState(x=>({...x,currentNote:null}));
            }
        }
    }
    saveComments = () => {

        const bookMark = new service.BookmarkDto({
            bookmarkId: uuidv4(),
            upn: "",
            configId: this.props.match.params.configId,
            revision: +this.props.match.params.revision,
            bookmarkedIdFullPath: this.props.selectedText.bookmarkedIdFullPath,
            bookmarkedId: this.props.selectedText.bookmarkedId,
            customText: "",
            globalOrder: 0,
            fullReference: "",
            renderedTitleText: "",
            fullReferenceWithoutDocument: "",
            htmlOrder: 0,
            lastValidInRevision:+this.props.match.params.revision
        })
        this.props.saveUserNote(bookMark,true);
        this.props.highlightBookmark(bookMark.bookmarkId)
        this.props.editBookmark(bookMark.bookmarkId)
        this.props.textSelected("", "", 0, "", "", 0, 0);


    }
    saveBookmark = () => {

        const bookMark = new service.BookmarkDto({
            bookmarkId: uuidv4(),
            upn: "",
            configId: this.props.match.params.configId,
            revision: +this.props.match.params.revision,
            bookmarkedIdFullPath: this.props.selectedText.bookmarkedIdFullPath,
            bookmarkedId: this.props.selectedText.bookmarkedId,
            customText: "",
            globalOrder: 0,
            fullReference: "",
            renderedTitleText: "",
            fullReferenceWithoutDocument: "",
            htmlOrder: 0,
            lastValidInRevision:+this.props.match.params.revision
        })
        this.props.saveUserNote(bookMark,false);
        this.props.textSelected("", "", 0, "", "", 0, 0);
    }

    render() {

        return (!this.state.currentNote && <><DialogBoxIcon style={{ position: "absolute", top: this.props.selectedText.coordY - 58, left: (this.props.selectedText.coordX - 48) }} />
        <BookmarkIcon title="Add bookmark"  data-cy="contextaddbokmark" onClick={this.saveBookmark} style={{
            position: "absolute",fill:"white", top: this.props.selectedText.coordY - 45, left: (this.props.selectedText.coordX - 30), height: "22px", width: "18px", strokeWidth: "40", stroke: "white"
            
        }} />
        <NoteIcon title="Add note" data-cy="contextaddnote" onClick={this.saveComments} style={{ position: "absolute", top: this.props.selectedText.coordY - 46, left: (this.props.selectedText.coordX + 10), height: "25px", width: "20px", stroke:"white", fill: "white" }} />
    </>)
    }
}

const provider = provide((state) => ({
    selectedText: state.selectedText,
    userNotes: state.userNotes,
    userNotesHighlighted: state.userNotes.highlightBookmark,

}), { ...userNotesActions, ...selectedTextActions }).withExternalProps<{documentCard: DocumentCardElement}>();


type Props = typeof provider.allProps;
export const ContextMenu = provider.connect(withRouter(ContextMenuComponent));

