import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";

interface ISetSearchBoxText { type: "SET_SEARCHBOXTEXT"; payload: { searchBoxText: string; }; }

export type SearchBoxActions = ISetSearchBoxText;
type TA = ThunkAction<void, IRootState, void, SearchBoxActions>;

export const searchBoxActions = {
    onChangeSearchBox: (searchBoxText: string): TA => (dispatch) => {
        dispatch({
            type: "SET_SEARCHBOXTEXT",
            payload: { searchBoxText }
        });
    }
}