import React from 'react';
import * as service from '../../api/SmartLibraryService';
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import TagManager from 'react-gtm-module'
import { provide } from "../../store/provide";
import { ESubIcon } from '../Icons/ESubIcon';
import { editionyyyyMMtoStringShort } from '../../utils/documentHelpers';
import { IDocumentRouteProps } from '../../utils/routeProps/DocumentRouteProps';
import { documentActions } from '../../store/actions/documentActions';
import { ridgeborderleft, ridgeborderright, ridgeborderbottom } from '../StyledComponents/CustomStyles';
import ReactTooltip from 'react-tooltip';
import { filterActions } from '../../store/actions/filterActions';

import styled from 'styled-components/macro';

export interface DocumentCardElement {
    document: service.IDocument,
    category: service.ICategory,
    subCategory: service.ICategory | null,
    searchScore: number,
    htmlHit: service.IHit | null,
    titleHit: service.IHit | null,
    documentCodeHit: service.IHit | null,
}

export type RowCounter = {
    rowcount: number;
}

const StyledDocumentCodeDiv = styled.div<{ isOpen: boolean }>`
    padding-left:1.5rem;
    grid-column: 1;   
    color: ${({ theme }) => theme.colors.text.neutral80}; 
    display: flex;
    white-space: nowrap;
    align-items: center;
    ${({ theme, isOpen }) => isOpen ? ridgeborderleft(theme) : ''};
    ${({ theme, isOpen }) => isOpen ? 'background-color: ' + theme.colors.background.skyebluelightest+ '7F' : ''};  
`
const StyledDocumentDescriptionContainerDiv = styled.div<{ isOpen: boolean }>`
    grid-column: 2;
    padding-left:1.5rem;
    ${({ theme, isOpen }) => isOpen ? 'background-color: ' + theme.colors.background.skyebluelightest + '7F': ''};  
`

const StyledDocumentCategoryDiv = styled.div`
    margin-top: 0.3125rem;
    color: ${({ theme }) => theme.colors.text.neutral80};   
    font-size:${({ theme }) => theme.fonts.sizes.medium}; 
    letter-spacing: 0.0625em;    
`

const StyledDocumentSubCategoryDiv = styled.div`
    font-size:${({ theme }) => theme.fonts.sizes.medium}; 
    color: ${({ theme }) => theme.colors.text.darkblue};   
    letter-spacing: 0.0625em;
`

const StyledTitleLink = styled(Link)`
    margin-bottom: 0.3125rem;
    font-family:${({ theme }) => theme.fonts.AvenirNextMedium};
    font-size:${({ theme }) => theme.fonts.sizes.applicationtitle}; 
   
`

const StyledExplorerPlusIconContainer = styled.div<{  isOpen: boolean }>`
    grid-column: 4;    
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    ${({ theme, isOpen }) => isOpen ? 'background-color: ' + theme.colors.background.skyebluelightest + '7F' : ''};
`

const StyledEditionDiv = styled.div<{ isOpen: boolean}>` 
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    grid-column: 3; 
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size:${({ theme }) => theme.fonts.sizes.medium}; 
    color: ${({ theme }) => theme.colors.text.neutral80};   
    ${({ theme, isOpen }) => isOpen ? ridgeborderright(theme) : ''};
    ${({ theme, isOpen }) => isOpen ? 'background-color: ' + theme.colors.background.skyebluelightest + '7F' : ''};   
`

const StyledEntireRowOverlayClickDiv = styled.div<{ isOpen: boolean }>`
cursor: pointer; 
z-index: 3;
grid-column-start: 1;
grid-column-end: 5;
${({ theme }) => ridgeborderbottom(theme)};
`

const StyledEntireRowUnderlayClickDiv = styled.div<{ isOpen: boolean }>`
    width: 100%;
    height: 100%;
    z-index: -1;
    grid-column-start: 1;
    grid-column-end: 5;
   
`

const StyledHtmlHitsContainerDiv = styled.div`
grid-column-start: 1;
grid-column-end: 5;
padding: 1.5rem;
${({ theme}) => ridgeborderleft(theme)};
${({ theme}) => ridgeborderright(theme)};
`

const StyledHtmlHitsDiv = styled.div`
font-size: ${({ theme }) => theme.fonts.sizes.small};
text-decoration: none solid ${({ theme }) => theme.colors.text.darkblue};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`

class DocumentCardComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{
    track = () => {
        if (this.props.isSearch) {
            TagManager.dataLayer({
                dataLayer: {
                    'event': 'GAEvent',
                    'eventCategory': 'SearchResultClick',
                    'eventAction': this.props.searchResult.query,
                    'eventLabel': 'SearchResultIndex',
                    'eventValue': this.props.index
                }
            });
        }
    }
    gotoDocument = () => {
        this.track();
        this.props.documentClearErrors();
        window.scrollTo(0, 0);
        this.props.history.push("document/" + this.props.documentCard.document.configId + "/" + this.props.documentCard.document.revision)
        this.props.setCategoryFilter(this.props.documentCard.category.code||null, this.props.documentCard.subCategory && this.props.documentCard.subCategory.code ? this.props.documentCard.subCategory.code : null)
    }
    render() {
        let editionDate: string | null = this.props.documentCard.document.edition ? editionyyyyMMtoStringShort(this.props.documentCard.document.edition) : null;
        let amendedDate: string | null = this.props.documentCard.document.amended ? editionyyyyMMtoStringShort(this.props.documentCard.document.amended) : null;
        const isOpen = this.props.documentCard.htmlHit && this.props.documentCard.htmlHit.highlight ? true : false;
        this.props.rowCounter.rowcount++;
        const content =
            <React.Fragment key={this.props.documentCard.document.configId}>
                <StyledDocumentCodeDiv style={{gridRow:this.props.rowCounter.rowcount}}  isOpen={isOpen} data-cy="documentcode">{this.props.documentCard.document.documentReference}</StyledDocumentCodeDiv>
                <StyledDocumentDescriptionContainerDiv style={{gridRow:this.props.rowCounter.rowcount}} isOpen={isOpen} >
                    <StyledDocumentCategoryDiv>{this.props.documentCard.category.description} ({this.props.documentCard.category.code})</StyledDocumentCategoryDiv>
                    {this.props.documentCard.subCategory && <StyledDocumentSubCategoryDiv>{this.props.documentCard.subCategory.code}: {this.props.documentCard.subCategory.description}</StyledDocumentSubCategoryDiv>}
                    <StyledTitleLink to={"/document/" + this.props.documentCard.document.configId + "/" + this.props.documentCard.document.revision}>{this.props.documentCard.document.chapter && "Ch " + this.props.documentCard.document.chapter + ": "}{this.props.documentCard.document.title}</StyledTitleLink>
                </StyledDocumentDescriptionContainerDiv>                
                <StyledEditionDiv isOpen={isOpen} style={{gridRow:this.props.rowCounter.rowcount}}  title={"Edition: " + editionDate + ". Amended: " + (amendedDate ? amendedDate : "none")}>{editionDate} {amendedDate && <><br />({amendedDate})</>}</StyledEditionDiv>
                <StyledExplorerPlusIconContainer style={{gridRow:this.props.rowCounter.rowcount}}  isOpen={isOpen}> {this.props.documentCard.document.requiredVeracitySubscriptions?.length !== undefined ? this.props.documentCard.document.requiredVeracitySubscriptions?.length > 0 ? <><ESubIcon data-tip="Rules and Standards Explorer+ document" style={{ zIndex: 4, cursor: "pointer" }} onClick={this.gotoDocument} title="" /><ReactTooltip type="light" border place="left" /></> : "" : ""}</StyledExplorerPlusIconContainer>
                <StyledEntireRowOverlayClickDiv className="hoverRow" isOpen={isOpen} style={{gridRowStart:this.props.rowCounter.rowcount,gridRowEnd:this.props.rowCounter.rowcount + (isOpen ? 2 : 1) }} onClick={this.gotoDocument} />
                <StyledEntireRowUnderlayClickDiv className="hoverRowUnder" isOpen={isOpen}  style={{gridRowStart:this.props.rowCounter.rowcount,gridRowEnd:this.props.rowCounter.rowcount + (isOpen ? 2 : 1) }} ></StyledEntireRowUnderlayClickDiv>
                {this.props.documentCard && this.props.documentCard.htmlHit && this.props.documentCard.htmlHit.highlight && this.props.documentCard.htmlHit.highlight.length > 0 &&
                    <StyledHtmlHitsContainerDiv  style={{gridRow:this.props.rowCounter.rowcount + 1}}>                     
                            {this.props.documentCard.htmlHit.highlight.map((x, index) => <StyledHtmlHitsDiv key={(this.props.documentCard.document.configId || '') + index}  dangerouslySetInnerHTML={{ __html: x + "..." }} />)}                     
                    </StyledHtmlHitsContainerDiv>}
            </React.Fragment>
        if (isOpen)
            this.props.rowCounter.rowcount++;
        return content;
    }
}
const provider = provide((state) => ({
    searchResult: state.searchResult,
    filterState: state.filterState,
    documentListState: state.documentList,
}), { ...documentActions,...filterActions }).withExternalProps<{ documentCard: DocumentCardElement, isSearch: boolean, index: number, rowCounter: RowCounter }>();

type Props = typeof provider.allProps;
export const DocumentCard = provider.connect(withRouter(DocumentCardComponent));
