import { Menu } from 'antd';
import './Menuheader.css'
import { useHistory } from 'react-router-dom';

const topMenuItems = [
    {label: 'Publications', key: 'explorer'},
    {label: 'Documents on hearing', key: 'hearing'}
];

export const MenuHeader = () => {
    const history = useHistory();
    var application = (window as any).application;
    const onClick = (e:any) => {
        if (e.key === 'explorer')
        {
            history.push({pathname:"/"})
        }
        if (e.key === "hearing")
        {
            window.location.href = application.hearingUrl !== "#{Hearing:Url}" ? application.hearingUrl : "https://standards-staging.dnv.com/hearing/";
            //window.location.href = "https://standards-staging.dnv.com/hearing/";
        }
    };

    return <Menu style={{marginRight: "auto",paddingTop:"10px"}} items={topMenuItems} selectedKeys={["explorer"]} mode="horizontal" onClick={onClick} />
}