import * as React from 'react';
import { provide } from '../../../store/provide';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import * as service from '../../../api/SmartLibraryService';
import { SelectedIndicator } from '../../Icons/SelectedIndicator';
import { DotIcon } from '../../Icons/DotIcon';
import { FavouriteToggler } from '../../FavouriteToggler/FavouriteToggler';
import { documentActions } from '../../../store/actions/documentActions';
import styled from 'styled-components/macro';


const StyledDocumentRow = styled.div<{$paddingLeft:string,$isSelected:boolean }>`
 grid-column-start: 1;
    grid-column-end: 4;
    padding-left: ${({$paddingLeft})=> $paddingLeft};
    
    padding-right: 1rem;
    font-weight: ${({$isSelected})=> $isSelected ?"bold":"unset"};
    background-color: ${({theme,$isSelected})=> $isSelected ?theme.colors.background.neutral10:theme.colors.background.neutral05};
    display:flex;
    align-items:center;
`
const StyledDocumentRowLink = styled(Link)`
   display: inline-block;
   width: 100%; 
   color: ${({ theme }) => theme.colors.text.neutral80};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
`
class DocumentRowComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>> {
   
    shortenDocumentCode = (categoryCode: string, code: string) => {
        if (code.includes("CP"))
        {
            return code.replace("CP-", "");
        }

        if (code.includes("RU-SHIP-CSR"))
        {
            return code.replace("RU-SHIP-", "") + ":";
        }

        if (categoryCode.includes("RU-GEN"))
        {
            if (code.includes("RU-GEN-"))
            {
                return code.replace("RU-GEN-", "")
            }
            return code.replace("RU-", "");
        }

        if (code.includes("RU-FD"))
        {
            return code.replace("RU-", "") + ":";
        }
        
        return code.replace(categoryCode + "-", "");              
    };

    render() {
        const isSelected = this.props.location.pathname.indexOf(this.props.d.configId || 'NODOC') > 0 || false;
       return <StyledDocumentRow $paddingLeft={this.props.paddingLeft} $isSelected={isSelected} style={{ gridRow: this.props.rowCounter}}>
       <div style={{ display: "flex",alignItems:"center",  width: "0.5rem" }}>{this.props.outOfDateIndicatorsWithBookmarks && this.props.outOfDateIndicatorsWithBookmarks.find(b => b.configId === this.props.d.configId) && <DotIcon />}</div>
       <div style={{ display: "flex",alignItems:"center",justifyContent:"center",width: "1rem" }}>{this.props.notes && this.props.notes.find(note => note.configId === this.props.d.configId && !note.bookmarkedId) && <FavouriteToggler allowToogle={false} document={this.props.d} />}</div>
       <div style={{ display: "flex",alignItems:"center", width: "0.5rem" }}> {isSelected && <SelectedIndicator style={{height: "1rem" }} />}</div>
       <StyledDocumentRowLink onClick={this.props.documentClearErrors}       
        to={"/document/" + this.props.d.configId + "/" + this.props.d.revision} title={this.props.d.title}> {this.props.d.chapter ? "Ch " + this.props.d.chapter + ": " + this.props.d.title : this.shortenDocumentCode(this.props.category.code || '', this.props.d.documentCode || '') + " " + this.props.d.title}</StyledDocumentRowLink>
      
   </StyledDocumentRow>;
    }
}
const provider = provide((state) => ({   
    notes: state.userNotes.notes
}), { ...documentActions }).withExternalProps<{category: service.ICategory, d: service.IDocument, rowCounter: number, paddingLeft: string,outOfDateIndicatorsWithBookmarks: service.LastRevisionForUserIndicatorDto[] | null }>();

type Props = typeof provider.allProps;
export const DocumentRow = provider.connect(withRouter(DocumentRowComponent));