import { Reducer } from "redux";
import { MathJaxActions } from "../actions/mathJaxActions";
export interface IMathJaxStatus {
    isReady:boolean    
  }
export const mathJaxReducer: Reducer<IMathJaxStatus,MathJaxActions>
    = (state = { isReady:false}, action) => {

        switch (action.type) {
            case "MATHJAX_READY": {                             
                return { ...state,isReady:true};
            }   
            default:
                break;
        }

        return state;
    };