import { ThunkAction } from "redux-thunk";
import {getClient} from '../../utils/proxyFactory';
import * as service from '../../api/SmartLibraryService';
import { IRootState } from "../../store/store";
import { flattenDocsInCategories } from "../../utils/documentHelpers";
import { DocumentCardElement } from "./DocumentCard";

interface ILoadList { type: "LOAD_LIST"; }
interface ILoadListComplete { type: "LOAD_LIST_COMPLETE"; payload:DocumentCardElement[] }

interface ISethasmore { type: "SET_HAS_MORE"; payload:{renderarrayHasmore: boolean}}
interface IUpdateValues { type: "UPDATE_VALUES"; }
interface IUpdateList { type: "UPDATE_VALUES"; }
interface IClearList { type: "CLEAR_VALUES"; }

interface IDocumentsListSort { type: "DOCUMENTS_LIST_SORT"; column: "none" | "code" | "title", order:"Ascending"|"Descending" }


interface ILoadListFailed { type: "LOAD_LIST_FAILED"; payload:{error:string} }

export type DocumentsActions = ILoadList | ILoadListComplete | ILoadListFailed |  ISethasmore | IUpdateValues | IClearList|IDocumentsListSort;

type TA = ThunkAction<void, IRootState, void, DocumentsActions>;

export const DocumentsActions = {
    requestDocuments: (list: DocumentCardElement[]): TA => async (dispatch,getState)  => {
        dispatch({
            type: "LOAD_LIST"
        });               
        
        if (list != null) {            
            dispatch({ type: "LOAD_LIST_COMPLETE", payload: list })
          }else{
            dispatch({ type: "LOAD_LIST_FAILED", payload:{error:"No TOC returned"}})
          }    
    },

    setHasmore:(renderarrayHasmore:boolean):TA=>(dispatch)=>{
      dispatch({type:"SET_HAS_MORE", payload:{renderarrayHasmore:renderarrayHasmore}})
    },

    setUpdateValues:():TA=>(dispatch)=>{
      dispatch({type:"UPDATE_VALUES"})
    },
    clearValues :():TA=>(dispatch)=>{
      dispatch({type:"CLEAR_VALUES"})
    },
    setSorting: (column: "none" | "code" | "title", order: "Ascending"|"Descending"): TA => async (dispatch) => {
      dispatch({
        type: "DOCUMENTS_LIST_SORT", column:column,order:order
      });
    }
}