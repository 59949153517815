import React from 'react';
import SearchIcon from '../Icons/SearchIcon';
import { CrossIcon } from '../Icons/CrossIcon';
import styled from 'styled-components/macro';
import { provide } from "../../store/provide";
import { searchBoxActions } from "../../store/actions/searchBoxActions";
import { searchActions } from '../../store/actions/searchActions';
import { RouteComponentProps, withRouter } from 'react-router';
import { filterActions } from '../../store/actions/filterActions';

const StyledSearch = styled.input`
border:0rem;
border-radius:1rem;
padding: 7px;
width:100%;
padding-left:1.25rem;
outline: none;
box-shadow: 0px 4px 8px ${({theme}) => theme.colors.shadow.darkshadow};
&:hover {
  outline: none;

  box-shadow: 0px 4px 8px ${({theme}) => theme.colors.shadow.darkshadow};
}
&:focus {
  outline: none;
  border: 1px solid  ${({theme}) => theme.colors.border.cyanlight}; 
  box-shadow: 0px 4px 8px ${({theme}) => theme.colors.shadow.darkshadow};
}
`;
const StyledControls = styled.span`
    display: flex;
    align-items: center;
    top: 0.3125rem;
    right: 1.75rem;
    margin-left: -3.5rem;
    z-index: 3;
    display:inline-flex;
`;
const StyledCrossIcon = styled(CrossIcon)<{$hastext:boolean}>`
    fill:${({ theme }) => theme.colors.icon.digitalblue};
    height: 1rem;
    width: 0.625rem;   
    cursor: pointer;    
    visibility: ${({ $hastext }) => $hastext? "unset" : "hidden"};
`;

const StyledSearchIcon = styled(SearchIcon)`
    fill:${({ theme }) => theme.colors.icon.darkblue};
    cursor: pointer;
    margin-left: 0.625rem;
    height: 1.25rem;
    width: 1.25rem;
`;
class DocumentSearchComponent extends React.Component<Props & RouteComponentProps>{
  gotoStartIfNotAlreadyThere = () => {
    // if (this.props.location.pathname !== "/") {
      this.props.history.push("/documents");
    // }
  }
  search = () => {
    if (this.props.searchBoxText !="") {
      this.props.history.push("/search");
      this.props.clearCategory();
      this.props.requestSearch(this.props.searchBoxText);
      window.scroll(0,0);
    }
    
  }
  searchIfEnter = (x: React.KeyboardEvent<HTMLInputElement>) => {
    if (x.key === 'Enter') {
      this.search();
    }
  }
  crossOnClick = () => {
    if (this.props.searchBoxText) {
      this.gotoStartIfNotAlreadyThere();
      //this.props.clearFilters();
      this.props.clearSearch();
    }
  }

  /*:focus*/
  render() {
    return <><StyledSearch type="text" placeholder="Search in full-text" data-cy="globalsearch" value={this.props.searchBoxText}
      onKeyUp={this.searchIfEnter}
      onChange={(x: any) => this.props.onChangeSearchBox(x.currentTarget.value)}
    />
      <StyledControls >    
          <StyledCrossIcon onClick={this.crossOnClick} data-cy="globalsearchinlineclear" $hastext={(!!this.props.searchBoxText)}/>
          <StyledSearchIcon onClick={this.search} />     
      </StyledControls>
    </>
  }
}

const provider = provide((state) => ({
  searchBoxText: state.searchBox.searchBoxText
}), { ...searchBoxActions, ...searchActions, ...filterActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const DocumentSearch = provider.connect(withRouter(DocumentSearchComponent));
