import { keyframes } from 'styled-components/macro';



// Colors are defined from OneDesign (along with their names)
// The value as value syntax is for the intellisense to show the actual values (and not just :string) in styled components
const colorPalette = {  
  white:'white' as 'white',
  neutral02: '#F9F9F9' as  '#F9F9F9',
  neutral05: '#F2F2F2' as '#F2F2F2',
  neutral10: '#E5E5E5' as '#E5E5E5',
  neutral20: '#CCCCCC' as '#CCCCCC',
  neutral40: '#999999' as '#999999',
  neutral60: '#666666' as '#666666',
  neutral80: '#333333' as '#333333',
  skyeblueevenlighter:'#F2FAFD' as '#F2FAFD',
  skyebluelightest:'#E5F5FB' as '#E5F5FB',
  skyebluelight:'#E0F8FF' as '#E0F8FF',
  skyeblue:'#99D9F0' as '#99D9F0',
  
  cyanlight: '#33B2E1' as '#33B2E1',
  cyan:'#009FDA' as '#009FDA',
  digitalblue:'#0067C5' as '#0067C5',
  uknownblue:'#19367F' as '#19367F',
  digitalblue2: 'RGBA(0,103,197,0.5)' as 'RGBA(0,103,197,0.5)',
  darkbluedarkest:'#09122B' as '#09122B',
  darkblue:'#0F204B' as '#0F204B',
  redevenlighter:'#FFE2E3' as '#FFE2E3',
  redlight:'#CC4148' as '#CC4148',
  red:'#C4262E' as '#C4262E',
  yellowevenlighter:'RGBA(255,245,204,0.5)' as 'RGBA(255,245,204,0.5)',
  yellowlightest:'#FFECA0' as '#FFECA0',
  yellowdark:'#EDBE00' as '#EDBE00',
  orange:'#E98300' as '#E98300',
  landgreenevenlighter:'#DCF2D7' as '#DCF2D7',
  landgreenlight:'#4F9946' as '#4F9946',
  landgreen:'#36842D' as '#36842D',
  industryFilterBorder:'#ced5e6' as '#ced5e6',
  transparent: "transparent" as "transparent",
  darkshadow: "#0000004D" as " #0000004D"

};

const spacing ={
  small:'4px' as '4px',
  medium:'8px' as '8px',
  large:'16px' as '16px'
}
const border = {
  thinnest:"1px" as "1px",
  medium:"3px" as "3px",
  thick:"5px" as "5px"
}


// const screenSizes = {
//   large: 900,
//   unit: 'px',
// };
export type ThemeType  = typeof Theme;
export const Theme = {
  fonts: {
    family:  "Avenir Regular" as "Avenir Regular",
    fallbacks: ["Arial"],
    title: "DNV Display Regular" as "DNV Display Regular",
    AvenirDemi: "Avenir Demi" as "Avenir Demi",
    AvenirNextMedium: "Avenir Next Medium" as "Avenir Next Medium",
    weights: {
      //light: 300,
      regular: 400 as 400,
      slightlybold: 500 as 500,
      almostbold: 600 as 600,
      bold:700 as 700,        
    },   
    sizes: {
      applicationtitle: '1.125rem' as '1.125rem',
      small: '0.75rem' as '0.75rem',
      medium: '0.875rem' as '0.875rem',
      large: '1rem' as '1rem'
    },
  },
  colors:{
    shadow:{ darkshadow: colorPalette.darkshadow},
    background:{
      white:colorPalette.white,
      darkblue:colorPalette.darkblue,
      yellowlightest:colorPalette.yellowlightest,
      skyebluelightest:colorPalette.skyebluelightest,
      neutral02:colorPalette.neutral02,
      neutral05:colorPalette.neutral05,
      neutral10:colorPalette.neutral10,
      skyeblueevenlighter:colorPalette.skyeblueevenlighter,
      neutral80:colorPalette.neutral80,
      transparent:colorPalette.transparent,
      uknownblue:colorPalette.uknownblue
    },
    text:{
      white:colorPalette.white,
      darkblue:colorPalette.darkblue,
      neutral20:colorPalette.neutral20,
      neutral60:colorPalette.neutral60,
      neutral80:colorPalette.neutral80,
      digitalblue:colorPalette.digitalblue
     
    },
    link:{
      digitalblue:colorPalette.digitalblue
    },
    hover:{
      skyebluelightest:colorPalette.skyebluelightest
    },
    border:{
      skyeblue:colorPalette.skyeblue,
      neutral05:colorPalette.neutral05,
      neutral10:colorPalette.neutral10,
      neutral20:colorPalette.neutral20,
      yellowdark:colorPalette.yellowdark,
      darkblue:colorPalette.darkblue,
      cyan:colorPalette.cyan,
      cyanlight:colorPalette.cyanlight,
      industryFilterBorder:colorPalette.industryFilterBorder
    },
    icon:{
      darkblue:colorPalette.darkblue,
      neutral40:colorPalette.neutral40,
      neutral80:colorPalette.neutral80,
      digitalblue:colorPalette.digitalblue
    },
    toast:{
      landgreenevenlighter:colorPalette.landgreenevenlighter,
      landgreenlight: colorPalette.landgreenlight,
      yellowlightest:colorPalette.yellowlightest,
      yellowdark:colorPalette.yellowdark,
      neutral80:colorPalette.neutral80
    }
  },
 // boxShadow_unfinished: `0px 2px 11px 1px ${colorPalette.landgreen}`,
 padding:{
  small:spacing.small,  
  medium:spacing.medium,
  large:spacing.large
},    
  // spacing: {
  //   pagePadding: spacing.large,
   
  //   // lg: '35px',
  //   // md: '15px',
  //   // sm: '10px',
  // },
  margin :{
    small:spacing.small,
    medium:spacing.medium,
    large:spacing.large,
   
  },
  borderWidth:{
    thinnest:border.thinnest,
    medium:border.medium,
    thick:border.thick
  },
  // borders_unfinished: {
  //   default: `solid 2px ${colorPalette.landgreen}`,
  //   radius: '4px',
  // },
  // transition_unfinished: '400ms ease',
  // screenSizes_unfinished: screenSizes,
  // containerWidth_unfinished: `${screenSizes.large}${screenSizes.unit}`,
  // breakPoints_unfinished: {
  //   lgUp: `(min-width: ${screenSizes.large}${screenSizes.unit})`,
  //   lgDown: `(max-width: ${screenSizes.large - 1}${screenSizes.unit})`,
  // },
  animations: {
    fadeIn: keyframes`
      from {
        opacity:0;
      },
    
      to {
        opacity: 1;
      }
    `,
    duration: '1000ms',
  },
};