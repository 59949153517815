import { Reducer } from "redux";
import { VeracityActions } from "../actions/veracityActions";

import * as service from '../../api/SmartLibraryService';
export interface IVeracityPolicy {
    validationError: service.IValidationError | null;
    isFetching: boolean;     
    error:string | null;
    complete:boolean;
  }
export interface IAddUserTrial {
    userAdded: boolean;
    isAdding: boolean;
    complete: boolean;
    error: string | null;
}

export const veracityReducer: Reducer<IVeracityPolicy,VeracityActions>
    = (state = { validationError:null,isFetching:false,error:null, complete:false }, action) => {

        switch (action.type) {
            case "ENSURE_VALID_POLICIES": {     
                        
                return { ...state,complete:false, isFetching:true};
            }   
            case "ENSURE_VALID_POLICIES_COMPLETE":{
                return { ...state,complete:true, isFetching:false, validationError : action.payload.result, error:null};
            }
            case "ENSURE_VALID_POLICIES_FAILED":{
                return { ...state,complete:true, isFetching:false, validationError : null, error:action.payload.error};
            }
            default:
                break;
        }

        return state;
    };

    export const veracityTrialReducer: Reducer<IVeracityPolicy,VeracityActions>
    = (state = { validationError:null,isFetching:false,error:null, complete:false }, action) => {

        switch (action.type) {
            case "ENSURE_VALID_TRIAL_POLICIES": {     
                        
                return { ...state,complete:false, isFetching:true};
            }   
            case "ENSURE_VALID_TRIAL_POLICIES_COMPLETE":{
                return { ...state,complete:true, isFetching:false, validationError : action.payload.result, error:null};
            }
            case "ENSURE_VALID_TRIAL_POLICIES_FAILED":{
                return { ...state,complete:true, isFetching:false, validationError : null, error:action.payload.error};
            }
            default:
                break;
        }

        return state;
    };

    export const addUserToTrialReducer: Reducer<IAddUserTrial,VeracityActions>
    = (state = { userAdded:false,isAdding:false,complete:false,error:null }, action) => {

        switch (action.type) {
            case "ADD_USER_TO_TRIAL": {     
                        
                return { ...state,complete:false, isAdding:true};
            }   
            case "ADD_USER_TO_TRIAL_COMPLETE":{
                return { ...state,complete:true, isAdding:false, userAdded : action.payload.result, error:null};
            }
            case "ADD_USER_TO_TRIAL_FAILED":{
                return { ...state,complete:true, isAdding:false, userAdded:false, error:action.payload.error};
            }
            default:
                break;
        }

        return state;
    };