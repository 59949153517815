import { useParams } from "react-router-dom";
import { IAttachmentsRouteProps } from "./IAttachmentsRouteProps";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attachmentActions } from "./AttachmentActions";
import { IRootState } from "../../store/store";
import { Empty, Spin } from "antd";

export function Attachments() {
  const [isFetched, setFetching] = useState(false);
  const { documentReference, documentEdition, fileName } =
    useParams<IAttachmentsRouteProps>();
  const fetchedFile = useSelector((state: IRootState) => {
    return state.attachment;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFetched === false) {
      setFetching(true);
      dispatch(
        attachmentActions.loadAttachments(
          documentReference,
          documentEdition,
          fileName
        )
      );
    }
    if (fetchedFile.data !== null) {
      download();
    }
  }, [fetchedFile]);

  const download = () => {
    if (isFetched) {
      if (fetchedFile.data !== null) {
        var url = window.URL.createObjectURL(fetchedFile.data?.data);
        var a = document.createElement("a");
        a.href = url;
        a.download = fetchedFile.data.fileName
          ? fetchedFile.data.fileName
          : "notfound";
        a.click();
      }
    }
  };

  const text = "Attachment " + fileName + " for " + documentReference + " downloaded.";
  const fetching = "Downloading " + fileName + "...";
  const error = "Download failed, check if you have the correct url.";

  return (
    <div>
      {fetchedFile.isFetching && fetchedFile.error === null ? (
        <div style={{textAlign: "center"}}><Empty description={fetching} /><Spin size="large" style={{marginTop: "20px"}} /></div>
      ) : (
        fetchedFile.error === null ?
        <Empty description={text} /> : <Empty description={error} />
      )}
    </div>
  );
}
