import { Reducer } from "redux";
import { SideBarActions } from "../actions/sideBarActions";
import { SearchActions } from "../actions/searchActions";
interface ISidebarState {
    visible: boolean;
    tab: "HOME" | "NAV" | "MYBOOKS" | "FILTER";
    openBookmarkDocument:string
}
export const sidebarReducer: Reducer<ISidebarState, SideBarActions|SearchActions>
    = (state = { visible: true,tab:"HOME",openBookmarkDocument:"" }, action) => {

        switch (action.type) {
            case 'EXECUTE_SEARCH':
                return { ...state, visible: true,tab:"NAV" };
            case "TOGGLE_SIDEBAR": {
                return { ...state, visible: action.payload.visible };
            }
            case "TOGGLE_SIDEBAR_NAVIGATION":{
                return { ...state, tab: action.payload.tab, visible:true };
            }
            case "TOGGLE_OPENBOOKMARKDOCUMENT":{
                return { ...state, openBookmarkDocument: action.payload.configId };
            }
            default:
                break;
        }

        return state;
    };