import { toast, ToastOptions, ToastContainer } from "react-toastify";
import { CheckIcon } from "../Components/Icons/CheckIcon";
import { ExclamationTriangleIcon } from "../Components/Icons/ExclamationTriangleIcon";
import { ExclamationCircleIcon } from "../Components/Icons/ExclamationCircleIcon";
import 'react-toastify/dist/ReactToastify.css';

export function success(nodeOrMsg: string, options?: ToastOptions | undefined) {
  return toast.success(
    <div>
      <CheckIcon
        style={{
          width: "16px",
          marginRight: "1em",
          fill: "#36842d",
          fontSize: "14px",
        }}
      />
      {nodeOrMsg}
    </div>,
    options
  );
}

export function warn(nodeOrMsg: string) {
  return toast.warn(
    <div style={{ display: "flex", fontSize: "14px" }}>
      <div style={{ width: "18px", height: "15px", marginRight: "1em" }}>
        <ExclamationTriangleIcon
          style={{ width: "18px", height: "15px", marginRight: "1em" }}
        />
      </div>
      <div>{nodeOrMsg}</div>
    </div>
  );
}

export function info(nodeOrMsg: string, options?: ToastOptions | undefined)  {
  return toast.warn(
    <div style={{ display: "flex", fontSize: "14px" }}>
      <div style={{ width: "18px", height: "15px", marginRight: "1em" }}>
        <ExclamationCircleIcon
          style={{ width: "18px", height: "15px", marginRight: "1em" }}
        />
      </div>
      <div>{nodeOrMsg}</div>
    </div>,
    options
  );
}

// Close all open/active toast-messages
export const dismissAll = () => toast.dismiss();
