import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
interface IToggleSidebar { type: "TOGGLE_SIDEBAR"; payload:{visible:boolean} }
interface IToggleSidebarNavgationPopover { type: "TOGGLE_SIDEBAR_NAVIGATION"; payload:{visible:boolean,tab: "HOME" | "NAV" | "MYBOOKS" | "FILTER"} }
interface IToggleOpenBoomarkDocument { type: "TOGGLE_OPENBOOKMARKDOCUMENT"; payload:{configId: string} }

export type SideBarActions = IToggleSidebar |IToggleSidebarNavgationPopover | IToggleOpenBoomarkDocument;
type TA = ThunkAction<void, IRootState, void, SideBarActions>;

export const sideBarActions = {
    toggleSideBar: (visible:boolean): TA => async (dispatch)  => {
        dispatch(({type: "TOGGLE_SIDEBAR",payload:{visible:visible}}));        
    },
    toggleSideBarNavigation: (visible:boolean,tab: "HOME" | "NAV" | "MYBOOKS" | "FILTER"): TA => async (dispatch)  => {
        dispatch(({type: "TOGGLE_SIDEBAR_NAVIGATION",payload:{visible:visible,tab:tab }}));        
    },
    toggleOpenBookmarkDocument: (configId:string): TA => async (dispatch)  => {
        dispatch(({type: "TOGGLE_OPENBOOKMARKDOCUMENT",payload:{configId:configId }}));        
    }
    
}