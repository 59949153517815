import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../store/store";
import { RedirectContent } from "./RedirectToContent";
import { ErrorMessagePageNotFound } from "../ErrorMessage/ErrorMessagePageNotFound";
import { useEffect } from "react";
import { tocActions } from "../../store/actions/tocActions";
import { userSettingsActions } from "../../store/actions/userSettingsActions";
import { userNotesActions } from "../../store/actions/userNotesActions";
import { DocumentRender } from "../Document/DocumentRender";
import { SubscriptionList } from "../Subscription/SubscriptionList";
import { Dashboard } from "../Dashboard/Dashboard";
import { MyDashboard } from "../Dashboard/MyDashboard";
import { RenderFeature } from "../FeaturesSettings/RenderFeature";
import { DocumentListRender } from "../Documents/DocumentListRender";
import { SearchRender } from "../GlobalSearch/SearchRender";
import { OfflinePackages } from "../OfflinePackages";
import { Attachments } from "../Attachments";

export function ContentRouter() {
  const dispatch = useDispatch();
  const toc = useSelector((state: IRootState) => state.toc);
  const user = useSelector((state: IRootState) => state.user);
  const userSetting = useSelector((state: IRootState) => state.userSettings);


  useEffect(() => {
    if (user) {
      if (toc.categories === null && !toc.isFetching && toc.error === null) {
        dispatch(tocActions.requestToc());
      }
      if (userSetting.settings === null && !userSetting.isFetching && userSetting.error === null) {
        dispatch(userSettingsActions.loadUserSettings());
      }
    }
  });

  useEffect(() => {
    dispatch(userNotesActions.loadUserNotes());
  }, []);

  return (
    <Switch>
      <Route path={["/", "/null"]} exact={true}>
        <Dashboard />
      </Route>
      <Route path={"/attachments/:documentReference/:documentEdition/:fileName"}>
        <Attachments />
      </Route>
      <Route path={["/bookmarks"]} exact={true}>
        <MyDashboard />
      </Route>
      <Route path={"/document/:configId/:revision"}>
        <DocumentRender />
      </Route>
      <Route path={"/document/:configId"}>
        <RedirectContent />
      </Route>
      <Route path={"/documents/:doctype"}></Route>
      <Route path={"/documents"}>
        <DocumentListRender />
      </Route>
      <Route path={"/offline-packages"}>
        <OfflinePackages />
      </Route>
      <Route path={"/search"}>
        <SearchRender />
      </Route>
      <Route path={"/settings/features"}>
        <RenderFeature/>
      </Route>
      <Route path={"/settings"}></Route>
      <Route path={"/subscription"}>
        <SubscriptionList />
      </Route>
      <Route>
        <ErrorMessagePageNotFound />
      </Route>
    </Switch>
  );
}
