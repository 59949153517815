import { Link } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { IRootState } from '../../../../store/store';
import { userSettingsActions } from '../../../../store/actions/userSettingsActions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import epluss from '../../../../images/e-pluss.svg';
import { veracityActions } from '../../../../store/actions/veracityActions';
import { FreeTrailButton } from '../../../Subscription/FreeTrial/FreeTrialButton';

import { getUpn,isInternal } from '../../../../utils/user';
import { provide } from '../../../../store/provide';


const StyledSubscriptionInfoGridDiv = styled.div`
  display: grid;
  grid-auto-rows: auto;
  justify-items: stretch;
  justify-content: stretch;
  font-size:${({ theme }) => theme.fonts.sizes.medium};
  background-color: #E5F5FB;
  margin: 2rem 0;
  border-radius: 10px;
`

const StyledSubscriptionInfoBottomContainer = styled.div`
  display: grid;
  grid-template-columns: auto 40px auto;
  grid-template-rows: 50px auto 70px;
  grid-template-areas: 
    "titleTrial titleLine titleSub"
    "trial line sub"
    "trialB lineB subB";
  padding: 0 4.5rem;
`
// Styled components for List type (see all subscription-based documents overview page /subscription)
const StyledInternalInfobox = styled.div`  
  
`

const StyledNoSubscriptionInfobox = styled.div`

`

const StyledTitle = styled.h1`
    margin: 0;
    padding: 0;
`

const StyledNoSubscriptonActionBox = styled.div`
  display: grid;
  grid-template-columns: auto 40px auto;
  grid-template-rows: 50px auto 50px;
  grid-template-areas: 
    "titleTrial titleLine titleSub"
    "trial line sub"
    "trialB lineB subB";
  padding: 1rem 4.5rem;
  background-color: #E5F5FB;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 1rem;
`
// Type: List & Document
interface InfoBox {
    type: string;
    title:string|undefined;
}


export function SubscriptionInfobox(props: InfoBox) {
    const userSetting = useSelector((state: IRootState) => { return state.userSettings.settings });
    if (userSetting?.subscription && props.type === "List") {
        return (
            <StyledInternalInfobox>
                <StyledTitle>Rules and Standards Explorer+ subscription</StyledTitle>
                {userSetting.internal ?
                    <p>As an internal user you have full access to all subscription-based documents and complete functionality. Rules and Standards Explorer+ content is marked with the E+ symbol.</p>
                    : 
                    <p>You have an active subscription and can read complete documents or download PDFs. Rules and Standards Explorer+ content is marked with the E+ symbol.</p>
                }
            </StyledInternalInfobox>                
        );
    }

    if (!userSetting?.subscription && props.type === "List") {
        if (userSetting?.trial) {
            const endDate = new Date(userSetting.trialEndDateTime);  
            const todaysDate = new Date();      

            var date = endDate.getDate();
            var month = endDate.getMonth() + 1;
            var year = endDate.getFullYear();

            var Tdate = todaysDate.getDate();
            var Tmonth = todaysDate.getMonth() + 1;
            var Tyear = todaysDate.getFullYear();

            const formatedEndDate = new Date(year + '/' + month + '/' + date);
            const formatedTodayDate = new Date(Tyear + '/' + Tmonth + '/' + Tdate);

            if (formatedEndDate >= formatedTodayDate) {
                const oneDay = 1000 * 60 * 60 * 24;                
                //const daysLeft = Math.round((endDate.getTime() - todaysDate.getTime()) / oneDay);
                const daysLeft = Math.round(Number(formatedEndDate) - Number(formatedTodayDate)) / oneDay;

                if(daysLeft >= 0){

                    return (
                        <StyledNoSubscriptionInfobox>
                            <StyledTitle>Rules and Standards Explorer+ subscription</StyledTitle>
                            <p style={{whiteSpace:"pre-line"}}>	You have an active trial subscription and can read the complete document. To download PDFs, an active Rules and Standards Explorer+ subscription is required. {'\n'} Rules and Standards Explorer+ content is marked with the E+ symbol.</p>
                            <StyledNoSubscriptonActionBox>
                                <SubscriptionInfoboxFreeTrial days={daysLeft} trial={true} type={props.type} />
                                <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                                <SubscriptionInfoboxSubscription />                                                     
                                <SubscriptionInfoboxSubButton type="List" title=" " />                            
                            </StyledNoSubscriptonActionBox>                        
                        </StyledNoSubscriptionInfobox>
                    );
                }
            }
            else{
                return (
                    <StyledNoSubscriptionInfobox>
                        <StyledTitle>Rules and Standards Explorer+ subscription</StyledTitle>
                        <p style={{whiteSpace:"pre-line"}}>To read the complete document or download a PDF, you need an active Rules and Standards Explorer+ subscription.{'\n'} Rules and Standards Explorer+ content is marked with the E+ symbol.</p>
                        <StyledNoSubscriptonActionBox>
                            <SubscriptionInfoboxFreeTrial days={-1} trial={userSetting?.trial ? true : false} type={props.type}/>
                            <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                            <SubscriptionInfoboxSubscription />                                                     
                            <SubscriptionInfoboxSubButton type="List" title=" "/>                            
                        </StyledNoSubscriptonActionBox>                        
                    </StyledNoSubscriptionInfobox>
                );
            }
        }

        return (
            <StyledNoSubscriptionInfobox>
                <StyledTitle>Rules and Standards Explorer+ subscription</StyledTitle>
                <p style={{whiteSpace:"pre-line"}}>To read the complete document or download a PDF, you need an active Rules and Standards Explorer+ subscription. You can also start a 14 day free trial, which gives you full access minus downloading of PDFs.{'\n'} Rules and Standards Explorer+ content is marked with the E+ symbol.</p>
                <StyledNoSubscriptonActionBox>
                    <SubscriptionInfoboxFreeTrial days={0} trial={userSetting?.trial ? true : false} type={props.type}/>
                    <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                    <SubscriptionInfoboxSubscription />
                    {userSetting?.trial ? "" : <span style={{ gridArea: "trialB" }}><FreeTrailButton type="List" title=" "/></span> }                            
                    <SubscriptionInfoboxSubButton type="List" title=" "/>
                </StyledNoSubscriptonActionBox>
            </StyledNoSubscriptionInfobox>
        );
    }

    if (!userSetting?.internal && props.type === "List") {
        return (
            <StyledSubscriptionInfoGridDiv id="SubscriptionInfo">
            <SubscriptionInfoboxInformation />
           
            <StyledSubscriptionInfoBottomContainer>
                <SubscriptionInfoboxFreeTrial  days={0} trial={false} type={props.type}/>
                <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                <SubscriptionInfoboxSubscription />
                <span style={{ gridArea: "trialB" }}><FreeTrailButton type="List" title=" "/></span>
                <SubscriptionInfoboxSubButton type="List" title=" "/>
            </StyledSubscriptionInfoBottomContainer>           
            
            </StyledSubscriptionInfoGridDiv>
        );
    }

    if (userSetting?.subscription && props.type === "Document") {
        return (
            null
        );
    }

    if (!userSetting?.subscription && props.type === "Document") {
        if (userSetting?.trial) {
            const endDate = new Date(userSetting.trialEndDateTime);  
            const todaysDate = new Date();      

            var date = endDate.getDate();
            var month = endDate.getMonth() + 1;
            var year = endDate.getFullYear();

            var Tdate = todaysDate.getDate();
            var Tmonth = todaysDate.getMonth() + 1;
            var Tyear = todaysDate.getFullYear();

            const formatedEndDate = new Date(year + '/' + month + '/' + date);
            const formatedTodayDate = new Date(Tyear + '/' + Tmonth + '/' + Tdate);

            if (formatedEndDate >= formatedTodayDate) {
                const oneDay = 1000 * 60 * 60 * 24;                
                //const daysLeft = Math.round((endDate.getTime() - todaysDate.getTime()) / oneDay);
                const daysLeft = Math.round(Number(formatedEndDate) - Number(formatedTodayDate)) / oneDay;
                
                return (
                    <StyledSubscriptionInfoGridDiv id="SubscriptionInfo">
                        <SubscriptionInfoboxInformation />
                        <StyledSubscriptionInfoBottomContainer>
                            <SubscriptionInfoboxFreeTrial days={daysLeft} trial={true} type={props.type}/>
                            <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                            <SubscriptionInfoboxSubscription />                                                     
                            <SubscriptionInfoboxSubButton type="Document" title={props.title}/>                            
                        </StyledSubscriptionInfoBottomContainer>                        
                    </StyledSubscriptionInfoGridDiv>
                );
            }
            else {
                return (
                    <StyledSubscriptionInfoGridDiv id="SubscriptionInfo">
                        <SubscriptionInfoboxInformation />
                        <StyledSubscriptionInfoBottomContainer>
                            <SubscriptionInfoboxFreeTrial days={-1} trial={userSetting?.trial ? true : false} type={props.type}/>
                            <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                            <SubscriptionInfoboxSubscription />
                            { userSetting?.trial ? "" : <span style={{ gridArea: "trialB" }}><FreeTrailButton type="Document" title={props.title}/></span> }                            
                            <SubscriptionInfoboxSubButton type="Document" title={props.title} />
                        </StyledSubscriptionInfoBottomContainer>
                    </StyledSubscriptionInfoGridDiv>
                );
            }
        }

        return (
            <StyledSubscriptionInfoGridDiv id="SubscriptionInfo">
                <SubscriptionInfoboxInformation />
                <StyledSubscriptionInfoBottomContainer>
                    <SubscriptionInfoboxFreeTrial days={0} trial={userSetting?.trial ? true : false} type={props.type}/>
                    <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                    <SubscriptionInfoboxSubscription />
                    { userSetting?.trial ? "" : <span style={{ gridArea: "trialB" }}><FreeTrailButton type="Document" title={props.title}/></span> }                            
                    <SubscriptionInfoboxSubButton type="Document" title={props.title} />
                </StyledSubscriptionInfoBottomContainer>
            </StyledSubscriptionInfoGridDiv>
        );
    }

    return (
        <StyledSubscriptionInfoGridDiv id="SubscriptionInfo">
            <SubscriptionInfoboxInformation />           
            <StyledSubscriptionInfoBottomContainer>
                <SubscriptionInfoboxFreeTrial days={0} trial={false} type={props.type}/>
                <div style={{ gridArea: "line", borderLeft: "2px solid #99D9F0" }}></div>
                <SubscriptionInfoboxSubscription />
                <span style={{ gridArea: "trialB" }}><FreeTrailButton type="Document" title={props.title}/></span>
                <SubscriptionInfoboxSubButton type="Document" title={props.title} />
            </StyledSubscriptionInfoBottomContainer>
        </StyledSubscriptionInfoGridDiv>
    );
}

const StyledSubscriptionInfoboxInformationDiv = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 1px;
  justify-items: stretch;
  justify-content: stretch;
  font-size:${({ theme }) => theme.fonts.sizes.medium};
  margin: 1rem 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #99D9F0;
`
function scrolltoTop() {
    window.scroll(0,0);
}
function SubscriptionInfoboxInformation() {
    const userSetting = useSelector((state: IRootState) => { return state.userSettings.settings });
    let roleBasedText=" ";
    if(userSetting?.trial){
            const endDate = new Date(userSetting.trialEndDateTime);                    
            const todaysDate = new Date();      
            var date = endDate.getDate();
            var month = endDate.getMonth() + 1;
            var year = endDate.getFullYear();

            var Tdate = todaysDate.getDate();
            var Tmonth = todaysDate.getMonth() + 1;
            var Tyear = todaysDate.getFullYear();

            const formatedEndDate = new Date(year + '/' + month + '/' + date);
            const formatedTodayDate = new Date(Tyear + '/' + Tmonth + '/' + Tdate);     
            if (formatedEndDate >= formatedTodayDate) {
                const oneDay = 1000 * 60 * 60 * 24;                
                //const daysLeft = Math.round((endDate.getTime() - todaysDate.getTime()) / oneDay);
                const daysLeft = Math.round(Number(formatedEndDate) - Number(formatedTodayDate)) / oneDay;

                if(daysLeft>0){
                    //external with trial
                    roleBasedText = `To download PDFs and to avoid losing access to this and other Rules and Standards Explorer+ documents after your trial expires in ${daysLeft} days, a subscription is needed.`;
                }
                else if(daysLeft == 0){
                    roleBasedText = `To download PDFs and to avoid losing access to this and other Rules and Standards Explorer+ documents after your trial expires today, a subscription is needed.`;

                }
            }
            else{
                // external trial has ended
                roleBasedText = "To read the complete document or download a PDF, you need an active Rules and Standards Explorer+ subscription. Rules and Standards Explorer+ content is marked with the E+ symbol.";
            }
    }
    else{
        // external user
        roleBasedText = `To read the complete document or download a PDF, you need an active Rules and Standards Explorer+ subscription. You can also start a 14 day free trial, which gives you full access minus downloading of PDFs. \n Rules and Standards Explorer+ content is marked with the E+ symbol.`;
    }
    return (
        <StyledSubscriptionInfoboxInformationDiv>
            <div style={{ paddingTop: "0.5em" }}>
                <img src={epluss} width="44px" alt="eplus" />
            </div>
            <div>
                <div style={{ fontSize: "xx-large", fontWeight: "bold" }}>Rules and Standards Explorer+ subscription</div>
                {/* <div>To read the complete docuement or download PDF, you need an active Rules and Standards Explorer+ subscription. You can also start a 14 day free trial, which gives full access minus downloading of PDFs. Rules and Standards Explorer+ content is marked with the E+ symbol.</div> */}
                <div style={{whiteSpace:"pre-line"}}>{roleBasedText}</div>
                <div style={{ marginTop: "1rem" }}><Link to="/subscription" onClick={scrolltoTop} style={{ textDecoration: "underline" }}>View the complete list of documents included in the subscription here</Link></div>
            </div>
        </StyledSubscriptionInfoboxInformationDiv>
    );
}

interface FreeTrial {
    days: number;
    trial: boolean;
    type: string;
}

function SubscriptionInfoboxFreeTrial(props: FreeTrial) {
    let type;
    if(props.type == "List"){
        type = true;
    }
    else if(props.type == "Document"){
        if(props.trial){
            type = false;
        }
    }
    return (
        <>
            {/* {props.trial ? <div style={{ fontSize: "x-large", fontWeight: "bold", gridArea: "titleTrial" }}>{props.days > 0 ? props.days + " days left of your free trial" : "Your trial period has ended"}</div> : <div style={{ fontSize: "x-large", fontWeight: "bold", gridArea: "titleTrial" }}>14 day free trial</div> } */}
            {props.trial ? <div style={{ fontSize: "x-large", fontWeight: "bold", gridArea: "titleTrial" }}>{props.days > 0 ? props.days + " days left of your free trial" : props.days== 0 ? "Your trial period expires today" : props.days==-1?"Your trial period has ended":""}</div> : <div style={{ fontSize: "x-large", fontWeight: "bold", gridArea: "titleTrial" }}>14 day free trial</div> }
            {type ? <div style={{ gridArea: "trial" }}>
                 
              <div>Read complete Rules and Standards Explorer+ documents online</div>
              <div style={{ marginTop: "1rem" }}>Trials are made available for one individual user account and are valid for one-time only</div> 
            </div>: "" }
        </>
    );
}

const StyledSubscriptionInfoboxButton = styled.button`
  background-color: #36842D;
  border-color: #36842D;
  border: 1px solid transparent;
  text-decoration: none;
  color: #ffffff;
  font-size: medium;
  font-weight: 400;
  width: 160px;
  height: 30px;
  margin-top: 1rem;

  :hover {
    background-color: #276121;
  }
`

// function SubscriptionInfoboxFreeTrailButton() {
//     const dispatch = useDispatch();
//     const userVeracity = useSelector((state: IRootState) => { return state.userVeracityTrial });
//     const getTrial = () => {
//         const setTrialUserSetting = new UserSettingAddDto;
//         setTrialUserSetting.trial = true;
//         dispatch(veracityActions.ensureValidTrialPolicies());
//         dispatch(userSettingsActions.saveUserSettings(setTrialUserSetting))
//     }

//   return (
//     <>
//       <StyledSubscriptionInfoboxButton style={{ gridArea: "trialB" }} onClick={ () => getTrial() }>Start 14 day trial</StyledSubscriptionInfoboxButton>
//     </>
//   );
// }

function SubscriptionInfoboxSubscription() {
    return (
        <>
            <div style={{ fontSize: "x-large", fontWeight: "bold", gridArea: "titleSub" }}>Subscription</div>
            <div style={{ gridArea: "sub" }}>
              <div>Choose a subscription according to your organization's needs to:</div>
              <div style={{ marginTop: "1rem" }}>Read complete Rules and Standards Explorer+ documents online</div>
              <div style={{ marginTop: "1rem" }}>Download Rules and Standards Explorer+ PDFs</div>              
            </div>
        </>
    );
}


function Getsubscription(title:any, userEmail:any, text:any){
    const url = "https://store.veracity.com/rules-and-standards-explorer-plus";
    const DocEloqua = "https://s861531437.t.eloqua.com/e/f2?elqFormName=ES_GLOB_TRACK_DOWNLOAD_RS_EXP&elqSiteID=861531437&emailAddress=" + userEmail + "&docTitle=" + title + " " + "&docUrl=" + url + "&type=" + text;
    fetch(DocEloqua);
    window.open(url, '_blank');
}

function SubscriptionInfoboxSubButton(props: InfoBox) {
    const userSetting = useSelector((state: IRootState) => { return state.userSettings.settings });
    const userInfo = useSelector((state: IRootState) => {return state.user.userInfo});
    let eloquaText = " ";
    if(userSetting?.trial == true){
        eloquaText = "Trial clicks get subscription"
    }
    else {
        eloquaText= "Non Subscriber clicks get subscription"
    }
    if(props.type == "List"){
        return (
            <>
              <StyledSubscriptionInfoboxButton style={{ gridArea: "subB" }} onClick={ () => window.open('https://store.veracity.com/rules-and-standards-explorer-plus', '_blank') }>Find out more</StyledSubscriptionInfoboxButton>
            </>
          );
    }
    else if(props.type == "Document"){
        
        return (
            <>
              <StyledSubscriptionInfoboxButton style={{ gridArea: "subB" }} onClick={ ()=>Getsubscription(props.title, getUpn(userInfo), eloquaText) }>Find out more</StyledSubscriptionInfoboxButton>
            </>
          );
    }

    return(
        <></>
    );
  
}