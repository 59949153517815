import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import {getClient} from '../../utils/proxyFactory';
import * as service from '../../api/SmartLibraryService';

interface ILoadToc { type: "LOAD_TOC"; }
interface ILoadTocComplete { type: "LOAD_TOC_COMPLETE"; payload:service.ICategory[] }
interface ILoadTocFailed { type: "LOAD_TOC_FAILED"; payload:{error:string} }

export type TocActions = ILoadToc | ILoadTocComplete | ILoadTocFailed;

type TA = ThunkAction<void, IRootState, void, TocActions>;

export const tocActions = {
    requestToc: (): TA => async (dispatch,getState)  => {
        dispatch({
            type: "LOAD_TOC"
        });
        var client = await getClient(dispatch,getState());
        await client.getToc()
        .then(x => {         
          if (x != null) {
            dispatch({ type: "LOAD_TOC_COMPLETE", payload: x })
          }else{
            dispatch({ type: "LOAD_TOC_FAILED", payload:{error:"No TOC returned"}})
          }          
        },
          (x:string) => {
            dispatch({ type: "LOAD_TOC_FAILED", payload:{error:x}})           
          }
        );
    },
}