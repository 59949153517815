import * as React from 'react';
import { provide } from "../../../store/provide";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { userNotesActions } from '../../../store/actions/userNotesActions';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import { PrimaryButton } from '../../Styled/Buttons/PrimaryButton';
import * as service from '../../../api/SmartLibraryService';
import IdleTimer from 'react-idle-timer'
import styled from 'styled-components/macro';
import { StyledLinkButton } from '../../Styled/Buttons/LinkButton';

const StyledTextAreaContainerDiv = styled.div`
    padding:8px;
`

const StyledTextArea = styled.textarea`
    font-family:${({theme}) => theme.fonts.family};
    width: 100%;
    outline: none;
    min-height: 5rem;
    font-size: 12px;
    border-radius: 10px;
    border: 2px solid  ${({theme}) => theme.colors.border.cyanlight};
    padding: 0.5rem 1rem;
`

const StyledBookmarkControlsContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 2.5%;
    margin-left: 2.5%;
    padding-bottom: 0.7rem;
    color: ${({theme}) => theme.colors.text.neutral60};
    padding-top: 0.7rem;
`

const StyledSaveButton = styled(PrimaryButton)`
   
    margin-left: ${({theme}) => theme.margin.medium};
`

class DocumentMapBookmarkEditComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>> {


    private refTextArea = React.createRef<HTMLTextAreaElement>();
    private idleTimer: IdleTimer | null = null;


    onClearNote = () => {
        if (this.refTextArea.current) {
            this.refTextArea.current.value = '';
        }
        this.props.updateBookmark(this.props.bookmark.bookmarkId, '');
    }
    handleOnIdle = () => {
        this.updateBookmark();
    }
    updateBookmark = () => {
        if (!this.props.isDeleting) {
            var bookmarkToUpdate = this.props.bookmark;
            bookmarkToUpdate.customText = this.refTextArea.current ? this.refTextArea.current.value : '';
            this.props.autosaveBookmark(bookmarkToUpdate);
        }

    }
    componentWillUnmount = () => {
        this.updateBookmark();
    }
    render() {
        const lastSavedText = this.props.userNotes.lastAutosave && this.props.userNotes.lastAutosave.bookmarkId === this.props.bookmark.bookmarkId ? "Last saved: " + new Date(this.props.userNotes.lastAutosave.when).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }) : "";
        return (
            <>
                <StyledTextAreaContainerDiv >
                    <StyledTextArea ref={this.refTextArea} maxLength={1000} defaultValue={this.props.bookmark.customText}></StyledTextArea>
                </StyledTextAreaContainerDiv>

                <StyledBookmarkControlsContainerDiv>
                    <StyledLinkButton data-cy="clear-note" onClick={this.onClearNote}>Clear note</StyledLinkButton>
                    <span>
                        {lastSavedText}<StyledSaveButton data-cy="save-note" onClick={() => this.props.updateBookmark(this.props.bookmark.bookmarkId, this.refTextArea.current ? this.refTextArea.current.value : '')} >Save and close</StyledSaveButton>
                    </span>
                </StyledBookmarkControlsContainerDiv>
                <IdleTimer
                    ref={(ref: IdleTimer | null) => { this.idleTimer = ref }}
                    timeout={1000 * 5}
                    //     onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    //onAction={this.handleOnAction}
                    debounce={250}
                />
            </>);
    }

}
const provider = provide((state) => ({
    userNotes: state.userNotes,
    document: state.document.document

}), { ...userNotesActions }).withExternalProps<{ bookmark: service.IBookmarkDto, isDeleting: boolean }>();

type Props = typeof provider.allProps;
export const DocumentMapBookmarkEdit = provider.connect(withRouter(DocumentMapBookmarkEditComponent));