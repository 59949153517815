import * as React from 'react';
import { provide } from '../../store/provide';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BackdropLeftIcon from '../Icons/BackdropLeftIcon';
import { BackdropRightIcon } from '../Icons/BackdropRightIcon';
import { DocumentSearch } from './DocumentSearch'
import styled from 'styled-components/macro';
import { TimesCircleIcon } from '../Icons/TimesCircleIcon';
import { Header } from '../Header/Header';
import { createStore } from 'redux';
import { globalsearchActions } from "../../store/actions/globalsearchActions";
import { editionMMtoStringShort, editionyyyyMMtoStringShort, editionyyyytoStringShort, flattenDocsInCategories } from '../../utils/documentHelpers';
import { filterActions } from '../../store/actions/filterActions';
import * as service from '../../api/SmartLibraryService';
import { Spinner } from '../Icons/Spinner';
import { sideBarActions } from '../../store/actions/sideBarActions';
import { DatePicker } from 'antd';
import { DocumentsActions } from '../Documents/DocumentsActions';
import { isAfter, isBefore } from "date-fns";
import moment from 'moment';

const StyledSearchContainer = styled.div`
position: fixed;
display: flex;
width:100%; 
-webkit-transition: all 1s ease-in;
height: 6.6rem;
justify-content: center;
background-image: linear-gradient(
  0deg,
  rgba(0, 94, 128, 0.1) 3%,
  rgba(0, 94, 128, 0.1) 3%,
  rgba(0, 94, 128, 0.88) 100%
  );
background-color:white;
transform: rotate(0deg) translate(0px, -1px);
background-position: center center;
  z-index:10;
`
const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const StyledBackdropLeftIcon = styled(BackdropLeftIcon)`
    position: absolute;
    left:  0;
    width: 15.8125rem;
    top: 5.1rem;
    height: 2.9375rem;
`

const StyledBackdropRightIcon = styled(BackdropRightIcon)`
 position: absolute;
 right: 0;
 width: 29.9375rem;
 top: 3.8rem;
 height: 4.3125rem;
`
const StyledCloseIcon = styled(TimesCircleIcon)`
 width: 24px;
 height: 24px;
 position: absolute;
 right: 0;
 cursor:pointer;
 margin:15px;
`


const StyledSearchContainerTop = styled.div`
  display: flex;

  margin-bottom: 2rem;
  height: 6.6rem;
  justify-content: center;
  background-image: linear-gradient(
  0deg,
  rgba(0, 94, 128, 0.1) 3%,
  rgba(0, 94, 128, 0.1) 3%,
  rgba(0, 94, 128, 0.88) 100%
  );
  
`
class GlobalSearchComponent extends React.Component<Props & RouteComponentProps> {

  closebtn = () => {
    this.props.showGlobalSearch(!this.props.globalsearch.compVisible)
  }

  gotoStartIfNotAlreadyThere = () => {
    this.props.toggleSideBarNavigation(true, "NAV");
        this.props.clearValues();
        this.props.clearCategory();
        window.scrollTo(0,0);
        if(this.props.searchResult !=null && this.props.searchResult.query!=null){
          this.props.history.push("/search");
        }
        else{
          this.props.history.push("/documents");
        }
  }

  render() {
    const isGlobSer = this.props.globalsearch.compVisible;
    let todayDate = new Date;
    let day = todayDate.getDate();
    let mnth = todayDate.getMonth();
    let yr = todayDate.getFullYear();
    let isAfterYear = yr + "-" + mnth +"-"+ day;
    
    const allDocs = this.props.toc && this.props.toc.categories ? flattenDocsInCategories(this.props.toc.categories) : [];
    const loadYear = allDocs.map(toc => editionyyyytoStringShort(toc.document.edition || ''))
    let tempArr = loadYear.filter((q, indx) => loadYear.indexOf(q) === indx).sort();
    let isBeforeYear = String(tempArr[0]);
    
    const onDatepickerChange = (date: any, dateString: any) => {
      
      if(date === null){
        this.props.clearFilters();
        this.gotoStartIfNotAlreadyThere();

      }
      else{
        var yymm = dateString;
        var arrStr = yymm.split("-");
        const year = arrStr[0];
        const month = arrStr[1];
        this.props.setSelectedYearFilter(year);
        this.props.setSelectedMonthFilter(month);
        const dt = year + month;
        this.props.setIssuedDtFilter(dt);
  
        

        this.gotoStartIfNotAlreadyThere();
        
      }
      

    }

    const value = () => {      
      if (this.props.filterState.selectedYear === null ||  this.props.filterState.selectedMonth === null)
      {
        return null;
      }

      return moment((this.props.filterState.selectedYear + "-" + this.props.filterState.selectedMonth), "YYYY-MM");      
    }

    return (

      isGlobSer ? (<StyledSearchContainer>
        <div style={{ display: "grid", width: "50%", height: "80%", justifyContent: "center", alignItems: "center", marginTop: "10px", gridTemplateColumns: "1fr auto 8.5rem" }}>
          <div style={{ gridArea: "1/1", marginLeft: "10px" }}><label style={{ color: "white" }}><b>Search</b></label></div>
          <div style={{ gridArea: "2/1", marginLeft: "10px" , marginBottom:"15px" }}>
            <DocumentSearch />
          </div>

          <div style={{ gridArea: "1/2", marginLeft: "10px" }}>
            <label style={{ color: "white" }}><b>Edition</b></label>
          </div>
          <div style={{ gridArea: "2/2", marginLeft:"10px", marginBottom:"15px" }}>
            <DatePicker picker='month' onChange={onDatepickerChange} value={value()} disabledDate={(current) => current.isAfter(isAfterYear) || current.isBefore("2015-10-12")} ></DatePicker>

          </div>
        </div>

        <StyledBackdropLeftIcon />

        <StyledBackdropRightIcon />
        <StyledCloseIcon onClick={this.closebtn} />

      </StyledSearchContainer>) : (<StyledSearchContainerTop>
        <div style={{ display: "grid", width: "50%", height: "80%", justifyContent: "center", alignItems: "center", marginTop: "10px", gridTemplateColumns: "1fr auto 8.5rem" }}>
          <div style={{ gridArea: "1/1", marginLeft: "10px" }}><label style={{ color: "white" }}><b>Search</b></label></div>
          <div style={{ gridArea: "2/1", marginLeft: "10px", marginBottom:"15px" }}>
            <DocumentSearch />
          </div>

          <div style={{ gridArea: "1/2", marginLeft: "10px" }}>
            <label style={{ color: "white" }}><b>Edition</b></label>
          </div>
          <div style={{ gridArea: "2/2", marginLeft:"10px", marginBottom:"15px" }}>
            
            <DatePicker picker='month' onChange={onDatepickerChange} value={value()} disabledDate={(current) => current.isAfter(isAfterYear) || current.isBefore("2015-10-12")} ></DatePicker>
          </div>
        </div>
        <StyledBackdropLeftIcon style={{ top: '9.7rem' }} />

        <StyledBackdropRightIcon style={{ top: '8.2rem' }} />

      </StyledSearchContainerTop>)


    );
  }
}

const provider = provide((state) => ({
  globalsearch: state.globalsearch,
  toc: state.toc,
  filterState: state.filterState,
  searchResult:state.searchResult

}), { ...globalsearchActions, ...filterActions, ...sideBarActions, ...DocumentsActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const GlobalSearch = provider.connect(withRouter(GlobalSearchComponent));