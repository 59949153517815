import { Reducer } from "redux";



export interface IAppUpdate {
    isNewVersion:boolean,
    oldVersion:string,
    newVersion:string
  }
export const appUpdateReducer: Reducer<IAppUpdate>
    = (state = { isNewVersion:false, oldVersion:"",newVersion:"" }, action) => {

        switch (action.type) {
            case "APP_UPDATE": {                             
                return { ...state,isNewVersion:action.payload.oldVersion && action.payload.newVersion && action.payload.oldVersion!==action.payload.newVersion, oldVersion:action.payload.oldVersion, newVersion:action.payload.newVersion};
            }   
          
            default:
                break;
        }

        return state;
    };