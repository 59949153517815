import * as service from '../api/SmartLibraryService';
import { ThunkDispatch } from "redux-thunk";
import { IRootState } from "../store/store";

export const getClient = async (dispatch: ThunkDispatch<IRootState, void, { type: string }>, state: IRootState): Promise<service.SmartLibraryClient> => {

    const client = new service.SmartLibraryClient(process.env.PUBLIC_URL);   
    if (state.user.userInfo?.accessToken) {
           
        client.onNewVersion = (oldVersion: string, newVersion: string) => dispatch({ type: "APP_UPDATE", payload: { oldVersion: oldVersion, newVersion: newVersion } })
        window.document.cookie = "jwtToken=" + state.user.userInfo?.accessToken+ ";domain=" + window.location.hostname + ";path=/; Secure; SameSite=Strict";
        client.jwtToken = state.user.userInfo?.accessToken;      
    }
    return Promise.resolve(client);
    
}

export const getErrorClient = async (dispatch: ThunkDispatch<IRootState, void, { type: string }>, state: IRootState): Promise<service.ClientErrorClient> => {

    const client = new service.ClientErrorClient(process.env.PUBLIC_URL);  
    if (state.user.userInfo?.accessToken) {
             
        client.onNewVersion = (oldVersion: string, newVersion: string) => dispatch({ type: "APP_UPDATE", payload: { oldVersion: oldVersion, newVersion: newVersion } })
        window.document.cookie = "jwtToken=" + state.user.userInfo?.accessToken + ";domain=" + window.location.hostname + ";path=/; Secure; SameSite=Strict";
        client.jwtToken = state.user.userInfo?.accessToken;
    }
    return Promise.resolve(client);
    
}


export const getBookmarkClient = async (dispatch: ThunkDispatch<IRootState, void, { type: string }>, state: IRootState): Promise<service.BookmarkClient> => {

    const client = new service.BookmarkClient(process.env.PUBLIC_URL);  
    if (state.user.userInfo?.accessToken) {
             
        client.onNewVersion = (oldVersion: string, newVersion: string) => dispatch({ type: "APP_UPDATE", payload: { oldVersion: oldVersion, newVersion: newVersion } })
        window.document.cookie = "jwtToken=" + state.user.userInfo?.accessToken + ";domain=" + window.location.hostname + ";path=/; Secure; SameSite=Strict";
        client.jwtToken = state.user.userInfo?.accessToken;
    }
    return Promise.resolve(client);
    
}

export const getUserSettingClient = async (dispatch: ThunkDispatch<IRootState, void, { type: string }>, state: IRootState): Promise<service.UserSettingClient> => {
    const client = new service.UserSettingClient(process.env.PUBLIC_URL);
    
    if (state.user.userInfo?.accessToken) {
        client.onNewVersion = (oldVersion: string, newVersion: string) => dispatch({ type: "APP_UPDATE", payload: { oldVersion: oldVersion, newVersion: newVersion } })
        window.document.cookie = "jwtToken=" + state.user.userInfo?.accessToken + ";domain=" + window.location.hostname + ";path=/; Secure; SameSite=Strict";
        client.jwtToken = state.user.userInfo?.accessToken;
    }

    return Promise.resolve(client);
}

export const getOfflinePackagesClient = async (dispatch: ThunkDispatch<IRootState, void, { type: string }>, state: IRootState): Promise<service.OfflinePackageClient> => {
    const client = new service.OfflinePackageClient(process.env.PUBLIC_URL);
    
    if (state.user.userInfo?.accessToken) {
        client.onNewVersion = (oldVersion: string, newVersion: string) => dispatch({ type: "APP_UPDATE", payload: { oldVersion: oldVersion, newVersion: newVersion } })
        window.document.cookie = "jwtToken=" + state.user.userInfo?.accessToken + ";domain=" + window.location.hostname + ";path=/; Secure; SameSite=Strict";
        client.jwtToken = state.user.userInfo?.accessToken;
    }

    return Promise.resolve(client);
}

export const getAttachmentsClient = async (dispatch: ThunkDispatch<IRootState, void, { type: string }>, state: IRootState): Promise<service.AttachmentClient> => {
    const client = new service.AttachmentClient(process.env.PUBLIC_URL);
    
    if (state.user.userInfo?.accessToken) {
        client.onNewVersion = (oldVersion: string, newVersion: string) => dispatch({ type: "APP_UPDATE", payload: { oldVersion: oldVersion, newVersion: newVersion } })
        window.document.cookie = "jwtToken=" + state.user.userInfo?.accessToken + ";domain=" + window.location.hostname + ";path=/; Secure; SameSite=Strict";
        client.jwtToken = state.user.userInfo?.accessToken;
    }

    return Promise.resolve(client);
}
