import styled from 'styled-components/macro';
export const PrimaryButton = styled.span`
  
  padding:  ${({ theme }) => theme.padding.small + ' ' + theme.padding.medium};
  background-color: ${({ theme }) => theme.colors.background.darkblue};
  font-family:${({ theme }) => theme.fonts.AvenirNextMedium}; 
  font-size:  ${({ theme }) => theme.fonts.sizes.small};
color: ${({ theme }) => theme.colors.text.white};
  text-decoration: none solid  ${({ theme }) => theme.colors.text.white};
  text-align: center;
  cursor:pointer;
  display:inline;
  &:hover{
    background-color: ${({ theme }) => theme.colors.background.uknownblue};
    color: ${({ theme }) => theme.colors.text.white};
  }
`;