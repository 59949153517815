import React from 'react';
import {ReactComponent as ChevronDouble} from '../../images/chevron-double-left.svg';
type ChevronDoubleProps = {
    direction:"Up"|"Down"|"Left"|"Right",
    expanded:boolean,
}
export const ChevronDoubleIcon:React.FC<ChevronDoubleProps& React.HTMLAttributes<HTMLDivElement>>= (props)=>{
    const {direction,expanded,onClick,...restProps} = props;
    const classes = ["rs-chevron",
    direction ==="Up" && "rs-chevron-up",
    direction ==="Down" && "rs-chevron-down",
    direction ==="Left" && "rs-chevron-left",
    direction ==="Right" && "rs-chevron-right",
    expanded && "rs-chevron-selected"
].filter(e=>!!e).join(' ');

return <span onClick={onClick} {...restProps}><ChevronDouble  className={classes}/></span>
}

export const SimpleChevronDoubleIcon :React.FC<React.HTMLAttributes<HTMLOrSVGElement>>= (props)=>{
    return <ChevronDouble {...props}/>
}