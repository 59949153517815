import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store/store";
import { editionyyyyMMtoStringLong, flattenDocsInCategories } from "../../utils/documentHelpers";
import { Menu } from "../Sidebar/Menu";
import { DocumentList } from "./DocumentList";
import styles from "../ContentManager/ContentManager.module.scss";
import styled from 'styled-components/macro';
import { ridgeborderbottom } from '../StyledComponents/CustomStyles'
import { DocumentCard, DocumentCardElement, RowCounter } from "./DocumentCard";
import { SortIndicator } from "../Icons/SortIndicator";
import { useEffect, useMemo, useState } from "react";
import { DocumentsActions } from "./DocumentsActions";
import { IDocumentFilters } from "../../store/reducers/filterReducer";
import React from 'react';
import{FixedSizeList, VariableSizeList} from 'react-window';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as service from '../../api/SmartLibraryService';
import { Spinner } from "../Icons/Spinner";
import { Tag } from "antd";
import { documentListActions } from "../../store/actions/documentListActions";
import { dismissAll } from "../../utils/customToast";
import { ISearchContext } from "../../store/reducers/searchReducer";

const StyledDocumentGridDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 90px auto;
  justify-items: stretch;
  justify-content: stretch;	
  grid-template-rows: auto; 
  font-size:${({ theme }) => theme.fonts.sizes.medium}; 
  height: "100%"; 
`
const StyledDocumentGridHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.AvenirDemi};
  font-size:${({ theme }) => theme.fonts.sizes.large};
  font-weight: ${({ theme }) => theme.fonts.weights.regular};
  text-decoration: none solid ${({ theme }) => theme.colors.text.darkblue};
  line-height: 2.3rem;
  white-space:nowrap;
  display:flex;
  align-items:center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.darkblue};
`
const StyledDocumentTitleDiv = styled.div`
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    ${({ theme }) => ridgeborderbottom(theme)};
`



const StyledFakeBorderDiv = styled.div`
    grid-column-start:1;
    grid-column-end:5;
    height:0.0625rem;
    background-color: ${({ theme }) => theme.colors.border.neutral10};
`

const StyledLabel = styled.label`
box-sizing: border-box;
       margin: 0 8px 0 0;
       color: #fff;
       font-variant: tabular-nums;
       list-style: none;
       font-feature-settings: "tnum";
       display: inline-block;
       padding: 5px 9px;
       height: auto;
line-height: 20px;
white-space: nowrap;
border-radius: 2px;
opacity: 1;
background-color: rgb(0, 103, 197);
border-radius: 1.5rem;
`

export function DocumentsList() {
    
    const dispatch = useDispatch();
    const list =  useSelector((state: IRootState) => state.documents);
    const allDocs = useSelector((state: IRootState) => state.toc.categories);
    const currentDocs = flattenDocsInCategories(allDocs).filter(doc => doc.document.isCurrent);
    const documentlistState =  useSelector((state: IRootState) => state.documentList);
    const filterState =  useSelector((state: IRootState) => state.filterState);
    const searchResult =  useSelector((state: IRootState) => state.searchResult);
    dismissAll();
    

    const sortCode = () => {
      if (documentlistState.column === "code") {

          if (documentlistState.order === "Ascending") {
              dispatch(documentListActions.setSorting(documentlistState.column, "Descending"));
          }
          else {
            dispatch(documentListActions.setSorting(documentlistState.column, "Ascending"));
          }
      } else {
        dispatch(documentListActions.setSorting("code", "Ascending"));
      }
  }
  const sortTitle = () => {
      if (documentlistState.column === "title") {
          if (documentlistState.order === "Ascending") {
            dispatch(documentListActions.setSorting(documentlistState.column, "Descending"));
          }
          else {
            dispatch(documentListActions.setSorting(documentlistState.column, "Ascending"));
          }
      } else {
        dispatch(documentListActions.setSorting("title", "Ascending"));
      }
  }

  let filteredDocs: DocumentCardElement[] = [];
  let filterRangeDoc = Filter(flattenDocsInCategories(allDocs), filterState); 

    //only filter
    if(filterState.isFiltered && searchResult.query === null){
      filteredDocs = filterRangeDoc; 
    }

    //only search
    if (searchResult.query && searchResult.searchResult && !filterState.isFiltered) {
        let filterRangeSearchDoc = Filter(currentDocs, filterState); 
        filteredDocs = filterBasedOnSearch(filterRangeSearchDoc, searchResult.searchResult);
    }

    //only current docs
    if(!filterState.isFiltered && searchResult.query ===null){
        filteredDocs = Filter(currentDocs, filterState);  
    }

    //both filter and search
    if(filterState.isFiltered && searchResult.query && searchResult.searchResult){
        filteredDocs = filterBasedOnSearch(filterRangeDoc, searchResult.searchResult);
    }
  
    

   
    const rowCounter: RowCounter = { rowcount: 2 };

    const render =(array:DocumentCardElement[], index:number)=>{
       return array.map((documentCard, index) => <DocumentCard key={array[index].document.configId} documentCard={documentCard} index={index} isSearch={false} rowCounter={rowCounter} />)
    }
    
    const fetchmore= ()=>{
        
        if(list.list.length >= filteredDocs.length){
            dispatch(DocumentsActions.setHasmore(false));
        }
        dispatch(DocumentsActions.requestDocuments(list.list.concat(filteredDocs.slice(list.start+150, list.stop+150))));
        dispatch(DocumentsActions.setUpdateValues());

    }
    useEffect(() => {
      if (list) {
        if (list.list.length < 1 && list.isFetching != true) {
          dispatch(DocumentsActions.requestDocuments(filteredDocs.slice(list.start, list.stop)));
        }
      }
    });
    const getMonthName =(monthNumber:any)=>{
      const date = new Date();
      date.setMonth(monthNumber - 1);
      return date.toLocaleString('en-US', {
        month: 'long',
      });
    }
  
    return (
        <>
        {searchResult.query && <StyledLabel style={{marginLeft:"8px"}}>{searchResult.query}</StyledLabel>}                            
        {filterState.selectedMonth !=null ? <StyledLabel style={{marginLeft:"8px"}}>{getMonthName(filterState.selectedMonth)} {filterState.selectedYear}</StyledLabel>:''}                            
        <InfiniteScroll dataLength={list.list.length} next={fetchmore} hasMore={list.hasMore} loader={"loading"}>
            <StyledDocumentTitleDiv>Showing <span data-cy="documentListCount">{filteredDocs.length}</span>  rules and standards </StyledDocumentTitleDiv>
            <StyledDocumentGridDiv>
            {filterState.category != " "? <StyledDocumentGridHeader style={{ paddingLeft: "1rem", cursor: "pointer", }} onClick={sortCode} >Document code {true && <span style={{ marginLeft: "1rem" }}> <SortIndicator enabled={documentlistState.column === "code"} direction={documentlistState.order} /></span>}</StyledDocumentGridHeader>: <StyledDocumentGridHeader style={{ paddingLeft: "1rem"}}>Document code </StyledDocumentGridHeader>}
            {filterState.category != " "? <StyledDocumentGridHeader style={{ paddingLeft: "1rem", cursor: "pointer" }} onClick={sortTitle}>Title {true && <span style={{ marginLeft: "1rem" }}> <SortIndicator enabled={documentlistState.column === "title"} direction={documentlistState.order} /></span>}</StyledDocumentGridHeader>: <StyledDocumentGridHeader style={{ paddingLeft: "1rem"}}>Title</StyledDocumentGridHeader>}
                <StyledDocumentGridHeader>Edition</StyledDocumentGridHeader>
                <StyledDocumentGridHeader></StyledDocumentGridHeader>
                <StyledFakeBorderDiv />
                {list.list.map((doc, index)=>{return(<DocumentCard key={doc.document.configId} documentCard={doc} index={index} isSearch={false} rowCounter={rowCounter} />)})}
            </StyledDocumentGridDiv>
            </InfiniteScroll>
        </>
    )
}

function Filter(currentDocs: DocumentCardElement[], filterstate:IDocumentFilters){
  
  if(filterstate.isFiltered ){
    let filterDoc = currentDocs.filter(cat=> filterstate.category == " " ? cat.category.code != null :  (filterstate.subCategory != null ?cat.category.code == filterstate.category && cat.subCategory?.code == filterstate.subCategory: cat.category.code == filterstate.category))
    
    let newArr :DocumentCardElement[] =[] ;
    //newArr= filterDoc.filter(doc=>doc.category.code ==="WPI" || doc.category.code==="ITG");
    let newArray = filterDoc.filter(doc=>doc.document.issued!=undefined && filterstate.issuedDate!= null ? parseInt(doc.document.issued) <= parseInt(filterstate.issuedDate) || doc.category.code?.includes("WPI") || doc.category.code?.includes("ITG"): '' );
    newArray.forEach((doc, index)=>{
                //console.log(doc);
                let result=  newArr.filter(x=> x.document.documentCode === doc.document.documentCode )
                if(result.length ==0){
                    newArr.push(doc);

                }
            });
            return newArr;
    // return filterDoc.filter(doc=>doc.document.issued!=undefined && filterstate.issuedDate!= null ? parseInt(doc.document.issued) <= parseInt(filterstate.issuedDate) : '')
  }
    return currentDocs.filter(cat=> filterstate.category == " " ? cat.category.code != null :  (filterstate.subCategory != null ?cat.category.code == filterstate.category && cat.subCategory?.code == filterstate.subCategory: cat.category.code == filterstate.category));
}

function filterBasedOnSearch (documents: DocumentCardElement[], searchResult: service.IResult) {
  const searchHits: { [id: string]: service.IHit; } = {};
  const documentCodeHits: { [id: string]: service.IHit; } = {};
  const titleHits: { [id: string]: service.IHit; } = {};
  const htmlHits: { [id: string]: service.IHit; } = {};
  //Find allowed documents


  if (searchResult.documentCode) {
      searchResult.documentCode.forEach(hit => {
          if (!searchHits[hit.configId || '']) searchHits[hit.configId || ''] = hit;
          if (!documentCodeHits[hit.configId || '']) documentCodeHits[hit.configId || ''] = hit;
      });
  }
  if (searchResult.title) {
      searchResult.title.forEach(hit => {
          if (!searchHits[hit.configId || '']) searchHits[hit.configId || ''] = hit;
          if (!titleHits[hit.configId || '']) titleHits[hit.configId || ''] = hit;
      });
  }
  if (searchResult.html) {
      searchResult.html.forEach(hit => {
          if (!searchHits[hit.configId || '']) searchHits[hit.configId || ''] = hit;
          if (!htmlHits[hit.configId || '']) htmlHits[hit.configId || ''] = hit;
      });
  }

  //Filter documents to only allowed documents
  documents = documents.filter(x => searchHits.hasOwnProperty(x.document.configId || 'ERROR'));


  documents.forEach(x => {
      var hitKey = x.document.configId || 'ERROR';
      x.searchScore = searchHits[hitKey] && searchHits[hitKey].score ? searchHits[hitKey].score || 0 : 0;
      x.htmlHit = htmlHits[hitKey] && htmlHits[hitKey];
      x.titleHit = titleHits[hitKey] && titleHits[hitKey];
      x.documentCodeHit = documentCodeHits[hitKey] && documentCodeHits[hitKey];
      if (x.documentCodeHit) {
          x.searchScore = (x.documentCodeHit.score || 0) * 10000;
      }
      if (x.titleHit) {
          x.searchScore = (x.titleHit.score || 0) * 100;
      }
  });

  documents = documents.sort((a, b) => b.searchScore - a.searchScore);

  return documents;
}
