
import { Menu } from "../Sidebar/Menu";
import styles from "../ContentManager/ContentManager.module.scss";
import { DocumentsListSearch } from "./DocumentsListSearch";

export function SearchRender() {

    return (
        <Menu layout="Search">
            <div className={styles.documentList}>
                <DocumentsListSearch  />
            </div>
        </Menu>
    )
}