import { useSelector } from "react-redux";
import { IRootState } from "../../store/store";
import { flattenDocsInCategories } from "../../utils/documentHelpers";
import { DocumentList } from "../Documents/DocumentList";
import styles from "../ContentManager/ContentManager.module.scss";
import { Menu } from "../Sidebar/Menu";

export function SubscriptionList() {
    const allDocs = useSelector((state: IRootState) => flattenDocsInCategories(state.toc.categories));
    const subscriptionDocs = allDocs.filter(doc => doc.document.requiredVeracitySubscriptions && doc.document.isCurrent);   

    return (
        <Menu layout="SubscriptionList">
            <div className={styles.documentList}>
                <DocumentList documentCards={subscriptionDocs} subscription={true} />                                
            </div>
        </Menu>
        
    );

}