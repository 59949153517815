import { Reducer } from "redux";
import { ScrollMemoryActions } from "../actions/scrollMemoryActions";

type LocationsWithScoll = {
    path:string;
    offset: number;
}
interface IScrollMemory {
    locationsWithScoll: LocationsWithScoll[];
}
export const scrollMemoryReducer: Reducer<IScrollMemory, ScrollMemoryActions>
    = (state = { locationsWithScoll: []}, action) => {

        switch (action.type) {
            case "SET_SCROLL_POSITION": {         
                const newArray = state.locationsWithScoll.filter(x=>x.path !== action.payload.path);
                newArray.push({path:action.payload.path,offset:action.payload.offset})
                return { ...state, locationsWithScoll: newArray};
            }

            default:
                break;
        }

        return state;
    };