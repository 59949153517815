
import * as React from 'react';
import { ErrorMessage } from './ErrorMessage'
import { PdfIcon } from '../Icons/PdfIcon';
import { DocumentCardElement } from '../Documents/DocumentCard';
export const ErrorMessagePdfOnly = (props: { documentCardElement: DocumentCardElement }) => {
    return <>
       
        <ErrorMessage
            icon={<PdfIcon style={{ width: "4.25rem", height: "4.25rem", fill:"black",strokeWidth:2  }} />}
            message={<>

                <div style={{ fontSize: "1.5rem" }}>This document is currently available as PDF only</div>
                <div style={{ marginTop: "1.875rem" }}>All editions can be accessed on the right hand side.</div>
            </>}
        />
    </>
}
