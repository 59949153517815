import React from 'react';

import { scrolltoElement } from '../../../utils/scroll'
import SearchIcon from '../../Icons/SearchIcon';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { provide } from "../../../store/provide";
import { searchDocumentBoxActions } from "../../../store/actions/searchDocumentBoxActions";
import { documentActions } from '../../../store/actions/documentActions';
import { highlightActions } from "../../../store/actions/highlightActions"
import { CrossIcon } from '../../Icons/CrossIcon';
import { ChevronIcon } from '../../Icons/Chevron';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import styled from 'styled-components/macro';
import ReactDOM from 'react-dom';
const StyledSearch = styled.input`
border-width: 0px 0px 1px 0px;
border-bottom-color: ${({ theme }) => theme.colors.border.neutral20};
padding: 7px;
width:100%;

&:hover,&:focus {
  outline:none;
  box-shadow: 0px 4px 8px ${({ theme }) => theme.colors.shadow.darkshadow};
}
`;

const StyledHighlightsNumberContainerDiv = styled.div`
  height: 1.5rem;
  padding-left: 0.375rem;
  margin-top: ${({ theme }) => theme.margin.large};
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
`;

const StyledSearchContainerDiv = styled.div`
display:flex;
align-items:center;
height:34px;     
`
const StyledHighlightWithinSearchContainerSpan = styled.span<{ $showinghighlights: boolean }>`
  margin-left: ${({ $showinghighlights }) => $showinghighlights ? "-6.2em" : "-1.6rem"};
  display: flex;
  justify-content: flex-end;
  height: 90%;
`

const StyledCrossIconContainerSpan = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.7rem;
  cursor: pointer;
`

const StyledPrevNextContainerSpan = styled.span<{ $prevnextenabled: boolean }>`
display: flex;
align-items: center;
justify-content: center;
width: 2.375rem;
height: 100%;
background: ${({ theme }) => theme.colors.background.neutral02};
cursor:${({ $prevnextenabled }) => $prevnextenabled ? "pointer" : "unset"};
&:hover{
  background: ${({ theme }) => theme.colors.background.neutral05};
}
`
const StyledClearSearchCrossIcon = styled(CrossIcon)`
fill: ${({ theme }) => theme.colors.icon.digitalblue};
height: 1.0625rem;
width: 0.6875rem;
`


class SearchWithinDocumentComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>>{


    search = () => {
        if (this.props.document && this.props.document.configId) {
            this.props.requestDocument(this.props.document.configId, this.props.document.revision, this.props.searchDocumentBoxText, this.props.filterState.isFiltered, this.props.filterState.issuedDate);
            this.props.setHighlightText(this.props.searchDocumentBoxText);
            this.props.toggleHighlight(true);
            this.props.setAutoScroll(true);
        }
    }

    

    searchIfEnter = (x: React.KeyboardEvent<HTMLInputElement>) => {
        if (x.key === 'Enter') {
            this.search();
        }
    }
    toggleHighlight = () => this.props.toggleHighlight(!this.props.enabled);


    setActive = (el: Element | null) => {
        const active = document.querySelector('.rs-current-highlight');
        if (active != null) {
            active.classList.remove('rs-current-highlight');
        }


        if (el != null) {
            el.classList.add('rs-current-highlight');
        }
    }

    scrollToNext = () => {

    //console.log("Scrolling to index " +(this.props.autoScroll) );     
    if(this.props.highlightIndex==-1 && this.props.autoScroll==false){
      var el = document.querySelectorAll(".rs-highlight-search-term")[this.props.highlightIndex+1];
      scrolltoElement(el);
      this.setActive(el);
      this.props.setHighlightIndex(this.props.highlightIndex + 2);
    }
    
    else{
      var el = document.querySelectorAll(".rs-highlight-search-term")[this.props.highlightIndex];
      scrolltoElement(el);
      this.setActive(el);
      this.props.setHighlightIndex(this.props.highlightIndex + 1);
    }

    }

    scrollToPrev = () => {

    //  console.log("Scrolling to index " +(this.props.highlightIndex -1) );
    var el = document.querySelectorAll(".rs-highlight-search-term")[this.props.highlightIndex - 2];
    scrolltoElement(el);
    this.setActive(el);
    this.props.setHighlightIndex(this.props.highlightIndex - 1);

  }
  componentDidMount = () => {
    if (this.props.enabled && this.props.autoScroll) {
      this.scrollToNext();
    }
  }
  componentDidUpdate = () => {
    if (this.props.enabled && this.props.highlightIndex === -1 && this.props.count > 0 && this.props.autoScroll) {
      this.scrollToNext();
    }
  }
  prevClickedEnabled = () => this.props.count > 0 && this.props.highlightIndex > 1;

    prevClicked = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (this.prevClickedEnabled()) {
            this.scrollToPrev();
        }

    }

  nextClickedEnabled = () => this.props.count > 0 && this.props.highlightIndex <= this.props.count - 1;
  nextClicked = (e: React.MouseEvent) => {
    e.stopPropagation();

        if (this.nextClickedEnabled()) {
            this.scrollToNext();
        }

    }


    render() {

    var showingHighlights = this.props.enabled && this.props.documentReady && this.props.count >= 0;
    var nextEnabled = this.nextClickedEnabled();
    var prevEnabled = this.prevClickedEnabled();
    return (
      <>
        { <StyledHighlightsNumberContainerDiv>
          {showingHighlights ?
            this.props.count === 0 ? ("0 results for '" + this.props.highlightText + "'") :
              ((this.props.highlightIndex  > 0 ? this.props.highlightIndex  + " of " : "") + this.props.count + " results for '" + this.props.highlightText + "'")
            : '\u00A0'}
        </StyledHighlightsNumberContainerDiv>}


                <StyledSearchContainerDiv>
                    <StyledSearch
                        placeholder="Search within document"
                        aria-label="Search within document"
                        data-cy="searchwithindocument"

                        value={this.props.searchDocumentBoxText}
                        onKeyUp={this.searchIfEnter}
                        onChange={(x: any) => this.props.onChangeSearchBox(x.currentTarget.value)} />


                    <StyledHighlightWithinSearchContainerSpan $showinghighlights={showingHighlights} >
                        {showingHighlights ?
                            <>
                                <StyledCrossIconContainerSpan onClick={() => { this.props.onChangeSearchBox(''); this.props.toggleHighlight(!this.props.enabled) }}><StyledClearSearchCrossIcon /></StyledCrossIconContainerSpan>
                                <StyledPrevNextContainerSpan onClick={this.prevClicked} title="Previous result" $prevnextenabled={prevEnabled}><ChevronIcon direction="Up" expanded={false} /></StyledPrevNextContainerSpan>
                                <StyledPrevNextContainerSpan onClick={this.nextClicked} title="Next result" $prevnextenabled={nextEnabled}><ChevronIcon direction="Down" expanded={false} /></StyledPrevNextContainerSpan>

                            </>
                            : <span onClick={this.search} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}><SearchIcon style={{ height: "1.25rem", width: "1.25rem" }} /></span>}
                    </StyledHighlightWithinSearchContainerSpan>
                </StyledSearchContainerDiv>
            </>)

    }
}

const provider = provide((state) => ({
    searchDocumentBoxText: state.searchDocumentBox.searchDocumentBoxText,
    document: state.document.document,
    documentReady: !state.document.isFetching && state.document.mounted,
    enabled: state.highlight.enabled,
    highlightText: state.highlight.highlightText,
    highlightIndex: state.highlight.highlightIndex,
    count: state.highlight.count,
    autoScroll: state.highlight.autoScroll,
    filterState: state.filterState
}), { ...searchDocumentBoxActions, ...documentActions, ...highlightActions }).withExternalProps<{}>();

type Props = typeof provider.allProps;
export const SearchWithinDocument = provider.connect(withRouter(SearchWithinDocumentComponent));
