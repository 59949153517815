import * as React from 'react';
import { provide } from "../../../store/provide";
import { scrolltoId, scrollParentToChild } from '../../../utils/scroll';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { StyledMenuShadowed, StyledMenuAction } from "../../Styled/ContextMenu/ContextMenu";
import { userNotesActions } from '../../../store/actions/userNotesActions';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import * as service from '../../../api/SmartLibraryService';
import { editionAndAmendedLongStrings } from '../../../utils/documentHelpers';
import { groupCommentsByLastValidInRevision } from '../../../utils/comments';
import { DocumentCardElement } from '../../Documents/DocumentCard';
import { DocumentMapBookmark } from './DocumentMap_Bookmark'
import { ToggleOffIcon } from '../../Icons/ToggleOff';
import { ToggleOnIcon } from '../../Icons/ToggleOn';
import { ChevronIcon } from '../../Icons/Chevron';
import { CrossIcon } from '../../Icons/CrossIcon';
import { StyledMenuItemButton } from '../../Styled/Buttons/MenuItemButton';
import { InformationBox } from '../../Styled/Boxes/InformationBox'
import * as tooltips from '../../../utils/tooltips';
import { TutorialBookmarks } from '../../InfoBox/TutorialBookmarks'
import { BookmarkIcon } from '../../Icons/Bookmark';
import { EllipsisHIcon } from '../../Icons/Ellipsis-h';
import { StyledLinkButton } from '../../Styled/Buttons/LinkButton';
import { SubtleButton } from '../../Styled/Buttons/SubtleButton';

const StyledVerticalDivider = styled.span`
   display: inline-block;
   width: 1px;
   background-color:${({ theme }) => theme.colors.text.neutral20};
   height: 16px;
   margin-left: ${({ theme }) => theme.margin.small};
   margin-right: ${({ theme }) => theme.margin.small};
`
const StyledNotesOnlyTitleSpan = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  margin-right: 1rem;
`

type IBookmarksState = {
    notesOnly: boolean,
    prevBookmarkId: string,
    prevElementId: string,
    nextBookmarkId: string,
    nextElementId: string,
    documentBookmarkDiv: Element | null,
    olderRevisionWithBookmark: service.IBookmarkDto | null,
    allBookmarks: service.IBookmarkDto[] | null,
    visibleBookmarks: service.IBookmarkDto[] | null,
    allBookmarksThisRevision: service.IBookmarkDto[] | null,
    topLevelBookmarkNotPointingToCurrentDocument: boolean,
    meatballMenuOpen: boolean,
    copyBookmarkDialogShow: boolean
}


class DocumentMapBookmarksComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>, IBookmarksState> {
    state: IBookmarksState = {
        notesOnly: false,
        prevBookmarkId: '',
        prevElementId: '',
        nextBookmarkId: '',
        nextElementId: '',
        documentBookmarkDiv: null,
        allBookmarks: null,
        olderRevisionWithBookmark: null,
        visibleBookmarks: null,
        allBookmarksThisRevision: null,
        topLevelBookmarkNotPointingToCurrentDocument: false,
        meatballMenuOpen: false,
        copyBookmarkDialogShow: false
    };


    notesOnlyToogle = () => {

        this.setState((s) => {
            if (s.notesOnly) {
                return ({ ...s, notesOnly: !s.notesOnly, allBookmarks: null });
            } else {
                return ({ ...s, notesOnly: !s.notesOnly, allBookmarks: null, prevBookmarkId: '', prevElementId: '', nextBookmarkId: '', nextElementId: '' });
            }

        });
    }
    copyToCurrentRevision = (fromRevision: number) => {
        this.props.copyFromRevisionToRevision(this.props.match.params.configId, fromRevision, +this.props.match.params.revision);
        this.dismissCopyBookmarkDialog();
    }
    componentDidMount() {
        this.setState((s) => ({ ...s, documentBookmarkDiv: document.querySelector("#documentBookmarkDiv") }));
        this.updateNotes();
        this.autoSetLastSeenRevision();
    }
    componentDidUpdate() {
        this.autoSetLastSeenRevision();
        var bookmarkContainer = this.state.documentBookmarkDiv;
        if (this.props.userNotes.highlightBookmark && bookmarkContainer) {
            var editNoteDiv = document.querySelector("div[data-bookmark-id='" + this.props.userNotes.highlightBookmark + "']");
            if (editNoteDiv) {

                scrollParentToChild(bookmarkContainer, editNoteDiv)
            }

        }
        this.updateNotes();
        this.detectPrevNextBookmark();
    }
    autoSetLastSeenRevision = () => {
        if (this.props.outOfDateIndicatorsWithBookmarks && this.props.currentlyViewing.document.isCurrent) {
            this.props.updateLastSeen(this.props.currentlyViewing.document.configId || '', this.props.currentlyViewing.document.revision);
        }


    }
    updateNotes = () => {
        const allNotesAllRevisions = this.props.userNotes.notes ? this.props.userNotes.notes.filter(x => x.configId === this.props.match.params.configId && x.bookmarkedId).sort((a, b) => a.htmlOrder - b.htmlOrder) : null;
        const currentRevisionBookmarks = allNotesAllRevisions && allNotesAllRevisions.filter(y => y.revision === +this.props.match.params.revision).sort((a, b) => a.htmlOrder - b.htmlOrder);
        const visibleBookmarks = currentRevisionBookmarks && currentRevisionBookmarks.filter(y => !this.state.notesOnly || (this.state.notesOnly && y.customText));
        const otherRevisionBookmarks = allNotesAllRevisions && allNotesAllRevisions.filter(y => y.revision !== +this.props.match.params.revision && y.revision < +this.props.match.params.revision).sort((a, b) => b.lastValidInRevision - a.lastValidInRevision);
        const topLevelBookmarkNotPointingToCurrentDocument = allNotesAllRevisions && allNotesAllRevisions.filter(y => y.revision !== +this.props.match.params.revision && !y.bookmarkedId).length === 0 ? false : true;
        const firstOtherRevisionBookmark = otherRevisionBookmarks && otherRevisionBookmarks.length > 0 ? otherRevisionBookmarks[0] : null;
        if (this.props.userNotes.notes && this.props.userNotes.notes !== this.state.allBookmarks) {
            this.setState(s => ({ ...s, visibleBookmarks: visibleBookmarks, allBookmarksThisRevision: currentRevisionBookmarks, olderRevisionWithBookmark: firstOtherRevisionBookmark, allBookmarks: this.props.userNotes.notes, topLevelBookmarkNotPointingToCurrentDocument: topLevelBookmarkNotPointingToCurrentDocument }));
        }
    }
    updatePrevNextState = (prevId: string, prevElementId: string, nextId: string, nextElementId: string) => {
        if (this.state.prevBookmarkId !== prevId || this.state.nextBookmarkId !== nextId) {
            this.setState((s) => ({ ...s, prevBookmarkId: prevId, prevElementId: prevElementId, nextBookmarkId: nextId, nextElementId: nextElementId }));
        }
    }

    prevClicked = () => {
        if (this.state.prevBookmarkId) {
            this.props.highlightBookmark(this.state.prevBookmarkId);
            scrolltoId(this.state.prevElementId);
        }

    }
    nextClicked = () => {
        if (this.state.nextBookmarkId) {
            this.props.highlightBookmark(this.state.nextBookmarkId);
            scrolltoId(this.state.nextElementId);
        }
    }

    detectPrevNextBookmark = () => {
        const bookmarksToDisplay = this.state.visibleBookmarks;
        if (bookmarksToDisplay && bookmarksToDisplay.length > 0) { // If we have some bookmarks at all
            if (this.props.userNotes.highlightBookmark) {
                const currentNote = bookmarksToDisplay.find(x => x.bookmarkId === this.props.userNotes.highlightBookmark);
                if (currentNote) {
                    const currentNoteIndex = bookmarksToDisplay.indexOf(currentNote);
                    let prevId: string = '';
                    let nextId: string = '';
                    let prevElementId: string = '';
                    let nextElementId: string = '';
                    if (currentNoteIndex - 1 >= 0) {
                        prevId = bookmarksToDisplay[currentNoteIndex - 1].bookmarkId;
                        prevElementId = bookmarksToDisplay[currentNoteIndex - 1].bookmarkedId || '';
                    }
                    if (currentNoteIndex + 1 < bookmarksToDisplay.length) {
                        nextId = bookmarksToDisplay[currentNoteIndex + 1].bookmarkId;
                        nextElementId = bookmarksToDisplay[currentNoteIndex + 1].bookmarkedId || '';
                    }
                    this.updatePrevNextState(prevId, prevElementId, nextId, nextElementId);


                } else {
                    this.updatePrevNextState('', '', bookmarksToDisplay[0].bookmarkId, bookmarksToDisplay[0].bookmarkedId || '');
                }
            } else {
                this.updatePrevNextState('', '', bookmarksToDisplay[0].bookmarkId, bookmarksToDisplay[0].bookmarkedId || '');
            }
        } else { //No bookmarks to display
            this.updatePrevNextState('', '', '', '');
        }
    }
    bookmarkClick = (boomark: service.IBookmarkDto, isOpen: boolean | undefined) => {
        if (isOpen) {
            this.props.highlightBookmark('');
        } else {
            this.props.highlightBookmark(boomark.bookmarkId);
            scrolltoId(boomark.bookmarkedId || '');
        }

    }
    meatballMenuClick = () => {
        if (this.state.olderRevisionWithBookmark) {
            this.setState(s => ({ ...s, meatballMenuOpen: !s.meatballMenuOpen }))
        }

    }
    closeMeatballMenu = () => {
        this.setState(s => ({ ...s, meatballMenuOpen: false }))
    }
    showCopyBookmarkDialog = (e: React.MouseEvent) => {
        this.setState(s => ({ ...s, meatballMenuOpen: false, copyBookmarkDialogShow: true }));
        e.stopPropagation();
    }

    dismissCopyBookmarkDialog = () => this.setState(s => ({ ...s, copyBookmarkDialogShow: false }));


    render() {
        const prevEditionBookmarkLabel = this.state.olderRevisionWithBookmark ? editionAndAmendedLongStrings(this.state.olderRevisionWithBookmark.edition, this.state.olderRevisionWithBookmark.amended) : "";
        if (this.props.userNotes.notes && this.props.userNotes.notes.length === 0)
            return <div style={{overflow:"hidden auto", height:"49vh"}}><TutorialBookmarks /></div>
        if (this.state.olderRevisionWithBookmark && (!this.state.allBookmarksThisRevision || this.state.allBookmarksThisRevision.length === 0) && this.props.currentlyViewing.document.isCurrent && this.props.currentlyViewing.document.withdrawn === service.WithdrawnType.None)
            return (<InformationBox>
                <div>
                    <div  style={{ fontFamily: "Avenir Demi", fontSize: "16px", color: "#333333", paddingBottom:"8px" }}>{tooltips.rightNavigationBookmarksNewEditionTitle}</div>
                    <div style={{ paddingBottom:"8px" }}>{tooltips.rightNavigationBookmarksNewEdition.replace("#PREV", prevEditionBookmarkLabel)}</div>
                    <div style={{ display: "flex", justifyContent: "flex-end", padding: "12px" }}><SubtleButton data-cy="copy_bookmarks" style={{ marginLeft: "8px"}} onClick={() => this.copyToCurrentRevision(this.state.olderRevisionWithBookmark ? this.state.olderRevisionWithBookmark.revision : 0)} >Copy</SubtleButton>
                    </div>
                </div>
            </InformationBox>)

        const revisionMarkedAsCurrent = this.props.allRevisions.find(x => x.document.isCurrent);

        const brokenBookmarks = this.state.visibleBookmarks && this.state.visibleBookmarks.filter(x => x.htmlOrder === 0);
        return (
            <div data-cy="documentbookmarks" >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "0.5rem" }}>
                    <span style={{ display: "flex", alignItems: "center" }}><StyledNotesOnlyTitleSpan >Notes only</StyledNotesOnlyTitleSpan>{!this.state.notesOnly ? <ToggleOffIcon onClick={this.notesOnlyToogle} /> : <ToggleOnIcon onClick={this.notesOnlyToogle} />}</span>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <StyledMenuItemButton onClick={this.prevClicked} >
                            <ChevronIcon direction="Up" title={tooltips.rightNavigationBookmarksPrev} expanded={true} />
                        </StyledMenuItemButton>
                        <StyledMenuItemButton onClick={this.nextClicked} >
                            <ChevronIcon direction="Down" title={tooltips.rightNavigationBookmarksNext} expanded={true} />
                        </StyledMenuItemButton>
                        <StyledVerticalDivider />
                        <span style={{ display: "inline-block" }}>{this.state.olderRevisionWithBookmark ? <StyledMenuItemButton style={{ position: "relative" }} onClick={this.meatballMenuClick}><EllipsisHIcon style={{ width: "50%" }} />
                            {this.state.meatballMenuOpen && <StyledMenuShadowed onMouseLeave={this.closeMeatballMenu} style={{ right: "0px", top: "40px" }}><StyledMenuAction onClick={this.showCopyBookmarkDialog} style={{ whiteSpace: "nowrap" }}>Copy bookmarks from previous edition</StyledMenuAction></StyledMenuShadowed>}
                        </StyledMenuItemButton> :
                            <StyledMenuItemButton style={{ position: "relative", pointerEvents: "none" }} onClick={this.meatballMenuClick}><EllipsisHIcon style={{ width: "50%", fill: "#ccc" }} />
                                {this.state.meatballMenuOpen && <StyledMenuShadowed style={{ right: "0px", top: "40px" }}><StyledMenuAction onClick={this.showCopyBookmarkDialog} style={{ whiteSpace: "nowrap" }}>Copy bookmarks from previous edition</StyledMenuAction></StyledMenuShadowed>}
                            </StyledMenuItemButton>}
                        </span>

                    </div>
                </div>
                {this.props.currentlyViewing && this.props.currentlyViewing.document.documentCode
                    && (this.props.currentlyViewing.document.documentCode.indexOf("WPI") > 0 || this.props.currentlyViewing.document.documentCode.indexOf("ITG") > 0)
                    &&
                    <InformationBox>
                        When a new edition of this document is published, your bookmarks and notes from this edition will be transferred to the new edition.
                </InformationBox>}
                {this.state.copyBookmarkDialogShow && <InformationBox>
                    <div style={{ paddingRight: "8px" }}>
                        <div style={{ fontFamily: "Avenir Demi", fontSize: "16px", color: "#333333", paddingBottom:"8px" }}>Copy bookmarks from the {prevEditionBookmarkLabel} edition </div>
                        <div >Only one bookmark or note can be linked to each content element. Bookmarks and notes already added to this edition will be prioritized. No bookmarks or notes will be removed from the {prevEditionBookmarkLabel} edition
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingTop:"8px" }}>
                                <StyledLinkButton onClick={this.dismissCopyBookmarkDialog} style={{ marginRight: "20px" }}>Cancel</StyledLinkButton><SubtleButton onClick={() => this.copyToCurrentRevision(this.state.olderRevisionWithBookmark ? this.state.olderRevisionWithBookmark.revision : 0)}>Copy</SubtleButton>
                            </div>
                        </div>
                    </div>
                    <div>
                        <CrossIcon style={{ fill: "#999", height: "1rem", width: "0.625rem", cursor: "pointer" }} onClick={this.dismissCopyBookmarkDialog} />
                    </div>
                </InformationBox>}
                <div id="documentBookmarkDiv" style={{ overflowY: "auto", fontSize: "0.75rem", height: "50vh", overflowX: "hidden" }}>
                    {this.props.outOfDateIndicatorsWithBookmarks && !this.props.currentlyViewing.document.isCurrent && revisionMarkedAsCurrent && this.props.document &&
                        <div style={{ marginTop: "0.5em" }}>
                            <InformationBox>
                                <div style={{ display: "flex" }}>
                                    <div style={{ fontSize: "14px" }}>{revisionMarkedAsCurrent && revisionMarkedAsCurrent.document.withdrawn !== service.WithdrawnType.None ? tooltips.rightNavigationBookmarksNewerEditionReplacedRetired : tooltips.rightNavigationBookmarksNotSeenNewEdition}</div>
                                    <div style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}> <CrossIcon data-cy="dismiss_notification" style={{ height: "16px", width: "11px", fill: "#999999" }} onClick={() => this.props.updateLastSeen(this.props.currentlyViewing.document.configId || '', revisionMarkedAsCurrent.document.revision)} />
                                    </div>
                                </div>
                            </InformationBox>
                        </div>
                    }
                    {this.state.allBookmarksThisRevision && this.state.allBookmarksThisRevision.length === 0 && <div style={{ textAlign: "center", width: "100%", marginTop: "1em" }}>
                        {revisionMarkedAsCurrent && this.props.document && revisionMarkedAsCurrent.document.revision === this.props.document.revision && revisionMarkedAsCurrent.document.withdrawn !== service.WithdrawnType.None ? tooltips.rightNavigationBookmarksRetiredOrReplaced : <><BookmarkIcon style={{ stroke: "#999999", strokeWidth: "34", fill: "transparent" }} /><> {tooltips.rightNavigationBookmarksEmpty}</></>}</div>}
                    {this.state.visibleBookmarks && this.state.visibleBookmarks.filter(x => x.htmlOrder > 0).map(bookmark => <DocumentMapBookmark key={bookmark.bookmarkId} bookmark={bookmark} />)}
                    {brokenBookmarks && brokenBookmarks.length > 0 && <>
                        <div style={{ marginBottom: "1rem" }}>
                            <div style={{ fontFamily: "Avenir Demi", fontSize: "14px", marginBottom: "4px" }}>Broken references</div>
                            <InformationBox>{tooltips.rightNavigationBookmarksBroken}</InformationBox>
                        </div>
                        {
                            Array.from(groupCommentsByLastValidInRevision(brokenBookmarks))
                                .map(([revision, entries]) => {
                                    return <React.Fragment key={editionAndAmendedLongStrings(entries[0].edition, entries[0].amended)}><div style={{ width: "100%", display: "flex", whiteSpace: "nowrap", justifyContent: "center", alignItems: "center" }}>
                                        {editionAndAmendedLongStrings(entries[0].edition, entries[0].amended)}<span style={{ display: "inline-block", height: "1px", marginLeft: "0.5rem", width: "100%", backgroundColor: "#ccc" }}></span></div>{entries.sort((a, b) => a.globalOrder - b.globalOrder).map(bookmark => <DocumentMapBookmark key={bookmark.bookmarkId} bookmark={bookmark} />)}</React.Fragment>;
                                })}
                    </>}
                </div>
            </div>)
    }

}
const provider = provide((state) => ({
    userNotes: state.userNotes,
    document: state.document.document,
    userNoteHighlighted: state.userNotes.highlightBookmark

}), { ...userNotesActions }).withExternalProps<{ outOfDateIndicatorsWithBookmarks: service.ILastRevisionForUserIndicatorDto | undefined, allRevisions: DocumentCardElement[], currentlyViewing: DocumentCardElement }>();

type Props = typeof provider.allProps;
export const DocumentMapBookmarks = provider.connect(withRouter(DocumentMapBookmarksComponent));