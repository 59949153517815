import React from 'react';
import {ReactComponent as SortArrow} from '../../images/sort-arrow.svg';
type SortIndicatorProps = {
    direction:"Descending"|"Ascending",
    enabled:boolean,

  
}
export const SortIndicator:React.FC<SortIndicatorProps& React.HTMLAttributes<HTMLDivElement>>= (props)=>{
    const {direction,onClick,enabled,...restProps} = props;

return <span onClick={onClick} {...restProps} style={{display:"flex",alignItems:"center" }} ><SortArrow style={{width:"10px", height:"15px",fill: props.enabled && props.direction==="Ascending"?"black":"#e5e5e5"}} /> <SortArrow style={{width:"10px", height:"15px", transform:"rotate(180deg)",fill: props.enabled && props.direction==="Descending"?"black":"#e5e5e5"}} /></span>
}