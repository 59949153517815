import styled from 'styled-components/macro';
const StyledMenuItemBase = styled.div`   
    padding-top: ${({ theme }) =>  theme.padding.large};
    padding-bottom:  ${({ theme }) =>  theme.padding.large};
    display: flex;  
    flex-direction: column;
    align-items: center;
    cursor: pointer;    
`;
// export const StyledMenuItemToggleDiv = styled(StyledMenuItemBase)`   
//    &:hover{
//     background-color: ${({ theme }) =>  theme.colors.background.skyebluelightest};
//    } 
// `;

export const StyledMenuItemDiv = styled(StyledMenuItemBase)<{isActive: boolean, activeBorderOrientation:"left"|"bottom"}>`   
   
    ${({ isActive,theme }) => isActive  && `background-color:` + theme.colors.background.skyebluelightest};
    ${({ isActive,theme,activeBorderOrientation }) => isActive  && `border-` + activeBorderOrientation + `:` + theme.borderWidth.medium + ` solid ` + theme.colors.border.darkblue};  
    &:hover{
    background-color: ${({ theme }) =>  theme.colors.background.neutral05};
   }  
`;
