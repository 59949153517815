import styled from 'styled-components/macro';

export const StyledMenu = styled.div`
position:absolute;
padding-left:28px;
padding-right:28px;
background:white;
border: 1px solid #ccc; 
color: #0f204b;
padding-bottom:0.5rem;
padding-top: 0.5rem; 
`;

export const StyledMenuShadowed = styled(StyledMenu)`
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
`

export const StyledMenuHeader = styled.div`
    text-transform: uppercase;            
    font-family:${({ theme }) => theme.fonts.AvenirNextMedium}; 
    ${({ theme }) => theme.fonts.sizes.small};   
`;

export const StyledMenuName = styled.div`
font-family:${({ theme }) => theme.fonts.family}; 
font-size: ${({ theme }) => theme.fonts.sizes.large};
padding-bottom:0.25rem;
padding-top: 0.25rem; 
`;

export const StyledMenuDivider = styled.div`
background-color: ${({ theme }) => theme.colors.background.darkblue};
height:2px;
margin-left:-28px;
margin-right:-28px;
margin-top:5px;
margin-bottom:5px;
`;
export const StyledMenuAction = styled.div`

font-family:${({ theme }) => theme.fonts.family}; 
font-size:${({ theme }) => theme.fonts.sizes.large};
color:${({ theme }) => theme.colors.text.neutral80};
margin-left:-28px;
margin-right:-28px;
padding-left:28px;
padding-right:28px;
cursor:pointer;
&:hover{
    background-color: ${({ theme }) => theme.colors.background.neutral05};
}
`;