import { Console } from 'console';
import { useSelector } from 'react-redux';
import { ICategory, IDocument, Audience, Category } from '../../../api/SmartLibraryService';
import { ISearchContext } from '../../../store/reducers/searchReducer';
import { DocumentCardElement } from '../DocumentCard';

export type FilteredCategory = {
    category: ICategory,
    visibleDocuments: IDocument[];
    subCategoryDocuments: IDocument[];
    filteredCategories: FilteredCategory[];
}

export function FilterCategory (category: ICategory, validDocuments: Set<string>, filterState:any, searchResult:ISearchContext){
    const filtered: FilteredCategory = { category: category, visibleDocuments: [], subCategoryDocuments: [], filteredCategories: []};

    if (category.documents) {

        if(filterState.isFiltered){

            let newArr :IDocument[] =[] ;
            let newArray = category.documents.filter(doc => doc.issued != undefined && filterState.issuedDate != null ? parseInt(doc.issued) <= parseInt(filterState.issuedDate) || doc.documentCode?.includes("WPI") || doc.documentCode?.includes("ITG") : '');
            newArray.forEach((doc, index) => {
                let result = newArr.filter(x => x.documentCode === doc.documentCode)
                if (result.length == 0) {
                    newArr.push(doc);
                }
            });

            // var combineDoc = [ ...newArr,...internalDoc]
            // console.log("filDocMenu", combineDoc);

            filtered.visibleDocuments = newArr;
        }
        if(filterState.isFiltered && searchResult.query !=null && searchResult.searchResult){
            let newArr :IDocument[] =[] ;
            let newArray = category.documents.filter(doc => doc.issued != undefined && filterState.issuedDate != null ? parseInt(doc.issued) <= parseInt(filterState.issuedDate) || doc.documentCode?.includes("WPI") || doc.documentCode?.includes("ITG") : '');
            newArray.forEach((doc, index) => {
                let result = newArr.filter(x => x.documentCode === doc.documentCode)
                if (result.length == 0) {
                    newArr.push(doc);
                }
            });

            // let internalDoc = category.documents.filter(doc => doc.documentCode?.includes("WPI") || doc.documentCode?.includes("ITG"));
            // var docList = [...newArr, ...internalDoc]
            // filtered.visibleDocuments = docList.filter(doc=>validDocuments.has(doc.documentCode || 'NODOC'));
            
            filtered.visibleDocuments = newArr.filter(doc=>validDocuments.has(doc.documentCode || 'NODOC'));
        }
        if(!filterState.isFiltered){
            filtered.visibleDocuments = category.documents.filter(document => validDocuments.has(document.documentCode || 'NODOC') && document.isCurrent);
        }
    }
    
    if (category.subCategories) {
        filtered.filteredCategories = category.subCategories.map(x => FilterCategory(x, validDocuments, filterState, searchResult));
    }
    filtered.subCategoryDocuments = filtered.visibleDocuments.concat(filtered.filteredCategories.flatMap(subCategory => subCategory.subCategoryDocuments));
    return filtered;
};