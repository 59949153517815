import { ThunkAction } from "redux-thunk";
import { IRootState } from "../store";
import * as service from '../../api/SmartLibraryService';
interface ILoadNews { type: "LOAD_NEWS"; }
interface ILoadNewsComplete { type: "LOAD_NEWS_COMPLETE"; payload:service.INews }
interface ILoadNewsFailed { type: "LOAD_NEWS_FAILED"; payload:{error:string} }
export type NewsActions = ILoadNews | ILoadNewsComplete | ILoadNewsFailed;
type TA = ThunkAction<void, IRootState, void, NewsActions>;

export const newsActions = {
    requestNews: (): TA => async (dispatch)  => {
        dispatch({
            type: "LOAD_NEWS"
        });
        const client = new service.SmartLibraryClient(process.env.PUBLIC_URL)

        await client.getNews()
        .then(x => {         
          if (x != null) {
            dispatch({ type: "LOAD_NEWS_COMPLETE", payload: x })
          }else{
            dispatch({ type: "LOAD_NEWS_FAILED", payload:{error:"No news returned"}})
          }          
        },
          (x:string) => {
            dispatch({ type: "LOAD_NEWS_FAILED", payload:{error:x}})           
          }
        );

    }
}