import * as React from 'react';
import { provide } from "../../../store/provide";
import * as service from '../../../api/SmartLibraryService';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { flattenDocsInCategories } from '../../../utils/documentHelpers'
import { scrolltoId } from '../../../utils/scroll';
import { highlightActions } from "../../../store/actions/highlightActions"
import { scrolltoElement, scrollToCorrection, scrollParentToChild } from '../../../utils/scroll';
import { NoteIcon } from '../../Icons/NoteIcon';
import { IDocumentRouteProps } from '../../../utils/routeProps/DocumentRouteProps';
import styled from 'styled-components/macro';
import { globalsearchActions } from '../../../store/actions/globalsearchActions';
import { userSettingsActions } from '../../../store/actions/userSettingsActions';

import { DocumentCardElement } from '../../Documents/DocumentCard';
import { tocActions } from '../../../store/actions/tocActions';


const StyledSubsec1ContainerDiv = styled.div<{ iscurrentlyviewing: boolean, hashighlight: boolean }>`
    margin-right: 0.9375rem;
    white-space: nowrap;
    margin-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme, iscurrentlyviewing }) => iscurrentlyviewing ? theme.colors.background.skyebluelightest : ""};
    font-weight:${({ theme, hashighlight }) => hashighlight ? theme.fonts.weights.bold : "unset"}; 
    &:hover{
        text-decoration:underline;
    }   
`;

const StyledStartContainerDiv = styled.div`
    padding-top: ${({ theme }) => theme.padding.large}; 
`

const StyledStartTitleDiv = styled.span`
    font-weight: bolder;
    cursor: pointer;
    &:hover{
        text-decoration:underline;
    }   
`

const StyledSelectedIndicator = styled.div<{ iscurrentlyviewing: boolean }>`
 background:  ${({ theme, iscurrentlyviewing }) => iscurrentlyviewing ? theme.colors.background.darkblue : theme.colors.background.transparent};
 height: 1rem;
 margin-top: 1px;
 margin-bottom: 1px;
 margin-right: 0.3rem;
 display: inline-block;
 width:0.25rem;

`



type SectionLocation = {
    top: number;
    height: number;
    element: Element;
}

type DocumentMapLink = {
    id: string;
    element: Element;
}
type DocumentMapState = {
    sectionLocations: SectionLocation[] | null;
    documentMapLinks: DocumentMapLink[] | null;
    currentHighlighted: string | null;
    documentNavigationDiv: Element | null;
    scrollBasedOnClick: boolean;
    configId: string,
    revision: number,
    entireHeight: number
}

class DocumentMapTableOfContentsComponent extends React.Component<Props & RouteComponentProps<IDocumentRouteProps>, DocumentMapState> {
    highlightNav = 1;
    state: DocumentMapState = { sectionLocations: null, documentMapLinks: null, currentHighlighted: "", documentNavigationDiv: null, scrollBasedOnClick: false, configId: "", revision: 0, entireHeight: 0 };
    componentDidMount() {
        this.getTargetPositions();

        window.addEventListener('scroll', this.handleScroll, { passive: true });
        this.highlightNav = 1;
    }
    componentDidUpdate() {

        this.getTargetPositions();
        this.highlightNav = 1;
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }
    last_known_scroll_position = 0;
    ticking = false;
    findCurrentSection = (scroll_pos: number) => {
        if (this.state.sectionLocations != null) {
            const allabove = this.state.sectionLocations.filter((x: SectionLocation) => x.top <= scroll_pos);
            if (allabove.length === 0) {
                const section: SectionLocation = this.state.sectionLocations[0];
                return section;

            } else {
                const section: SectionLocation = allabove[allabove.length - 1];
                return section;
            }
        }
    }


    getMapLink = (id: string) => {
        if (this.state.documentMapLinks != null) {
            const links = this.state.documentMapLinks.filter(x => x.id === id);
            if (links.length > 0) {
                return links[0].element;
            }
        }
        return null;

    }
    highlightCurrentSection = (scroll_pos: number) => {
        const currentSection = this.findCurrentSection(scroll_pos);
        if (currentSection != null) {
            if (this.state != null && this.state.currentHighlighted !== currentSection.element.id) {
                const childElement = this.getMapLink(currentSection.element.id);
                if (childElement != null && this.state.documentNavigationDiv != null) {
                    if (!this.state.scrollBasedOnClick) {
                        scrollParentToChild(this.state.documentNavigationDiv, childElement);
                    }
                }
                this.setState({ currentHighlighted: currentSection.element.id })
            }
        }
    }
    scrollTimeout: any;
    handleScroll = () => {
        this.last_known_scroll_position = window.pageYOffset;

        if (!this.ticking) {
            window.requestAnimationFrame(() => {
                this.highlightCurrentSection(this.last_known_scroll_position);
                this.ticking = false;
                window.clearTimeout(this.scrollTimeout);
                this.scrollTimeout = setTimeout(() => {
                    this.setState({ scrollBasedOnClick: false });
                }, 100);
            });

            this.ticking = true;
        }
    }
    scrollToHighlight = (e: React.MouseEvent, index: number) => {

        scrolltoElement(document.querySelectorAll(".rs-highlight-search-term")[index - 1]);
        this.props.setHighlightIndex(index - 1);
        e.stopPropagation();
    }


    otherSectionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        window.scrollTo({top:document.body.offsetHeight, left: 0 , behavior: 'smooth'});
    }

    handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, toc: service.Toc) => {
            const scrollsection = scrolltoId(e.currentTarget.getAttribute('data-target')|| 'X');
            if(scrollsection){
                this.setState({ scrollBasedOnClick: true });
                scrolltoId(e.currentTarget.getAttribute('data-target') || 'X');
                e.stopPropagation();
            }
            else{
                window.scrollTo({top:document.documentElement.scrollHeight, left: 500 , behavior: 'smooth'});
            }
        
        
    }
    getTargetPositions = () => {
        if (this.props.documentMounted && (this.state.sectionLocations == null || (this.props.document.document && (this.props.document.document.configId !== this.state.configId || this.props.document.document.revision !== +this.state.revision || this.state.entireHeight !== document.documentElement.scrollHeight)))) {
            const sectionMap: SectionLocation[] = [];
            const documentSections = document.querySelectorAll(".documentViewer .subsec1");
            for (var index = 0; index < documentSections.length; index++) {
                const boundingRect = documentSections[index].getBoundingClientRect();
                sectionMap.push({ top: Math.floor(boundingRect.top + scrollToCorrection + window.scrollY), height: boundingRect.height, element: documentSections[index] });
            }
            const documentMapElements = document.querySelectorAll(".documentNavigation .subsec1");
            const mapArray: DocumentMapLink[] = []
            for (var index2 = 0; index2 < documentMapElements.length; index2++) {
                const attr = documentMapElements[index2].attributes.getNamedItem("data-target");
                // console.log(attr);
                if (attr != null) {
                    mapArray.push({ id: attr.value, element: documentMapElements[index2] })
                }

            }
            const documentNavigationDiv = document.querySelector("#documentNavigationDiv");
            this.setState(s => ({ sectionLocations: sectionMap, documentMapLinks: mapArray, documentNavigationDiv: documentNavigationDiv, configId: this.props.document.document ? this.props.document.document.configId + "" : "", revision: this.props.document.document ? +this.props.document.document.revision : 0, entireHeight: document.documentElement.scrollHeight }));
        }


    }
    displayDebugTocLocation = (toc: service.Toc) => {
        if (this.state.sectionLocations) {
            var els = this.state.sectionLocations.filter((x: SectionLocation) => x.element.id === toc.guid);
            if (els.length === 1) {
                return els[0].top + "-" + els[0].height + "-" + this.last_known_scroll_position;
            }
        }
        return "NO DATA";
    }
    displayPercentTocLocation = (toc: service.Toc, isActive: boolean) => {
        if (this.state.sectionLocations && isActive) {
            var els = this.state.sectionLocations.filter((x: SectionLocation) => x.element.id === toc.guid);
            if (els.length === 1) {
                return " - " + Math.round((this.last_known_scroll_position - els[0].top) / els[0].height * 100) + "%";
            }
        }
        return "";
    }

    getNode = (path: string): Element | Node | null => {
        const pathArray = path.split("|");
        // console.log(pathArray);
        const theNode = pathArray.reduce<Element | Node | null>((prev, current) => {
            if (prev == null) {
                return document.querySelector("#" + current);
            }
            if (Number.isInteger(+current)) {
                const childIndex = +current as number;
                // console.log(childIndex);
                const theElement = prev.childNodes[childIndex];
                // console.log(theElement);
                return theElement as Node;
            } else if ((prev as Element)) {
                return (prev as Element).querySelector("#" + current);
            }

            return null;
        }, null);


        return theNode;
    }

    renderNote = (comment: service.IBookmarkDto | undefined) => {
        if (comment) {

            return <NoteIcon onClick={(e) => { e.stopPropagation(); }} style={{ height: "1rem" }} />;
        }
    }

    isChangesStart = (toc: service.Toc) => {
        return toc.elementName === "currentchangesstart" || toc.elementName === "historicchangesstart";
    }

    //TODO: BOOKMARKS comments:Map<string,service.IBookmark>,
    renderToc = (toc: service.Toc, disableNumbering: boolean): any => {
        const currentFirstHighlight = this.highlightNav;
        const isCurrentlyViewing = (toc.elementName_Guid || 'X') === (this.state.currentHighlighted || "");
        const higlightedSections = this.props.document && this.props.document.document && this.props.document.document.highlightedSections;
        let hasHighlight = this.props.showHitCount && higlightedSections && higlightedSections.find(y => y.key != null ? y.key === toc.elementName_Guid : 'X');
        const hits = hasHighlight && hasHighlight.value;
        this.highlightNav += hits ? hits : 0;
        if (this.isChangesStart(toc)) {
            disableNumbering = true;
        }
        let trailUser;
        if(this.props.userSetting.settings?.trial){
            const endDate = new Date(this.props.userSetting.settings.trialEndDateTime);
            const todaysDate = new Date();
            var date = endDate.getDate();
            var month = endDate.getMonth() + 1;
            var year = endDate.getFullYear();
    
            var Tdate = todaysDate.getDate();
            var Tmonth = todaysDate.getMonth() + 1;
            var Tyear = todaysDate.getFullYear();
    
            const formatedEndDate = new Date(year + '/' + month + '/' + date);
            const formatedTodayDate = new Date(Tyear + '/' + Tmonth + '/' + Tdate);
            if (formatedEndDate >= formatedTodayDate) {
                const oneDay = 1000 * 60 * 60 * 24;                
                const daysLeft = Math.round(Number(formatedEndDate) - Number(formatedTodayDate)) / oneDay;
                // const daysLeft = Math.round((endDate.getDate() - todaysDate.getDate()));
                if(daysLeft>=0){
                    trailUser = true;
                }
                else{
                    trailUser = false;
                }
            }
        }

        if(!this.props.documentce.document.requiredVeracitySubscriptions || this.props.userSetting.settings?.subscription || trailUser || this.props.userSetting.settings?.internal ){
            if (toc.elementName && ["currentchangesstart", "sectionstart", "appendixstart", "historicchangesstart", "chaptermap"].indexOf(toc.elementName) > -1) { //"frontstart",
                return (<StyledStartContainerDiv key={toc.guid}>
                    {/* {isCurrentlyViewing && <SelectedIndicator style={{ height: "0.5rem" }} />} */}
                    <div onClick={e => this.handleClick(e, toc)} data-target={toc.elementName_Guid}>
                        <StyledStartTitleDiv className="title" >{!disableNumbering && toc.counterFormatted} {toc.title}</StyledStartTitleDiv>
                        {/* {this.displayPercentTocLocation(toc, isCurrentlyViewing)} */}
                        {toc.tocs && toc.elementName !== "historicchangesstart" && toc.tocs.map((x, index) => this.renderToc(x, disableNumbering))}
                    </div>
                </StyledStartContainerDiv>)
            } else if (toc.elementName && toc.elementName === "subsec1") {
                return <StyledSubsec1ContainerDiv key={toc.guid} onClick={e => this.handleClick(e, toc)} data-target={toc.elementName_Guid} iscurrentlyviewing={isCurrentlyViewing} hashighlight={!!hasHighlight} >
                    <span style={{ display: "flex" }}>{<StyledSelectedIndicator iscurrentlyviewing={isCurrentlyViewing} />}
                        {!disableNumbering && toc.counterFormatted} {toc.title}</span>
                    <span>
                        {/* TODO: BOOKMARKS {toc.guid != null && comments.has(toc.guid) && this.renderNote(comments.get(toc.guid))} */}
                        <span data-cy="sectionhits" onClick={e => this.scrollToHighlight(e, currentFirstHighlight)}>{hasHighlight && hits} </span>
                    </span>
                </StyledSubsec1ContainerDiv>
            }
        }
        else{
                
                if (toc.counterFormatted=="Current changes 1" || toc.counterFormatted=="Section 1" && toc.elementName && ["currentchangesstart", "sectionstart", "appendixstart", "historicchangesstart", "chaptermap"].indexOf(toc.elementName) > -1) { //"frontstart",
                    return (<StyledStartContainerDiv key={toc.guid}>
                        {/* {isCurrentlyViewing && <SelectedIndicator style={{ height: "0.5rem" }} />} */}
                        <div onClick={e => this.handleClick(e, toc)} data-target={toc.elementName_Guid}>
                            <StyledStartTitleDiv className="title" >{!disableNumbering && toc.counterFormatted} {toc.title}</StyledStartTitleDiv>
                            {/* {this.displayPercentTocLocation(toc, isCurrentlyViewing)} */}
                            {toc.tocs && toc.elementName !== "historicchangesstart"  && toc.tocs.map((x, index) => this.renderToc(x, disableNumbering))}
                        </div>
                    </StyledStartContainerDiv>)
                } else if (toc.elementName && toc.elementName === "subsec1") {
                    return <StyledSubsec1ContainerDiv key={toc.guid} onClick={e => this.handleClick(e, toc)} data-target={toc.elementName_Guid} iscurrentlyviewing={isCurrentlyViewing} hashighlight={!!hasHighlight} >
                        <span style={{ display: "flex" }}>{<StyledSelectedIndicator iscurrentlyviewing={isCurrentlyViewing} />}
                            {!disableNumbering && toc.counterFormatted} {toc.title}</span>
                        <span>
                            {/* TODO: BOOKMARKS {toc.guid != null && comments.has(toc.guid) && this.renderNote(comments.get(toc.guid))} */}
                            <span data-cy="sectionhits" onClick={e => this.scrollToHighlight(e, currentFirstHighlight)}>{hasHighlight && hits} </span>
                        </span>
                    </StyledSubsec1ContainerDiv>
                }
                else{
                    if (toc.elementName && ["currentchangesstart", "sectionstart", "appendixstart", "historicchangesstart", "chaptermap"].indexOf(toc.elementName) > -1) { //"frontstart",
                    return (<StyledStartContainerDiv key={toc.guid} style={{opacity:"50%"}}>
                        {/* {isCurrentlyViewing && <SelectedIndicator style={{ height: "0.5rem" }} />} */}
                        <div onClick={e => this.handleClick(e, toc)} data-target={toc.elementName_Guid} style={{opacity:"50%"}}>
                            <StyledStartTitleDiv className="title" >{!disableNumbering && toc.counterFormatted} {toc.title}</StyledStartTitleDiv>
                            {/* {this.displayPercentTocLocation(toc, isCurrentlyViewing)} */}
                            {toc.tocs && toc.elementName !== "historicchangesstart"  && toc.tocs.map((x, index) => this.renderToc(x, disableNumbering))}
                        </div>
                    </StyledStartContainerDiv>)
                    }
                }
  
        } 
            
        

    }

    render() {
        const isGlobSer = this.props.globalsearch.compVisible;
        const allDocuments = flattenDocsInCategories(this.props.toc);
        const currentdocuments = allDocuments.filter(x => x.document.configId === this.props.match.params.configId && x.document.revision === +this.props.match.params.revision);
        if (currentdocuments.length === 0) {
            return <></>;
        }
        return (<>

            <div id="documentNavigationDiv" data-cy="documentnavigation" className="documentNavigation" style={isGlobSer?{overflowY: "auto", fontSize: "0.75rem", height: "32vh", overflowX: "hidden" }:{ overflowY: "auto", fontSize: "0.75rem", height: "49vh", overflowX: "hidden" }}>

                {this.props.document.document && this.props.document.document.toc && this.props.document.document.toc.map(x => this.renderToc(x, false))}

            </div>
        </>)
    }

}
const provider = provide((state) => ({
    documentMounted: state.document.mounted,
    searchWithinDocument: state.searchDocumentResult,
    showHitCount: state.highlight.enabled,
    searchResult: state.searchResult.searchResult,
    document: state.document,
    toc: state.toc.categories,
    userNotes: state.userNotes.notes,
    globalsearch: state.globalsearch,
    userSetting: state.userSettings

}), { ...highlightActions,...globalsearchActions, ...userSettingsActions }).withExternalProps<{documentce:DocumentCardElement}>();

type Props = typeof provider.allProps;
export const DocumentMapTableOfContents = provider.connect(withRouter(DocumentMapTableOfContentsComponent));